import React, { useEffect, useState } from "react";
import {
  editPreferences,
  getAllPreferences,
} from "../../../services/student/student-profile";
import { toast } from "react-toastify";
import {
  getAllParentCourse,
  getAllSubCourse,
  getColleges,
  getCountries,
  getIntakes,
} from "../../../services/career/career-service";
import EditPreference from "../../../Components/ProfileComponents/Preference/edit-preference";
import { useAuth } from "../../../Contexts/auth-context";

const Preferences = () => {
  const { user } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [preference, setPreference] = useState([]);
  const [intakes, setIntakes] = useState([{ preferred_intake: "" }]);
  const [country, setCountry] = useState([]);
  const [parentCourse, setParentCource] = useState([]);
  const [subCourse, setSubCourse] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [preferedCountry, setPreferedCountry] = useState([{ country_id: "" }]);
  const [preferedCourse, setPreferdCourse] = useState([
    {
      course_id: "",
      //  subCourse_id: ""
    },
  ]);
  const [preferedCollege, setPreferedCollege] = useState([{ college_id: "" }]);
  const [allInteks, setAllInteks] = useState([{ preferred_intake: "" }]);
  const [collegeConfidence, setCollegeConfidence] = useState(true);
  const [countryConfidence, setCountryConfidence] = useState(true);
  const [courseConfidence, setCourseConfidence] = useState(true);
  const addPreferenceData = async () => {
    let payload = {
      // id ? {student_id: id} :null
      country_id: preferedCountry?.map((x) => {
        let t = x.country_id;
        return t;
      }),

      college_id:
        user?.standard_alias && user?.standard_alias != "school"
          ? preferedCollege?.map((x) => {
              let t = x.college_id;

              return t;
            })
          : [],
      course_sub_department_id: preferedCourse
        ? preferedCourse.map((x) => {
            let t = parseInt(x.course_id);
            return t;
          })
        : [],
      preferred_intake:
        allInteks &&
        allInteks
          ?.map((x) => {
            let t = x.preferred_intake;
            return t;
          })
          .toString(),
      have_finalized_country: countryConfidence,
      have_finalized_course: courseConfidence,
      have_finalized_target_college: collegeConfidence,
    };

    await editPreferences(payload).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        toast.success(message);
        getAllPreference();
        setIsEdit(false);
      }
    });
  };
  useEffect(() => {
    if (preference) {
      setCountryConfidence(preference?.countries?.length ? true : false);
      setCollegeConfidence(preference?.colleges?.length ? true : false);
      setCourseConfidence(preference?.courses?.length ? true : false);
      setPreferedCountry(preference?.countries ? preference?.countries : [{}]);
      setPreferedCollege(preference?.colleges ? preference?.colleges : [{}]);
      let temp = preference?.courses?.map((c, i) => {
        let t = {
          course_id: c.course_id,
          // subCourse_id: c.course_id,
        };
        return t;
      });
      setPreferdCourse(temp ? temp : [{}]);
      setAllInteks([
        {
          preferred_intake: preference?.preferred_intake
            ? preference?.preferred_intake
            : [{}],
        },
      ]);
    }
  }, [preference]);
  const getAllPreference = async () => {
    await getAllPreferences().then((res) => {
      if (
        res &&
        res.data?.data?.colleges?.length === 0 &&
        res.data?.data?.countries?.length === 0 &&
        res.data?.data?.courses?.length === 0
      ) {
        setIsEdit(true);
      } else if (res && res.data?.data) {
        setPreference(res?.data?.data);
      }
    });
  };

  const getAllCountryes = async () => {
    await getCountries().then((res) => {
      if (res.data?.data?.records) {
        let temp = res.data?.data?.records.map((x) => {
          let payload = {
            id: x.id,
            name: x.name,
            alias: x.alias,
            logo: x.logo,
            flag: x.flag,
            phone_code: x.phone_code,
          };
          return payload;
        });
        if (temp.length) {
          setCountry(temp);
        }
      }
    });
  };

  const getAllCourse = async () => {
    await getAllParentCourse().then((res) => {
      if (res.data?.data) {
        setParentCource(res.data?.data);
      }
    });
  };
  const getSubCourse = async () => {
    await getAllSubCourse({ parent_id: null }).then((res) => {
      if (res.data?.data) {
        setSubCourse(res.data?.data);
      }
    });
  };

  const getAllCollege = async () => {
    await getColleges().then((res) => {
      if (res.data.data) {
        setColleges(res.data.data);
      }
    });
  };
  const getAllIntakes = async () => {
    await getIntakes().then((res) => {
      if (res.data?.data?.records) {
        setIntakes(res.data?.data?.records);
      }
    });
  };

  useEffect(() => {
    getAllPreference();
    getAllCountryes();
    getAllCourse();
    getAllCollege();
    getSubCourse();
    getAllIntakes();
  }, []);
  let finalizedCountry = preference?.countries?.find((x) => x.country_name);
  let finalizedCource = preference?.courses?.find((x) => x.course_name);
  let finalizedCollege = preference?.colleges?.find((x) => x.college_name);
  return (
    <div>
      {" "}
      <div class="preferences-detail tab-white-block">
        <p
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setIsEdit(!isEdit)}
          className="pencil-edit"
        ></p>
        {isEdit ? (
          <EditPreference
            setIsEdit={setIsEdit}
            preference={preference}
            intakes={intakes}
            addPreferenceData={addPreferenceData}
            country={country}
            parentCourse={parentCourse}
            subCourse={subCourse}
            colleges={colleges}
            allInteks={allInteks}
            setAllInteks={setAllInteks}
            preferedCountry={preferedCountry}
            setPreferedCountry={setPreferedCountry}
            preferedCourse={preferedCourse}
            setPreferdCourse={setPreferdCourse}
            preferedCollege={preferedCollege}
            setPreferedCollege={setPreferedCollege}
            collegeConfidence={collegeConfidence}
            setCollegeConfidence={setCollegeConfidence}
            countryConfidence={countryConfidence}
            setCountryConfidence={setCountryConfidence}
            getSubCourse={getSubCourse}
            courseConfidence={courseConfidence}
            setCourseConfidence={setCourseConfidence}
          />
        ) : (
          <>
            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-book"></div>
                <div
                  className="mb-1 d-flex flex-wrap justify-content-between"
                  style={{ width: "100%" }}
                >
                  <label className="font-20 font-500">Course Preference</label>
                  <span className="text-orange fst-italic font-14 font-500 pt-1">
                    {finalizedCource
                      ? null
                      : "Haven't finalized the courses yet"}
                  </span>
                </div>
              </div>
              <div class="customRow-detail">
                {preference?.courses?.map((x, i) => {
                  return (
                    <>
                      <div className="" key={i}>
                        <div className="feild d-flex">
                          <span className="name pe-3">
                            {x.course_name ? x.course_name : "-"}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-global"></div>
                <div
                  className="mb-1 d-flex flex-wrap justify-content-between"
                  style={{ width: "100%" }}
                >
                  <label className="font-20 font-500">Country Preference</label>
                  <span className="text-orange fst-italic font-14 font-500 pt-1">
                    {finalizedCountry
                      ? null
                      : "Haven't finalized the countries yet"}
                  </span>
                </div>
              </div>
              <div class="customRow-detail">
                {preference?.countries?.map((x, i) => {
                  return (
                    <>
                      <div className="" key={i}>
                        <div className="feild d-flex">
                          <span className="name pe-3">
                            {x.country_name ? x.country_name : "-"}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-university-college"></div>
                <div
                  className="mb-1 d-flex flex-wrap justify-content-between"
                  style={{ width: "100%" }}
                >
                  <label className="font-20 font-500">College Preference</label>
                  <span className="text-orange fst-italic font-14 font-500 pt-1">
                    {finalizedCollege
                      ? null
                      : "Haven't finalized the colleges yet"}
                  </span>
                </div>
              </div>
              <div class="customRow-detail">
                {preference?.colleges?.map((x, i) => {
                  return (
                    <>
                      <div className="" key={i}>
                        <div className="feild d-flex ">
                          <span className="name pe-3">
                            {x.college_name ? x.college_name : "-"}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            {/* <div>
              <span>Planning to join in :</span>
              <span class="font-18 font-500">Sep-Dec 2023</span>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Preferences;
