import React from "react";
import { Modal } from "react-bootstrap";

const CongratulationModal = ({
  setCongratulationOpenModal,
  openNextTimeModal,
  qres,
  CongratulationOpenModal,
}) => {
  return (
    <Modal
      show={CongratulationOpenModal}
      centered
      onHide={() => setCongratulationOpenModal(false)}
    >
      <div class="modal-body p-5">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <span class="d-flex justify-content-center align-items-center mb-4">
            <img src="/assets/images/group-40900.svg" width="115" />
          </span>
          <h3 class="font-24 font-600 mb-4">Congratulations!</h3>
          <div class="font-18 font-400 text-center px-0 px-sm-3 ">
            You scored{" "}
            <b class="font-18 font-500">{qres?.percentage?.toFixed(2)}%</b> in
            the quiz You have successfully completed the lesson on Time
            Management
          </div>
          <div class="d-flex justify-content-center align-items-center margin-top-40 w-100">
            <button
              class="btn-blue max-200 w-100 d-flex text-center align-items-center font-14 font-500 mx-2"
              onClick={() => setCongratulationOpenModal(false)}
            >
              Go To Milestone
            </button>
            <button
              type="button"
              class="btn radius max-200 height-40 d-block w-100 px-4 mx-2 font-14 font-500"
              style={{
                background: "#5cae48",
                color: "#fff",
                zIndex: "2",
                position: "relative",
                borderRadius: "25px",
                webkitBorderRadius: "25px",
              }}
              onClick={openNextTimeModal}
            >
              Move To Next Activity
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CongratulationModal;
