import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import Historian from "./Historian";
import PopularCareer from "./popular-career";
import OffbeatCareer from "./offbeat-career";
import EverGreen from "./ever-green";

const CareerTrands = () => {
  const [activeKey, setActiveKey] = useState("last-years");

  return (
    <div className="main sideBarEnable">
      <div className="container flex-1">
        <div className="pb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="sticky-top mainUl" style={{ zIndex: "9" }}>
                  <Card style={{ border: "none" }}>
                    <Nav
                      variant="pills"
                      className="job_internship_tab polartabsec nav-pills-hor"
                    >
                      <Nav.Item eventKey="last-years">
                        <ul className="nav nav-tabs">
                          <li className="nav-item" role="presentation">
                            <button
                              onClick={() => {
                                setActiveKey("last-years");
                              }}
                              className={
                                activeKey === "last-years"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="last-years-tab"
                            >
                              Popular Careers of the Last 10 Years
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                activeKey === "popular"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="popular-tab"
                              onClick={() => {
                                setActiveKey("popular");
                              }}
                            >
                              Popular Careers for the Next 10 Years
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                activeKey === "offbeat"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="offbeat-tab"
                              onClick={() => setActiveKey("offbeat")}
                            >
                              Offbeat Career Options
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                activeKey === "evergreen"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="evergreen-tab"
                              onClick={() => setActiveKey("evergreen")}
                            >
                              Evergreen Career Options
                            </button>
                          </li>
                        </ul>
                      </Nav.Item>
                    </Nav>
                  </Card>
                </div>
                <div className="border-radius-10 pt-3 pb-0 mb-4">
                  <div>
                    <div className="row m-0 pt-4 JobInternshipListing">
                      <div className="col-lg-12">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="last-years"
                          activeKey={activeKey}
                          onSelect={(k) => setActiveKey(k)}
                        >
                          <Tab.Content>
                            <Tab.Pane eventKey="last-years">
                              <Historian />
                            </Tab.Pane>
                            <Tab.Pane eventKey="popular">
                              <PopularCareer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="offbeat">
                              <OffbeatCareer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="evergreen">
                              <EverGreen />
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTrands;
