import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import Explore from "./explore";
import Recommend from "./recommend";

const ExploreColleges = () => {
  const [activeKey, setActiveKey] = useState("explore-college");

  return (
    <React.Fragment>
      <div className="flex-1 container">
        <div className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <Card style={{ border: "none" }}>
                  <Nav
                    variant="pills"
                    className="job_internship_tab polartabsec"
                  >
                    <Nav.Item eventKey="explore-college">
                      <ul className="nav nav-tabs">
                        <li className="nav-item" role="presentation">
                          <button
                            onClick={() => setActiveKey("explore-college")}
                            className={
                              activeKey === "explore-college"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="ExploreCollege-tab"
                          >
                            Explore Colleges
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              activeKey === "recommend"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="recommend-tab"
                            onClick={() => setActiveKey("recommend")}
                          >
                            Recommended Colleges
                          </button>
                        </li>
                      </ul>
                    </Nav.Item>
                  </Nav>
                </Card>

                <div className="row m-0 pt-4 pb-3 JobInternshipListing">
                  <div className="col-lg-12">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="explore-college"
                      activeKey={activeKey}
                      onSelect={(k) => setActiveKey(k)}
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="explore-college">
                          <Explore />
                        </Tab.Pane>

                        <Tab.Pane eventKey="recommend">
                          <Recommend />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExploreColleges;
