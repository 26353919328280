import React from "react";
import { Accordion } from "react-bootstrap";

const TopCollegeAccordion = ({ data }) => {
  return (
    <React.Fragment>
      <div className="accord">
        <div className="college-accord">
          <Accordion className="college_blk mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="collegeName d-flex flex-wrap">
                <div className="d-flex flex-wrap">
                  <div className="colllegeLogo me-3">
                    <img
                      src={data.logo}
                      alt="university melbourne"
                      width="84"
                      className="img-fluid"
                    />
                  </div>
                  <div className="collegeNameDetail flex-1">
                    <h3
                      className="mb-0"
                      onClick={() =>
                        data?.website_url && window.open(data.website_url)
                      }
                    >
                      {data?.name}
                    </h3>
                    {/* <label className="tick font-13 font-500 d-block">
                      Rank: #{data?.ranking_from}th {data?.ranking_given_by}
                    </label> */}
                    <label className="tick font-14 font-500 me-3">
                      <i className="icon-location-pin blue-text  mt-1 me-2"></i>
                      {data?.city},{data?.state},{data?.country}
                    </label>
                    {/* <label className="tick font-14 font-500">
                      <i
                        className="fa fa-check-circle fa-lg blue-text  mt-1 me-2"
                        aria-hidden="true"
                      ></i>
                      21 Students Shortlisted
                    </label> */}
                  </div>
                  {data?.course_count != "0" && (
                    <div
                      className="toggle-link"
                      style={{
                        marginTop: "40px",
                      }}
                    >
                      <a className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none">
                        <span className="font-14 font-500 text-center">
                          {data?.course_count} Courses
                        </span>
                        <span className="icon-down-arrow text-white font-13 ms-2"></span>
                      </a>
                    </div>
                  )}

                  <div className="college_detail_price_deadline d-block w-100 mt-2">
                    <div className="row w-auto mb-0">
                      <div className="col-md-auto col-6 mb-4 mb-md-0">
                        {data.average_tuition_fee != "0" && (
                          <div className="college_detail_price w-100 d-flex flex-wrap">
                            {data?.currency_logo && (
                              <div className="icon fee me-2">
                                <img
                                  src={data?.currency_logo}
                                  className=""
                                ></img>
                              </div>
                            )}

                            <div className="font-13 font-500">
                              Fees / Year
                              <label className="font-16 d-block">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data?.currency_unicode_character,
                                  }}
                                ></span>{" "}
                                {data?.average_tuition_fee}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-auto col-6 mb-4 mb-md-0">
                        {data?.scholrship_count != "0" && (
                          <div className="college_detail_price w-100 d-flex flex-wrap">
                            <div className="icon medal me-2">
                              <i className="icon-loan"></i>
                            </div>
                            <div className="font-13 font-500">
                              Scholarships
                              <label className="font-16 d-block">
                                {data?.scholrship_count}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-4 col-md-auto col-12">
                        {data?.exam_count != "0" && (
                          <div className="college_detail_price w-100 d-flex flex-wrap">
                            <div className="icon medal me-2">
                              <i className="icon-book"></i>
                            </div>
                            <div className="font-13 font-500">
                              Entrance Exam
                              <label className="font-16 d-block">
                                {data?.exam_count}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <div className="college-list">
                  <div className="college_detail">
                    <div className="row g-3">
                      {data?.courses?.map((x, index) => {
                        return (
                          <div className="col-md-6" key={index}>
                            <div className="college">
                              <div className="college-brife m-0">
                                <label>
                                  <a
                                    className="font-16 font-500 cursor-pointer"
                                    onClick={() =>
                                      x?.course_url && window.open(x.course_url)
                                    }
                                  >
                                    {x.name}
                                  </a>
                                </label>
                                <div className="text-gray-41 d-flex justify-content-between mt-2">
                                  <div className=" college-name flex-1 font-16 font-500 me-3">
                                    <b className="d-block font-14 font-500">
                                      Fees:
                                    </b>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          data?.currency_unicode_character,
                                      }}
                                    ></span>{" "}
                                    {x.avg_tution_fee} / Yearly
                                  </div>
                                  <div className="duration flex-1 font-16 font-500">
                                    <b className="d-block font-14 font-500">
                                      Duration:
                                    </b>
                                    {x.duration} Months
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="w-100 text-center">
                      <a className="btn btn-viewdetail">
                        <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                          View All Details
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopCollegeAccordion;
