import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  getAllCity,
  getBoards,
  updateStudentDetail,
} from "../../../services/student/student-profile";
import { getCountries } from "../../../services/career/career-service";
import { getAllStandard } from "../../../services/standard/standard-service";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Typeahead } from "react-bootstrap-typeahead";

const EditBasicInfo = ({ details, setIsEdit, refreshData }) => {
  const { id } = useParams();
  const [countryList, setCountryList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [country_id, setCountryId] = useState(undefined);
  const [standardList, setStandardList] = useState([]);
  const [search, setSearch] = useState("");
  let basicInfoSchema = Yup.object({
    fname: Yup.string().required(),
    lname: Yup.string().required(),
    country: Yup.string(),
    phone: Yup.string(),
    city: Yup.string(),
    address_line_1: Yup.string(),
    address_line_2: Yup.string(),
    pincode: Yup.string(),
    primary_citizenship: Yup.string(),
    secondary_citizenship: Yup.string(),
    standard_id: Yup.string(),
    board_id: Yup.string(),
    gender: Yup.string(),
    secondary_email: Yup.string(),
  });

  let onBasicInfoSubmit = async (e) => {
    const payload = Object.assign({}, values);
    payload["student_id"] = id;
    const res = await updateStudentDetail(payload);
    const { status, message } = res?.data?.meta;
    if (status) {
      toast.success(message);
      refreshData();
      setIsEdit(false);
    }
  };

  const getCountryList = async (payload = {}) => {
    await getCountries(payload).then((res) => {
      if (res && res.data?.data) {
        setCountryList(res.data?.data?.records);
      }
    });
  };

  const getBoardList = async () => {
    await getBoards().then((res) => {
      if (res && res.data?.data) {
        setBoardList(res.data?.data?.records);
      }
    });
  };

  const getStandards = async () => {
    const res = await getAllStandard();
    if (res && res.data?.data?.records) {
      setStandardList(res.data?.data?.records);
    }
  };

  const getCities = async () => {
    const res = await getAllCity({ country_id: country_id, search: search });
    if (res && res.data?.data?.records) {
      setCityList(res.data?.data?.records);
    }
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: details,
    validationSchema: basicInfoSchema,
    onSubmit: onBasicInfoSubmit,
  });

  const parseValues = () => {
    const fields = [
      "fname",
      "lname",
      "country",
      "phone",
      "city",
      "address_line_1",
      "address_line_2",
      "pincode",
      "primary_citizenship",
      "secondary_citizenship",
      "standard_id",
      "board_id",
      "gender",
      "secondary_email",
    ];
    let payload = {};
    for (let i = 0; i < fields?.length; i++) {
      payload[fields[i]] = values[fields[i]] == null ? "" : values[fields[i]];
    }
    setValues(payload);
  };

  useEffect(() => {
    parseValues();
    getCountryList();
    getBoardList();
    getStandards();
    // getCities();
    if (details) {
      if (details?.country) {
        setCountryId(details?.country);
      }
    }
  }, [details]);

  useEffect(() => {
    getCities();
  }, [country_id, search]);

  return (
    <div className="accordion-body">
      <div className="px-lg-4">
        <form
          className="lstCustForm row g-4"
          action="post"
          onSubmit={handleSubmit}
        >
          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="First Name"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              First Name
            </label>
            <input
              type="text"
              className="form-control flex-1"
              name="fname"
              error={errors.fname}
              value={values?.fname}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder=""
            />
            {errors.fname && touched?.fname && (
              <span className="text-danger">{errors.fname}</span>
            )}
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="Last Name"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Last Name
            </label>
            <input
              type="text"
              className="form-control flex-1"
              id="Last Name"
              name="lname"
              error={errors.lname}
              value={values?.lname}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder=""
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="MinExp"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Gender:
            </label>
            <div className="row my-2">
              <div className="col-auto">
                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    onChange={(e) => handleChange(e)}
                    checked={values?.gender === "Male"}
                    className="form-check-input custom-border-84-20 m-0"
                  />
                  <label
                    htmlFor="gender_male"
                    className="form-check-label font-16 font-500 ms-2"
                  >
                    Male
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    onChange={(e) => handleChange(e)}
                    checked={values?.gender === "Female"}
                    id="gender_female"
                    className="form-check-input custom-border-84-20 m-0"
                  />
                  <label
                    htmlFor="gender_female"
                    className="form-check-label font-16 font-500 ms-2"
                  >
                    Female
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="designation"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Your Registered Email/Username
              <i
                className="icon-info text-blue btn-white-tooltip"
                title="This is your permanent username with which you can login to the platform. All communication will be sent to your email ID."
              ></i>
            </label>
            <input
              type="text"
              name="email"
              className="form-control flex-1"
              id="designation"
              disabled
              value={values?.email}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="MinExp"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Your Contact Email
            </label>
            <input
              type="text"
              className="form-control flex-1"
              id="MinExp"
              name="secondary_email"
              value={values?.secondary_email}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span className="font-14 fst-italic opacity-70 line-height-16 d-flex mt-1">
              All communication for student account will be sent on this email
            </span>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="PhoneNumber"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Mobile number (with country code)
            </label>
            <div className="row g-2">
              <div className="col">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  error={errors.phone}
                  value={values?.phone}
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
            </div>
            <span className="font-14 fst-italic opacity-70 line-height-16 d-flex mt-1">
              All communication for student account will be sent on this mobile
            </span>
          </div>

          <div className="col-md-12">
            <label
              htmlFor="Last Name"
              className="form-label m-0 mb-1 font-16 font-500 w-100"
            >
              Your Currently located in
            </label>
            <div className="row g-4">
              <div className="col-md-6">
                <Typeahead
                  id={"Country-Preference-1"}
                  labelKey="name"
                  options={countryList}
                  placeholder="Choose country preference..."
                  selected={
                    countryList.filter((x) => x.id == values?.country) || []
                  }
                  name={"country"}
                  value={values?.country}
                  onChange={(e) => {
                    console.log(e);
                    setCountryId(e[0]?.id);
                    setFieldValue("country", e[0]?.id);
                  }}
                  onInputChange={(search) => getCountryList({ search })}
                  required
                />
                {/* <select
                  className="form-select form-control country-code"
                  name="country"
                  aria-label="Default select example"
                  required
                  value={values?.country}
                  onChange={(e) => {
                    setCountryId(e.target.value);
                    handleChange(e);
                  }}
                >
                  <option>Select Country</option>
                  {countryList?.length &&
                    countryList?.map((obj) => (
                      <>
                        <option value={obj?.id}>{obj?.name}</option>
                      </>
                    ))}
                </select> */}
              </div>
              <div className="col-md-6">
                <Typeahead
                  id={"cityList-Preference-1"}
                  labelKey="name"
                  options={cityList}
                  placeholder="Choose city preference..."
                  selected={cityList.filter((x) => x.id == values?.city) || []}
                  name={"city"}
                  value={values?.city}
                  onChange={(e) => {
                    console.log(e);
                    setFieldValue("city", e[0]?.id);
                  }}
                  onInputChange={(search) => setSearch(search)}
                  required
                />
                {/* <select
                  className="form-select form-control country-code"
                  name="city"
                  aria-label="Default select example"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values?.city}
                >
                  <option>Select City</option>
                  {cityList?.length &&
                    cityList?.map((obj) => (
                      <>
                        <option value={obj?.id}>{obj?.name}</option>
                      </>
                    ))}
                </select> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="designation"
              className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
            >
              Address Line 1
            </label>
            <input
              type="text"
              name="address_line_1"
              className="form-control flex-1"
              id="designation"
              placeholder="Street Name, Locality"
              value={values?.address_line_1}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="designation"
              className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
            >
              Address Line 2
              <span className="font-14 opacity-90 fst-normal ps-1">
                (Optional)
              </span>
            </label>
            <input
              type="text"
              name="address_line_2"
              className="form-control flex-1"
              id="designation"
              placeholder="Flat Number, Building Name"
              value={values?.address_line_2}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="designation"
              className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
            >
              Your Pincode/Zip Code
            </label>
            <input
              type="text"
              name="pincode"
              className="form-control flex-1"
              id="designation"
              placeholder="Ex: 500089"
              value={values?.pincode}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="designation"
              className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
            >
              Citizenship
            </label>
            <select
              className="form-select form-control country-code"
              name="primary_citizenship"
              aria-label="Default select example"
              required
              value={values?.primary_citizenship}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option>Select Country</option>
              {countryList?.length &&
                countryList?.map((obj) => (
                  <>
                    <option value={obj?.id}>{obj?.name}</option>
                  </>
                ))}
            </select>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <div className="row g-2">
              <div className="col">
                <label
                  htmlFor="designation"
                  className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
                >
                  Your Class
                </label>
                <select
                  className="form-select form-control country-code"
                  name="standard_id"
                  aria-label="Default select example"
                  required
                  value={values?.standard_id}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select Class</option>
                  {standardList?.length &&
                    standardList?.map((obj) => (
                      <>
                        <option value={obj?.id}>{obj?.name}</option>
                      </>
                    ))}
                </select>
              </div>
              <div className="col-7">
                <label
                  htmlFor="designation"
                  className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
                >
                  Your Board
                </label>
                <select
                  className="form-select form-control country-code"
                  name="board_id"
                  aria-label="Default select example"
                  required
                  value={values?.board_id}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select Board</option>
                  {boardList?.length &&
                    boardList?.map((obj) => (
                      <>
                        <option value={obj?.id}>{obj?.name}</option>
                      </>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6">
            <label
              htmlFor="PresentEducationalEmploymentStatus"
              className="form-label d-flex m-0 mb-1 font-16 font-500 w-100"
            >
              Second Citizenship
              <span className="font-14 opacity-90 fst-normal ps-1">
                (Optional)
              </span>
            </label>
            <select
              className="form-select form-control country-code"
              name="secondary_citizenship"
              aria-label="Default select example"
              required
              value={values?.secondary_citizenship}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option>Select Country</option>
              {countryList?.length &&
                countryList?.map((obj) => (
                  <>
                    <option value={obj?.id}>{obj?.name}</option>
                  </>
                ))}
            </select>
          </div>

          <div className="col-12 pt-1">
            <div className="row justify-content-end align-items-end">
              <button
                onClick={() => setIsEdit(false)}
                className="btn btn-cancel mx-2 d-block height-40 w-auto"
              >
                <span>Cancel</span>
              </button>
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn btn-green text-white mx-2 d-block height-40 px-5 w-auto"
              >
                <span>{isSubmitting ? "Loading..." : "Submit"}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBasicInfo;
