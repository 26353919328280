import React, { useEffect, useState } from "react";
import EditEntranceExams from "./edit-entrance-exam";
import {
  editEntranceExams,
  getAllExams,
  getStudentExams,
} from "../../../services/student/student-profile";
import { getLevels } from "../../../services/career/career-service";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const EntranceExams = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [exams, setExams] = useState([]);
  const [allExam, setAllExam] = useState([]);
  const [levels, setLevels] = useState([]);
  const [isAware, setIsAware] = useState(false);
  const [studentExam, setStudentExam] = useState([]);
  const [exam, setExam] = useState({ exam_id: "", level_id: "" });

  const getExams = async () => {
    await getStudentExams({ student_id: id }).then((res) => {
      if (res && res.data?.data.length === 0) {
        setIsEdit(true);
      } else if (res && res.data?.data) {
        setExams(res.data?.data);
      }
    });
  };

  const searchExam = (search) => {
    entranceExam(search);
  };

  const entranceExam = async (search) => {
    await getAllExams({ search: search }).then((res) => {
      if (res && res.data?.data) {
        setAllExam(res.data?.data);
      }
    });
  };

  const getLevelsList = async () => {
    await getLevels({ type: "" }).then((res) => {
      if (res && res.data?.data) {
        setLevels(res.data?.data);
      }
    });
  };

  const addStudentExam = () => {
    if (exam.exam_id && exam.level_id) {
      let temp = Object.assign([], studentExam);
      temp.push(exam);
      setStudentExam(temp);
      setExam({ exam_id: "", level_id: "" });
    }
  };

  const removeExam = (index) => {
    let temp = Object.assign([], studentExam);
    temp.splice(index, 1);
    setStudentExam(temp);
  };

  const onExamSubmit = async () => {
    let data = {
      student_id: id,
      is_aware_college_country_exams: isAware,
      entrance_exam: studentExam?.map((s) => {
        let t = {
          exam_id: s.exam_id,
          level_id: s.level_id,
        };
        return t;
      }),
    };
    await editEntranceExams(data).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        toast.success(message);
        getExams();
        setIsEdit(false);
      }
    });
  };

  useEffect(() => {
    entranceExam();
    getLevelsList();
  }, []);

  useEffect(() => {
    getExams();
  }, []);

  useEffect(() => {
    if (exams?.length > 0) {
      setStudentExam(exams);
    }
  }, [exams]);

  return (
    <div className="gap" id="entranceexams">
      <div id="entranceexams-content" className="AboutBox">
        <p
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setIsEdit(!isEdit)}
          className="icon-edit-pencil btn-gray-edit"
        ></p>
        {isEdit ? (
          <EditEntranceExams
            isAware={isAware}
            setIsAware={setIsAware}
            studentExam={studentExam}
            addStudentExam={addStudentExam}
            onExamSubmit={onExamSubmit}
            removeExam={removeExam}
            searchExam={searchExam}
            exam={exam}
            setExam={setExam}
            exams={exams}
            allExam={allExam}
            levels={levels}
            setIsEdit={setIsEdit}
          />
        ) : (
          <div className="accordion-body">
            <div className="row">
              <div className="col-lg-12">
                {exams &&
                  exams?.map((val, i) => {
                    return (
                      <div className="entranceexams_row" key={i}>
                        <label className="examName mb-2">
                          {allExam.find((x) => x.id === val.exam_id)?.name}
                        </label>
                        <b className="colon">:</b>
                        <div className="preparationStatus">
                          {levels.find((x) => x.id === val.level_id)?.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntranceExams;
