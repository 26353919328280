import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../Contexts/auth-context";
import { fileUpload } from "../../../../services/util/util-service";
import { toast } from "react-toastify";
import { updateProfilePic } from "../../../../services/student/student-profile";

const BasicInformation = ({ infoData }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  console.log(id, user);
  const uploadRef = useRef(null);
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData).then((res) => {
      if (res && res.data && res?.data?.data?.fileLink) {
        uploadProfilePic(res?.data?.data?.fileLink);
      }
    });
  };
  const uploadProfilePic = async (link) => {
    let payload = {
      profile_pic: link,
      student_id: id,
    };
    console.log(payload);
    const res = await updateProfilePic(payload);
    if (res?.data?.meta?.status) {
      user["profile_pic"] = res.data.data;
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  const handleImport = async (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (
      file?.type?.includes("image/jpeg") ||
      file?.type?.includes("image/png")
    ) {
      imageUpload(file);
    } else {
      toast.error("Image must ba valid .jpeg file");
    }
  };
  return (
    <div className="basicInformation bg-darker-orange p-4 p-sm-5 pb-sm-4 position-relative">
      <a
        onClick={() => navigate("/user/edit-basicinfo")}
        className="btn-edit-pop position-absolute top-40 right-40 text-decoration-none cursor-pointer"
      >
        <i
          className="icon-edit-pencil text-white"
          onClick={() => navigate("/user/email-settings")}
        ></i>
      </a>
      <div className="row">
        <div className="col-12">
          <h2 className="font-24 text-white mb-4">Basic Information</h2>
        </div>
        <div className="col-md-5 d-flex mb-3 mb-sm-0">
          {user?.profile_pic ? (
            <span className="userimg position-relative">
              <img src={user?.profile_pic} alt="video-thumb" />
            </span>
          ) : (
            <div
              data-initials={
                user?.fname[0]?.toUpperCase() + user?.lname[0]?.toUpperCase()
              }
            ></div>
          )}
          {/* <p
            style={{
              backgroundColor: "rgb(243, 173, 24)",
              borderRadius: "50%",
              position: "relative",
              zIndex: "9999",
              height: "21px",
              width: "21px",
              left: "-19px",
            }}
          >
            <span
              style={{
                position: "absolute",
                zIndex: "9999",
                top: "4px",
                left: "5px",
                color: "black",
                fontSize: "12px",
              }}
              className="icon-edit-pencil"
              onClick={(e) => {
                e.preventDefault();
                uploadRef?.current?.click();
              }}
            ></span>
          </p> */}
          <input
            hidden
            ref={uploadRef}
            type="file"
            name="upload"
            id="upload"
            onChange={handleImport}
          />
          {/* <div className="align-items-start">
            <figure className="mb-0">
              <span className="d-inline-block position-relative profile_pic">
                <img
                  src="/assets/images/basic-information-pp.png"
                  alt="basic-information"
                />
                <span className="d-inline-block position-absolute profile_picedt d-flex align-items-center justify-content-center">
                  <i className="icon-edit-pencil text-white font-12"></i>
                </span>
              </span>
              <em>
                <i className="fa fa-pencil"></i>
              </em>
            </figure>
            <div className="profileLinks">
              <span className="btn-remove">Remove Picture</span>
              <span className="btn-upload">Upload New Picture</span>
            </div>
          </div> */}
          <div className="profilenameclass ms-4">
            <div className="profilename text-white font-20 font-600">
              {infoData?.fname ? infoData?.fname : "-"} {infoData?.lname}
            </div>
            <div className="d-flex align-items-center text-white">
              <i className="icon-location-pin text-orange-fc font-16 position-relative me-1"></i>
              <span className="position-relative font-14 font-500 text-orange-fc">
                {infoData?.home_address?.city},{infoData?.home_address?.country}
              </span>
            </div>
            <div className="univarsity-school mt-3">
              <p className="font-18 font-500 text-white line-height-18 mb-4">
                {infoData?.bio ? infoData?.bio : "-"}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 d-flex locbtncol">
          <div className="row flex-column">
            <div className="col-md-12 mb-3">
              <div className="w-100 d-flex flex-wrap align-items-center">
                <div className="phone_number d-flex max-135">
                  <label className="font-14">Contact Number</label>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <span className="font-16 text-white ms-3">
                    {infoData?.phone}
                  </span>
                  {/* <span className="btn-white-border verifynow font-13 ms-3 cursor-pointer">
                    <span>Verify Now</span>
                  </span> */}
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="w-100 d-flex flex-wrap align-items-center">
                <div className="phone_number d-flex contemail">
                  <label className="font-14">Contact Email</label>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <span className="font-16 text-white ms-3">
                    <span className="text-white text-decoration-none">
                      {infoData?.email}
                    </span>
                  </span>
                  {/* <span className="btn-greenm btnverified ms-3 cursor-pointer">
                    <i className="icon-varified text-white font-16 me-1"></i>
                    Verified
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
