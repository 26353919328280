import React from "react";
import ProductTable from "../../../Components/DashboardComponents/ProductTable/product-table";
import { useNavigate } from "react-router-dom";
const ProductCard = ({ data, tableData }) => {
  const navigate = useNavigate();
  return (
    <div className="dashboard_card">
      <div className="dashboard_card_header">
        <h2>
          <span>{"Inventory Consumption"}</span>
        </h2>
        {/* <div className="custom_date">
          <input type="date" className="form-control " />
        </div> */}
      </div>
      <div className="dashboard_card_body">
        <div className="row">
          <div className="col-md-6">
            {/* <div className="text-center mb-5 pb-2">
              <div className="custom_select justify-content-center">
                <select
                  className="form-select w-140"
                  name="complete Class 12"
                  aria-label="Default select example"
                >
                  <option value="">All Products</option>
                </select>
              </div>
            </div> */}
            <div className="bigVerticalProgress eightyHeight flex-column">
              <div className="horizontal_Progress">
                <div className="graphContainer">
                  <div
                    className="progress"
                    data-toggle="tooltip"
                    title={data.total_purchase}
                    data-original-title={data.total_purchase}
                  >
                    <div
                      className="progress-bar bg-green"
                      role="progressbar"
                      style={{ width: "57%" }}
                      date-value={data.total_purchase}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {data.total_purchase}
                    </div>
                  </div>
                </div>
                <label className="caption wd-100">
                  <span className="font-18 font-500">
                    {/* {data?.proName} */}
                    Total Purchased
                    <br />
                  </span>
                </label>
              </div>
              <div className="horizontal_Progress">
                <div className="graphContainer">
                  <div
                    className="progress"
                    data-toggle="tooltip"
                    title={data.assigned_count}
                    data-original-title={data.assigned_count}
                  >
                    <div
                      className="progress-bar bg-darker-blue"
                      role="progressbar"
                      style={{ width: "39%" }}
                      date-value={data.assigned_count}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {data.assigned_count}
                    </div>
                  </div>
                </div>
                <label className="caption wd-100">
                  <span className="font-18 font-500">
                    Total Assigned
                    <br />
                  </span>
                </label>
              </div>
              <div className="horizontal_Progress">
                <div className="graphContainer">
                  <div
                    className="progress"
                    data-toggle="tooltip"
                    title={data.consumed_count}
                    data-original-title={data.consumed_count}
                  >
                    <div
                      className="progress-bar bg-dark-orange"
                      role="progressbar"
                      style={{
                        width: "15%",
                      }}
                      date-value={data.consumed_count}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {data.consumed_count}
                    </div>
                  </div>
                </div>
                <label className="caption wd-100">
                  <span className="font-18 font-500">
                    Total Consumed
                    <br />
                  </span>
                </label>
              </div>
            </div>
            <div className="text-center mt-5 pt-2">
              <a
                onClick={() => navigate("/user/inventory")}
                className="btn-gray-border"
              >
                <span>View All</span>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <ProductTable tableData={tableData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
