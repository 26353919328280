import React, { useState } from "react";
import * as Yup from "yup";

import { BsPlusLg } from "react-icons/bs";
import { useFormik } from "formik";
import ActivitiesTable from "./activities-table";

const EditActivities = ({
  addData,
  activityList,
  setIsEdit,
  setActivityList,
}) => {
  const removeActivity = (index) => {
    const temp = Object.assign([], activityList);
    temp.splice(index, 1);
    setActivityList(temp);
  };
  const initialValues = {
    title: "",
    description: "",
    completion_date: "",
  };
  const validationSchema = Yup.object({
    title: Yup.string().required(),
    description: Yup.string().required(),
    completion_date: Yup.string().required(),
  });
  const onSubmit = (values) => {
    let temp = Object.assign([], activityList);
    let temp1 = Object.assign({}, values);
    temp1["completion_date"] = values.completion_date;
    temp.push(temp1);
    setActivityList(temp);
    setValues({ title: "", description: "", completion_date: "" });
  };
  const { handleChange, handleSubmit, values, errors, setValues } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="row g-4 mt-2">
            <div className="col-lg-6 col-md-12 col-sm-6">
              <label className="form-label m-0 mb-1 font-16 font-500 w-100">
                Activity Title
              </label>
              <input
                type="text"
                className="form-control flex-1"
                id="designation"
                name="title"
                placeholder="Name of the program"
                value={values.title}
                error={errors.title}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-6">
              <label className="form-label m-0 mb-1 font-16 font-500 w-100">
                <label className="form-label mb-0 font-16 font-500 w-100">
                  Completion Year and Month
                </label>
              </label>
              <input
                type="date"
                className="form-control flex-1 p-0 text-center"
                placeholder="Name of the program"
                name="completion_date"
                value={values.completion_date}
                required
                pattern="\d{2}/\d{2}/\d{4}"
                style={{ height: "40px" }}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={errors.completion_date}
              />
            </div>

            <div className="col-md-12">
              <label className="form-label m-0 mb-1 font-16 font-500 w-100">
                Activity Description
                <span className="font-14 font-400 text-dark-blue">
                  (max 250 characters)
                </span>
              </label>
              <textarea
                className="form-control"
                rows="2"
                placeholder="Ex: The book is a fictional account of events happened during……."
                name="description"
                value={values.description}
                error={errors.description}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></textarea>
            </div>
            <div className="col-12 my-3 d-flex">
              <button className="btn-add me-3" type="submit">
                <BsPlusLg />
                Add 
              </button>
            </div>
          </div>
        </form>
        <div>
          <ActivitiesTable
            activityData={activityList}
            removeActivity={removeActivity}
          />
        </div>

        <div className="col-12">
          <div className="d-flex justify-content-end align-items-end border-top pt-3">
            <button
              type="books"
              className="btn btn-cancel me-4 d-block height-40 w-auto"
              onClick={() => setIsEdit(false)}
            >
              <span>Cancel</span>
            </button>
            <button
              type="books"
              className="btn btn-green text-white d-block height-40 px-5 w-auto"
              onClick={addData}
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditActivities;
