import React from "react";
import { Card } from "react-bootstrap";
import { IoMdCheckmark } from "react-icons/io";

const UserSuccess = () => {
  return (
    <div
      className="user-success-main-div w-100 d-flex justify-content-center align-items-center"
      style={{ height: "calc(100vh - 170px)" }}
    >
      <Card
        className=""
        style={{
          width: "490px",
          height: "475px",
          padding: "10px",
          border: "none",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
      >
        <div className="icon-div d-flex justify-content-center mt-5">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgb(240 249 225)",
              borderRadius: "50%",
              height: "150px",
              width: "150px",
            }}
          >
            <IoMdCheckmark
              style={{
                color: "rgb(141 173 85)",
                height: "70px",
                width: "70px",
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <h1
            className="text-center font-bold"
            style={{ color: "rgb(141 173 85)", fontWeight: "bold" }}
          >
            Success
          </h1>
          <h4
            className="text-center mt-3"
            style={{ color: "rgb(121 125 128)" }}
          >
            Your Payment Was Successful.
          </h4>
        </div>
        <button
          style={{
            margin: "15px",
            padding: "10px 10px",
            color: "#FFF",
            backgroundColor: "rgb(114 141 66)",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Continue..
        </button>
      </Card>
    </div>
  );
};

export default UserSuccess;
