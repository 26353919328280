import React, { useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import BasicInformation from "./BasicInformation/basic-information";
import AddtionalInfo from "./Additional-Information/addtional-info";
import Pereference from "./Preference/pereference";
import { getUserInfo } from "../../../services/user-basicinfo/user-basicinfo";
import { useParams } from "react-router";
const PersonalInfo = () => {
  const [activeKey, setActiveKey] = useState("personalInfo");
  const [infoData, setInfoData] = useState({});
  const getUserInformation = async () => {
    await getUserInfo().then((res) => {
      if (res && res?.data?.data) {
        setInfoData(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    getUserInformation();
  }, []);

  return (
    <div className="profileContainer p-0 mt-5 mb-4">
      <Card style={{ border: "none" }}>
        <Nav
          variant="pills"
          className="job_internship_tab polartabsec"
          style={{ backgroundColor: "#f2f9ff" }}
        >
          <Nav.Item eventKey="personalInfo">
            <ul className="nav nav-tabs">
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setActiveKey("personalInfo")}
                  className={
                    activeKey === "personalInfo"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="UpcomingEvents-tab"
                >
                  Personal Info
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeKey === "Preferences" ? "nav-link active" : "nav-link"
                  }
                  id="past-tab"
                  onClick={() => setActiveKey("Preferences")}
                >
                  Preferences
                </button>
              </li>
            </ul>
          </Nav.Item>
        </Nav>
      </Card>

      <div className="row m-0 pb-3 JobInternshipListing">
        <div className="col-lg-12 p-0">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="personalInfo"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
          >
            <Tab.Content>
              <Tab.Pane eventKey="personalInfo">
                <div>
                  <BasicInformation infoData={infoData} />
                  <div>
                    <AddtionalInfo infoData={infoData} />
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Preferences">
                <div>
                  <BasicInformation infoData={infoData}/>
                  <div>
                    <Pereference activeKey={activeKey} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
