import React from "react";

const EmailSettings = () => {
  return (
    <div>
      <React.Fragment>
        <div className="flex-1">
          <div className="d-flex justify-content-between align-items-start pt-5">
            <div className="welcome-title">
              <h1>Email Settings</h1>
            </div>
          </div>
          <div className="email_settings_wrap">
            <div className="email_settings_left">
              <ul>
                <li>
                  <span className="active">Amazon SES</span>
                </li>
                <li>
                  <span>Mandrill</span>
                </li>
                <li>
                  <span>Sendgrid</span>
                </li>
                <li>
                  <span>Sparkpost</span>
                </li>
                <li>
                  <span>Custom SMPT</span>
                </li>
              </ul>
            </div>
            <div className="email_settings_right">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="pills-default-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-default"
                    type="button"
                    role="tab"
                    aria-controls="pills-default"
                    aria-selected="true"
                  >
                    Default
                  </button>
                </li>
                <li className="nav-item add" role="presentation">
                  <span className="add-link"></span>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-default"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="email_form_container">
                    <form className="row g-4 needs-validation" novalidate>
                      <div className="col-md-12">
                        <label
                          for="validationCustom01"
                          className="form-label font-16 font-500 mb-0"
                        >
                          SMTP Host Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder="SMTP Host Name"
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          for="validationCustom02"
                          className="form-label font-16 font-500 mb-0"
                        >
                          SMTP Port
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom02"
                          placeholder="SMTP Port"
                          required
                        />
                        <div className="font-14 line-height-18 text-gray-84 fst-italic">
                          This is the SMTP Port number to connect to
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                      <div className="col-md-6">
                        <label
                          for="validationCustomUsername"
                          className="form-label font-16 font-500 mb-0"
                        >
                          SMTP Protocal
                        </label>
                        <div className="row g-3 justify-content-between">
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center height-45">
                              <input
                                type="radio"
                                name="SMTP_Protocal"
                                id="SMTP_Protocal_SSL"
                                value="SSL"
                                className="form-check-input custom-gary-border m-0"
                              />
                              <label
                                for="SMTP_Protocal_SSL"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                SSL
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center height-45">
                              <input
                                type="radio"
                                name="SMTP_Protocal"
                                id="SMTP_Protocal_TLS"
                                value="TLS"
                                className="form-check-input custom-gary-border m-0"
                              />
                              <label
                                for="SMTP_Protocal_TLS"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                TLS
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center height-45">
                              <input
                                type="radio"
                                name="SMTP_Protocal"
                                id="SMTP_Protocal_None"
                                value="none"
                                className="form-check-input custom-gary-border m-0"
                              />
                              <label
                                for="SMTP_Protocal_None"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                None
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="font-14 line-height-18 text-gray-84 fst-italic">
                          This is the SMTP Port number to connect to
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          for="validationCustom03"
                          className="form-label font-16 font-500 mb-0"
                        >
                          SMTP Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                          placeholder="SMTP Username"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid city.
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label
                          for="validationCustom05"
                          className="form-label font-16 font-500 mb-0"
                        >
                          SMTP Password
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="SMTP Password"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid Password.
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          for="validationCustom05"
                          className="form-label font-16 font-500 mb-0"
                        >
                          Maximum Send Rate
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom05"
                          placeholder="SMTP Username"
                          required
                        />
                        <div className="font-14 line-height-18 text-gray-84 fst-italic">
                          Maximum number of emails per second that SES can
                          accept from your account. You can find this in your
                          AWS Account — SES Home — Sending Statistics
                        </div>
                        <div className="invalid-feedback">
                          Please provide a valid Password.
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default EmailSettings;
