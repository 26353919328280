import React, { useEffect, useState } from 'react';

const CareerDeepList = ({ filters }) => {
  const [careers, setCareers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCareers = async () => {
      setLoading(true);
      setError(null);

      const { departments = [], likes = [], abilities = [] } = filters || {};

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}career/careerDeepDiveList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            department: departments.join(','),
            likes: likes.join(','),
            naturally_good: abilities.join(','),
          }),
        });

        const result = await response.json();
        if (result.success) {
          setCareers(result.data.careers);
          setTotalCount(result.data.totalCount);
        } else {
          setError(result.message || 'Failed to fetch careers');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    // Delay the API call by 1 second
    const delayApiCall = setTimeout(() => {
      fetchCareers();
    }, 1000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(delayApiCall);
  }, [filters]);

  if (loading) {
    return <p>Loading careers...</p>;
  }

  const trimText = (text, length) => {
    return text.length > length ? text.slice(0, length).trim() + '…' : text;
  };

  const getBasePath = () => {
    // Determine if the current URL is for user or student
    if (window.location.pathname.includes('/user/career-list')) {
      return '/user';
    } else if (window.location.pathname.includes('/student/career-list')) {
      return '/student';
    }
    return '';
  };
  
  return (
    <div className="flex-1 polarcontsec tab-content" id="myTabContent">
      <div className="ActiveListings_present">
        <h3 className="font-18 font-500 mb-0">{totalCount} Careers found</h3>
        <div className="row EventsBlk">
          {careers.map((career, index) => (
            <div className="col-lg-6 col-md-12 album_blk_parent" key={index}>
              <div className="album_blk">
                <div className="album_imgblk position-relative">
                  <img src={career.thumbnail_image} alt={career.name} />
                </div>
                <div className="album_contblk eventsbox">
                  {/* Trimmed Career Title to 15 Characters and added hover */}
                  <h3
                    className="font-18 font-600 mb-2"
                    title={career.name}
                  >
                    {trimText(career.name, 15)}
                  </h3>
                  {/* Trimmed Career Description to 130 Characters */}
                  <p className="font-15 line-height-23">
                    {trimText(career.about_section.replace(/<\/?[^>]+(>|$)/g, ""), 130)}
                    <a 
                      href={`${window.location.origin}${getBasePath()}/career/${career.name.replace(/\s+/g, '-').toLowerCase()}/${career.id}`}
                      className="green-link read-more"  // Added a class for custom styling
                      target="_blank" // Open in a new tab
                      rel="noopener noreferrer"
                    >
                      {" "}Read More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerDeepList;
