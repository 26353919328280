import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import EditAchievements from "./edit-achievements";
import EditBooks from "./edit-books";
import EditActivities from "./edit-activities";
import {
  getCurricularData,
  updateAchievements,
} from "../../../services/student/student-profile";
import { toast } from "react-toastify";
import {
  getCategory,
  getLevels,
} from "../../../services/career/career-service";
import { useParams } from "react-router";

const ExtraCurricular = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [achievementList, setAchievementList] = useState([]);
  const [booksList, setBooksList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [curricular, setCurricular] = useState([]);
  const [category, setCategory] = useState([]);
  const [levels, setLevels] = useState([]);
  const [bio, setBio] = useState("");
  const [activeKey, setActiveKey] = useState("achievements");
  const addData = async (e) => {
    e.preventDefault();
   
    let data = {
      student_id: id,
      bio: bio,
      achievments:
        achievementList?.length > 0
          ? achievementList.map((a) => {
              let t = {
                program_name: a.program_name,
                category_id: a.category_id,
                level_id: a.level_id,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],

      books:
        booksList?.length > 0
          ? booksList.map((a) => {
              let t = {
                title: a.title ? a.title : a.book_name,
                author: a.author ? a.author : a.book_author,
                description: a.description ? a.description : a.book_description,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],

      activities:
        activityList?.length > 0
          ? activityList.map((a) => {
              let t = {
                title: a.title ? a.title : a.activity_name,
                description: a.description
                  ? a.description
                  : a.activity_description,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],
    };
    await updateAchievements(data).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        toast.success(message);
        getCurricular();
        setIsEdit(false);
      }
    });
  };
  const getCurricular = async () => {
    await getCurricularData(id && { student_id: id }).then((res) => {
      if (res && res.data?.data?.achievments?.length === 0) {
        setIsEdit(true);
      } else if (res && res.data?.data) {
        setCurricular(res.data?.data);
        setBio(res.data?.data?.bio)
        setAchievementList(res.data?.data?.achievments);
        setBooksList(res.data?.data?.books);
        setActivityList(res.data?.data?.activities);
      }
    });
  };

  useEffect(() => {
    getCurricular();
  }, []);

  const getCategoryList = async () => {
    await getCategory().then((res) => {
      if (res && res.data?.data) {
        setCategory(res.data?.data);
      }
    });
  };
  const getLevelsList = async () => {
    await getLevels({ type: "" }).then((res) => {
      if (res && res.data?.data) {
        setLevels(res.data?.data);
      }
    });
  };
  return (
    <div id="extracurricularsachievements-content">
      <div className="accordion-body p-0  ">
        <div className="AboutBox mb-4">
          <div className="d-flex justify-content-between">
            <label className="subheading font-18">About me</label>
            <p
              className="icon-edit-pencil btn-gray-edit"
              data-bs-toggle="modal"
              data-bs-target="#about"
              onClick={() => setIsEdit(!isEdit)}
              style={{ cursor: "pointer" }}
            >
              <span>Edit</span>
            </p>
          </div>
          {isEdit ? (
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                name="bio"
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
              ></textarea>
            </div>
          ) : (
            <p className="font-16 mb-0">
              {curricular.bio ? curricular.bio : "-"}
            </p>
          )}
        </div>
        <ul className="main-Ul noulprop">
          <li>
            <Nav.Item eventKey="achievements">
              <ul className="nav nav-pills ex_ach_tab">
                <li className="nav-item" role="presentation">
                  <button
                    onClick={() => setActiveKey("achievements")}
                    className={
                      activeKey === "achievements"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="achievements-tab"
                  >
                    Achievements
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    type="button"
                    className={
                      activeKey === "books" ? "nav-link active" : "nav-link"
                    }
                    id="books-tab"
                    onClick={() => setActiveKey("books")}
                  >
                    Books
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      activeKey === "activities"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="activities-tab"
                    onClick={() => setActiveKey("activities")}
                  >
                    Activities
                  </button>
                </li>
              </ul>
            </Nav.Item>
          </li>
        </ul>
        <div className="row m-0 pb-3 JobInternshipListing">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="achievements"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
          >
            <Tab.Content className="px-0">
              <Tab.Pane eventKey="achievements">
                {isEdit ? (
                  <EditAchievements
                    isEdit={isEdit}
                    achievementList={achievementList}
                    setAchievementList={setAchievementList}
                    addData={addData}
                    getCategoryList={getCategoryList}
                    getLevelsList={getLevelsList}
                    category={category}
                    levels={levels}
                    curricular={curricular}
                    setIsEdit={setIsEdit}
                  />
                ) : (
                  <>
                    <div className="table-responsive attendee_dtl mt-4">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="university-name" width="25%">
                              Activity Title
                            </th>
                            <th width="25%">Category</th>
                            <th width="25%">
                              <span className="labelItem">Level</span>
                            </th>
                            <th width="25%">
                              <span className="labelItem">Completed In</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {curricular.achievments?.map((a, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                    {a.program_name}
                                  </div>
                                </td>
                                <td>{a.category_name}</td>

                                <td>{a.level_name}</td>
                                <td>
                                  {new Date(a.completion_date).getFullYear()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="view-parent mt-2">
                      <button
                        type="submit"
                        className="btn btn-green text-white d-block height-40 w-140"
                      >
                        <span>View All</span>
                      </button>
                    </div>
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="books">
                {isEdit ? (
                  <EditBooks
                    isEdit={isEdit}
                    booksList={booksList}
                    setBooksList={setBooksList}
                    addData={addData}
                    setIsEdit={setIsEdit}
                  />
                ) : (
                  <>
                    <div className="table-responsive attendee_dtl mt-4">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="university-name" width="25%">
                              Book Title
                            </th>
                            <th width="20%">Author</th>
                            <th width="40%">
                              <span className="labelItem">Description</span>
                            </th>
                            <th width="15%">
                              <span className="labelItem">Completed In</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {curricular.books?.map((a, i) => {
                            return (
                              <tr>
                                <td>
                                  <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                    {a.book_name}
                                  </div>
                                </td>
                                <td>{a.book_author}</td>

                                <td>{a.book_description}</td>
                                <td>
                                  {new Date(a.completion_date).getFullYear()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="view-parent mt-2">
                      <button
                        type="submit"
                        className="btn btn-green text-white d-block height-40 w-140"
                      >
                        <span>View All</span>
                      </button>
                    </div>
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="activities">
                {isEdit ? (
                  <EditActivities
                    isEdit={isEdit}
                    activityList={activityList}
                    setActivityList={setActivityList}
                    addData={addData}
                    setIsEdit={setIsEdit}
                  />
                ) : (
                  <>
                    <div className="table-responsive attendee_dtl mt-4">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="university-name" width="25%">
                              Activity Title
                            </th>
                            <th width="55%">Desription:</th>
                            <th width="20%">
                              <span className="labelItem">Completed In</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {curricular.activities?.map((a, i) => {
                            return (
                              <tr>
                                <td>
                                  <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                    {a.activity_name}
                                  </div>
                                </td>
                                <td>{a.activity_description}</td>

                                <td>
                                  {new Date(a.completion_date).getFullYear()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="view-parent mt-2">
                      <button
                        type="submit"
                        className="btn btn-green text-white d-block height-40 w-140"
                      >
                        <span>View All</span>
                      </button>
                    </div>
                  </>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default ExtraCurricular;
