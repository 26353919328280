import apiHeader from "../apiHeader";
import api from "../axiosService";
import { convertObjToQueryParams } from "../commonHelper";

// export const getCollegesList = async (payload) => {
//   const res = await api.get("college/getAllCollegeWithRanking", {
//     Headers: apiHeader(),
//     params: payload,
//   });
//   return res;
// };

export const getCollegesList = async (payload) => {
  const res = await api.get("college/getCollegeDetails", {
    Headers: apiHeader(),
    params: payload,
  });
  return res;
};

export const getCompareCollegesList = async (payload) => {
  const res = await api.post("college/getCollegeByIdArray", payload, {
    Headers: apiHeader(),
    // params:payload
  });
  return res;
};
export const getState = async (params) => {
  const res = await api.get("state/getStates", {
    Headers: apiHeader(),
    params:params
  });
  return res;
};
