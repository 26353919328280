import api from "../axiosService";
import apiHeader from "../apiHeader";

export const getCompanyByAlias = async (alias) => {
  const res = await api.post(
    "company/getCompanyByAlias",
    {
      alias: alias,
    },
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const fileUpload = async (data) => {
  const res = await api.post("user/uploadFile", data, {
    headers: apiHeader(),
    "Content-Type": "multipart/form-data",
  });
  return res;
};

export const convertUnicode = (data) => {
  return String.fromCharCode(data);
};
