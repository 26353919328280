import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const VerifyNumber = ({
  verifyNumberModal,
  setVerifyNumberModal,
  setVerifyContactModal,
}) => {
  const [otpInput, setOtpInput] = useState(new Array(4).fill())
  
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false
    setOtpInput([...otpInput.map((d, idx) => (idx === index ? element.value : d))])
    if (element.value) {
        if (element.nextSibling) {
            element.nextSibling.focus()
        }
    } else {
        if (element.previousSibling) {
            element.previousSibling.focus()
        }
    }
}
  return (
    <Modal
      show={verifyNumberModal}
      centered
      size="lg"
      onHide={() => setVerifyNumberModal(false)}
    >
      <div>
        <div className="modal-header border-0 ">
          <h5
            className="modal-title font-24 text-dark-blue text-center w-100"
            id="staticBackdropLabel"
          >
            Let’s verify your mobile number
          </h5>
          <button
            type="button"
            className="btn-close btn-close-small"
            onClick={() => setVerifyNumberModal(false)}
          ></button>
        </div>
        <div className="modal-body pt-0">
          <div className="p-4 bg-gray radius-10 mx-2">
            <p className="font-16 text-gray-41 text-center">
              Please enter the OTP sent to
            </p>
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
              <span className="font-24">8130966922</span>
              <button className="btn-edit mt-3 mt-sm-0">
                <i className="icon-edit me-1"></i>
                Edit Number
              </button>
            </div>
            <div className="d-flex justify-content-center my-4">
              {otpInput.map((data, index) => {
                return (
                  <input
                    className="input-fieldss input-otp text-center"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
         
            </div>
            <span className="btn-green max-190 mx-auto">
              <span>Verify</span>
            </span>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center py-3">
            <p className="font-16 text-gray-41 m-0">Didn’t receive the OTP?</p>
            <span
              className="btn-border-gray width-97 mx-auto my-2"
            >
              <span className="position-relative">Resend</span>
            </span>
            <span className="font-14 text-gray-41 fst-italic">
              Resend after 1:30 minutes
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyNumber;
