import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import { useParams } from 'react-router-dom';

const CareerDetailsAbout = () => {
  const [careerData, setCareerData] = useState({
    name: '',
    about_section: '',
    thumbnail_image: ''
  });
  const { id } = useParams();


  useEffect(() => {
    const fetchCareerData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}career/fetchBasicInfo`, {
          career_id: id
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });

        const fetchedData = response.data.data[0];
        setCareerData({
          name: fetchedData.name,
          about_section: fetchedData.about_section,
          large_image: fetchedData.large_image
        });
      } catch (error) {
        console.error('Error fetching career data:', error);
      }
    };

    fetchCareerData();
  }, []);

  return (
    <section className="container-fluid py-5 position-relative z-0" id="about">
      <div className="max-1140 mx-auto">
        <div className="row g-3 g-md-5 flex-md-row-reverse">
          <div className="col-auto">
            <img src={careerData.large_image} className="img-fluid" width="320" alt={careerData.name} />
          </div>
          <div className="col">
            <h1 className="font-32 font-900 mb-3 text-dark-blue">
              About {careerData.name}
            </h1>
            <p 
              className="font-15 line-height-23"
              dangerouslySetInnerHTML={{ __html: careerData.about_section }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerDetailsAbout;
