import React, { useState } from "react";

const EducationField = ({ label, id, type, placeholder, name, value, onChange, error }) => (
  <div className="col-md-3 col-sm-6">
    <label htmlFor={id} className="form-label m-0 mb-1 font-16 font-500 w-100">
      {label}
    </label>
    {type === "input" ? (
      <>
        <input
          type="text"
          className={`form-control flex-1 ${error ? "is-invalid" : ""}`}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </>
    ) : (
      <>
        <select
          className={`form-select form-control country-code ${error ? "is-invalid" : ""}`}
          name={name}
          aria-label="Default select example"
          value={value}
          onChange={onChange}
        >
          <option value="">Select {label}</option>
          {placeholder.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
      </>
    )}
  </div>
);

const EditEducations = ({
  schools,
  boards,
  standards,
  education,
  setEducation,
  handleSubmit,
  setIsEdit,
}) => {
  
  const [errors, setErrors] = useState([]);
  const handleInputChange = (standard_id, field, value) => {
    setEducation((prevEducation) =>
      prevEducation.map((edu) =>
        edu.standard_id === standard_id ? { ...edu, [field]: value } : edu
      )
    );
    setErrors((prevErrors) => prevErrors.filter((err) => err.standard_id !== standard_id || err[field] !== field));
  };

  const validateAndSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    const newErrors = education.map((edu) => {
      const requiredFields = ["completion_year", "school_id", "grades", "board_id"];
      const missingFields = requiredFields.filter((field) => !edu[field]);
      if (missingFields.length) {
        isValid = false;
        return {
          standard_id: edu.standard_id,
          ...missingFields.reduce((acc, field) => ({ ...acc, [field]: `${field.replace('_', ' ')} is required` }), {}),
        };
      }
      return null;
    }).filter(Boolean);

    setErrors(newErrors);
    if (isValid) handleSubmit();
  };
  return (
    <form className="accordion-body" onSubmit={validateAndSubmit}>
      {education.map((edu) => {
        let className= standards.find((std) => std.id === edu.standard_id)?.name;
        className = className == "Completed UG" ? "UG College information" : className + " Class";
        return(
        <div className="row g-4 mt-2" key={edu.standard_id}>
          <div className="col-12">
            <label className="subheading mb-0">{className}</label>
          </div>
          <EducationField
            label="Completion Year"
            id={`${edu.standard_id}-completion-year`}
            type="input"
            placeholder="Completion Year"
            name="completion_year"
            value={edu.completion_year}
            onChange={(e) => handleInputChange(edu.standard_id, "completion_year", e.target.value)}
            error={errors.find((error) => error.standard_id === edu.standard_id)?.completion_year}
          />
          <EducationField
            label= {className == "UG College information" ?"College Name":"School"}
            id={`${edu.standard_id}-school-name`}
            type="board"
            placeholder={schools}
            name="school_id"
            value={edu.school_id}
            onChange={(e) => handleInputChange(edu.standard_id, "school_id", e.target.value)}
            error={errors.find((error) => error.standard_id === edu.standard_id)?.school_id}
          />
          <EducationField
            label="Marks/Grades"
            id={`${edu.standard_id}-grades`}
            type="input"
            placeholder="Ex: 90.6%"
            name="grades"
            value={edu.grades}
            onChange={(e) => handleInputChange(edu.standard_id, "grades", e.target.value)}
            error={errors.find((error) => error.standard_id === edu.standard_id)?.grades}
          />
          {className != "UG College information" &&
          <EducationField
            label="Board Name"
            id={`${edu.standard_id}-board-name`}
            type="board"
            placeholder={boards}
            name="board_id"
            value={edu.board_id}
            onChange={(e) => handleInputChange(edu.standard_id, "board_id", e.target.value)}
            error={errors.find((error) => error.standard_id === edu.standard_id)?.board_id}
          />}
        </div>
      )})}
      <div className="row justify-content-end align-items-end mt-4">
        <button
          type="button"
          className="btn btn-cancel mx-2 d-block height-40 w-auto"
          onClick={() => setIsEdit(false)}
        >
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          className="btn btn-green text-white mx-2 d-block height-40 px-5 w-auto"
        >
          <span>Submit</span>
        </button>
      </div>
    </form>
  );
};

export default EditEducations;
