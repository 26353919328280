import React, { useEffect, useRef, useState } from "react";
import {
  getAllParentCourse,
  getAllSubCourse,
  getCountries,
} from "../../../services/career/career-service";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";
import { Typeahead } from "react-bootstrap-typeahead";

const CourseFee = () => {
  const [countryData, setCountryData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [courseSelectedOptions, setCourseSelectedOptions] = useState();
  const [searchCourseQuery, setSearchCourseQuery] = useState();
  const asyncRef = useRef(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const getAllCountries = async () => {
    await getCountries().then((res) => {
      if (res.data?.data?.records) {
        let getCountry = res.data?.data?.records?.map((x) => {
          return { label: x.name, value: x.id };
        });
        setCountryData(getCountry);
      }
    });
  };

  const loadOptions = async (inputValue, callback) => {
    const res = await getAllParentCourse({ search: inputValue });
    console.log(res?.data?.data);
    if (res?.data?.data) {
      callback(
        res.data?.data?.map((x) => {
          console.log(x);
          return { label: x.name, value: x.id };
        })
      );
    }
  };

  const getAllCourse = async () => {
    await getAllParentCourse({ search: searchCourseQuery }).then((res) => {
      if (res.data?.data) {
        let getCource = res.data?.data?.map((x) => {
          return { label: x.name, value: x.id };
        });
        setCourseData(getCource);
      }
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let ids = selectedOptions.map((a) => {
      return parseInt(a.value);
    });
    user?.student_id
      ? navigate(
          "/student/fee-calculator/?country_ids=" +
            ids.join(",") +
            "&course_id=" +
            courseSelectedOptions.value
        )
      : navigate(
          "/user/fee-calculator/?country_ids=" +
            ids.join(",") +
            "&course_id=" +
            courseSelectedOptions.value
        );
  };
  useEffect(() => {
    getAllCountries();
    getAllCourse();
  }, []);
  useEffect(() => {
    getAllCourse();
  }, []);
  return (
    <React.Fragment>
      <div
        className="main flex-1"
        style={{
          height: "calc(100vh - 20vh)",
        }}
      >
        <div className="max-1140 mx-auto d-flex h-100">
          <div className="w-100 d-flex flex-column">
            <div className="flex-1">
              <div className="mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="h-100 p-0">
                      <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                        <h3
                          data-aos="fade-left"
                          data-aos-delay="600"
                          className="font-32 font-600 mb-1 block-title aos-init aos-animate"
                        >
                          Course Fee Calculator
                        </h3>
                        <p className="font-16 font-400 mb-2">
                          Get a quick estimate of budgets in various countries
                          for different courses and colleges
                        </p>
                      </div>

                      <div className="course_fee_calculator">
                        <div className="row justify-content-center">
                          <div className="col-md-8">
                            <form
                              className="w-500 m-auto"
                              action="post"
                              onSubmit={handleSubmit}
                            >
                              <div className="form-group row mb-3 d-flex flex-column flex-lg-row flex-md-row flex-sm-row">
                                <label
                                  htmlFor=""
                                  className="col-3 col-form-label font-16 font-500 text-start text-lg-end text-md-end text-sm-end pt-2"
                                >
                                  Country
                                </label>
                                <div
                                  className="col-lg-6 col-md-12 col-sm-12 mb-3 countryData-div pe-0"
                                  style={{ width: "75%" }}
                                >
                                  <Select
                                    options={countryData}
                                    placeholder="Select"
                                    value={selectedOptions}
                                    onChange={(e) => {
                                      setSelectedOptions(e);
                                    }}
                                    isSearchable={true}
                                    isMulti={true}
                                  />
                                </div>
                                {/* <div className="col">
                                  <select
                                     multiple
                                    // id="inputState"
                                    value={selectedOptions}
          onChange={handleChange}      
                                    className="form-select font-16 font-500 height-45"
                                  >
                                    <option selected="">
                                      Country of Interest
                                    </option>
                                    {countryData?.map((a,i)=>{
                                      return  <option value={a.id}>{a.name}</option>
                                    })}
                                   
                                    <option>Canada</option>
                                  </select>
                                </div> */}
                              </div>
                              <div className="form-group row mb-3 d-flex flex-column flex-lg-row flex-md-row flex-sm-row">
                                <label
                                  htmlFor="Courses_Name"
                                  className="col-3 col-form-label font-16 font-500 text-start text-lg-end text-md-end text-sm-end pt-2"
                                >
                                  Department
                                </label>
                                <div
                                  className="col-lg-6 col-md-12 col-sm-12 mb-3 countryData-div pe-0"
                                  style={{ width: "75%" }}
                                >
                                  <AsyncSelect
                                    defaultOptions={courseData}
                                    placeholder="Select"
                                    value={courseSelectedOptions}
                                    onChange={(e) => {
                                      console.log(e);
                                      setCourseSelectedOptions(e);
                                    }}
                                    cacheOptions
                                    ref={asyncRef}
                                    isSearchable={(e) => console.log(e)}
                                    loadOptions={loadOptions}
                                    // isMulti={true}
                                  />
                                </div>
                                {/* {allCourses?.map((x, i) => {
                    console.log(x);

                    return (
                      <React.Fragment key={i}>
                        <Typeahead
                          allowNew
                          id={"Courses_Name" + i}
                          labelKey="course"
                          options={courseData.map((y) =>{
                            return y.label
                          })}
                          placeholder="Choose a prospective college..."
                          selected={courseData?.filter(
                            
                            (c) =>   c?.label === x
                            
                              // console.log(c?.label == x)

                             
                           
                            
                          )}
                          required
                          name={"courses" + i}
                          value={x.search}
                          onChange={(e) => {
                            console.log(e)
                            let temp = Object.assign([], allCourses);
                            temp[i].search = e[0];
                            console.log(temp);
                            // temp[i].id = e[0]?.id;

                            setAllCourses(temp);
                          }}
                        />
                      </React.Fragment>
                    );
                  })} */}
                                {/* <div className="col">
                                  <select
                                    id="inputState"
                                    className="form-select font-16 font-500 height-45"
                                    value={courseSelectedOptions?.label}
                                    onChange={(e) => {
                                      console.log(e);
                                      setCourseSelectedOptions(e)
                                    }}
                                  >
                                    {console.log(courseData)}
                                       {courseData?.map((a,i)=>{
                                      return  <option value={a.value}>{a.label}</option>
                                    })}
                                    <option selected="">
                                      Select Department
                                    </option>

                                    {/* <option>Department</option>
                                    <option>Department</option> 
                                    */}
                                {/* </select>
                                </div> */}
                              </div>
                              <button
                                type="submit"
                                className="btn btn-completed radius height-45 max-120 ms-auto d-block w-100 mt-4 font-16 font-500"
                                style={{ zIndex: "unset" }}
                              >
                                <span>Submit</span>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseFee;
