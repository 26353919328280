import React from "react";
import { AiOutlineDelete } from "react-icons/ai";

const ActivitiesTable = ({ activityData, removeActivity }) => {
  return (
    <div>
      <div className="table-responsive attendee_dtl mt-4">
        <table className="table">
          <thead>
            <tr>
              <th className="university-name" width="25%">
                Activity Title
              </th>
              <th width="55%">Desription:</th>
              <th width="20%">
                <span className="labelItem">Completed In</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {activityData &&
              activityData?.map((z, i) => {
                return (
                  <tr>
                    <td>
                      <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                        {z.title ? z.title : z.activity_name}
                      </div>
                    </td>
                    <td>
                      {z.description ? z.description : z.activity_description}
                    </td>

                    <td>
                      {z.completion_date
                        ? new Date(z.completion_date).getFullYear()
                        : new Date(z.completion_date).getFullYear()}
                    </td>
                    <td>
                      <span
                        className="btn-add me-4"
                        style={{ width: "unset" }}
                        onClick={() => removeActivity(i)}
                      >
                        {" "}
                        <AiOutlineDelete />
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivitiesTable;
