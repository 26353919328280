import React, { useEffect, useState } from "react";
import ProductsItem from "../../../Components/ProductsItem/productsItem";
import ProductsFilterBox from "./products-filter-box";
import { getAllProducts } from "../../../services/products/product-service";
import { useNavigate } from "react-router-dom";
import AddProduct from "../../../Modals/add-product";

const Products = () => {
  const [productsList, setProductsList] = useState([]);
  const navigate = useNavigate();
  const [productDetail, setProductDetail] = useState({});
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);

  const getProducts = async () => {
    const res = await getAllProducts();
    setLoading(false);
    if (res?.data?.meta?.status) {
      setProductsList(res?.data?.data?.records);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const addProducttoCart = () => {
    let cart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
    if (cart && cart.length > 0) {
      if (cart.findIndex((x) => x.id === productDetail.id) > -1) {
        cart[cart.findIndex((x) => x.id === productDetail.id)].quantity =
          quantity;
      } else {
        cart.push({
          product: productDetail,
          quantity: quantity,
          total:
            (parseInt(productDetail?.price) -
              parseInt(
                productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price
              ) +
              (productDetail.tax_percentage
                ? ((parseInt(productDetail?.price) -
                    (productDetail.discount_type === "flat"
                      ? productDetail.discount
                      : (productDetail.discount / 100) * productDetail.price)) *
                    productDetail.tax_percentage) /
                  100
                : 0)) *
            quantity,
          // parseInt(productDetail?.price) -
          // (productDetail.discount_type === "flat"
          //   ? productDetail.discount
          //   : (productDetail.discount / 100) * productDetail.price) *
          //   quantity,
          tax: productDetail.tax_percentage
            ? (((parseInt(productDetail?.price) -
                (productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price)) *
                productDetail.tax_percentage) /
                100) *
              quantity
            : 0,
        });
      }
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      cart = [];
      cart.push({
        product: productDetail,
        quantity: quantity,
        total:
          (parseInt(productDetail?.price) -
            parseInt(
              productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price
            ) +
            (productDetail.tax_percentage
              ? ((parseInt(productDetail?.price) -
                  (productDetail.discount_type === "flat"
                    ? productDetail.discount
                    : (productDetail.discount / 100) * productDetail.price)) *
                  productDetail.tax_percentage) /
                100
              : 0)) *
          quantity,
        tax: productDetail.tax_percentage
          ? (((parseInt(productDetail?.price) -
              (productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price)) *
              productDetail.tax_percentage) /
              100) *
            quantity
          : 0,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    setShowAddProduct(false);
    navigate("/user/product-checkout/" + productDetail.id);
  };

  const addToInventory = (x) => {
    setProductDetail(x);
    setShowAddProduct(true);
  };

  return (
    <>
      <div className="flex-1">
        <div className="mt-4 pb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="m-w-90 d-flex justify-content-between align-items-center mt-3 mb-3">
                  <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                    Products
                  </h3>
                </div>

                <div className="search_sort">
                  <div className="search flex-1">
                    <form className="form-inline d-flex flex-wrap justify-content-between">
                      <button
                        className="btn btn-search icon icon-search"
                        type="submit"
                      ></button>
                      <input
                        className="form-control flex-1 font-16 font-500"
                        type="search"
                        placeholder="Search"
                      />
                    </form>
                    <a className="btn-search-toggle icon-search"></a>
                  </div>
                  <div className="sort_by">
                    <select
                      className="form-select form-control w-180"
                      name="DesignationCoun"
                    >
                      <option>Sort By</option>
                      <option value="Educator">Popular</option>
                      <option value="Counsellor">Low to High</option>
                      <option value="Management">High to Low</option>
                      <option value="Others">Newest First</option>
                    </select>
                  </div>
                </div>

                <div className="row m-0 pb-3 JobInternshipListing">
                  <div className="flex-1 polarcontsec tab-content mt-38 pe-md-0 ps-md-3">
                    <div className="tab-pane fade show active">
                      <div className="row g-3">
                        {productsList?.map((x, i) => {
                          return (
                            <>
                              <div className="col-lg-6 col-md-12" key={i}>
                                <ProductsItem
                                  data={x}
                                  addToCart={addToInventory}
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {loading && (
                        <a className="load_more_btn">
                          <img src="/assets/images/load_more_icon.jpg" />
                          Load More
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddProduct
        setShowAddProduct={setShowAddProduct}
        showAddProduct={showAddProduct}
        product={productDetail}
        quantity={quantity}
        setQuantity={setQuantity}
        addtoCart={addProducttoCart}
      />
    </>
  );
};

export default Products;
