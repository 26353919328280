import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  getCategory,
  getLevels,
} from "../../../services/career/career-service";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import * as Yup from "yup";
import AchievementTable from "./achievement-table";

const EditAchievements = ({
  addData,
  achievementList,
  getLevelsList,
  getCategoryList,
  setAchievementList,
  levels,
  category,
  curricular,
  setIsEdit,
}) => {
  const initialValues = {
    program_name: "",
    category_id: 0,
    level_id: 0,
    completion_date: "",
  };
  const validationSchema = Yup.object({
    program_name: Yup.string().required(),
    category_id: Yup.string().required(),
    level_id: Yup.string().required(),
    completion_date: Yup.string().required(),
  });

  const onSubmit = (values, e) => {
    let temp = Object.assign([], achievementList);
    let temp1 = Object.assign({}, values);
    let date = new Date(values.completion_date);
    temp1["category_id"] = parseInt(values.category_id);
    temp1["level_id"] = parseInt(values.level_id);
    temp1["completion_date"] = values.completion_date;

    temp.push(temp1);
    setAchievementList(temp);

    setValues({
      program_name: "",
      category_id: "",
      level_id: "",
      completion_date: "",
    });
  };
  useEffect(() => {
    getCategoryList();
    getLevelsList();
  }, []);

  const { handleChange, handleSubmit, values, errors, setValues } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const removeAchievement = (index) => {
    const temp = Object.assign([], achievementList);
    temp.splice(index, 1);
    setAchievementList(temp);
  };
  return (
    <div>
      <div className="row my-1">
        <form className="lstCustForm" onSubmit={handleSubmit}>
          <div className="row g-4 my-1">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <input
                type="text"
                className="form-control flex-1"
                placeholder="Name of the program"
                name="program_name"
                required
                value={values.program_name}
                onChange={(e) => handleChange(e)}
                error={errors.program_name}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <select
                className="form-select form-control country-code"
                name="category_id"
                aria-label="Default select example"
                required
                value={values?.category_id}
                errors={errors.category_id}
                onChange={(e) => handleChange(e)}
              >
                <option>Select Category</option>
                {category?.length &&
                  category?.map((x) => {
                    return <option value={x.id}>{x.name}</option>;
                  })}
              </select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <select
                className="form-select form-control country-code"
                name="level_id"
                aria-label="Default select example"
                required
                errors={errors.level_id}
                value={values?.level_id}
                onChange={(e) => handleChange(e)}
              >
                <option>Select Level</option>
                {levels?.length &&
                  levels?.map((z) => {
                    return <option value={z.id}>{z.name}</option>;
                  })}
              </select>
            </div>
            <div className="col-md-12">
              <label
                for="designation"
                className="form-label m-0 mb-1 font-16 font-500 w-100"
              >
                Completion Year and Month
              </label>
              <div className="row g-2">
                <div className="col-lg-4 col-md-8 col-sm-12">
                  <input
                    type="date"
                    className="form-control flex-1 p-0 text-center"
                    placeholder="Name of the program"
                    name="completion_date"
                    value={values.completion_date}
                    required
                    pattern="\d{2}/\d{2}/\d{4}"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={errors.completion_date}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 my-3 d-flex">
              <button className="btn-add me-3" type="submit">
                <BsPlusLg />
                Add 
              </button>
            </div>
          </div>
        </form>
        <div>
          <AchievementTable
            data={achievementList}
            removeAchievement={removeAchievement}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-end align-items-end border-top pt-3">
          <button
            type="button"
            className="btn btn-cancel me-4 d-block height-40 w-auto"
            onClick={() => setIsEdit(false)}
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn btn-green text-white d-block height-40 px-5 w-auto"
            onClick={addData}
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAchievements;
