import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import {
  getAllParentCourse,
  getAllSubCourse,
  getColleges,
  getCountries,
} from "../../../services/career/career-service";
import { useAuth } from "../../../Contexts/auth-context";

const EditPreference = ({
  setIsEdit,
  preference,
  intakes,
  addPreferenceData,
  country,
  parentCourse,
  subCourse,
  colleges,
  allInteks,
  setAllInteks,
  preferedCountry,
  setPreferedCountry,
  preferedCourse,
  setPreferdCourse,
  preferedCollege,
  setPreferedCollege,
  collegeConfidence,
  setCollegeConfidence,
  countryConfidence,
  setCountryConfidence,
  courseConfidence,
  setCourseConfidence,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [mainCourses, setMainCourses] = useState([]);
  const [subCourses, setSubCourses] = useState([]);
  const [col, setCol] = useState([]);
  const { user } = useAuth();
  const timeoutRef = useRef();
  const handleAdd = () => {
    setPreferedCountry([...preferedCountry, { country_id: "" }]);
  };
  const handleAddCourse = () => {
    setPreferdCourse([...preferedCourse, { course_id: "" }]);
  };
  const handleAddClick = () => {
    setPreferedCollege([...preferedCollege, { college_id: "" }]);
  };

  const removeCountry = (i) => {
    let temp = Object.assign([], preferedCountry);
    temp.splice(i, 1);
    setPreferedCountry(temp);
  };

  const removeCourse = (i) => {
    let temp = Object.assign([], preferedCourse);
    temp.splice(i, 1);
    setPreferdCourse(temp);
  };

  const removeCollege = async (i) => {
    let temp = Object.assign([], preferedCollege);
    temp.splice(i, 1);
    setPreferedCollege(temp);
  };

  const getAllCountryes = async (payload = {}) => {
    // clearTimeout(timeoutRef.current);
    // timeoutRef.current = setTimeout(async () => {
    await getCountries(payload).then((res) => {
      console.log(res);
      if (res.data?.data?.records) {
        if (res.data?.data?.records?.length) {
          const country = res.data?.data?.records?.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
          console.log(country);
          setCountryList(country);
        }
      }
    });
    // }, 500);
  };
  console.log(countryList);
  const getAllCourse = async (payload = {}) => {
    // clearTimeout(timeoutRef.current);
    // timeoutRef.current = setTimeout(async () => {
    await getAllParentCourse(payload).then((res) => {
      if (res.data?.data) {
        setMainCourses(res.data?.data);
      }
    });
    // }, 500);
  };

  const getSubCourse = async (payload = {}) => {
    // clearTimeout(timeoutRef.current);
    // timeoutRef.current = setTimeout(async () => {
    await getAllSubCourse(payload).then((res) => {
      console.log(res);
      if (res.data?.data) {
        setSubCourses(res.data?.data);
      }
    });
    // }, 500);
  };

  const getAllCollege = async (search) => {
    await getColleges({ search: search }).then((res) => {
      if (res.data.data) {
        setCol(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllCourse();
    getAllCollege();
    getAllCountryes();
  }, []);

  return (
    <div className="accordion-body">
      <div className="row">
        <div className="formBody border-0 p-0">
          <div className="formSection">
            <div className="formRow">
              <div className="icon">
                <img
                  src="/assets/images/world.svg"
                  className="img-fluid"
                  width="24"
                  alt="world"
                />
              </div>

              <div className="form-field">
                <div className="form-group">
                  <label htmlFor="Country-Preference-1">
                    for your college studies, what is your preferred country?
                    <sup className="astric">*</sup>
                  </label>
                </div>

                {preferedCountry.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {countryConfidence ? (
                        <>
                          <Typeahead
                            id={"Country-Preference-1" + i}
                            labelKey="label"
                            options={countryList}
                            placeholder="Choose country preference..."
                            selected={
                              preferedCountry.length &&
                              preferedCountry[i]?.country_id
                                ? [
                                    {
                                      label: preferedCountry[i].country_name,
                                      value: parseInt(
                                        preferedCountry[i].country_id
                                      ),
                                    },
                                  ]
                                : []
                            }
                            name={"country_id" + i}
                            value={x.country_id}
                            onChange={(e) => {
                              let temp = Object.assign([], preferedCountry);
                              temp[i].country_id = e[0]?.value;
                              temp[i].country_name = e[0]?.label;
                              setPreferedCountry(temp);
                            }}
                            onInputChange={(search) =>
                              getAllCountryes({ search })
                            }
                            required
                          />
                        </>
                      ) : null}
                      <div
                        className={
                          !countryConfidence
                            ? "mt-2"
                            : "form-group d-flex justify-content-end mt-2"
                        }
                      >
                        {countryConfidence ? (
                          <>
                            {preferedCountry.length !== 1 && (
                              <div
                                className={
                                  preferedCountry.length - 1 !== i &&
                                  "d-flex justify-content-end w-100"
                                }
                              >
                                <button
                                  className="btn-add mb-2"
                                  onClick={() => removeCountry(i)}
                                >
                                  <AiOutlineDelete />
                                  Remove
                                </button>
                              </div>
                            )}
                            {preferedCountry.length - 1 === i && (
                              <button className="btn-add" onClick={handleAdd}>
                                <BsPlusLg />
                                Add More
                              </button>
                            )}
                          </>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
                <div className="form-check flex-1">
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="countrychoices"
                      name="inputs"
                      checked={!countryConfidence}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCountryConfidence(false);
                          setPreferedCountry([]);
                        } else {
                          setCountryConfidence(true);
                          setPreferedCountry([{}]);
                        }
                      }}
                      value={!countryConfidence}
                    />

                    <label className="form-check-label">
                      I haven't finalized the countries yet
                    </label>
                  </>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="icon">
                <img
                  src="/assets/images/open-book-white.svg"
                  className="img-fluid"
                  width="24"
                  alt="book"
                />
              </div>
              <div className="form-field">
                <div className="form-group">
                  <label htmlFor="Country-Preference-1">
                    for your college studies, what is your preferred course?
                    <sup className="astric">*</sup>
                  </label>
                  {preferedCourse.map((x, i) => {
                    return (
                      <div key={i}>
                        {courseConfidence ? (
                          <div className="row g-2">
                            <div className="col-12">
                              <Typeahead
                                id={"Countrmain_cource-1" + i}
                                labelKey="name"
                                options={mainCourses}
                                filterBy={["name", "popular_name", "slug"]}
                                placeholder="Choose main course..."
                                selected={mainCourses?.filter(
                                  (c) => c.id === x.course_id
                                )}
                                value={x.course_id}
                                onChange={(e) => {
                                  let temp = Object.assign([], preferedCourse);
                                  console.log(temp);
                                  temp[i].course_id = e[0]?.id;
                                  console.log(temp);
                                  setPreferdCourse(temp);
                                  // getSubCourse({
                                  //   search: "",
                                  //   parent_id: temp[i].course_id,
                                  // });
                                }}
                                onInputChange={(search) =>
                                  getAllCourse({ search })
                                }
                                required
                              />
                              {/* <select
                                value={x.course_id}
                                onChange={(e) => {
                                  let temp = Object.assign([], preferedCourse);
                                  temp[i].course_id = e.target.value;
                                  setPreferdCourse(temp);
                                }}
                                className="form-select form-control form-modal height-42"
                              >
                                <option>Main Course</option>
                                {parentCourse?.map((x) => {
                                  return <option value={x.id}>{x.name}</option>;
                                })}
                              </select> */}
                            </div>
                            {/* <div className="col-6">
                              <Typeahead
                                id={"CountrSub_cource-1" + i}
                                labelKey="name"
                                options={subCourses}
                                placeholder="Choose sub course..."
                                selected={subCourses?.filter(
                                  (c) => c.id === x.subCourse_id
                                )}
                                value={x.subCourse_id}
                                onChange={(e) => {
                                  let temp = Object.assign([], preferedCourse);
                                  temp[i].subCourse_id = e[0]?.id;
                                  console.log(temp);
                                  setPreferdCourse(temp);
                                }}
                                onInputChange={(search) =>
                                  getSubCourse({
                                    search,
                                    parent_id: x.course_id,
                                  })
                                }
                                required
                              />
                            </div> */}
                          </div>
                        ) : null}
                        <div
                          className={
                            !courseConfidence
                              ? "mt-2"
                              : "form-group d-flex justify-content-end mt-2"
                          }
                        >
                          {courseConfidence ? (
                            <>
                              {preferedCourse.length !== 1 && (
                                <div
                                  className={
                                    preferedCourse.length - 1 !== i &&
                                    "d-flex justify-content-end w-100"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn-add mb-2"
                                    onClick={() => {
                                      removeCourse(i);
                                    }}
                                  >
                                    <AiOutlineDelete />
                                    Remove
                                  </button>
                                </div>
                              )}
                              {preferedCourse.length - 1 === i && (
                                <button
                                  className="btn-add"
                                  onClick={handleAddCourse}
                                >
                                  <BsPlusLg />
                                  Add More
                                </button>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  <div className="form-check flex-1">
                    <>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="countrychoices"
                        name="inputs"
                        checked={!courseConfidence}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCourseConfidence(false);
                            setPreferdCourse([]);
                          } else {
                            setCourseConfidence(true);
                            setPreferdCourse([{}]);
                          }
                        }}
                        value={!courseConfidence}
                      />

                      <label className="form-check-label">
                        I haven't finalized the courses yet
                      </label>
                    </>
                  </div>
                </div>
              </div>
            </div>
            {user?.standard_alias && user?.standard_alias != "school" ? (
              <div className="formRow mb-0">
                <div className="icon">
                  <img
                    src="/assets/images/college.svg"
                    className="img-fluid"
                    width="24"
                    alt="college"
                  />
                </div>
                <div className="form-field">
                  <div className="form-group">
                    <label htmlFor="COLLEGE_PREFERENCE">
                      What Colleges are you targeting to join?
                      <sup className="astric">*</sup>
                    </label>
                    {preferedCollege.map((x, i) => {
                      return (
                        <React.Fragment key={i}>
                          {collegeConfidence ? (
                            <>
                              <Typeahead
                                id={"COLLEGE_PREFERENCE" + i}
                                labelKey="name"
                                filterBy={["name", "tags"]}
                                options={col}
                                placeholder="Choose a college preference..."
                                selected={col?.filter(
                                  (c) => c.id === x.college_id
                                )}
                                required
                                name={"college_id" + i}
                                value={x.college_id}
                                onChange={(e) => {
                                  let temp = Object.assign([], preferedCollege);
                                  temp[i].college_id = e[0]?.id;
                                  setPreferedCollege(temp);
                                }}
                                onInputChange={(search) =>
                                  getAllCollege(search)
                                }
                              />
                            </>
                          ) : null}

                          <div
                            className={
                              !collegeConfidence
                                ? "mt-2"
                                : "form-group d-flex justify-content-end mt-2"
                            }
                          >
                            {collegeConfidence ? (
                              <>
                                {preferedCollege.length !== 1 && (
                                  <div
                                    className={
                                      preferedCollege.length - 1 !== i &&
                                      "d-flex justify-content-end w-100"
                                    }
                                  >
                                    <button
                                      className="btn-add mb-2"
                                      onClick={() => removeCollege(i)}
                                    >
                                      <AiOutlineDelete />
                                      Remove
                                    </button>
                                  </div>
                                )}
                                {preferedCollege.length - 1 === i && (
                                  <button
                                    className="btn-add"
                                    onClick={handleAddClick}
                                  >
                                    <BsPlusLg />
                                    Add More
                                  </button>
                                )}
                              </>
                            ) : null}
                          </div>
                        </React.Fragment>
                      );
                    })}
                    <div className="form-check flex-1">
                      <>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="countrychoices"
                          name="inputs"
                          checked={!collegeConfidence}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCollegeConfidence(false);
                              setPreferedCollege([]);
                            } else {
                              setCollegeConfidence(true);
                              setPreferedCollege([{}]);
                            }
                          }}
                          value={!collegeConfidence}
                        />

                        <label className="form-check-label">
                          I haven't finalized the college yet
                        </label>
                      </>
                    </div>
                  </div>

                  <div className="sep"></div>
                </div>
              </div>
            ) : null}

            <div className="formRow">
              <div className="icon opacity-0">
                <img
                  src="/assets/images/world.svg"
                  className="img-fluid"
                  width="24"
                  alt="world"
                />
              </div>
              <div className="form-field">
                <div className="form-group">
                  <label htmlFor="Country-Preference-1">
                    Prospective Sessions to join college (optional)
                  </label>
                  {allInteks?.map((x, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Typeahead
                          allowNew
                          id={"Country-Preference-1" + i}
                          labelKey="name"
                          options={intakes}
                          placeholder="Choose a prospective college..."
                          selected={intakes?.filter(
                            (c) => c.name === x.preferred_intake
                          )}
                          required
                          name={"intake_id" + i}
                          value={x.preferred_intake}
                          onChange={(e) => {
                            let temp = Object.assign([], allInteks);
                            temp[i].preferred_intake = e[0]?.name;
                            temp[i].id = e[0]?.id;
                            setAllInteks(temp);
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row justify-content-end align-items-end">
              <button
                className="btn btn-cancel mx-2 d-block height-40 w-auto"
                onClick={() => setIsEdit(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn btn-green text-white mx-2 d-block height-40 px-5 w-auto"
                onClick={addPreferenceData}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPreference;
