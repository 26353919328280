import React, { useState } from "react";
import Chart from "../../../../Components/DashboardComponents/Chart/chart";

const SmartApply = () => {
  const [data, setData] = useState([
    {
      labels: ["1", "2", "3", "4", "5", "6", "7", "8"],
      datasets: [
        {
          label: "# of Votes",
          data: [40, 18, 13, 11, 7, 6, 3, 2],
          backgroundColor: [
            "#192d45",
            "#419cd2",
            "#46d6be",
            "#f7d76f",
            "#ef7f62",
            "#b996f6",
            "#b9cef2",
            "#dce7f8",
          ],
          borderWidth: 1,
        },
      ],
    },
    {
      labels: ["abcd", "efgh", "hijk", "lmno", "pqrs", "tuvw", "xyza", "abc"],
      datasets: [
        {
          label: "# of Votes",
          data: [40, 18, 13, 11, 7, 6, 3, 2],
          backgroundColor: [
            "#192d45",
            "#419cd2",
            "#46d6be",
            "#f7d76f",
            "#ef7f62",
            "#b996f6",
            "#b9cef2",
            "#dce7f8",
          ],
          borderWidth: 1,
        },
      ],
    },
  ]);

  return (
    <div className="row g-3">
      <div className="col-md-12">
        <div className="xscroll">
          <ul className="smart_apply_block">
            <li className="leads">
              <div className="icon leads"></div>
              <h3>LEADS</h3>
              <div className="count">109</div>
              <div className="pgUgCount">
                <span>PG - 40</span>
                <span>UG - 69</span>
              </div>
              <a className="block-link icon-arrow-right"></a>
            </li>
            <li className="registered_students">
              <div className="icon registered_students"></div>
              <h3>REGISTERED STUDENTS</h3>
              <div className="count">65</div>
              <div className="pgUgCount">
                <span>UG - 45</span>
                <span>PG - 20</span>
              </div>
              <a className="block-link icon-arrow-right"></a>
            </li>
            <li className="applications">
              <div className="icon applications"></div>
              <h3>APPLICATIONS</h3>
              <div className="count">45</div>
              <div className="pgUgCount">
                <span>UG - 29</span>
                <span>PG - 16</span>
              </div>
              <a className="block-link icon-arrow-right"></a>
            </li>
            <li className="visa_applications">
              <div className="icon visa_applications"></div>
              <h3>VISA APPLICATIONS</h3>
              <div className="count">109</div>
              <div className="pgUgCount">
                <span>UG - 70</span>
                <span>PG - 39</span>
              </div>
              <a className="block-link icon-arrow-right"></a>
            </li>
            <li className="enrolled_students">
              <div className="icon enrolled_students"></div>
              <h3>ENROLLED STUDENTS</h3>
              <div className="count">31</div>
              <div className="pgUgCount">
                <span>UG - 14</span>
                <span>PG - 17</span>
              </div>
              <a className="block-link icon-arrow-right"></a>
            </li>
          </ul>
        </div>
      </div>
      {data?.map((val) => {
        return (
          <>
            <div className="col-md-6">
              <Chart data={val} />
            </div>
          </>
        );
      })}

      <div className="col-md-12">
        <div className="dashboard_card">
          <div className="dashboard_card_header">
            <h2>
              <span>Revenue INR</span>
              {/* <b>
                <img
                  data-bs-toggle="tooltip"
                  src="/assets/images/info.svg"
                  width="16"
                />
              </b> */}
            </h2>
            <div className="custom_select">
              <select className="form-select me-2" name="complete Class 12">
                <option value="">UG/PG</option>
              </select>
              <select className="form-select" name="complete Class 12">
                <option value="">Intake</option>
              </select>
            </div>
          </div>
          <div className="dashboard_card_body p-0">
            <div className="p-40">
              <div className="bigVerticalProgress eightyHeight flex-column">
                <div className="horizontal_Progress">
                  <div className="graphContainer">
                    <div
                      className="progress"
                      data-toggle="tooltip"
                      title="78"
                      data-original-title="78"
                    >
                      <div
                        className="progress-bar bg-green"
                        role="progressbar"
                        style={{ width: "57.3%" }}
                        date-value="5.73 L"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        78
                      </div>
                    </div>
                  </div>
                  <label className="caption">
                    <span className="font-18 font-500">Received</span>
                  </label>
                </div>
                <div className="horizontal_Progress">
                  <div className="graphContainer">
                    <div
                      className="progress"
                      data-toggle="tooltip"
                      title="56"
                      data-original-title="56"
                    >
                      <div
                        className="progress-bar bg-darker-blue"
                        role="progressbar"
                        style={{ width: "39%" }}
                        date-value="3.99 L"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        56
                      </div>
                    </div>
                  </div>
                  <label className="caption">
                    <span className="font-18 font-500">Yet to credit</span>
                  </label>
                </div>
                <div className="horizontal_Progress">
                  <div className="graphContainer">
                    <div
                      className="progress"
                      data-toggle="tooltip"
                      title="46"
                      data-original-title="46"
                    >
                      <div
                        className="progress-bar bg-skyblue"
                        role="progressbar"
                        style={{ width: "69.9%" }}
                        date-value="6.09 L"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        46
                      </div>
                    </div>
                  </div>
                  <label className="caption">
                    <span className="font-18 font-500">Potential</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="rangeSection">
              <div className="range_placeholder">
                <label for="percentage"></label>
                <div className="range">
                  <input
                    id="percentage"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value="0"
                  />
                </div>
                <div className="notify_wrap">
                  <div className="min-value">0</div>
                  <div className="middle-value">Expected Conversion %</div>
                  <div className="max-value">100</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartApply;
