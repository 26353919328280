import React from "react"
import "./sticky-footer.css"
import { Link } from "react-router-dom"
export default function AskMe() {
  return (
    <Link className="btn-chat-ai" target="_blank" to="/ask-me" data-bs-original-title="Chat with your favourite Al Super Counselor">
      <i className="icon-headphones"></i> <span>Chat with SIA</span>
    </Link>
  )
}
