import React from "react";
import { Modal } from "react-bootstrap";

const ScoreModal = ({ isScoreShow, setIsScoreShow }) => {
  return (
    <React.Fragment>
      <Modal
        centered={true}
        show={isScoreShow}
        className="modal fade"
        dialogClassName="modal-90w"
        onHide={() => setIsScoreShow(false)}
      >
        <>
          <div className="text-center">
            <div className="radius-20">
              <div
                className="modal-body text-capitalize"
                style={{ paddingLeft: "60px", paddingRight: "60px", paddingTop: "0" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="col-md-12 px-2 px-sm-4">
                    <div className="chart d-flex justify-content-center mt-4 mb-4 pb-2">
                      <img
                        src="/assets/images/half-chart-83.jpg"
                        className="img-fluid"
                        width="410"
                        alt="halfChart"
                      />
                    </div>
                    <div className="row g-3">
                      <div className="col-6 col-md-6">
                        <div className="d-flex flex-column justify-content-center align-items-center bg-lighter-blue border-radius-6 py-3 px-4 h-100">
                          <p className="font-16 font-400 text-center mb-1">
                            Your Current Score
                          </p>
                          <h4 className="font-20 font-600 mb-0 text-center">
                            35%
                          </h4>
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="d-flex flex-column justify-content-center align-items-center bg-lighter-blue border-radius-6 py-3 px-4 h-100">
                          <p className="font-16 font-400 text-center mb-1">
                            Average Required Score
                          </p>
                          <h4 className="font-20 font-600 mb-0 text-center">
                            70% - 75%
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font-18 font-500 text-center">
                  <p className="font-16 font-400 text-center mt-4 mb-4">
                    Hey there, your current profile score falls in the medium
                    range. But, with the existing competition, we suggest that
                    you work further on your profile to make it stand out. We
                    can help you build a college-ready profile with ProMap.
                  </p>

                  <div className="d-flex flex-wrap justify-content-center">
                    <button
                      type="button"
                      className="btn-green mx-2 font-16 px-4 max-250 w-100"
                    >
                      <span>Improve your profile score</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </React.Fragment>
  );
};

export default ScoreModal;
