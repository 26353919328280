import React from "react"

export default function Introduction() {
  return (
    <div className="flex-1 d-flex align-items-end">
      <div className="bg-lighter-blue p-4 border-radius-6">
        <p className="mb-0 font-20 text-start">👋 Hi there! I’m SIA, your AI-powered career counselor. 🎓 Whether you’re exploring career options, looking for personalized guidance, or just need some expert advice, I’m here to help you find the right path. Let’s start your journey toward a brighter future!🌟</p>
      </div>
    </div>
  )
}
