import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  getCurricularData,
  updateAchievements,
} from "../../../../services/student/student-profile";
import {
  getCategory,
  getLevels,
} from "../../../../services/career/career-service";
import { useParams } from "react-router";
import EditBooks from "../../../../Components/ProfileComponents/ExtraCurricular/edit-books";
import { toast } from "react-toastify";
import EditAchievements from "../../../../Components/ProfileComponents/ExtraCurricular/edit-achievements";
import EditActivities from "../../../../Components/ProfileComponents/ExtraCurricular/edit-activities";

const ExtraCurricular = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [Edit, setEdit] = useState(false);

  const [achievementList, setAchievementList] = useState([]);
  const [booksList, setBooksList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [curricular, setCurricular] = useState([]);
  const [category, setCategory] = useState([]);
  const [levels, setLevels] = useState([]);
  const [bio, setBio] = useState();
  const addData = async (e) => {
    e.preventDefault();
    let payload = {
      bio: bio ? bio :null,
      achievments:
        achievementList?.length > 0
          ? achievementList.map((a) => {
              let t = {
                program_name: a.program_name,
                category_id: a.category_id,
                level_id: a.level_id,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],

      books:
        booksList?.length > 0
          ? booksList.map((a) => {
              let t = {
                title: a.title ? a.title : a.book_name,
                author: a.author ? a.author : a.book_author,
                description: a.description ? a.description : a.book_description,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],

      activities:
        activityList?.length > 0
          ? activityList.map((a) => {
              let t = {
                title: a.title ? a.title : a.activity_name,
                description: a.description
                  ? a.description
                  : a.activity_description,
                completion_date: a.completion_date,
              };
              return t;
            })
          : [],
    };
    await updateAchievements(payload).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        toast.success(message);
        getCurricular();
        setIsEdit(false);
        setEdit(false);
      }
    });
  };
  const getCurricular = async () => {
    await getCurricularData().then((res) => {
      setCurricular(res.data?.data);
      setBio(res.data?.data?.bio);

      if (res && res.data?.data?.achievments?.length === 0) {
        setIsEdit(false);
        setEdit(false);
      } else if (res && res.data?.data) {
        setIsEdit(false);
        setEdit(false);
        setCurricular(res.data?.data);
        setBio(res.data?.data?.bio);
        setAchievementList(res.data?.data?.achievments);
        setBooksList(res.data?.data?.books);
        setActivityList(res.data?.data?.activities);
      }
  
    });
  };

  useEffect(() => {
    getCurricular();
  }, []);

  const getCategoryList = async () => {
    await getCategory().then((res) => {
      if (res && res.data?.data) {
        setCategory(res.data?.data);
      }
    });
  };
  const getLevelsList = async () => {
    await getLevels({ type: "" }).then((res) => {
      if (res && res.data?.data) {
        setLevels(res.data?.data);
      }
    });
  };
  return (
    <div className="extraCurriculars-list">
      <div className="tab-white-block pb-3 py-4">
        <a className="pencil-edit" onClick={() => setEdit(!Edit)}></a>
        <div className="row g-4">
          <div className="col-md-12 ps-0">
            <div className="customRow-header align-items-start">
              <div className="icon icon-exam-note"></div>
              <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                <span className="font-20 font-600">About Me</span>
                {Edit ? (
                  <div>
                    <textarea
                      className="form-control"
                      style={{ boxShadow: "none", borderColor: "#dee2e6" }}
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="bio"
                      value={bio}
                      onChange={(e) => {
                        setBio(e.target.value);
                      }}
                    ></textarea>
                    <div className="mt-1 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-green text-white d-block height-40 px-5 w-auto"
                        onClick={addData}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <span className="font-16 text-black lineHeight-26">
                    {curricular?.bio ? curricular?.bio : "-"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion id="accordionProfile"
      //  defaultActiveKey="0"
       >
        <div className="curriculars mt-3">
          <Accordion.Item
            eventKey="0"
            className="mb-3"
            style={{ border: "1px solid #fff", borderRadius: "7px" }}
          >
            <Accordion.Header className="d-flex justify-content-end">
              <div className="d-flex  justify-content-between w-100">
                <div className="d-flex">
                  <div
                    className="icon-1 icon-achievements"
                    style={{
                      width: "47px",
                      height: "47px",
                      borderRadius: "50%",
                      backgroundColor: "#f8b018",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                    <span className="font-20 font-500">Achievements</span>
                  </div>
                </div>
                <p className="d-flex align-items-center mb-0">
                  <span className="icon mx-3"></span>
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              <div className="curriculars-body">
                <div className="d-flex justify-content-end w-100">
                  <p
                    className="pencil-edit mb-1"
                    onClick={() => setIsEdit(!isEdit)}
                    style={{
                      backgroundColor: "#bee0fb",
                      width: "52px",
                      height: "37px",
                      borderRadius: "19px",
                      opacity: "0.5",
                      position: "absolute",
                    }}
                  ></p>
                </div>
                <div className="customRow-detail pt-3">
                  {isEdit ? (
                    <EditAchievements
                      isEdit={isEdit}
                      achievementList={achievementList}
                      setAchievementList={setAchievementList}
                      addData={addData}
                      getCategoryList={getCategoryList}
                      getLevelsList={getLevelsList}
                      category={category}
                      levels={levels}
                      curricular={curricular}
                      setIsEdit={setIsEdit}
                    />
                  ) : (
                    <div className="row g-3">
                      <div className="col-md-12 mt-4 pt-3">
                        <div className="xscroll">
                          <table
                            className="curriculars_table"
                            style={{ minWidth: "1020px" }}
                          >
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Activity Title
                                  </div>
                                </th>
                                <th scope="col" width="350">
                                  <div className="font-16 font-500">
                                    Category
                                  </div>
                                </th>
                                <th scope="col" width="200">
                                  <div className="font-16 font-500">Level</div>
                                </th>
                                <th scope="col" width="150">
                                  <div className="font-16 font-500">
                                    Completed In
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {curricular.achievments?.map((a, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                        {a.program_name}
                                      </div>
                                    </td>
                                    <td>{a.category_name}</td>

                                    <td>{a.level_name}</td>
                                    <td>
                                      {new Date(
                                        a.completion_date
                                      ).getFullYear()}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            className="mb-3"
            style={{ border: "1px solid #fff", borderRadius: "7px" }}
          >
            <Accordion.Header className="d-flex justify-content-end">
              <div className="d-flex  justify-content-between w-100">
                <div className="d-flex">
                  <div
                    className="icon-1 icon-books"
                    style={{
                      width: "47px",
                      height: "47px",
                      borderRadius: "50%",
                      backgroundColor: "#f8b018",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                    <span className="font-20 font-500">Books</span>
                  </div>
                </div>
                <p className="d-flex align-items-center mb-0">
                  <span className="icon mx-3"></span>
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              <div className="curriculars-body">
                <div className="d-flex justify-content-end w-100">
                  <p
                    className="pencil-edit mb-1"
                    onClick={() => setIsEdit(!isEdit)}
                    style={{
                      backgroundColor: "#bee0fb",
                      width: "52px",
                      height: "37px",
                      borderRadius: "19px",
                      opacity: "0.5",
                      position: "absolute",
                    }}
                  ></p>
                </div>
                <div className="customRow-detail pt-3">
                  {isEdit ? (
                    <EditBooks
                      isEdit={isEdit}
                      booksList={booksList}
                      setBooksList={setBooksList}
                      addData={addData}
                      setIsEdit={setIsEdit}
                    />
                  ) : (
                    <div className="row g-3">
                      <div className="col-md-12 mt-4 pt-3">
                        <div className="xscroll">
                          <table
                            className="curriculars_table"
                            style={{ minWidth: "1020px" }}
                          >
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Book Title
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="font-16 font-500">Author</div>
                                </th>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Description
                                  </div>
                                </th>
                                <th scope="col" width="180">
                                  <div className="font-16 font-500">
                                    Completed In
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {curricular.books?.map((a, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div className="book-wrap">
                                        {/* <div className="book-img">
                                                <img
                                                  src="/assets/images/book_1.jpg"
                                                  alt=""
                                                  width="64"
                                                />
                                              </div>  */}
                                        <div className="book-detial">
                                          {a.book_name}
                                        </div>
                                      </div>
                                    </td>
                                    <td>{a.book_author}</td>
                                    <td>{a.book_description}</td>
                                    <td>
                                      {new Date(
                                        a.completion_date
                                      ).getFullYear()}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            className="mb-3"
            style={{ border: "1px solid #fff", borderRadius: "7px" }}
          >
            <Accordion.Header className="d-flex justify-content-end">
              <div className="d-flex  justify-content-between w-100">
                <div className="d-flex">
                  <div
                    className="icon-1 icon-activities"
                    style={{
                      width: "47px",
                      height: "47px",
                      borderRadius: "50%",
                      backgroundColor: "#f8b018",
                      marginRight: "10px",
                    }}
                  ></div>
                  <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                    <span className="font-20 font-500">Activities</span>
                  </div>
                </div>

                <p className="d-flex align-items-center mb-0">
                  <span className="icon mx-3"></span>
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              <div className="curriculars-body">
                <div className="d-flex justify-content-end w-100">
                  <p
                    className="pencil-edit mb-1"
                    onClick={() => setIsEdit(!isEdit)}
                    style={{
                      backgroundColor: "#bee0fb",
                      width: "52px",
                      height: "37px",
                      borderRadius: "19px",
                      opacity: "0.5",
                      position: "absolute",
                    }}
                  ></p>
                </div>
                <div className="customRow-detail pt-3">
                  {isEdit ? (
                    <EditActivities
                      isEdit={isEdit}
                      activityList={activityList}
                      setActivityList={setActivityList}
                      addData={addData}
                      setIsEdit={setIsEdit}
                    />
                  ) : (
                    <div className="row g-3">
                      <div className="col-md-12 mt-4 pt-3">
                        <div className="xscroll">
                          <table
                            className="curriculars_table"
                            style={{ minWidth: "1020px" }}
                          >
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Activity Title
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Activity Description
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="font-16 font-500">
                                    Completed In
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {curricular.activities?.map((a, i) => {
                                return (
                                  <tr>
                                    <td>{a.activity_name}</td>
                                    <td>{a.activity_description}</td>
                                    <td>
                                      {" "}
                                      {new Date(
                                        a.completion_date
                                      ).getFullYear()}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>
    </div>
  );
};

export default ExtraCurricular;
