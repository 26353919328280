import React from "react";
import { AiOutlineDelete } from "react-icons/ai";

const BooksTable = ({ booksData, removeBooks }) => {
  return (
    <div className="table-responsive attendee_dtl mt-4">
      <table className="table">
        <thead>
          <tr>
            <th className="university-name" width="25%">
              Book Title
            </th>
            <th width="20%">Author</th>
            <th width="40%">
              <span className="labelItem">Description</span>
            </th>
            <th width="15%">
              <span className="labelItem">Completed In</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {booksData?.length > 0 &&
            booksData?.map((a, i) => {
              return (
                <tr key={i}>
                  <td>
                    <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                      {a.book_name ? a.book_name : a.title}
                    </div>
                  </td>
                  <td>{a.book_author ? a.book_author : a.author}</td>

                  <td>
                    {a.book_description ? a.book_description : a.description}
                  </td>
                  <td>{new Date(a.completion_date).getFullYear()}</td>
                  <td>
                    <span
                      className="btn-add me-4"
                      style={{ width: "unset" }}
                      onClick={() => removeBooks(i)}
                    >
                      {" "}
                      <AiOutlineDelete />
                    </span>{" "}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default BooksTable;
