import apiHeader from "../apiHeader";
import api from "../axiosService";

export const getStudentDetails = async (params) => {
  const res = await api.get(
    "student/getStudentDetails",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};

export const getStudentEducation = async (params) => {
  const res = await api.get(
    "/student/getStudentEducationPreference",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};

export const getStudentExams = async (params) => {
  const res = await api.get(
    "student/getStudentExams",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};
export const getCurricularData = async (params) => {
  const res = await api.get(
    "student/getCurricularAndAchievment",
    
    {
      headers: apiHeader(),
      params:params
    }
  );
  return res;
};
export const getStudentManageData = async (params) => {
  const res = await api.post(
    "student/getAllAccessStudent",params,
    
    {
      headers: apiHeader(),
      // params:params
    }
  );
  return res;
};

export const getEntranceExam = async () => {
  const res = await api.post(
    "student/getEntranceExam",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getAllExams = async (param) => {
  const res = await api.post(
    "exam/getAllExams",param,
    
    {
      headers: apiHeader(),
  
    }
  );
  return res;
};

export const editEntranceExams = async (data) => {
  const res = await api.post("student/editEntranceExam", data, {
    headers: apiHeader(),
  });
  return res;
};
export const editStudentAccess = async (data) => {
  const res = await api.post("student/updateStudentAccess", data, {
    headers: apiHeader(),
  });
  return res;
};
export const getStudentAchievements = async () => {
  const res = await api.get(
    "student/getStudentAchievements",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const updateStudentDetail = async (data) => {
  const res = await api.post("student/updateStudent", data, {
    headers: apiHeader(),
  });
  return res;
};
export const updateAchievements = async (data) => {
  const res = await api.post("student/editCurricularAndAchievment", data, {
    headers: apiHeader(),
  });
  return res;
};
export const updateEducationDetail = async (data) => {
  const res = await api.post("/student/editStudentEducation", data, {
    headers: apiHeader(),
  });
  return res;
};
export const addStudentEducationDetails = async (data) => {
  const res = await api.post("student/addStudentEducation", data, {
    headers: apiHeader(),
  });
  return res;
};

export const getAllBoards = async () => {
  const res = await api.post(
    "board/getAllBoard",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getAllStandards = async () => {
  const res = await api.get("standard/getAllStandard", {
    headers: apiHeader(),
  });
  return res;
};

export const getAllSchools = async () => {
  const res = await api.post(
    "school/getAllSchools",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const createExam = async () => {
  const res = await api.post(
    "exam/createExam",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const addStudentAchievement = async (id) => {
  const res = await api.post("student/addStudentAchievement", id, {
    headers: apiHeader(),
  });
  return res;
};
export const getBoards = async () => {
  const res = await api.post(
    "board/getAllBoard",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getAllCity = async (data) => {
  const res = await api.post(
    "city/getAllCity",data,
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getAllPreferences = async (params) => {
  const res = await api.get(
    "student/getStudentPreferences",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};

export const editPreferences = async (data) => {
  const res = await api.post("student/editStudentPreferences", data, {
    headers: apiHeader(),
  });
  return res;
};

export const updateProfilePic = async (data) => {
  const res = await api.post(
    "student/updateProfilePic",
    data,
    {
      headers: apiHeader(),
    }
  );
  return res;
};
