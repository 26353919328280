import React, { useEffect, useState } from "react";
import CollegeAccordion from "../../college-components/college-accordion";
import FilterBox from "../../college-components/filterBox";
import CollegeFilterBox from "../../college-components/college-filterbox";
import ReactPaginate from "react-paginate";
import { IconContext } from "react-icons";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
const CollegeSearch = ({
  collegeData,
  courseData,
  subCourseData,
  countryData,
  cityData,
  stateData,
  // testData,
  setCourseData,
  setSubCourseData,
  setCountryData,
  setCityData,
  setStateData,
  // setTestData,
  filterData,
  setFilterData,
  loading,
  searchCityQuery,
  setSearchCityQuery,
  searchStateQuery,
  setSearchStateQuery,
  searchCountryQuery,
  setSearchCountryQuery,
  searchCourseQuery,
  setSearchCourseQuery,
  searchSubCourseQuery,
  setSearchSubCourseQuery,
  setPage,
  filteredData,
  searchCollegeQuery,
  setSearchCollegeQuery,
}) => {
  return (
    <div className="">
      <div className="row m-0 pt-4 mt-2 JobInternshipListing">
        <p className="font-16 mb-4">
          College search tools to help you find colleges from a list of over
          2000 in India and across the globe, matching your preferences.
        </p>
        <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate">
          <div className="mobileFilter mobileonly text-end">
            <button
              className="btn-filter btn-unscroll font-18 font-500"
              type="button"
            >
              <span className="icon-filter"></span>Filters
            </button>
          </div>
          <div
            className="filters leftPaneFilter collapse show"
            id="filter_collapse"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-filter btn-unscroll mobileonly"
                  type="button"
                >
                  Filters
                </button>
                <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
                  <span className="icon-filter"></span>Filters
                </h3>
              </div>
              <a
                className="font-14 font-500 text-burgandi text-decoration-underline cursor-pointer"
                onClick={() => {
                  let temp = Object.assign([], courseData);
                  temp = temp.map((t) => {
                    t["isChecked"] = false;
                    return t;
                  });
                  setCourseData(temp);
                  let SubCourseFilter = Object.assign([], subCourseData);
                  SubCourseFilter = SubCourseFilter.map((t) => {
                    t["isChecked"] = false;
                    return t;
                  });
                  setSubCourseData(SubCourseFilter);
                  let CountryFilter = Object.assign([], countryData);
                  CountryFilter = CountryFilter.map((t) => {
                    t["isChecked"] = false;
                    return t;
                  });
                  setCountryData(CountryFilter);
                  let CityFilter = Object.assign([], cityData);
                  CityFilter = CityFilter.map((t) => {
                    t["isChecked"] = false;
                    return t;
                  });
                  setCityData(CityFilter);
                  let stateFilter = Object.assign([], stateData);
                  stateFilter = stateFilter.map((t) => {
                    t["isChecked"] = false;
                    return t;
                  });
                  setStateData(stateFilter);
                  setSearchCourseQuery("");
                  setSearchSubCourseQuery("");
                  setSearchCityQuery("");
                  setSearchStateQuery("");
                  setSearchCollegeQuery("");
                  setFilterData({
                    offset: 0,
                    limit: 10,
                    course_id: "",
                    city_id: "",
                    state_id: "",
                    country_id: "",
                    has_ranking: 1,
                  });
                }}
              >
                Clear
              </a>
            </div>
            <CollegeFilterBox
              courseData={courseData}
              setCourseData={setCourseData}
              subCourseData={subCourseData}
              setSubCourseData={setSubCourseData}
              countryData={countryData}
              setCountryData={setCountryData}
              cityData={cityData}
              setCityData={setCityData}
              stateData={stateData}
              setStateData={setStateData}
              // testData={testData}
              // setTestData={setTestData}
              filterData={filterData}
              setFilterData={setFilterData}
              searchCityQuery={searchCityQuery}
              setSearchCityQuery={setSearchCityQuery}
              searchStateQuery={searchStateQuery}
              setSearchStateQuery={setSearchStateQuery}
              searchCountryQuery={searchCountryQuery}
              setSearchCountryQuery={setSearchCountryQuery}
              searchCourseQuery={searchCourseQuery}
              setSearchCourseQuery={setSearchCourseQuery}
              searchSubCourseQuery={searchSubCourseQuery}
              setSearchSubCourseQuery={setSearchSubCourseQuery}
            />
          </div>
        </div>

        <div className="flex-1 polarcontsec tab-content" id="myTabContent">
          <div className="d-flex justify-content-between flex-wrap align-items-end my-2 w-100">
            <div className="d-flex flex-wrap align-items-center">
              {!collegeData?.data?.length ? (
                ""
              ) : (
                <h3 className="font-18 font-600 mb-1">
                  {collegeData?.total_count} Colleges Found
                </h3>
              )}
            </div>

            <div className="filterSearchblk p-0 w-50 d-flex align-items-center filterSearch position-relative">
              {/* <form className="d-flex align-items-center filterSearch position-relative"> */}
              <input
                className="form-control flex-1 font-14 font-500 w-100 bg-white  mt-1"
                type="search"
                placeholder="Search"
                value={searchCollegeQuery}
                onChange={(e) => setSearchCollegeQuery(e.target.value)}
              />
              <button
                className="btn icon-search"
                style={{ top: "11px", right: "19px" }}
                type="button"
              ></button>
              {/* </form> */}
            </div>
          </div>
          <div
            className="ActiveListings_present"
            style={{ height: "100vh", overflow: "scroll" }}
          >
            <div className="row mt-0 college-details">
              {" "}
              {filteredData?.length ? (
                <>
                  {filteredData?.map((x, i) => {
                    return (
                      <div className="col-md-12" key={i}>
                        <div className="">
                          <CollegeAccordion data={x} />
                        </div>
                      </div>
                    );
                  })}{" "}
                </>
              ) : (
                <>
                  {loading ? "" : <h4 className="ms-5"> No Records Found.</h4>}
                </>
              )}
            </div>
            {loading && (
              <a className="load_more_btn">
                <img src="/assets/images/load_more_icon.jpg" />
                Load More
              </a>
            )}
            {!collegeData?.data?.length ? (
              ""
            ) : (
              <div className="pagination-div">
                <ReactPaginate
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  onPageChange={(event) => {
                    setFilterData({
                      ...filterData,
                      offset: parseInt(event.selected) * 10,
                    });
                  }}
                  breakLabel="..."
                  pageCount={Math.ceil(collegeData?.total_pages)}
                  previousLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "36px" }}
                    >
                      <AiFillLeftCircle />
                    </IconContext.Provider>
                  }
                  nextLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "36px" }}
                    >
                      <AiFillRightCircle />
                    </IconContext.Provider>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeSearch;
