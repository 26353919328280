import React from "react";

const FindOut = () => {
  return (
    <>
      <div className="main flex-1">
        <div className="max-1140 mx-auto d-flex h-100">
          <div className="flex-1">
            <div className="row overflow-hidden justify-content-center border-box-color border-radius-10">
              <div className="col-md-12">
                <div className="relevance_score_section">
                  <div className="max-980 mx-auto margin-top-40 mb-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-center flex-column text-center pb-4 margin-bottom-30 border-bottom">
                          <p className="font-26 font-600 lineHeight-30 mb-2 pb-1">
                            How Important Is Profile Building for You to Get
                            <br />
                            Admission in Your Dream College?
                          </p>
                          <p className="btn-find">Find Out Now</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="preferences-option-parent">
                          <h3 className="font-20 font-600 mb-3">
                            Select Your Preferences
                          </h3>
                          <div className="preferences-option">
                            <div className="preferences-option-list">
                              <div className="icon">
                                <img
                                  src="/assets/images/world.svg"
                                  className="img-fluid"
                                  width="24"
                                  alt="world"
                                />
                              </div>
                              <div className="preferences-option-select">
                                <select
                                  className="form-select form-modal wd-96"
                                  aria-label="Default select example"
                                >
                                  <option selected="">Country</option>
                                  <option value="1">India</option>
                                  <option value="2">Canada</option>
                                  <option value="3">China</option>
                                </select>
                              </div>
                            </div>
                            <div className="preferences-option-list">
                              <div className="icon">
                                <img
                                  src="/assets/images/open-book-white.svg"
                                  className="img-fluid"
                                  width="24"
                                  alt="book"
                                />
                              </div>
                              <div className="preferences-option-select">
                                <select
                                  className="form-select form-modal wd-96"
                                  aria-label="Default select example"
                                >
                                  <option selected="">Course</option>
                                  <option value="1">Course 1</option>
                                  <option value="2">Course 2</option>
                                  <option value="3">Course 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="preferences-option-list">
                              <div className="icon">
                                <img
                                  src="/assets/images/college.svg"
                                  className="img-fluid"
                                  width="24"
                                  alt="college"
                                />
                              </div>
                              <div className="preferences-option-select">
                                <select
                                  className="form-select form-modal wd-96"
                                  aria-label="Default select example"
                                >
                                  <option selected="">College</option>
                                  <option value="1">College 1</option>
                                  <option value="2">College 2</option>
                                  <option value="3">College 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="preferences-option-list">
                              <div className="icon">
                                <img
                                  src="/assets/images/brife-case.jpg"
                                  className="img-fluid"
                                  width="24"
                                  alt="brife-case"
                                />
                              </div>
                              <div className="preferences-option-select">
                                <select
                                  className="form-select form-modal wd-96"
                                  aria-label="Default select example"
                                >
                                  <option selected="">Career</option>
                                  <option value="1">Career 1</option>
                                  <option value="2">Career 2</option>
                                  <option value="3">Career 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="d-flex">
                              <button className="btn-green height-45 px-5">
                                <span>Submit</span>
                              </button>
                              <button className="clear-btn ms-3">Clear</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="chart d-flex justify-content-center mt-4 mb-3">
                          <img
                            src="/assets/images/half-chart.jpg"
                            className="img-fluid"
                            width="410"
                            alt="chart"
                          />
                        </div>
                        <div className="row g-3">
                          <div className="col-6 col-md-6">
                            <div className="d-flex flex-column justify-content-center align-items-center bg-lighter-blue border-radius-6 py-3 px-4 h-100">
                              <p className="font-16 font-400 text-center mb-1">
                                Required Ideal Score
                              </p>
                              <h4 className="font-20 font-600 mb-0 text-center">
                                67%
                              </h4>
                            </div>
                          </div>
                          <div className="col-6 col-md-6">
                            <div className="d-flex flex-column justify-content-center align-items-center bg-lighter-blue border-radius-6 py-3 px-4 h-100">
                              <p className="font-16 font-400 text-center mb-1">
                                Average Student's Score
                              </p>
                              <h4 className="font-20 font-600 mb-0 text-center">
                                20%-30%
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="border-top mt-4 pt-4">
                          <p className="font-16 lineHeight-22 px-2 px-sm-4 px-md-5 text-center margin-bottom-30">
                            Hi there! The Course, Country and College you
                            selected look for balance between Academics &amp; a
                            STRONG Non-academic profile. 9th to 12th standard
                            are very important years for building your CV. To
                            increase your admission chances, build a Strong
                            Profile/CV with ProMap today.
                          </p>
                        </div>
                        <div className="px-4 py-2 bg-darker-blue border-radius-10">
                          <div className="my-2 d-flex flex-wrap justify-content-center align-items-center">
                            <p className="text-white font-16 font-500 m-0 text-center mb-3 mb-sm-0">
                              Is Your Current Profile Good Enough?
                            </p>
                            <button className="btn-white mx-4 my-2">
                              Check Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FindOut;
