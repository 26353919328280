import React, { useEffect } from "react";
import { createProductOrder } from "../../../services/products/product-service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";

const ProductCheckout = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  const [cartData, setCartData] = React.useState(null);
  const getTotal = (cart) => {
    return cart.reduce((a, b) => a + b.total, 0);
  };

  const getTax = (cart) => {
    return cart.reduce((a, b) => a + b.tax, 0);
  };

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src =
      "https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js";
    script1.async = true;
    document.head.appendChild(script1);
    const script2 = document.createElement("script");
    script2.src =
      "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
    script2.async = true;
    document.head.appendChild(script2);
    // return () => {
    //   // Cleanup if necessary
    //   document?.head?.removeChild(script1);
    //   document?.head?.removeChild(script2);
    // };
  }, []);

  const doPayment = () => {
    placeOrder();
    // localStorage.removeItem("cart");
    // window.$.pnCheckout(cartData);
  };
  const placeOrder = async () => {
    let payload = cart.map((x) => {
      return {
        product_id: x.product.id,
        quantity: x.quantity,
      };
    });
    const res = await createProductOrder(payload);
    if (res?.data?.meta?.status) {
      setCartData(res.data.data);
      localStorage.removeItem("cart");
      window.$.pnCheckout(res.data.data);
    }
  };

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="mt-4 pb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="checkout_container">
                  <div className="checkout_steps">
                    <ul>
                      <li>
                        <b>1</b>
                        <span>SHOPPING CART</span>
                      </li>
                      <li className="active">
                        <b>2</b>
                        <span>CHECKOUT DETAILS</span>
                      </li>
                      <li>
                        <b>3</b>
                        <span>ORDER COMPLETE</span>
                      </li>
                    </ul>
                  </div>
                  <div className="checkout_summary">
                    <div className="billing_details">
                      <div className="title">
                        <h2>Billing Details</h2>
                      </div>
                      <div className="billing_detail_form">
                        <form action="" className="row g-4">
                          <div className="col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email Address
                                <span className="astric">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="ShopLoginFormModel_email"
                                value={user?.email}
                                readOnly
                                placeholder=""
                                required=""
                              />
                              <div className="invalid-feedback">
                                Please Enter vaild Email Address
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group form-control-firstName">
                              <label className="form-label">
                                First Name
                                <span className="astric">*</span>
                              </label>
                              <input
                                type="text"
                                value={user?.fname}
                                readOnly
                                className="form-control font-14 font-500"
                                id="ShopSignupFormModel[first_name]"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group form-control-lastName">
                              <label className="form-label">
                                Last Name
                                <span className="astric">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control font-14 font-500"
                                value={user?.lname}
                                readOnly
                                id="ShopSignupFormModel[last_name]"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group form-control-lastName">
                              <label className="form-label">Company Name</label>
                              <input
                                type="text"
                                value={user?.organization}
                                readOnly
                                className="form-control font-14 font-500"
                                id="ShopSignupFormModel[Company_name]"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label">
                              Phone
                              <span className="astric">*</span>
                            </label>
                            <div className="row g-2">
                              <div className="col-auto">
                                <select
                                  className="form-select font-14 font-500"
                                  aria-label="Class"
                                  required=""
                                >
                                  <option selected="">91</option>
                                  <option value="1">92</option>
                                  <option value="2">93</option>
                                </select>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    placeholder="Mobile Number"
                                    className="form-control"
                                    type="text"
                                    value={user?.phone}
                                    readOnly
                                    name="ShopSignupFormModel[mobile_number]"
                                    id="ShopSignupFormModel_mobile_number"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="checkout_cart_detail">
                    <div className="cart_detail">
                      <h2>Your Order</h2>
                      <div className="order_details_section">
                        <div className="order_title">
                          <label>PRODUCT</label>
                          <span>SUBTOTAL</span>
                        </div>
                        {cart.map((x, i) => {
                          return (
                            <div className="order_details_row">
                              <label>
                                {x?.product?.name}
                                <b>X {x?.quantity}</b>
                              </label>
                              <span>
                                <b>₹</b>
                                {/* {parseInt(x?.product?.price) * x?.quantity} */}
                                {(
                                  (parseInt(x?.product?.price) -
                                    (x?.product.discount_type === "flat"
                                      ? x?.product.discount
                                      : (x?.product.discount / 100) *
                                        x?.product.price)) *
                                  x?.quantity
                                ).toFixed(2)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="order_total_subtotal">
                        <div className="total_subtotal_row">
                          <label>Subtotal:</label>
                          <span>
                            <b>₹</b>
                            {(getTotal(cart) - +getTax(cart)).toFixed(2)}
                          </span>
                        </div>
                        <div className="total_subtotal_row">
                          <label>GST:</label>
                          <span>
                            <b>₹</b>
                            {getTax(cart).toFixed(2)}
                          </span>
                        </div>
                        <div className="total_subtotal_row">
                          <label>Total:</label>
                          <span>
                            <b>₹</b>
                            {getTotal(cart).toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="coupon_code_other">
                        <div className="cart_text">
                          <div className="cart_text_logo">
                            <span>Pay securly through Paynimo.</span>
                            <b>
                              <img src="images/paynimo.png" alt="" width="74" />
                            </b>
                          </div>
                          <div className="btn_text">
                            <button
                              className="btn-proceed"
                              onClick={() => doPayment()}
                            >
                              Place Order
                            </button>
                          </div>
                          <p>
                            Your personal data will be used to process your
                            order, support your experience throughout this
                            website, and for other purposes described in our
                            privacy policy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cart_summary_fixed">
                    <div className="blue_section">
                      <div className="total">
                        <label>Total</label>
                        <span>
                          <b>₹</b>
                          68,440
                        </span>
                      </div>
                      <div className="toggle_btn">
                        <a
                          className="top-arrow"
                          data-bs-toggle="collapse"
                          href="#price_Detail"
                          role="button"
                          aria-expanded="false"
                          aria-controls="price_Detail"
                        ></a>
                      </div>
                      <div className="mb_proceed_btn">
                        <button className="btn-proceed">Place Order</button>
                      </div>
                    </div>
                    <div className="collapse" id="price_Detail">
                      <div className="card_details_section">
                        <div className="price_row">
                          <label>Subtotal</label>
                          <span>
                            <b>₹</b>
                            58,000
                          </span>
                        </div>
                        <div className="price_row">
                          <label>GST</label>
                          <span>
                            <b>₹</b>
                            10,440
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductCheckout;
