import React, { useState } from "react";
import ExploreFilterBox from "../../../Components/ExploreList/explore-filterbox";
import ExploreAccordion from "../../../Components/ExploreList/explore-accordion";

const Recommend = () => {
  const data = [
    { uniName: "University Of Toronto" },
    { uniName: "University Of Toronto" },
    { uniName: "University Of Toronto" },
    { uniName: "University Of Toronto" },
  ];
  const [loading, setLoading] = useState(true);

  return (
    <div className="">
      <div className="row m-0 pt-4 pb-5 JobInternshipListing">
        <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate">
          <div className="mobileFilter mobileonly text-end">
            <button
              className="btn-filter btn-unscroll font-18 font-500"
              type="button"
            >
              <span className="icon-filter"></span>
              Filters
            </button>
          </div>
          <div
            className="filters leftPaneFilter collapse show"
            id="filter_collapse"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-filter btn-unscroll mobileonly"
                  type="button"
                >
                  Filters
                </button>
                <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
                  <span className="icon-filter"></span>
                  Filters
                </h3>
              </div>
              <a className="font-14 font-500 text-burgandi text-decoration-underline">
                Clear
              </a>
            </div>

            <ExploreFilterBox />
          </div>
        </div>

        <div className="flex-1 polarcontsec tab-content">
          <div className="ActiveListings_present">
            <div className="d-flex flex-wrap align-items-end my-2 w-100">
              <div className="d-flex flex-wrap align-items-center">
                <h3 className="font-18 font-500 mb-0">44 profiles found</h3>
              </div>
            </div>
            <div className="row mt-0 college-details">
              {data?.map((x, i) => {
                return (
                  <>
                    <div className="col-md-12" key={i}>
                      <div className="">
                        <ExploreAccordion data={x} />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {loading && (
              <a className="load_more_btn">
                <img src="/assets/images/load_more_icon.jpg" />
                Load More
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommend;
