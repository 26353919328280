import React, { useEffect, useState } from "react";
import ProductCard from "../../../../Components/DashboardComponents/ProductCard/product-card";
import {
  getAllProductInventory,
  getInventoryConsumption,
} from "../../../../services/products/product-service";

const Products = () => {
  const [consumption, setConsumption] = useState([]);
  const [orders, setOrders] = useState();

  const getUserOrders = async () => {
    const res = await getAllProductInventory();
    if (res?.data?.meta?.status) {
      setOrders(res?.data?.data?.data);
    }
  };

  const getConsumption = async () => {
    const res = await getInventoryConsumption({});
    console.log(res);
    if (res?.data?.meta?.status) {
      setConsumption(res.data.data.data);
    }
  };

  useEffect(() => {
    getUserOrders();
    getConsumption();
  }, []);

  console.log(consumption);
  return (
    <div className="flex-1 polarcontsec">
      <div className="row g-3">
        {consumption?.map((x) => {
          console.log(x);
          return (
            <>
              <div className="col-md-12">
                <ProductCard data={x} tableData={orders} />
              </div>
            </>
          );
        })}

        {/* <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="dashboard_card">
            <div className="dashboard_card_header">
              <h2>
                <span>Earnings INR</span>
                <b>
                  <img
                    data-bs-toggle="tooltip"
                    title=""
                    src="images/info.svg"
                    data-bs-original-title="important information"
                    aria-label="important information"
                    width="16"
                  />
                </b>
              </h2>
              <div className="custom_select">
                <select
                  className="form-select w-140 me-2"
                  name="complete Class 12"
                  aria-label="Default select example"
                >
                  <option value="">All Channels</option>
                </select>
                <select
                  className="form-select w-140"
                  name="complete Class 12"
                  aria-label="Default select example"
                >
                  <option value="">All Products</option>
                </select>
              </div>
            </div>
            <div className="dashboard_card_body p-0">
              <div className="p-40">
                <div className="bigVerticalProgress eightyHeight flex-column">
                  <div className="horizontal_Progress">
                    <div className="graphContainer">
                      <div
                        className="progress"
                        data-toggle="tooltip"
                        title="78"
                        data-original-title="78"
                      >
                        <div
                          className="progress-bar bg-green"
                          role="progressbar"
                          style={{ width: "57.3%" }}
                          date-value="571 K"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          571 K
                        </div>
                      </div>
                    </div>
                    <label className="caption">
                      <span className="font-18 font-500">Received</span>
                    </label>
                  </div>
                  <div className="horizontal_Progress">
                    <div className="graphContainer">
                      <div
                        className="progress"
                        data-toggle="tooltip"
                        title="56"
                        data-original-title="56"
                      >
                        <div
                          className="progress-bar bg-darker-blue"
                          role="progressbar"
                          style={{ width: "39%" }}
                          date-value="399 K"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          399 K
                        </div>
                      </div>
                    </div>
                    <label className="caption">
                      <span className="font-18 font-500">Yet to credit</span>
                    </label>
                  </div>
                  <div className="horizontal_Progress">
                    <div className="graphContainer">
                      <div
                        className="progress"
                        data-toggle="tooltip"
                        title="46"
                        data-original-title="46"
                      >
                        <div
                          className="progress-bar bg-skyblue"
                          role="progressbar"
                          style={{ width: "69.9%" }}
                          date-value="609 K"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          609 K
                        </div>
                      </div>
                    </div>
                    <label className="caption">
                      <span className="font-18 font-500">Potential</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="rangeSection">
                <div className="range_placeholder">
                  <label for="percentage"></label>
                  <div className="range">
                    <input
                      id="percentage"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      value="0"
                    />
                  </div>
                  <div className="notify_wrap">
                    <div className="min-value">0</div>
                    <div className="middle-value">Expected Conversion %</div>
                    <div className="max-value">100</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Products;
