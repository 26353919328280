import React, { useEffect, useRef, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import {
  getIntakes,
  getCareers,
  getColleges,
  getCountries,
  removeCollege,
  removeCountry,
  getStudentColleges,
  addStudentPreferedCollege,
  addStudentPreferedCareers,
  addStudentPreferedCountries,
  removeStudentCareers,
  getPreferedCareers,
  createCareers,
  getPreferedCountries,
  getPreferedIntakes,
  addStudentIntake,
} from "../../../services/career/career-service";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TellAboutCareer = () => {
  const [collegeConfidence, setCollegeConfidence] = useState(true);
  const [selectedCountries, setselectedCountries] = useState(true);
  const [selectedCareer, setSelectedCareer] = useState(true);

  const [preferedCollege, setPreferedCollege] = useState([{ college_id: "" }]);
  const [preferedCountry, setPreferedCountry] = useState([{ country_id: "" }]);
  const [preferedCareer, setPreferedCareer] = useState([{ career_id: "" }]);
  const [allInteks, setAllInteks] = useState([{ preferred_intake: "" }]);
  const [colleges, setColleges] = useState([]);
  const [country, setCountry] = useState([]);
  const [intakes, setIntakes] = useState([]);
  const [career, setCareer] = useState([]);
  const timeoutRef = useRef();

  const navigate = useNavigate();

  const goToNextPage = () => {
    if (
      preferedCollege?.length >= 1 ||
      preferedCountry?.length >= 1 ||
      preferedCareer?.length >= 1 ||
      allInteks?.length >= 1
    ) {
      navigate("/student/dashboard");
    } else {
      toast("Please Complete the form fields");
    }
  };

  const getAllCollege = async (payload) => {
    await getColleges(payload).then((res) => {
      if (res.data?.data) {
        setColleges(res.data?.data);
      }
    });
  };
  // const getAllCountryes = async (payload) => {
  //   await getCountries({payload}).then((res) => {
  //     if (res.data?.data?.records) {
  //       let temp = res.data?.data?.records?.map((x) => {
  //         let payload = {
  //           id: x.id,
  //           name: x.name,
  //           alias: x.alias,
  //           logo: x.logo,
  //           flag: x.flag,
  //           phone_code: x.phone_code,
  //         };
  //         return payload;
  //       });
  //       if (temp.length) {
  //         setCountry(temp);
  //       }
  //     }
  //   });
  // };

  const getAllCountryes = async (payload = {}) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      await getCountries(payload).then((res) => {
        if (res.data?.data?.records) {
          if (res.data?.data?.records?.length) {
            setCountry(res.data?.data?.records);
          }
        }
      });
    }, 500);
  };
  const getAllIntakes = async () => {
    await getIntakes().then((res) => {
      if (res?.data?.data?.records.length) {
        setIntakes(res?.data?.data?.records);
      }
    });
  };

  const getAllCareer = async () => {
    await getCareers().then((res) => {
      if (res.data?.data?.records?.length) {
        setCareer(res.data?.data?.records);
      }
    });
  };

  const getStundentPreferedCollege = async () => {
    await getStudentColleges().then((res) => {
      if (res && res.data?.data) {
        let temp = res.data?.data?.map((x) => {
          let p = {
            city_id: x.city_id,
            description: x.description,
            id: x.college_id,
            name: x.name,
            title: x.title,
          };
          return p;
        });
        if (temp.length) {
          setPreferedCollege(temp);
        }
      }
    });
  };
  const getStundentPreferedCountries = async () => {
    await getPreferedCountries().then((res) => {
      if (res && res.data?.data) {
        let temp = res.data?.data?.map((x) => {
          let p = {
            country_id: x.country_id,
            id: x.id,
            name: x.name,
            title: x.alias,
          };
          return p;
        });
        if (temp.length) {
          setPreferedCountry(temp);
        }
      }
    });
  };
  const getStudentPreferedCareers = async () => {
    await getPreferedCareers().then((res) => {
      if (res && res.data?.data) {
        let temp = res.data?.data?.map((v) => {
          let p = {
            id: v.career_id,
            name: v.career_name,
          };
          return p;
        });
        if (temp.length) {
          setPreferedCareer(temp);
        }
      }
    });
  };

  const getAllStudentsPreferdIntakes = async () => {
    await getPreferedIntakes().then((res) => {
      if (res && res.data?.data) {
        setAllInteks(res.data?.data);
      }
    });
  };

  const removeStudentPreferdCollege = async (college_id, i) => {
    if (college_id) {
      await removeCollege({ college_id: college_id }).then((res) => {
        if (res) {
          const temp = Object.assign([], preferedCollege);
          temp.splice(i, 1);
          setPreferedCollege(temp);
        }
      });
    } else {
      const temp = Object.assign([], preferedCollege);
      temp.splice(i, 1);
      setPreferedCollege(temp);
    }
  };

  const removeStudentPreferdCountry = async (country_id, i) => {
    if (country_id) {
      await removeCountry({ country_id: country_id }).then((res) => {
        if (res) {
          const temp = Object.assign([], preferedCountry);
          temp.splice(i, 1);
          setPreferedCountry(temp);
          if (res && res.data) {
            getStundentPreferedCountries();
          }
        }
      });
    } else {
      const temp = Object.assign([], preferedCountry);
      temp.splice(i, 1);
      setPreferedCountry(temp);
    }
  };

  const removeStudentPreferdCareer = async (career_id, i) => {
    if (career_id) {
      console.log(career_id);
      await removeStudentCareers({ career_id: career_id }).then((res) => {
        if (res) {
          const temp = Object.assign([], preferedCareer);
          temp.splice(i, 1);
          setPreferedCareer(temp);
        }
      });
    } else {
      const temp = Object.assign([], preferedCareer);
      temp.splice(i, 1);
      setPreferedCareer(temp);
    }
  };

  useEffect(() => {
    getAllCollege();
    getAllIntakes();
    getAllCareer();
    getAllCountryes();
    getStundentPreferedCollege();
    getStundentPreferedCountries();
    getAllStudentsPreferdIntakes();
    getStudentPreferedCareers();
  }, []);

  const addPrefererdCollege = async () => {
    let data = {
      has_confidence: collegeConfidence,
      college_id: collegeConfidence
        ? preferedCollege.map((e) => {
            let t = {
              id: e.id,
            };
            return t;
          })
        : [],
    };
    await addStudentPreferedCollege(data).then((res) => {
      if (res && res.data) {
        getStundentPreferedCollege();
      }
    });
  };

  const addPreferedCountries = async () => {
    let data = {
      country_id: preferedCountry.map((e) => {
        let t = e.id;
        return t;
      }),
    };
    await addStudentPreferedCountries(data).then((res) => {
      if (res && res.data) {
        getStundentPreferedCountries();
      }
    });
  };

  const addPreferedCareer = async (id) => {
    let data = {
      career_id: preferedCareer.map((e) => {
        let t = e.id;
        return t;
      }),
    };
    await addStudentPreferedCareers(data).then((res) => {
      if (res && res.data) {
        getStudentPreferedCareers();
      }
    });
  };

  const addIntake = async (data) => {
    await addStudentIntake(data).then((res) => {
      if (res && res.data) {
        getAllStudentsPreferdIntakes();
      }
    });
  };

  const createCareer = async (e) => {
    let payload = {
      name: e.name,
      status: e.status,
    };
    await createCareers(payload).then((res) => {
      if (res.data.data) {
        // setPreferedCareer(res.data.data);
      }
    });
  };

  const handleAddClick = () => {
    setPreferedCollege([...preferedCollege, { id: "" }]);
  };
  const handleAdd = () => {
    setPreferedCountry([...preferedCountry, { country_id: "" }]);
  };
  const handleAddCareer = () => {
    setPreferedCareer([...preferedCareer, { career_id: "" }]);
  };
  return (
    <React.Fragment>
      <div className="main flex-1">
        <div className="container  d-flex h-100">
          <div className="flex-1 d-flex flex-column">
            <div className="flex-1">
              <div className="d-flex justify-content-between align-items-start pt-4 mt-3 pb-1">
                <div className="welcome-title">
                  <h1>Tell us about your Career Preferences</h1>
                  <p>
                    Your career preferences help us provide you with the most
                    relevant and updated information!
                  </p>
                </div>
              </div>
              <div className="my-3 profile_setup_cont">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12 profile_setup p-0">
                    <div className="carrer-left-part formBody ">
                      <div className="formSection">
                        <div className="formRow">
                          <div className="icon">
                            <img
                              src="/assets/images/college.svg"
                              className="img-fluid"
                              width="24"
                              alt="college"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label>
                                What Colleges are you targeting to join?
                                <sup className="astric">*</sup>
                              </label>

                              {preferedCollege.map((x, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    {collegeConfidence ? (
                                      <>
                                        <Typeahead
                                          // allowNew
                                          id={"COLLEGE_PREFERENCE" + i}
                                          labelKey="name"
                                          options={colleges}
                                          filterBy={["name", "tags"]}
                                          placeholder="Choose a college..."
                                          selected={
                                            colleges &&
                                            colleges?.filter(
                                              (c) => c.id === x.id
                                            )
                                            // preferedCollege.length &&
                                            // preferedCollege[i]?.id
                                            //   ? [
                                            //       {
                                            //         name: preferedCollege[i]?.name,
                                            //         id: parseInt(
                                            //           preferedCollege[i]?.id
                                            //         ),
                                            //       },
                                            //     ]
                                            //   : []
                                          }
                                          required
                                          name={"college_id" + i}
                                          value={x?.id}
                                          onChange={(e) => {
                                            let temp = Object.assign(
                                              [],
                                              preferedCollege
                                            );
                                            temp[i].id = e[0]?.id;
                                            setPreferedCollege(temp);
                                            if (e[0]?.id) {
                                              addPrefererdCollege();
                                            }
                                          }}
                                          onInputChange={(search) =>
                                            getAllCollege({ search })
                                          }
                                        />
                                      </>
                                    ) : null}

                                    <div
                                      className={
                                        !collegeConfidence
                                          ? "mt-2"
                                          : "form-group d-flex justify-content-between mt-2"
                                      }
                                    >
                                      {preferedCollege.length - 1 === i && (
                                        <div className="form-check flex-1">
                                          <>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="countrychoices"
                                              name="inputs"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setCollegeConfidence(false);
                                                } else {
                                                  setCollegeConfidence(true);
                                                }
                                              }}
                                              value={!collegeConfidence}
                                            />

                                            <label className="form-check-label">
                                              I haven't finalized the college
                                              yet
                                            </label>
                                          </>
                                        </div>
                                      )}
                                      {collegeConfidence ? (
                                        <>
                                          {preferedCollege.length !== 1 && (
                                            <div
                                              className={
                                                preferedCollege.length - 1 !==
                                                  i &&
                                                "d-flex justify-content-end w-100"
                                              }
                                            >
                                              <button
                                                className="btn-add mb-2"
                                                onClick={() =>
                                                  removeStudentPreferdCollege(
                                                    x.id,
                                                    i
                                                  )
                                                }
                                              >
                                                <AiOutlineDelete />
                                                Remove
                                              </button>
                                            </div>
                                          )}

                                          {preferedCollege.length - 1 === i && (
                                            <button
                                              className="btn-add"
                                              onClick={handleAddClick}
                                            >
                                              <BsPlusLg />
                                              Add More
                                            </button>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="formRow">
                          <div className="icon">
                            <img
                              src="/assets/images/world.svg"
                              className="img-fluid"
                              width="24"
                              alt="world"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label>
                                What is your preferred country?
                                <sup className="astric">*</sup>
                              </label>
                              {preferedCountry.map((x, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    {selectedCountries ? (
                                      <>
                                        {console.log(preferedCountry, country)}
                                        <Typeahead
                                          allowNew
                                          id={"Country-Preference-1" + i}
                                          labelKey="name"
                                          options={country}
                                          placeholder="Choose a country..."
                                          selected={
                                            //    country?.filter(
                                            //   (c) => c.id === x.id
                                            // )
                                            preferedCountry.length &&
                                            preferedCountry[i]?.country_id
                                              ? [
                                                  {
                                                    name: preferedCountry[i]
                                                      ?.name,
                                                    id: parseInt(
                                                      preferedCountry[i]?.id
                                                    ),
                                                  },
                                                ]
                                              : []
                                          }
                                          name={"country_id" + i}
                                          value={x.country_id}
                                          onChange={(e) => {
                                            let temp = Object.assign(
                                              [],
                                              preferedCountry
                                            );
                                            temp[i].id = e[0]?.id;
                                            setPreferedCountry(temp);
                                            if (e[0]?.id) {
                                              addPreferedCountries();
                                            }
                                          }}
                                          required
                                          onInputChange={(search) =>
                                            getAllCountryes({ search })
                                          }
                                        />
                                      </>
                                    ) : null}
                                    <div
                                      className={
                                        !selectedCountries
                                          ? "mt-2"
                                          : "form-group d-flex justify-content-between mt-2"
                                      }
                                    >
                                      {preferedCountry.length - 1 === i && (
                                        <div className="form-check flex-1">
                                          <>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="countrychoices"
                                              name="inputs"
                                              value={!selectedCountries}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setselectedCountries(false);
                                                } else {
                                                  setselectedCountries(true);
                                                }
                                              }}
                                            />

                                            <label className="form-check-label">
                                              I haven't finalized the country
                                              yet
                                            </label>
                                          </>
                                        </div>
                                      )}
                                      {selectedCountries ? (
                                        <>
                                          {preferedCountry.length !== 1 && (
                                            <div
                                              className={
                                                preferedCountry.length - 1 !==
                                                  i &&
                                                "d-flex justify-content-end w-100"
                                              }
                                            >
                                              <button
                                                className="btn-add mb-2"
                                                onClick={() =>
                                                  removeStudentPreferdCountry(
                                                    x.id,
                                                    i
                                                  )
                                                }
                                              >
                                                <AiOutlineDelete />
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                          {preferedCountry.length - 1 === i && (
                                            <button
                                              className="btn-add"
                                              onClick={handleAdd}
                                            >
                                              <BsPlusLg />
                                              Add More
                                            </button>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="formRow">
                          <div className="icon">
                            <img
                              src="/assets/images/career.svg"
                              className="img-fluid"
                              width="24"
                              alt="career"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label>
                                What is your preferred career?
                                <sup className="astric">*</sup>
                              </label>

                              {preferedCareer.map((x, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    {selectedCareer ? (
                                      <>
                                        <Typeahead
                                          allowNew
                                          id={"Career-Preference" + i}
                                          labelKey="name"
                                          options={career}
                                          placeholder="Choose a career..."
                                          selected={career.filter(
                                            (c) => c.id === x.id
                                          )}
                                          name={"career_id" + i}
                                          value={x.id}
                                          onChange={(e) => {
                                            if (e.customOption) {
                                              createCareer(e);
                                            } else {
                                              let temp = Object.assign(
                                                [],
                                                preferedCareer
                                              );
                                              temp[i].id = e[0]?.id;
                                              setPreferedCareer(temp);
                                              if (e[0]?.id) {
                                                addPreferedCareer();
                                              }
                                            }
                                          }}
                                          required
                                        />
                                      </>
                                    ) : null}
                                    <div
                                      className={
                                        !selectedCareer
                                          ? "mt-2"
                                          : "form-group d-flex justify-content-between mt-2"
                                      }
                                    >
                                      {preferedCareer.length - 1 === i && (
                                        <div className="form-check flex-1">
                                          <>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="countrychoices"
                                              name="inputs"
                                              value={!selectedCareer}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setSelectedCareer(false);
                                                } else {
                                                  setSelectedCareer(true);
                                                }
                                              }}
                                            />

                                            <label className="form-check-label">
                                              I haven't finalized the career yet
                                            </label>
                                          </>
                                        </div>
                                      )}
                                      {selectedCareer ? (
                                        <>
                                          {preferedCareer.length !== 1 && (
                                            <div
                                              className={
                                                preferedCareer.length - 1 !==
                                                  i &&
                                                "d-flex justify-content-end w-100"
                                              }
                                            >
                                              <button
                                                className="btn-add mb-2"
                                                onClick={() =>
                                                  removeStudentPreferdCareer(
                                                    x?.id,
                                                    i
                                                  )
                                                }
                                              >
                                                <AiOutlineDelete />
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                          {preferedCareer.length - 1 === i && (
                                            <button
                                              className="btn-add"
                                              onClick={handleAddCareer}
                                            >
                                              <BsPlusLg />
                                              Add More
                                            </button>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="formRow">
                          <div className="icon opacity-0">
                            <img
                              src="/assets/images/world.svg"
                              className="img-fluid"
                              width="24"
                              alt="world"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label>
                                Prospective Sessions to join college (optional)
                              </label>
                              {allInteks?.map((x, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <Typeahead
                                      allowNew
                                      id={"Country-Preference-1" + i}
                                      labelKey="name"
                                      options={intakes}
                                      placeholder="Choose a college..."
                                      selected={intakes?.filter(
                                        (c) => c.name === x.preferred_intake
                                      )}
                                      required
                                      name={"intake_id" + i}
                                      value={x.preferred_intake}
                                      onChange={(e) => {
                                        let temp = Object.assign([], allInteks);
                                        temp[i].preferred_intake = e;
                                        setAllInteks(temp);
                                        if (e[0]?.id) {
                                          addIntake({
                                            preferred_intake: e[0]?.name,
                                          });
                                        }
                                      }}
                                    />
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btnrow d-flex flex-wrap justify-content-center my-4 py-2">
                      <button
                        className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-16 font-500 invite_frnds max-170 height-45"
                        style={{ backgroundColor: "#5cae48" }}
                        onClick={goToNextPage}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 profile_setup_help"
                    style={{ maxHeight: "50%", height: "100%" }}
                  >
                    <p className="font-18 font-600 mb-3">
                      How can we help you in your career planning?
                    </p>
                    <img
                      className="mb-3 img-fluid float_horizontal_animate"
                      src="/assets/images/profile_setup_img.png"
                      alt="Profile Setup"
                    />
                    <p className="font-16 font-400 m-0">
                      Have big career plans ahead? Even if you don’t - it’s
                      okay! Tell us about your thoughts and preferences for the
                      road ahead.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TellAboutCareer;
