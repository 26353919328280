import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CareerTopCollege = () => {
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to show while fetching data
  const { id } = useParams();

  // Fetch top colleges on component mount
  useEffect(() => {
    const fetchTopColleges = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}career/fetchTopCollege`, {
          career_id: id,
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });

        if (response.data.success && response.data.data) {
          setColleges(response.data.data);
        } else {
          console.error('Failed to fetch colleges:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching colleges:', error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchTopColleges();
  }, [id]);

  // Get a set of unique countries from the colleges data
  const uniqueCountries = [...new Set(colleges.map(college => college.country))];

  if (!loading && colleges.length < 2) {
    return null; 
  }

  return (
    <>
      <section className="container-fluid py-5 bg-darker-blue position-relative z-0" id="topColleges">
        <div className="max-1140 mx-auto">
          <h1 className="font-32 font-900 mb-3 text-white">Top Colleges</h1>
          <div className="row g-3">
            {/* Show loading message while data is being fetched */}
            {loading ? (
              <p className="text-white">Loading colleges...</p>
            ) : (
              <>
                {/* Check if colleges exist */}
                {colleges.map((college) => {
                  // Split university_name and university_website by commas
                  const universityNames = college.university_name ? college.university_name.split('#') : [];
                  const universityWebsites = college.university_website ? college.university_website.split('#') : [];

                  return (
                    <div className="col-md-6" key={college.id}>
                      <div className="card">
                        <div className="p-3 border-bottom">
                          <div className="row g-3 align-items-center">
                            <div className="col-auto">
                              {college.logo && (
                                <img src={college.logo} width="45" alt={`${college.university_name || 'University'} logo`} className="ms-2" />
                              )}
                            </div>
                            <div className="col-auto font-22 font-500">{college.country}</div>
                          </div>
                        </div>
                        <div className="card-body">
                          {/* Conditionally render the duration if available */}
                          {college.course_duration && (
                            <h5 className="card-title">Duration: {college.course_duration}</h5>
                          )}
                          {/* Conditionally render the average fees if available */}
                          {college.average_fees && (
                            <p className="card-text">Fee per course/annum: Rs.{college.average_fees}</p>
                          )}
                          <hr />
                          <ul className="green-correct mb-2">
                            {universityNames.length > 0 && universityNames.map((name, index) => {
                              const website = universityWebsites[index]?.trim();
                              const formattedWebsite = website && !/^https?:\/\//i.test(website) ? `https://${website}` : website;
                              
                              return (
                                name.trim() && website ? (
                                  <li key={index}>
                                    <a href={formattedWebsite} target="_blank" rel="noopener noreferrer">
                                      {name.trim()}
                                    </a>
                                  </li>
                                ) : null
                              );
                            })}
                          </ul>

                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerTopCollege;
