import React from "react";

import Profile from "../../../Components/ProfileComponents/profile";

const UserStudentProfile = () => {

  return (
    <React.Fragment>
    <Profile/>
   
    </React.Fragment>
  );
};

export default UserStudentProfile;
