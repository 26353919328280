import React from "react";

const UpcomingEvents = ({ events }) => {
  return (
    <div className="platform_summary">
      <div className="card">
        <div className="card-header Upcoming_Events_header">
          <h3>Upcoming Events</h3>
          {/* <div className="selectoptions">
            <select
              className="form-select w-135 me-2"
              aria-label="Default select example"
            >
              <option selected>Event Type</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <select
              className="form-select max-120"
              aria-label="Default select example"
            >
              <option selected>Date</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div> */}
        </div>
        <div className="card-body">
          <div className="table-responsive upcoming_event">
          {events?.upcoming_events?.length ? 
          <table className="table" style={{ minWidth: "1200px" }}>
              <thead>
                <tr>
                  <th scope="col" width="230">
                    Event Name
                  </th>
                  <th scope="col">Event Type</th>
                  <th scope="col">Presenter</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                  {events?.upcoming_events?.map((val,i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>
                            <div className="event_name">{val.EventTitle}</div>
                          </td>
                          <td>
                            <button className="event_type">
                              {val.event_type_name}
                            </button>
                          </td>
                          <td>
                            <div className="presenter_section">
                              <div className="presenter_section_img">
                                <img src={val.event_image} alt="presenter" />
                              </div>
                              <div className="presenter_section_name_univarsity">
                                <div className="presenter_section_name">
                                  Ashish Gautam,
                                </div>
                                <div className="presenter_section_univarsity">
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="event_day_time">
                              {val.FromDateTime}
                            </div>
                          </td>
                          <td>
                            <div className="event_action">
                              <div className="notify_text">
                                Registrations Closed
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>: <p>No upcoming events found.</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
