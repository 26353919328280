import apiHeader from "../apiHeader";
import api from "../axiosService";

export const updateBasicInfoDetail = async (data) => {
  const res = await api.post("user/updateBasicInformation", data, {
    headers: apiHeader(),
  });
  return res;
};

export const getPreferenceDetail = async () => {
  const res = await api.post(
    "user/getUserPreference",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};
export const getUserInfo = async () => {
  const res = await api.post(
    "user/getUserInformation",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};
export const updateAdditionalInfoDetail = async (data) => {
  const res = await api.post("user/updateAddidtionaInformation", data, {
    headers: apiHeader(),
  });
  return res;
};

export const updatePreference = async (data) => {
  const res = await api.post("/user/updateUserPreference", data, {
    headers: apiHeader(),
  });
  return res;
};

export const getAllSkills = async () => {
  const res = await api.get(
    "/skill/getSkill",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};
