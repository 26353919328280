import React, { useState } from "react";
import VerifyNumber from "../Modals/Counseller/verify-number";
import VerifyContactDetail from "../Modals/Counseller/verifycontact-detail";
import VerifyEmail from "../Modals/Counseller/verify-email";
const ProfileCard = ({ data, index }) => {
  const [isActive, setIsActive] = useState(0);
  const [verifyContactModal, setVerifyContactModal] = useState(false);
  const [verifyNumberModal, setVerifyNumberModal] = useState(false);
  const [verifyEmailModal, setVerifyEmailModal] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const onMobile = () => {
    setVerifyContactModal(false);
    setVerifyNumberModal(true);
  };
  const onEmail = () => {
    setVerifyContactModal(false);
    setVerifyEmailModal(true);
  };
  return (
    <>
      <div
        className="icn_details  mb-3"
        key={index}
      >

        <span className="iconsec d-flex justify-content-center align-items-center me-3">
          <b className={data.icon}></b>
        </span>
        <div className="flex-1">
          <p className="iconsec_title">{data.title}</p>
          <p className="iconsec_cont">{isActive ? <>{data.desc}</> : ""}</p>
          <span
            className="readmore text-decoration-underline text-primary"
            style={{ cursor: "pointer" }}
            onClick={toggleActive}
          >
            {isActive ? "Read Less" : "Read More"}
          </span>   
        </div>
        {data.button === "Verify" ? (
          <button
            className="btn-verify ms-4"
            onClick={() => setVerifyContactModal(true)}
          >
            {data.button}
          </button>
        ) : (
          <button className="btn-verify">{data.button}</button>
        )}
        </div>

      <VerifyContactDetail
        setVerifyContactModal={setVerifyContactModal}
        verifyContactModal={verifyContactModal}
        onMobile={onMobile}
        onEmail={onEmail}
      />
      <VerifyNumber
        verifyNumberModal={verifyNumberModal}
        setVerifyNumberModal={setVerifyNumberModal}
      />
      <VerifyEmail
        verifyEmailModal={verifyEmailModal}
        setVerifyEmailModal={setVerifyEmailModal}
      />
    </>
  );
};
export default ProfileCard;
