import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  editStudentAccess,
  getStudentManageData,
} from "../../../services/student/student-profile";
import { toast } from "react-toastify";

const ManageAccess = () => {
  const { id } = useParams();
  const [manageData, setManageData] = useState([]);
  const getManageAccess = async () => {
    await getStudentManageData({ student_id: id }).then((res) => {
      console.log(res);
      if (res && res.data?.data?.length) {
        setManageData(res.data?.data);
      }
    });
  };
  useEffect(() => {
    getManageAccess();
  }, []);

  const handleCheckboxChange = async (accessId, checked) => {
    console.log(accessId);
    let payload = {
      student_id: id,
      access_id: accessId,
      value: checked ? 1 : 0,
    };
    await editStudentAccess(payload).then((res) => {
      console.log(res);
      if (res?.data?.meta) {
        getManageAccess();
      }
    });
  };
  return (
    <div id="manage-access-content">
      <div className="parentInformation-detail tab-white-block mt-4 p-0">
        <div className="row g-4">
          <div className="package-wrap">
            <div className="col-lg-4">
              <div className="package-list">
                <ul>
                  {manageData &&
                    manageData?.map((data, i) => {
                      return (
                        <li key={i}>
                          <span>{data?.name}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="package-type">
                <div className="package-type-header bronze">Access</div>
                <div className="package-type-body">
                  <ul>
                    {manageData &&
                      manageData?.map((a, i) => {
                        console.log(a?.id);
                        return (
                          <li key={i}>
                            <input
                              id="flexCheckDefault"
                              className={
                                "form-check-input"
                                // a.has_access === null
                                //   ? "form-check-input"
                                //   : "d-none"
                              }
                              type="checkbox"
                              value={a?.has_access}
                              checked={a?.has_access}
                              onChange={(e) =>
                                handleCheckboxChange(a?.id, e.target.checked)
                              }
                            />
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccess;
