import api from "../axiosService";
import apiHeader from "../apiHeader";

export const getAllProducts = async () => {
  const res = await api.get("products/getProducts", {
    headers: apiHeader(),
  });
  return res;
};

export const getAllProductInventory = async (payload) => {
  const res = await api.post("products/getUserInventory", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getInventoryConsumption = async (payload) => {
  const res = await api.post("products/getUserInventoryConsumption", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const assignProductToStudent = async (payload) => {
  const res = await api.post("products/assignProductToStudent", payload, {
    headers: apiHeader(),
  });
  return res;
};
export const unAssignProductToStudent = async (payload) => {
  const res = await api.post("products/unAssignProductToStudent", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getStudentForProduct = async (payload) => {
  const res = await api.post("products/getStudentForProduct", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getProductById = async (param) => {
  const res = await api.get("products/getProductById", {
    params: param,
    headers: apiHeader(),
  });
  return res;
};

export const getProductByUserProductId = async (param) => {
  const res = await api.get("products/getProductByUserProductId", {
    params: param,
    headers: apiHeader(),
  });
  return res;
};

export const createProductOrder = async (payload) => {
  const res = await api.post("products/createUserOrder", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getTestLink = async (payload) => {
  const res = await api.post("products/getTestLink", payload, {
    headers: apiHeader(),
  });
  return res;
};
