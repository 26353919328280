import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const EditEntranceExams = ({
  setIsEdit,
  allExam,
  levels,
  isAware,
  setIsAware,
  studentExam,
  onExamSubmit,
  removeExam,
  exam,
  setExam,
  searchExam,
  addStudentExam,
}) => {
  return (
    <div className="accordion-body">
      <form className="lstCustForm row g-4">
        <div className="row_parent">
          <div className="row">
            <div className="col-md-12">
              <label className="form-label m-0 mb-2 font-16 font-500 w-100">
                Do you know the exams required for admission in your preferred
                countries and colleges?
              </label>
              <div className="row g-2">
                <div className="col-auto">
                  <input
                    className="form-check-input custom-radio d-none"
                    type="radio"
                    checked={isAware}
                    onChange={(e) => setIsAware(true)}
                    name="is_aware_college_country_exams"
                    id="yes"
                  />
                  <label
                    className="form-check-label custom-label"
                    htmlFor="yes"
                  >
                    <i></i>
                    <span>Yes</span>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input custom-radio d-none"
                    type="radio"
                    checked={!isAware}
                    onChange={(e) => setIsAware(false)}
                    name="is_aware_college_country_exams"
                    id="no"
                  />
                  <label className="form-check-label custom-label" htmlFor="no">
                    <i></i>
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row_parent">
          <div className="row">
            <div className="col-md-12">
              <div className="pt-3 pb-2">
                <div className="row g-3">
                  <div className="col-sm">
                    <div className="form-group">
                      <Typeahead
                        labelKey="name"
                        options={allExam}
                        filterBy={["name", "short_name"]}
                        placeholder="Search Exam Name"
                        selected={allExam.filter((x) => x.id === exam?.exam_id)}
                        name="exam_id"
                        value={exam.exam_id}
                        onChange={(e) => {
                          setExam({ ...exam, exam_id: e[0]?.id });
                        }}
                        onInputChange={(search) => searchExam(search)}
                      />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <select
                        value={exam.level_id}
                        onChange={(e) => {
                          setExam({ ...exam, level_id: e.target.value });
                        }}
                        className="form-select form-control form-modal height-42"
                      >
                        <option selected>Your preparation status</option>
                        {levels.map((x) => {
                          return <option value={x.id}>{x.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn-blue"
                      type="button"
                      onClick={() => addStudentExam()}
                    >
                      <i className="icon-plus me-2"></i>
                      Add
                    </button>
                  </div>
                </div>
                <div className="entranceExamDetailsSection">
                  <ul>
                    {studentExam.map((val, index) => (
                      <li>
                        <div className="examName">
                          {allExam.find((x) => x.id == val.exam_id)?.name}
                        </div>
                        <div className="preparationstatus">
                          <div className="preparationstatusName">
                            <i className="icon-tick"></i>
                            <span>
                              {levels.find((x) => x.id == val.level_id)?.name}
                            </span>
                          </div>
                          <span
                            onClick={removeExam}
                            className="btn-close"
                          ></span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row_parent">
          <div className="row justify-content-end align-items-end pt-2">
            <button
              onClick={() => setIsEdit(false)}
              className="btn btn-cancel mx-2 d-block height-40 w-auto"
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className="btn btn-green text-white mx-2 d-block height-40 px-5 w-auto"
              onClick={onExamSubmit}
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditEntranceExams;
