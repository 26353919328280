import React from "react";
import { Accordion } from "react-bootstrap";

const CareerCard = ({ data }) => {
  return (
    <div>
      <div className="influx career-page-section">
        <div className="title-career">
          <h1 className="font-24 font-600 text-blue mb-3">
            <a>
              {data.title}
            </a>
          </h1>
        </div>

        <div className="influx pt-2 pb-4">
          <p
            className="mb-4 pb-0"
            dangerouslySetInnerHTML={{ __html: data.desc }}
          ></p>
          <ul className="green-correct mt-3 d-flex flex-wrap">
            {data?.name1 && <li className="me-5 font-500">{data.name1}</li>}
            {data?.name2 && <li className="me-5 font-500">{data.name2}</li>}
            {data?.name3 && <li className="me-5 font-500">{data.name3}</li>}
            {data?.name4 && <li className="me-5 font-500">{data.name4}</li>}
          </ul>
        </div>

        <h2 className="font-18 font-600 position-relative pb-2 mb-3">
          Course to take:
        </h2>
        <div
          className="rightPaneAccordian"
          style={{ margin: "0", width: "unset" }}
        >
          <div className="career-accord">
            <Accordion id="accordionProfile" defaultActiveKey={0}>
              {data.itemData?.map((x, index) => {
                return (
                  <Accordion.Item eventKey={index} className="mb-3">
                    <Accordion.Header className="d-flex justify-content-end">
                      <span>
                        {x.titlename}
                        <b> {x.highlight}</b>
                      </span>

                      <p>
                        <span className="icon mx-3"></span>
                      </p>
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{ __html: x.description }}
                      ></p>
                      {x.tag &&
                        x.tag?.map((a) => {
                          return (
                            <ul className="green-correct mt-3 d-flex flex-wrap">
                              {" "}
                              <li className="me-5 font-500">{a}</li>
                            </ul>
                          );
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerCard;
