import React from "react";

const RecentActivity = ({ activities }) => {
  return (
    <div className="platform_summary">
      <div className="card">
        <div className="card-header">
          <h3>Recent Activity</h3>
        </div>
        <div className="card-body">
          <div className="recentActivity-steps">
            {activities?.length ?
              <ul>
                {activities?.map((activity, index) => {
                  return (
                    <li key={index}>
                      <i></i>
                      <div>
                        <span className="statusTxt">
                          {activity.activity_name}
                        </span>
                        <span className="time">
                          {new Date(activity.completion_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </span>
                      </div>
                    </li>
                  );
                })}
                </ul>
              : <p>No Records Found</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;
