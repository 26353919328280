import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CareerProsCons = () => {
  const [prosConsData, setProsConsData] = useState({ pros: [], cons: [] });
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error state

  useEffect(() => {
    // Fetch the Pros and Cons data from the API
    const fetchProsCons = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}career/fetchProsCons`,
          { career_id: id },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        // Check if the data is null or undefined
        if (response.data && response.data.data) {
          const pros = response.data.data.filter(
            (item) => item.select_type === 'Pros'
          );
          const cons = response.data.data.filter(
            (item) => item.select_type === 'Cons'
          );
          setProsConsData({ pros, cons });
        } else {
          // If data is null, set error message
          // setError('No Pros and Cons data available.');
        }
      } catch (error) {
        console.error('Error fetching Pros and Cons:', error);
        setError('Failed to load Pros and Cons data.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProsCons();
  }, [id]);

  if (loading) {
    return <p>Loading Pros &amp; Cons...</p>;
  }

  // Check if there are any pros or cons to display
  if (prosConsData.pros.length === 0 && prosConsData.cons.length === 0) {
    return null; // Hide the entire section if both are empty
  }

  return (
    <section className="container-fluid py-5 position-relative z-0" id="prosCons">
      <div className="max-1140 mx-auto">
        <h1 className="font-32 font-900 mb-3 text-dark-blue">Pros &amp; Cons</h1>
        <div className="row g-4">
        {prosConsData.pros.length > 0 && (
            <div className="col-sm-6">
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Pros</h4>
                <hr />
                {prosConsData.pros.some((pro) => pro.heading) ? (
                  <ul className="green-correct mb-2">
                    {[...new Set(prosConsData.pros
                      .filter((pro) => pro.heading) // Only include items with valid headings
                      .map((pro) => pro.heading)
                    )].map((uniquePro, index) => (
                      <li key={index}>{uniquePro}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No pros available</p>
                )}
              </div>
            </div>
          )}
          {prosConsData.cons.length > 0 && (
            <div className="col-sm-6">
              <div className="alert alert-secondary" role="alert">
                <h4 className="alert-heading">Cons</h4>
                <hr />
                {prosConsData.cons.some((con) => con.heading) ? (
                  <ul className="green-correct mb-2">
                    {[...new Set(prosConsData.cons
                      .filter((con) => con.heading) // Only include items with valid headings
                      .map((con) => con.heading)
                    )].map((uniqueCon, index) => (
                      <li key={index}>{uniqueCon}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No cons available</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CareerProsCons;
