import React, { useEffect, useRef } from "react";
import CareerCard from "./career-card";
import CareerTrendsSidebar from "./career-trends-sidebar";

const Historian = () => {
  const pageRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        ref1.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        ref2.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        ref3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        ref4.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        ref5.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset || window.scrollY;
    const elementOffsetTop = pageRef.current?.offsetTop - 30;
    const elementOffsetHeight = pageRef.current?.offsetHeight + 30;
    const ptabs = pageRef.current?.querySelector(".nav-tabs");
    const ptabsLink = ptabs?.querySelectorAll(".nav-link");
    const otabs = pageRef.current?.querySelectorAll(".card-container");
    for (let i = 0; i < otabs?.length; i++) {
      let ot = otabs[i].offsetTop - ptabs?.offsetHeight * 2;
      if (
        position >= ot &&
        position < otabs[i + 1]?.offsetTop - ptabs?.offsetHeight * 2
      ) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position >= ot && i === otabs?.length - 1) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position < otabs[1].offsetTop - ptabs?.offsetHeight * 2) {
        ptabsLink[0]?.classList?.add("active");
        ptabsLink[1]?.classList?.remove("active");
      } else {
        ptabsLink[i]?.classList?.remove("active");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const item = [
    "Historian",
    "Mathematician",
    "Accountant",
    "Business Processing Outsourcing",
    "Journalism",
  ];
  const HistorianData = {
    title: "Historian",
    desc: "Everything in the past affects our future,directly or indirectly. As a Historian, your job is to protect the past, so that our future generations understand how it all began, and how it all came to be. Historians are highly regarded in the society, and even though the career may have been more flourishing before, it is still an important part of society today.",
    name1: "Archaeologist",
    name2: "Archivist",
    name3: "Lecturer",
    itemData: [
      {
        titlename: "Bachelor of Fine Arts",
        description:
          "A Bachelor's degree in Fine Arts is the most popular and relevant course if you aim to pursue a career in History. The course offers a detailed view of the career, the different aspects, and how you can make a living in this career. Most colleges in India and abroad offer a BFA degree.",
      },
      {
        titlename: "Bachelor of Arts",
        description:
          "Apart from a BFA, another common course you can opt for is the Bachelor of Arts degree. You can opt for a BA degree with a specialisation in History. This degree course will be focused on History, India and abroad to offer an in-depth understanding of the subject and its scope.",
      },
    ],
  };
  const MathematicianData = {
    title: "Mathematician",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Even while you read these words, if you&rsquo;re thinking about numbers, a career in Mathematics could be the one for you. As a Mathematician, everyday is a mystery with a whole range of possible equations to discover and solve.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">If you aim to become a Mathematician, you must stay up to date with advancements in the mathematics field and applying mathematical knowledge to practical problems.</p>`,
    name1: "Teaching",
    name2: "Finance",
    name3: "Accounting",
    itemData: [
      {
        titlename: "Bachelors in Science",
        description:
          "To make your way to become a Mathematician, the first degree you would need is a BSc. degree, preferably with a specialisation in Maths. With this formal degree for 3 years, you can learn more about the subject, and its different aspects to make a foundation for your career.",
      },
      {
        titlename: "Bachelor of Arts",
        description:
          "Another course that you can opt for to become a Mathematician is BA Mathematics. This three year undergraduate degree course in Mathematics is divided into six semesters. A BA degree in Mathematics is about building a base studying different aspects of Maths such as -",
        tag: [
          "Classical Algebra",
          "Trigonometry",
          "Solid Algebra",
          "Integral Calculus",
          "Differential Equations",
        ],
      },
    ],
  };
  const AccountantData = {
    title: "Accountant",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Accounting and accountants are always in demand and they are needed in every industry. Not only is it one of the most respected professions in the world, it also is highly rewarding in terms of salary and exposure.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">The practitioners and professionals of accounting are known as Accountants. The certified professionals in these fields are known as Chartered Accountants.</p>`,
    name1: "Auditor",
    name2: "Management Accountant",
    name3: "Cost Accountant",
    itemData: [
      {
        titlename: "Bachelor of Commerce",
        description:
          "A B.Com degree is among the most opted courses to make a career as an accountant. The Bachelor of Commerce degree is offered with a specialisation of either accounts or computers. With this, you will be exposed to the different aspects of accounting, and its types to start off with.",
      },
      {
        titlename: "Bachelors in Business Administration",
        description:
          "BBA degree courses are another popular path that students choose in their pursuit of becoming an accountant. In the course of Business Administration, you get exposed to a wider range of the business world, from understanding the basics of commerce, business management, economics, and accounting.",
      },
    ],
  };
  const BusinessData = {
    title: "Business Processing Outsourcing",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Between 2010 to 2016, BPOs in India were all the rage! Everyone wanted to go and work for a BPO from college and earn their first paycheck. Business Processing Outsourcing is where organisations partner with or hire other organisations for certain essential
        business functions or tasks.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Generally, BPO companies hire freshers, predominantly graduates. So, if you wish to work at a BPO, you can do so with a graduation degree in any stream.</p>`,
    name1: "Customer care specialist",
    name2: "Customer success manager",
    name3: "Data collector",
    itemData: [
      {
        titlename: "Bachelors of Arts",
        description:
          "require a particular degree, even if you have done a Bachelor of Arts course, you can take up a job at a BPO. With the opportunity to specialise in multiple subjects such, a Bachelor of Arts degree offers an in-depth grasp on trends in the industry and understanding of people.",
      },
      {
        titlename: "Bachelors of Commerce",
        description:
          "A B.Com degree is also another popular course that is often seen in the BPO applicants of today. The Bachelor of Commerce degree is offered with a specialisation of either general, accounts or computers. With this, you  will be exposed to the different aspects of commerce, accounting, and its types to start off with.",
      },
    ],
  };
  const JournalismData = {
    title: "Journalism",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Originating from the Latin word, &lsquo;Diurnal&rsquo; or &lsquo;Daily&rsquo;, Journalism has been an ever popular career in India where Media serves as the fourth pillar of the constituency. However, with the shift to the digital platform, print journalism
        has been struggling to stay afloat in the country, and all over the world.</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">However, journalism as a career has its own set of pros and cons, depending on how passionate you are about pursuing it. Journalism offers a wide range of jobs, and each with its own set of exposure to the industry.</p>`,

    itemData: [
      {
        titlename: "Bachelor of Arts",
        description:
          " To make a career in Journalism, a Bachelor of Arts degree is the first choice for most people. With a specialisation in English, Psychology, Journalism, or Mass Communication, a BA degree can help you understand the  basics of the concepts and industry. Not only so, it also helps you understand  what it takes to stick to journalism’s values and stay relevant in an evolving industry.",
      },
    ],
  };
  return (
    <div style={{ marginTop: "100px" }}>
      <div className="career-trendz-tab">
        <div
          className="page-section d-flex fade show"
          id="Last10Years"
          role="tabpanel"
          ref={pageRef}
          aria-labelledby="Last10Years-tab"
        >
          <CareerTrendsSidebar onItemClick={handleMenuItemClick} item={item} />

          <div
            className="rightPane"
            style={{ flexDirection: "column", width: "calc(100% - 270px)" }}
          >
            <div
              ref={ref1}
              className="card-container"
              style={{ width: "100%" }}
            >
              <CareerCard data={HistorianData} />
            </div>

            <div
              ref={ref2}
              className="card-container mt-3"
              style={{ width: "100%" }}
            >
              <CareerCard data={MathematicianData} />
            </div>
            <div
              ref={ref3}
              className="card-container mt-3"
              style={{ width: "100%" }}
            >
              <CareerCard data={AccountantData} />
            </div>
            <div
              ref={ref4}
              className="card-container mt-3"
              style={{ width: "100%" }}
            >
              <CareerCard data={BusinessData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref5}
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={JournalismData} />
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 invite_frnds max-170 height-45">
                  <span>Activity Completed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historian;
