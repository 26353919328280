import React, { useState } from "react";
import ProfileSidebar from "../ProfileSidebar/profile-sidebar";
import { Accordion } from "react-bootstrap";
import BasicInformation from "./BasicInformation/basic-information";
import Preference from "./Preference/preference";
import EducationDetails from "./EducationDetails/education-details";
import EntranceExams from "./EntranceExams/entrance-exams";
import ExtraCurricular from "./ExtraCurricular/extra-curricular";
import { useParams } from "react-router";
import ManageAccess from "./ManageAccess/manage-access";

const Profile = () => {
  const { id } = useParams();
  const [isActive, setActive] = useState("0");
  const handleAccordionClick = (index) => {
    if (index !== isActive) {
      setActive(index.toString());
    }
  };
  return (
    <div
      className="flex-1"
      style={{
        background:
          "transparent linear-gradient(125deg, #F8F8F8 0%, #E1E7EB 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <div className="customcontainer d-flex flex-wrap h-100 container">
        <ProfileSidebar
          handleAccordionClick={handleAccordionClick}
          isActive={isActive}
        />
        <div className="rightPaneAccordian">
          <Accordion
            className="pb-4"
            id="accordionProfile"
            activeKey={isActive}
            onSelect={(e) => setActive(e)}
          >
            <div className="gap" id="basicinformation">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  id="basicinformation"
                  className="d-flex justify-content-end"
                >
                  Basic Information
                  <p>
                    <span className="icon mx-3"></span>
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <BasicInformation />
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div className="gap" id="preferences">
              <Accordion.Item eventKey="1">
                <Accordion.Header id="preferences">
                  Preferences
                  <p>
                    <span className="icon mx-3"></span>
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <Preference />
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div className="gap" id="educationdetails">
              <Accordion.Item eventKey="2">
                <Accordion.Header id="educationdetails">
                  Education Details
                  <p>
                    <span className="icon mx-3"></span>
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <EducationDetails />
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div className="gap" id="entranceexams">
              <Accordion.Item eventKey="3">
                <Accordion.Header id="entranceexams">
                  Entrance Exams
                  <p>
                    <span className="icon mx-3"></span>
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <EntranceExams />
                </Accordion.Body>
              </Accordion.Item>
            </div>

            <div className="gap" id="extracurricularsachievements">
              <Accordion.Item eventKey="4">
                <Accordion.Header id="extracurricularsachievements">
                  Extra Curricular & Achievements
                  <p>
                    <span className="icon mx-3"></span>
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <ExtraCurricular />
                </Accordion.Body>
              </Accordion.Item>
            </div>
            {id && (
              <div className="gap" id="manage-access">
                <Accordion.Item eventKey="5">
                  <Accordion.Header id="manage-access">
                    Manage Access
                    <p>
                      <span className="icon mx-3"></span>
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ManageAccess />
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Profile;
