import React from "react";

const SkillCard = ({ data, setSelectedSkill, selectedSkill }) => {
  return (
    <>
      <input
        type="checkbox"
        id={data.id}
        value={selectedSkill?.id === data.id}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedSkill(data);
          } else {
            setSelectedSkill(null);
          }
        }}
        name="gender"
      />
      <label
        htmlFor={data.id}
        className={
          selectedSkill?.id === data.id
            ? "active skill d-flex justify-content-between align-items-center px-4 py-3 cursor-pointer"
            : "skill d-flex justify-content-between align-items-center px-4 py-3 cursor-pointer"
        }
      >
        {!data.isActive ? (
          <span className="status font-11 font-500">mastered</span>
        ) : null}
        <h4 className="font-16 font-500 m-0">{data?.name}</h4>
        <span className="icon font-24">
          {selectedSkill?.id === data.id ? (
            <p className="icon-time-management"></p>
          ) : (
            <b className="icon-time-management"></b>
          )}
        </span>
      </label>
    </>
  );
};

export default SkillCard;
