import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  getQuizDataId,
  getSkillsDataId,
  skillQuizData,
} from "../../../services/skills/skills";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuizSubmitModal from "./quiz-submit-modal";
import CongratulationModal from "./congratulation-modal";
import QuizNextTimeModal from "./quiz-next-time-modal";

const CommunicationQuiz = () => {
  const now = 60;
  const { id } = useParams();
  const [quizData, setQuizData] = useState({});
  const [allQuizz, setAllQuizz] = useState([]);
  const [qres, setQRes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [quizOpenModal, setQuizOpenModal] = useState(false);
  const [CongratulationOpenModal, setCongratulationOpenModal] = useState(false);
  const [quizNextTimeModal, setQuizNextTimeModal] = useState(false);
  const [ansData, setAnsData] = useState([]);

  const getAllQuizData = async (id) => {
    await getQuizDataId(id).then((res) => {
      if (res?.data?.data) {
        setQuizData(res?.data?.data);
      }
    });
  };

  const skillData = async () => {
    const res = await getSkillsDataId(id);
    getAllQuizData(
      res?.data?.data?.quizzes[activeIndex]?.skill_quiz?.skill_quiz_id
    );
    setAllQuizz(res?.data?.data?.quizzes);
  };

  useEffect(() => {
    if (id) {
      skillData();
    }
  }, [id, activeIndex]);

  const nextClick = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handleSubmit = async () => {
    let payload = {
      user_skill_id: quizData?.user_skill_id,
      answers: ansData,
    };
    await skillQuizData(payload).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        if (allQuizz.length > activeIndex + 1) {
          nextClick();
        }
        setAnsData([]);
        setQRes(res.data.data);
        // toast.success(message);
        if (res.data.data.status === "failed") {
          openNextTimeModal();
        } else {
          openCongratsModal();
        }
      }
    });
  };
  const openCongratsModal = () => {
    setQuizOpenModal(false);
    setCongratulationOpenModal(true);
  };
  const openNextTimeModal = () => {
    setCongratulationOpenModal(false);
    setQuizNextTimeModal(true);
  };
  return (
    <div className="main flex-1">
      <div className="max-1140 mx-auto d-flex h-100">
        <div className="flex-1">
          <div className="mt-4">
            <div className="row">
              <div className="col-md-12">
                <div className="h-100 p-0">
                  <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                    <h3
                      data-aos="fade-left"
                      data-aos-delay="600"
                      className="font-32 font-600 mb-1 block-title aos-init aos-animate"
                    >
                      Life Skills
                    </h3>
                    <p className="font-16 font-400 mb-2">
                      Life Skills for everyone, be it a student in school and
                      college or a working professional.
                    </p>
                  </div>

                  <div className="life-skills-course">
                    <div className="skill-range">
                      <h2 className="font-26 font-600 text-center mb-0 pb-4">
                        {quizData?.quiz?.name} - Quiz {activeIndex + 1} of{" "}
                        {allQuizz?.length}
                      </h2>
                      {/* <div className="d-flex justify-content-center">
                        <ProgressBar now={now} label={`${now}%`} />
                      </div> */}
                    </div>
                    <div className="life-skills-course-detail">
                      <div className="max-930 mx-auto mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="rightcustomborder">
                              <p className="m-0">
                                Based on your understanding of the lesson
                                videos, answer the questions that follow. You
                                need to score at least{" "}
                                {quizData?.quiz?.pass_percentage}% marks in
                                order to successfully complete the activity
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-wrap align-items-center h-100 ps-2">
                              <div className="info">i</div>
                              <div className="flex-1">
                                <p className="font-16 font-400 fst-italic mb-0">
                                  You can retake the quiz after 12 hours from
                                  your previous attempt.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="max-930 mx-auto">
                        <form className="row questions">
                          <div className="border-top skillBlock">
                            <div className="row">
                              {quizData?.quiz?.questions?.map((que, index) => {
                                return (
                                  <div
                                    className="col-lg-12 mb-4 pb-4"
                                    key={index}
                                  >
                                    <div className="d-flex flex-wrap">
                                      <div className="s_no me-3">
                                        <label>{index + 1}</label>
                                      </div>
                                      <div className="flex-1">
                                        <h3 className="font-18 font-500 mb-3">
                                          {que?.question}
                                        </h3>

                                        <div className="row g-3">
                                          {que.type === "boolean" ? (
                                            <>
                                              <div className="row g-3">
                                                <div className="col-md-3 col-sm-4 col-6">
                                                  <input
                                                    className="form-check-input m-0 d-none"
                                                    type="radio"
                                                    name={`question_${que.id}`}
                                                    id={"option1" + index}
                                                    value="option1 true"
                                                    checked={ansData.some(
                                                      (item) =>
                                                        item.question_id ===
                                                          que.id.toString() &&
                                                        item.answer_id === 1
                                                    )}
                                                    onChange={() => {
                                                      let temp = Object.assign(
                                                        [],
                                                        ansData
                                                      );
                                                      const existingIndex =
                                                        temp.findIndex(
                                                          (item) =>
                                                            item.question_id ===
                                                            que.id.toString()
                                                        );
                                                      if (
                                                        existingIndex !== -1
                                                      ) {
                                                        const updatedAnsData =
                                                          Object.assign(
                                                            [],
                                                            temp
                                                          );
                                                        updatedAnsData[
                                                          existingIndex
                                                        ].answer_id = 1;
                                                        setAnsData(
                                                          updatedAnsData
                                                        );
                                                      } else {
                                                        setAnsData([
                                                          ...temp,
                                                          {
                                                            question_id:
                                                              que.id.toString(),
                                                            answer_id: 1,
                                                          },
                                                        ]);
                                                      }
                                                    }}
                                                  />
                                                  <label
                                                    className="form-check-label font-14 font-500 d-flex justify-content-around align-items-center border-radius-6 border w-100"
                                                    htmlFor={"option1" + index}
                                                  >
                                                    True
                                                  </label>
                                                </div>
                                                <div className="col-md-3 col-sm-4 col-6">
                                                  <input
                                                    className="form-check-input m-0 d-none"
                                                    type="radio"
                                                    name={`question_${que.id}`}
                                                    id={"option2" + index}
                                                    value="option2 false"
                                                    checked={ansData.some(
                                                      (item) =>
                                                        item.question_id ===
                                                          que.id.toString() &&
                                                        item.answer_id === 0
                                                    )}
                                                    onChange={() => {
                                                      let temp = Object.assign(
                                                        [],
                                                        ansData
                                                      );
                                                      const existingIndex =
                                                        temp.findIndex(
                                                          (item) =>
                                                            item.question_id ===
                                                            que.id.toString()
                                                        );
                                                      if (
                                                        existingIndex !== -1
                                                      ) {
                                                        const updatedAnsData =
                                                          Object.assign(
                                                            [],
                                                            temp
                                                          );
                                                        updatedAnsData[
                                                          existingIndex
                                                        ].answer_id = 0;
                                                        setAnsData(
                                                          updatedAnsData
                                                        );
                                                      } else {
                                                        setAnsData([
                                                          ...temp,
                                                          {
                                                            question_id:
                                                              que.id.toString(),
                                                            answer_id: 0,
                                                          },
                                                        ]);
                                                      }
                                                    }}
                                                  />
                                                  <label
                                                    className="form-check-label font-14 font-500 d-flex justify-content-around align-items-center border-radius-6 border w-100"
                                                    htmlFor={"option2" + index}
                                                  >
                                                    False
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {que?.options?.map(
                                                (optionValue, optionIndex) => {
                                                  return (
                                                    <div
                                                      className="col-md-3 col-sm-4 col-6"
                                                      key={optionIndex}
                                                    >
                                                      <input
                                                        className="form-check-input m-0 d-none"
                                                        type="radio"
                                                        name={`question_${que.id}`}
                                                        id={`option_${optionValue.id}`}
                                                        checked={ansData.some(
                                                          (item) =>
                                                            item.question_id ===
                                                              que.id.toString() &&
                                                            item.answer_id ===
                                                              optionValue.id.toString()
                                                        )}
                                                        onChange={() => {
                                                          let temp =
                                                            Object.assign(
                                                              [],
                                                              ansData
                                                            );
                                                          const existingIndex =
                                                            temp.findIndex(
                                                              (item) =>
                                                                item.question_id ===
                                                                que.id.toString()
                                                            );
                                                          if (
                                                            existingIndex !== -1
                                                          ) {
                                                            const updatedAnsData =
                                                              Object.assign(
                                                                [],
                                                                temp
                                                              );
                                                            updatedAnsData[
                                                              existingIndex
                                                            ].answer_id =
                                                              optionValue.id.toString();
                                                            setAnsData(
                                                              updatedAnsData
                                                            );
                                                          } else {
                                                            setAnsData([
                                                              ...temp,
                                                              {
                                                                question_id:
                                                                  que.id.toString(),
                                                                answer_id:
                                                                  optionValue.id.toString(),
                                                              },
                                                            ]);
                                                          }
                                                        }}
                                                      />

                                                      <label
                                                        className="form-check-label font-14 font-500 d-flex justify-content-around align-items-center border-radius-6 border w-100"
                                                        // htmlFor={"option" + i}
                                                        htmlFor={`option_${optionValue.id}`}
                                                      >
                                                        {optionValue?.option}
                                                      </label>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center my-5">
                    <span className="btn btn-nostyle d-flex justify-content-between align-items-center mx-3 font-16 font-500 hvr-icon-back my-auto video-btn border-2 me-3 px-0 py-1">
                      Previous Quiz
                    </span>
                    {/* {allQuizz.length > 1 ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-completed radius height-45 max-200 d-block w-100 mx-3 font-14 font-500"
                          onClick={handleSubmit}
                        >
                          <span>Next </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-completed radius height-45 max-200 d-block w-100 mx-3 font-14 font-500"
                          onClick={handleSubmit}
                        >
                          <span>Final Submit</span>
                        </button>
                      </>
                    )} */}
                    <button
                      type="button"
                      className="btn btn-completed radius height-45 max-200 d-block w-100 mx-3 font-14 font-500"
                      // onClick={handleSubmit}
                      onClick={() => setQuizOpenModal(true)}
                    >
                      <span>
                        {allQuizz.length === activeIndex + 1
                          ? "Final Submit"
                          : "Next"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuizSubmitModal
        setQuizOpenModal={setQuizOpenModal}
        quizOpenModal={quizOpenModal}
        handleSubmit={handleSubmit}
        quiz={quizData?.quiz}
      />
      <CongratulationModal
        setCongratulationOpenModal={setCongratulationOpenModal}
        qres={qres}
        CongratulationOpenModal={CongratulationOpenModal}
        openNextTimeModal={openNextTimeModal}
      />
      {
        <QuizNextTimeModal
          quizNextTimeModal={quizNextTimeModal}
          qres={qres}
          setQuizNextTimeModal={setQuizNextTimeModal}
        />
      }
    </div>
  );
};

export default CommunicationQuiz;
