import React from "react";
import ProfileCard from "../../../../Components/CounsellerProfileCard/profile-card";
const StudentOnboarding = () => {
  const profileData = [
    {
      title: "Upload student data",
      desc: "While creating your account, Univariety will send you a verification email. Click on the email to find your code.",
      button: "Upload",
      icon: "icon-student-data",
    },
    {
      title: "Send account activation communication",
      desc: "While you're creating your account, you'll get an email from Univareity. Open the email and find the verification code.",
      button: "Send",
      icon: "icon-account-activation",
    },
    {
      title: "Setup access control for students",
      desc: "While you're creating your account, you'll get an email from Univareity. Open the email and find the verification code.",
      button: "Setup",
      icon: "icon-setup-access",
    },
    {
      title: "Get Demo of student platform",
      desc: "While you're creating your account, you'll get an email from Univareity. Open the email and find the verification code.",
      button: "Watch",
      icon: "icon-student-platform",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-5">
          <div className="welcome-title">
            <h1>2. Student Onboarding</h1>
          </div>
          <div className="dashboard d-flex">
            <span className="btn-green">
              <span>Jump to Dashboard</span>
            </span>
            <span className="ms-3 me-3 dashb_arr dashb_left"></span>
            <span className="dashb_arr dashb_right ms-3"></span>
          </div>
        </div>
        <div className="container my-4 profile_setup_cont">
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="profile_setup p-0 mr-4">
                {profileData?.map((data, index) => {
                  return (
                    <div>
                      <ProfileCard data={data} index={index} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="profile_setup_help">
                <p className="font-18 font-600 mb-3 ">
                  How will profile
                  <br />
                  setup help you?
                </p>
                <img
                  className="mb-3 img-fluid float_horizontal_animate"
                  src="/assets/images/profile_setup_img.png"
                  alt="Profile Setup"
                />
                <p className="font-16 font-400 m-0">
                  Counsellors who setup their profile are more noticeable.
                  Students and universities tend to approach them more than
                  others.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="overlaystick">
          <div className="sticky-notification d-flex">
            <div className="imgplace">
              <img
                src="/assets/images/mask-group-28.svg"
                width="100"
                alt="mask-group"
              />
            </div>
            <div className="notification-detail d-flex align-items-center">
              <p className="font-18 font-500">
                Need help with getting started?
                <br />
                Contact your account success manager!
              </p>
              <div className="imglink">
                <div className="imgplace">
                  <img
                    src="/assets/images/mask-group-28.svg"
                    width="100"
                    alt="mask-group"
                  />
                </div>
                <div className="links">
                  <span className="btn-green">
                    <span>Schedule Meeting</span>
                  </span>
                  <span className="btn-message">
                    <span>Message</span>
                  </span>
                </div>
              </div>
              <span className="btn-close icon-cross"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="copyRight">
          International Educational Gateway Pvt. Ltd. © 2021. All Rights
          Reserved. Univariety
        </div>
        <div className="powerby">
          <span>Powered By</span>
          <img src="/assets/images/dark-logo.svg" alt="" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default StudentOnboarding;
