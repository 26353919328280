import React from 'react'
import CareerTrands from '../../../Components/Career-trends/career-trends'

const CareerTrend = () => {
  return (
    <div>
        <CareerTrands/>
    </div>
  )
}

export default CareerTrend