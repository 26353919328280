import apiHeader from "../apiHeader";
import api from "../axiosService";

export const skills = async () => {
  const res = await api.get("skill", {
    Headers: apiHeader(),
  });
  return res;
};
export const getSkillsDataId = async (id) => {
  const res = await api.get("skill/" + id, {
    headers: apiHeader(),
  });
  return res;
};
export const getQuizDataId = async (id) => {
  const res = await api.get("skill/quiz/" + id, {
    headers: apiHeader(),
  });
  return res;
};
export const skillQuizData = async (data) => {
  const res = await api.post("skill/submitTest", data,{
    headers: apiHeader(),
  });
  return res;
};
