import React from "react";
import { AiOutlineDelete } from "react-icons/ai";

const AchievementTable = ({ data, removeAchievement }) => {

  return (
    <div>
      <div className="table-responsive attendee_dtl mt-4">
        <table className="table">
          <thead>
            <tr>
              <th className="university-name" width="25%">
                Activity Title
              </th>
              <th width="25%">Category</th>
              <th width="25%">
                <span className="labelItem">Level</span>
              </th>
              <th width="25%">
                <span className="labelItem">Completed In</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((x, i) => {
              return (
                <tr>
                  <td>
                    <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                      {x?.program_name}
                    </div>
                  </td>
                  <td>{x?.category_id}</td>

                  <td>{x?.level_id}</td>

                  <td> {new Date(x.completion_date).getFullYear()}</td>

                  <td>
                    <span
                      className="btn-add me-4"
                      style={{ width: "unset" }}
                      onClick={() => removeAchievement(i)}
                    >
                      {" "}
                      <AiOutlineDelete />
                    </span>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AchievementTable;
