import React, { useEffect, useRef, useState } from "react";
import BasicInfo from "./basic-info";
import { Card, Nav, Tab } from "react-bootstrap";
import Education from "./education";
import { useAuth } from "../../../Contexts/auth-context";
import Preferences from "./preferences";
import Entrance from "./entrance";
import { toast } from "react-toastify";
import { fileUpload } from "../../../services/util/util-service";
import {
  getStudentDetails,
  updateProfilePic,
} from "../../../services/student/student-profile";
import ExtraCurricular from "./ExtraCurricular/extraCurricular";

const StudentProfile = () => {
  
  const [activeKey, setActiveKey] = useState("Preferences");
  const { user } = useAuth();
  const uploadRef = useRef(null);
  const [details, setDetails] = useState();
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData).then((res) => {
      if (res && res.data && res?.data?.data?.fileLink) {
        uploadProfilePic(res?.data?.data?.fileLink);
      }
    });
  };
  const StudentProfileDetail = async () => {
    await getStudentDetails().then((res) => {
      if (res && res.data?.data) {
        setDetails(res.data?.data);
      }
    });
  };

  useEffect(() => {
    StudentProfileDetail();
  }, []);
  const uploadProfilePic = async (link) => {
    const res = await updateProfilePic({ profile_pic: link });
    if (res?.data?.meta?.status) {
      user["profile_pic"] = res.data.data;
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  const handleImport = async (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (
      file?.type?.includes("image/jpeg") ||
      file?.type?.includes("image/png")
    ) {
      imageUpload(file);
    } else {
      toast.error("Image must ba valid .jpeg file");
    }
  };
  return (
    <React.Fragment>
      <div className="main flex-1">
        <section
          class="bg-white container-fluid alumni_profile_brife_wrap"
          id="alumni_profile_brife_wrap"
        >
          <div class="max-1140 mx-auto alumni_profile_brife">
            <div class="row">
              {/* <div class="col-12 mb-4">
                        <a class="btn-back"><i class="icon-left-arrow font-10"></i> <span>Back to Directory</span></a>
                   </div> */}
              <div class="col-12">
                <div class="alumni_profile_name_college_other_detail_wrap student_profile">
                  <div class="alumni_profile_image_name_other_detail">
                    {user?.profile_pic ? (
                      <span className="userimg position-relative">
                        <img src={user?.profile_pic} alt="video-thumb" />
                      </span>
                    ) : (
                      <div
                        data-initials={
                          user?.fname[0]?.toUpperCase() +
                          user?.lname[0]?.toUpperCase()
                        }
                      ></div>
                    )}
                    <p
                      style={{
                        backgroundColor: "rgb(243, 173, 24)",
                        borderRadius: "50%",
                        position: "relative",
                        zIndex: "9999",
                        height: "21px",
                        width: "21px",
                        left: "-19px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          zIndex: "9999",
                          top: "4px",
                          left: "5px",
                          color: "black",
                          fontSize: "12px",
                        }}
                        className="icon-edit-pencil"
                        onClick={(e) => {
                          e.preventDefault();
                          uploadRef?.current?.click();
                        }}
                      ></span>
                    </p>
                    <input
                      hidden
                      ref={uploadRef}
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={handleImport}
                    />
                    {/* <div class="alumni_profile_image">
                                <img src="/assets/images/yellow-student-boy.svg" alt=""/>
                            </div> */}
                    <div class="alumni_profile_name_other_detail">
                      <h2 class="mb-0">
                        {user?.fname} {user?.lname}
                      </h2>
                      <div class="student_profile_details">
                        <div class="alumni_profile_class_college">
                          <div class="alumni_profile_college_phone mb-1">
                            <span class="font-16 font-500 text-black line-height-18 me-3 mt-2">
                              +91 {user?.phone}
                            </span>
                            {/* {user?.is_phone_verified == true ? 
                                           
                                            <a class="verifiedLink"><i class="icon-varified font-16 me-2"></i><span>Verified</span></a> :
                                            <a class="verifyLink"><span>Verify Now</span></a> 
                                          } */}
                          </div>
                          <div class="alumni_profile_college_phone">
                            <span class="font-16 font-500 text-black line-height-18 me-3">
                              {user?.email}
                            </span>

                            {/* {user?.is_email_verified == true ? 
                                            <a class="verifiedLink"><i class="icon-varified font-16 me-2"></i><span>Verified</span></a>
:
                                            <a class="verifyLink"><span>Verify Now</span></a> 
                                          } */}
                          </div>
                        </div>
                        <ul class="school_other_details">
                          <li>
                            <span>Board</span>
                            <b>{details?.board_name}</b>
                          </li>
                          <li>
                            <span>Citizenship</span>
                            <b>{details?.primary_citizenship_name}</b>
                          </li>
                          <li>
                            <span>Current Location</span>
                            <b>{details?.country_name}</b>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex-1">
            <div className="pb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="sticky-top mainUl" style={{ zIndex: "9" }}>
                      <Card style={{ border: "none" }}>
                        <Nav
                          variant="pills"
                          className="polartabsec d-flex justify-content-center"
                        >
                          <Nav.Item eventKey="Preferences">
                            <ul className="nav nav-tabs">
                              <li className="nav-item" role="presentation">
                                <button
                                  onClick={() => {
                                    setActiveKey("Preferences");
                                  }}
                                  className={
                                    activeKey === "Preferences"
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id="Preferences-tab"
                                >
                                  Preferences
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className={
                                    activeKey === "basic-info"
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id="basic-info-tab"
                                  onClick={() => {
                                    setActiveKey("basic-info");
                                  }}
                                >
                                  Basic Information
                                </button>
                              </li>

                              <li className="nav-item" role="presentation">
                                <button
                                  className={
                                    activeKey === "education"
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id="education-tab"
                                  onClick={() => setActiveKey("education")}
                                >
                                  Education Details
                                </button>
                              </li>

                              <li className="nav-item" role="presentation">
                                <button
                                  className={
                                    activeKey === "entrance"
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id="entrance-tab"
                                  onClick={() => setActiveKey("entrance")}
                                >
                                  Entrance Exams
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className={
                                    activeKey === "curriculars"
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id="curriculars-tab"
                                  onClick={() => setActiveKey("curriculars")}
                                >
                                  Extra-curriculars
                                </button>
                              </li>
                            </ul>
                          </Nav.Item>
                        </Nav>
                      </Card>
                    </div>
                    <div className="border-radius-10 pt-3 pb-0 mb-4">
                      <div>
                        <div className="row m-0 pt-4 JobInternshipListing">
                          <div className="col-lg-12 customcontainer">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="Preferences"
                              activeKey={activeKey}
                              onSelect={(k) => setActiveKey(k)}
                            >
                              <Tab.Content>
                                <Tab.Pane eventKey="Preferences">
                                  <Preferences />
                                </Tab.Pane>
                                <Tab.Pane eventKey="basic-info">
                                  <BasicInfo />
                                </Tab.Pane>
                                <Tab.Pane eventKey="education">
                                  <Education details={details} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="entrance">
                                  <Entrance />
                                </Tab.Pane>
                                <Tab.Pane eventKey="curriculars">
                                  <ExtraCurricular />
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default StudentProfile;
