import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const CareerFilter = ({ onFilterChange }) => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [likeSearchQuery, setLikeSearchQuery] = useState('');
  const [selectedLikes, setSelectedLikes] = useState([]);
  const [abilitySearchQuery, setAbilitySearchQuery] = useState('');
  const [selectedAbilities, setSelectedAbilities] = useState([]);

  const location = useLocation(); // Hook to access the current URL

  const likesOptions = [
    { value: "Being Creative and Innovative", label: "Being Creative and Innovative" },
    { value: "Communicating with People", label: "Communicating with People" },
    { value: "Designing and Styling", label: "Designing and Styling" },
    { value: "Helping and Guiding People", label: "Helping and Guiding People" },
    { value: "Learning about other Cultures", label: "Learning about other Cultures" },
    { value: "Media, Glamour and Entertainment", label: "Media, Glamour and Entertainment" },
    { value: "Nature and Outdoors", label: "Nature and Outdoors" },
    { value: "Observing Human Behaviour", label: "Observing Human Behaviour" },
    { value: "Organizing and Structuring", label: "Organizing and Structuring" },
    { value: "Physical Activities and Sports", label: "Physical Activities and Sports" },
    { value: "Playing with Numbers", label: "Playing with Numbers" },
    { value: "Solving Problems", label: "Solving Problems" },
    { value: "Taking Risk and Adventure", label: "Taking Risk and Adventure" },
    { value: "Technology and Gadgets", label: "Technology and Gadgets" },
  ];

  const naturalAbilitiesOptions = [
    { value: "Analytical & Critical thinking", label: "Analytical & Critical thinking" },
    { value: "Computer Skills", label: "Computer Skills" },
    { value: "Dealing with People", label: "Dealing with People" },
    { value: "Drawing and Sketching", label: "Drawing and Sketching" },
    { value: "Imagining Things", label: "Imagining Things" },
    { value: "Making Decisions", label: "Making Decisions" },
    { value: "Planning and Supervising", label: "Planning and Supervising" },
    { value: "Scientific Curiosity", label: "Scientific Curiosity" },
    { value: "Solving Quantitative Problems", label: "Solving Quantitative Problems" },
    { value: "Team Work", label: "Team Work" },
    { value: "Verbal Communication", label: "Verbal Communication" },
    { value: "Working with Machines and Tools", label: "Working with Machines and Tools" },
    { value: "Working with Numerical Data", label: "Working with Numerical Data" },
  ];

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}career/departments`)
      .then(response => {
        if (response.data.success) {
          setDepartments(response.data.data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching department data:', error);
      });
  }, []);

  // Parse URL query parameters and set selected filters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const likeFromUrl = params.get('like');
    const abilityFromUrl = params.get('ability');

    if (likeFromUrl) {
      setSelectedLikes([likeFromUrl]); // Set the like from the URL query param
    }

    if (abilityFromUrl) {
      setSelectedAbilities([abilityFromUrl]); // Set the ability from the URL query param
    }
  }, [location.search]); // Trigger this when the URL changes

  useEffect(() => {
    onFilterChange({
      departments: selectedDepartments,
      likes: selectedLikes,
      abilities: selectedAbilities,
    });
  }, [selectedDepartments, selectedLikes, selectedAbilities]);

  const handleCheckboxChange = (id, selectedItems, setSelectedItems) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter(item => item !== id)
        : [...prevSelected, id]
    );
  };

  const filteredDepartments = departments.filter(department =>
    department?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredLikes = likesOptions.filter(like =>
    like?.label?.toLowerCase().includes(likeSearchQuery.toLowerCase())
  );

  const filteredAbilities = naturalAbilitiesOptions.filter(ability =>
    ability?.label?.toLowerCase().includes(abilitySearchQuery.toLowerCase())
  );

    return (
        <div className="filterGroup" id="accordionFilters">
            {/* Department Filter */}
            <div className="fiterItem mb-3">
                <div className="filterHeader">
                    <div className="d-flex justify-content-between align-items-center position-relative">
                        <button
                            className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseShortlistedCourse"
                            aria-expanded="true"
                            aria-controls="collapseShortlistedCourse"
                        >
                            <span className="font-18 font-600">Department</span>
                            <span className="toggle"></span>
                        </button>
                    </div>
                </div>
                <div className="collapse show" id="collapseShortlistedCourse">
                    <div className="filterSearchblk mb-3">
                        <form className="d-flex align-items-center filterSearch position-relative" onSubmit={(e) => e.preventDefault()}>
                            <input
                                className="form-control flex-1 font-14 font-500 w-100"
                                type="search"
                                placeholder="Search Departments"
                                aria-label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </form>
                    </div>
                    <div className="card card-body mt-2">
                        <div className="overflow-hidden">
                            <div className="scrollMe">
                                {filteredDepartments.length > 0 ? (
                                    filteredDepartments.map(({ id, name }) => (
                                        <div className="form-check d-flex flex-wrap" key={id}>
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value={id}
                                                id={`department-${id}`}
                                                onChange={() => handleCheckboxChange(id, selectedDepartments, setSelectedDepartments)}
                                                checked={selectedDepartments.includes(id)}
                                            />
                                            <label
                                                className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                htmlFor={`department-${id}`}
                                            >
                                                {name}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    <p>No departments found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* What All Do You Like Filter */}
            <div className="fiterItem mb-3">
                <div className="filterHeader">
                    <div className="d-flex justify-content-between align-items-center position-relative">
                        <button
                            className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseLikes"
                            aria-expanded="false"
                            aria-controls="collapseLikes"
                        >
                            <span className="font-18 font-600">What all do you like?</span>
                            <span className="toggle"></span>
                        </button>
                    </div>
                </div>
                <div className="collapse" id="collapseLikes">
                    <div className="filterSearchblk mb-3">
                        <form className="d-flex align-items-center filterSearch position-relative" onSubmit={(e) => e.preventDefault()}>
                            <input
                                className="form-control flex-1 font-14 font-500 w-100"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                value={likeSearchQuery}
                                onChange={(e) => setLikeSearchQuery(e.target.value)}
                            />
                        </form>
                    </div>
                    <div className="card card-body">
                        <div className="overflow-hidden">
                            <div className="scrollMe">
                                {filteredLikes.map(({ value, label }) => (
                                    <div className="form-check d-flex flex-wrap" key={value}>
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            id={`like-${value}`}
                                            onChange={() => handleCheckboxChange(value, selectedLikes, setSelectedLikes)}
                                            checked={selectedLikes.includes(value)}
                                        />
                                        <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1" htmlFor={`like-${value}`}>
                                            {label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* What You Are Naturally Good At Filter */}
            <div className="fiterItem mb-3">
                <div className="filterHeader">
                    <div className="d-flex justify-content-between align-items-center position-relative">
                        <button
                            className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseAbilities"
                            aria-expanded="false"
                            aria-controls="collapseAbilities"
                        >
                            <span className="font-18 font-600">What you are naturally good at?</span>
                            <span className="toggle"></span>
                        </button>
                    </div>
                </div>
                <div className="collapse" id="collapseAbilities">
                    <div className="filterSearchblk mb-3">
                        <form className="d-flex align-items-center filterSearch position-relative" onSubmit={(e) => e.preventDefault()}>
                            <input
                                className="form-control flex-1 font-14 font-500 w-100"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                value={abilitySearchQuery} // Bound to ability search state
                                onChange={(e) => setAbilitySearchQuery(e.target.value)} // Updates the state on input
                            />
                        </form>
                    </div>
                    <div className="card card-body">
                        <div className="overflow-hidden">
                            <div className="scrollMe">
                                {filteredAbilities.map(({ value, label }) => (
                                    <div className="form-check d-flex flex-wrap" key={value}>
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            id={`ability-${value}`}
                                            onChange={() => handleCheckboxChange(value, selectedAbilities, setSelectedAbilities)}
                                            checked={selectedAbilities.includes(value)}
                                        />
                                        <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1" htmlFor={`ability-${value}`}>
                                            {label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerFilter;
