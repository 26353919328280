import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const likesOptionsArray = [
  { value: "Being Creative and Innovative", label: "Being Creative and Innovative" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/creative.svg" },
  { value: "Communicating with People", label: "Communicating with People" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/chat.svg"},
  { value: "Designing and Styling", label: "Designing and Styling" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/design_vector.svg"},
  { value: "Helping and Guiding People", label: "Helping and Guiding People" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/helping-hand.svg"},
  { value: "Learning about other Cultures", label: "Learning about other Cultures" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/cultures.svg"},
  { value: "Media, Glamour and Entertainment", label: "Media, Glamour and Entertainment" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/media.svg"},
  { value: "Nature and Outdoors", label: "Nature and Outdoors" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/nature.svg"},
  { value: "Observing Human Behaviour", label: "Observing Human Behaviour" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/observing-human-behaviour.svg"},
  { value: "Organizing and Structuring", label: "Organizing and Structuring" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/leadership.svg"},
  { value: "Physical Activities and Sports", label: "Physical Activities and Sports" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/outline-create.svg"},
  { value: "Playing with Numbers", label: "Playing with Numbers" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/number-blocks.svg"},
  { value: "Solving Problems", label: "Solving Problems" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/idea.svg"},
  { value: "Taking Risk and Adventure", label: "Taking Risk and Adventure" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/taking-risk.svg"},
  { value: "Technology and Gadgets", label: "Technology and Gadgets" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/icon-network.svg"},
];

const naturalAbilitiesOptions = [
  { value: "Analytical & Critical thinking", label: "Analytical & Critical thinking" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/decision-making.svg"},
  { value: "Computer Skills", label: "Computer Skills" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/setup.svg"},
  { value: "Dealing with People", label: "Dealing with People" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/network.svg"},
  { value: "Drawing and Sketching", label: "Drawing and Sketching" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/drawing.svg"},
  { value: "Imagining Things", label: "Imagining Things" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/imagination.svg"},
  { value: "Making Decisions", label: "Making Decisions" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/decision.svg"},
  { value: "Planning and Supervising", label: "Planning and Supervising" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/planning.svg"},
  { value: "Scientific Curiosity", label: "Scientific Curiosity" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/scientific-curiosity.svg"},
  { value: "Solving Quantitative Problems", label: "Solving Quantitative Problems" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/problem-solving.svg"},
  { value: "Team Work", label: "Team Work" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/network.svg"},
  { value: "Verbal Communication", label: "Verbal Communication" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/chat.svg"},
  { value: "Working with Machines and Tools", label: "Working with Machines and Tools" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/conveyor.svg"},
  { value: "Working with Numerical Data", label: "Working with Numerical Data" , image :"https://sfe-media.s3.ap-south-1.amazonaws.com/icons/number.svg"},
];

const CareerInterestMatch = () => {
  const [selectedLikeValues, setSelectedLikeValues] = useState(null);
  const [selectedAbility, setSelectedAbility] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    console.log("Component rendered");
  }, []);

  const handleLikeSelectCall = (event) => {
    setSelectedLikeValues(event.target.value);
    console.log("Selected Like:", event.target.value);
  };

  const handleAbilitySelect = (event) => {
    setSelectedAbility(event.target.value);
    console.log("Selected Ability:", event.target.value);
  };

  const handleRedirect = () => {
    if (selectedLikeValues && selectedAbility) {
      // Build query parameters
      const queryParams = new URLSearchParams({
        like: selectedLikeValues,
        ability: selectedAbility,
      });
  
      // Check the current URL for 'user' or 'student'
      const currentUrl = window.location.href;
      let pathPrefix = "";
  
      if (currentUrl.includes("user")) {
        pathPrefix = "user"; // Add user to the path
      } else if (currentUrl.includes("student")) {
        pathPrefix = "student"; // Add student to the path
      }
  
      // Redirect to the appropriate URL
      window.location.href = `/${pathPrefix}/career-list?${queryParams.toString()}`;
    } else {
      alert("Please select both a Like and an Ability before viewing matched careers.");
    }
  };
  
  

  return (
    <div className="tab-pane fade" id="careerInterestMatch" role="tabpanel" aria-labelledby="careerInterestMatch-tab">
      <div className="skill-wrap position-relative">
        <div className="skill-range">
          <h2 className="font-24 font-500 text-center mb-0">
            {selectedLikeValues ? "What are you naturally good at" : "What all do you Like"}
          </h2>
        </div>
        <form>
          {/* Interest (Likes) Section */}
          <div className="select-skill row">
            <div className="d-flex justify-content-center flex-wrap text-center select-skill-container p-0">
              {!selectedLikeValues &&
                likesOptionsArray.map((like) => (
                  <div className="select" key={like.value}>
                    <input
                      type="radio"
                      id={`likes-${like.value}`}
                      value={like.value}
                      name="likes"
                      checked={selectedLikeValues === like.value}
                      onChange={handleLikeSelectCall}
                    />
                    <label htmlFor={`likes-${like.value}`} className="skill d-flex justify-content-between align-items-center px-4 py-3 cursor-pointer">
                      <img src={like.image} alt={like.label} className="icon-small mr-2" />
                      <h4 className="font-16 font-500 m-0">{like.label}</h4>
                    </label>
                  </div>
                ))}
            </div>
          </div>

          {/* Ability Section (Conditional Rendering Based on Like Selection) */}
          {selectedLikeValues && (
            <div className="select-skill row">
              <div className="d-flex justify-content-center flex-wrap text-center select-skill-container p-0">
                {!selectedAbility &&
                  naturalAbilitiesOptions.map((ability) => (
                    <div className="select" key={ability.value}>
                      <input
                        type="radio"
                        id={`abilitys-${ability.value}`}
                        value={ability.value}
                        name="abilities"
                        checked={selectedAbility === ability.value}
                        onChange={handleAbilitySelect}
                      />
                      <label htmlFor={`abilitys-${ability.value}`} className="skill d-flex justify-content-between align-items-center px-4 py-3 cursor-pointer">
                        <img src={ability.image} alt={ability.label} className="icon-small mr-2" />
                        <h4 className="font-16 font-500 m-0">{ability.label}</h4>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </form>

        {(selectedLikeValues || selectedAbility) && (
          <div className="sticky-select-skill-result">
            <div className="interested d-flex">
              {selectedLikeValues && (
                <div className="whatilike">
                  <label className="font-14 font-600">What I Like</label>
                  <div className="select">
                    <label className="skill d-flex justify-content-between align-items-center cursor-pointer">
                      <h4 className="font-16 font-500 m-0">{selectedLikeValues}</h4>
                      <i className="icon-checked"></i>
                    </label>
                  </div>
                </div>
              )}
              {selectedAbility && (
                <>
                  <div className="plus">#</div>
                  <div className="whatigood">
                    <label className="font-14 font-600">What I am Good at</label>
                    <div className="select">
                      <label className="skill d-flex justify-content-between align-items-center cursor-pointer">
                        <h4 className="font-16 font-500 m-0">{selectedAbility}</h4>
                        <i className="icon-checked font-24"></i>
                      </label>
                    </div>
                  </div>
                
              
              <div className="equalto">#</div>
              <div className="viewMatch">
                <button
                  type="button"
                  className="btn btn-completed radius height-40 max-200 mx-auto d-flex align-items-center w-100 mb-2 font-14 font-500 px-4"
                  onClick={handleRedirect}
                >
                  <span>View Matched Careers</span>
                </button>
              </div>
              </>
              )}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default CareerInterestMatch;
