import React, { useEffect, useRef } from "react";
import CareerCard from "./career-card";
import CareerTrendsSidebar from "./career-trends-sidebar";

const OffbeatCareer = () => {
  const pageRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);

  const handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        ref1.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        ref2.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        ref3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        ref4.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        ref5.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 5:
        ref6.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 6:
        ref7.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 7:
        ref8.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 8:
        ref9.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 9:
        ref10.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset || window.scrollY;
    const elementOffsetTop = pageRef.current?.offsetTop - 30;
    const elementOffsetHeight = pageRef.current?.offsetHeight + 30;
    const ptabs = pageRef.current?.querySelector(".nav-tabs");
    const ptabsLink = ptabs?.querySelectorAll(".nav-link");
    const otabs = pageRef.current?.querySelectorAll(".card-container");
    for (let i = 0; i < otabs?.length; i++) {
      let ot = otabs[i].offsetTop - ptabs?.offsetHeight * 2;
      if (
        position >= ot &&
        position < otabs[i + 1]?.offsetTop - ptabs?.offsetHeight * 2
      ) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position >= ot && i === otabs?.length - 1) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position < otabs[1].offsetTop - ptabs?.offsetHeight * 2) {
        ptabsLink[0]?.classList?.add("active");
        ptabsLink[1]?.classList?.remove("active");
      } else {
        ptabsLink[i]?.classList?.remove("active");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const item = [
    "Museology",
    "Rehabilitation Therapy",
    "Special Education",
    "Game Design",
    "Sports Management",
    "Acting and Theatre",
    "Stand Up Comic",
    "Image Consultant",
    "Event Management",
    "Cartoonist",
  ];
  const MuseologyData = {
    title: "Museology",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Museology as a career is taking a trip through time. From being an integral part of the workings in a museum, to taking care of exhibits and maintaining them, museology has it all!</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">This career has been highly popular amongst students who are interested in pursuing a career in History. In Museology, you get to work at reputed museums and art galleries under curators and managers.</p>`,
    name1: "Museum Curator",
    name2: "Archivist",
    name3: "Museum Director",
    itemData: [
      {
        titlename: "Bachelor of Fine Arts",
        description:
          "A Bachelor's degree in Fine Arts is the most popular and relevant course if you aim to pursue a career in museology. It offers in-depth knowledge about the career, its different aspects, and where you could fit in the entire ecosystem. Most colleges in India and abroad offer a BFA degree.",
      },
      {
        titlename: "Bachelor of Arts",
        description:
          "Another common course you can opt for is the Bachelor of Arts degree with a specialisation in History. Even though a specialisation is not needed, you can opt for a regular BA degree and then choose to specialise in Museology.",
      },
    ],
  };
  const RehabilitationData = {
    title: "Rehabilitation Therapy",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Imagine being in the forefront of a person&rsquo;s recovery. Being able to improve the quality of life of a person is what Rehabilitation Therapy is all about. For a career in Rehabilitation Therapy, you will need to have patience, the need for giving,
        and an intense understanding of people.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Rehabilitation Therapy is an important career and is the need of the hour, not only in India, but all over the world.</p>`,
    name1: "Physical Therapist",
    name2: "Occupational Therapist",
    name3: "Speech Therapist",
    itemData: [
      {
        titlename: "Bachelor of Science",
        description:
          "A Bachelor of Science degree is the quintessential course to make your way into the world of Rehabilitation Therapy. With this degree, and a specialisation in rehabilitation, you can learn and implement the ways by which you can provide care and treatment of patients under you.",
      },
    ],
  };
  const EducationData = {
    title: "Special Education",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Impacting change and educating children is what a Special Educator&rsquo;s motto is. Working with children and adults with disabilities offers a career that is both personally and professionally fulfilling.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Special Education, however, is not easy, and requires knowledge and passion for helping and giving all that you can while taking care of education for children. There are crores of special children in India, you could be one impacting change in their
        lives, as a Special Educator.</p>`,
    name1: "Special Educator",
    itemData: [
      {
        titlename: "Bachelor of Science",
        description:
          "Bachelor of Commerce is a 3-year undergraduate course. It helps in establishing a career in accountancy, finance, operations, audit, taxation, and other accountancy-related fields.",
      },

      {
        titlename: "BBA",
        highlight: "(multiple specializations)",
        description:
          "BBA Course is an undergraduate program that imparts managerial, business management, and entrepreneurial skills to students. It is usually a 3-year program.",
      },
      {
        titlename: "Chartered Accountancy",
        highlight: "(ICAI)",
        description:
          "In India, the ICAI grants the degree of Chartered Accountancy. Such professionals manage the financial accounts, budgeting, and taxation in companies and other establishments. Last year, the University Grant Commission (UGC) had granted the academic equivalence to Chartered Accountancy qualification with the Post Graduate degree.",
      },
      {
        titlename: "Cost and Management Accounting",
        highlight: "(ICMAI)",
        description:
          "In India, the ICMAI Institute (previously known as the ICWAI) awards the CMA professional degree. Students pursuing this can make it big through knowledge of Cost and Management Accounting.",
      },
      {
        titlename: "Company Secretary",
        highlight: "(ICSI)",
        description:
          "Company Secretaries from the ICSI are highly qualified professionals who play a key role in ensuring compliance with the laws and promoting sound Corporate Governance practices in Indian Corporates. It has 3 levels of examination stages to clear.",
      },
    ],
  };
  const GameData = {
    title: "Game Design",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">From creating online games, to online versions of board games, and more, a Game Designer&rsquo;s job offers creative freedom and satisfaction. Be a developer, or a designer, or a visual artist, you can do a lot in this field that is said to become a $300
        billion industry in the next few years!</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Opting for a career in game design can help you find your way to the credits of the next big game launch! If you think you&rsquo;re meant for the gaming industry, a career in game design could be for you!</p>`,
    name1: "Lead Designer",
    name2: "UI Designer",
    name3: "Software Developer",
    itemData: [
      {
        titlename: "Bachelors in Science",
        description:
          "To build a career in Game Design, you have to start from the scratch. A Bachelors of Science in Game Design is the most popular course for gamers and game makers today. It offers in-depth knowledge of the basics, and the other levels in the different aspects of the game, and how to implement them.",
      },
    ],
  };
  const SportsData = {
    title: "Sports Management",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Sports has been a career that has seen many greats in the past so many years, no matter what the sport is. But who makes the sport and the players who they are? Sports Managers. Sports Management is an equally thriving career, if not more. Managers, Psychologists,
        Statisticians, and Analysts, all play an integral role in sports and its management.</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">According to a survey published by India Today, in India, the sports industry is expected to grow by 18% on average. To thrive in a career in sports management, you need to have communication skills, and quick thinking.</p>`,
    name1: "UI Designer",
    name2: "UX Designer",
    name3: "Visual Designer",
    itemData: [
      {
        titlename: "Bachelor of Science in Game Design and Animation",
        description: `<p style="margin-bottom: 0px"><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
          id="isPasted">A BBA or a Bachelor&rsquo;s in Business Administration degree is a popular course of choice amongst individuals who aim to pursue a career in sports management. However, this career is mainly for the jobs in management, analysis, and for agents.</span>
          <br
          style="box-sizing: border-box; padding: 0px; margin: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
              <br style="box-sizing: border-box; padding: 0px; margin: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">A BBA degree helps in giving you detailed knowledge of concepts that surround sports and its management. Everything that happens behind the scenes in sports is covered in the course.</span></p>`,
      },
    ],
  };

  const ActingData = {
    title: "Acting and Theatre",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Film and Theatre have been a profession that has been around for many many decades. Offering the audience a chance to immerse themselves in the story and acting, film and theatre actors and other professionals bring the best in themselves to portray a
        role.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">But film and theatre is not only about acting. The career prospects in this field are many, and you can opt for any of them depending on your passion and interest.</p>`,
    name1: "Actor",
    name2: "Director",
    name3: "Cinematographer",
    name4: "Screenplay",

    itemData: [
      {
        titlename: "Bachelor of Arts",
        description:
          "One of the most popular courses for pursuing a career in Film and Theatre is Bachelor of Arts. By choosing a B.A. degree, you can opt for a specialisation such as Mass Communication, Psychology, etc. With a Mass Communication specialisation, you get exposed to the different media out there, different aspects of filmmaking, cinematography, etc.",
      },
      {
        titlename: "Bachelor of Fine Arts",
        description:
          "Apart from a Bachelor of Arts degree, you can also opt for a Bachelor of Fine Arts degree. A Bachelor of Fine Arts degree is much more targeted towards performing arts, rather than the technical side of things. If you aim to learn acting and its aspects, a Bachelor of Fine Arts degree is best-suited for it.",
      },
    ],
  };
  const ComicData = {
    title: "Stand Up Comic",
    desc: "When it comes to careers that are offbeat in nature, there are many. But Stand-Up Comedy is not only offbeat, it has become as popular as a regular career in any other field. Stand-Up Comedy is a lucrative career if your passion is to make everyone laugh. However, doing Stand-Up is just not about telling jokes, it’s much more than that. It’s about confidence, communication, stage presence, and more!",
    name1: "Comedian",
    name2: "Scriptwriter",

    itemData: [
      {
        titlename: "Bachelor in Arts",
        description:
          "By choosing a B.A. degree, you can opt for a specialisation such as Mass Communication, Psychology, etc. With a Mass Communication specialisation, you get exposed to the different media out there, different aspects of the stage, camera work, stage presence, etc. You can also opt for a Psychology specialisation to understand your audience better and make them laugh accordingly!",
      },
      {
        titlename: "Bachelor of Science",
        description:
          "If you aim to be a Psychologist or a Psychiatrist, a Bachelor of Science degree would be the ideal choice. It offers a research-based curriculum and the theoretical approach offers in-depth understanding of psychology and psychiatry, offering a stepping stone for future research.",
      },
    ],
  };
  const imageData = {
    title: "Image Consultant",
    desc: "Image Consulting is the profession of guiding, mentoring, educating and training people on managing their appearance. From clothing, to grooming and maintaining your body language, all of it is what an Image Consultant helps with. Not only that, they also help in maintaining etiquette to create positive and powerful first impressions to get more opportunities in life.",
    name1: "Corporate Trainer",
    name2: "Fashion Stylist",
    name3: "Personal Shopper",
    itemData: [
      {
        titlename: "Certificate Courses",
        description:
          "Since Image Consulting is not a mainstream career, it has only a handful of options when it comes to learning more about it academically. Due to this, most institutions and colleges have launched certification courses on Image Consulting for shorter periods of time. This enables candidates to learn the subject at their own pace and implement it as well.",
      },
      {
        titlename: "Bachelor of Arts",
        description:
          "To become an Image Consultant, you don’t need a specific degree. However, with a Bachelor of Arts, you can understand the industry and everything related around it. A B.A. degree helps you grasp different concepts that come under the media industry and how you can maximise your skills as an Image Consultant.",
      },
    ],
  };
  const EventData = {
    title: "Event Management",
    desc: "Be it an awards show for 1,000 people, or an intimate wedding ceremony for 10 people, an Event Manager’s skill comes to play here. Event Managers are responsible for planning and organising professional events for a particular set of audience. They understand the requirements of the clients and execute every aspect of the event in a way where there is no room for error.",
    name1: "Wedding Planner",
    name2: "Event Planner",
    name3: "Social Media Event Coordinator",
    itemData: [
      {
        titlename: "Bachelors in Business Administration",
        description:
          "A Bachelor’s in Business Administration or BBA is an ideal career to opt for if you aim to become an Event Manager. With this course, you get exposed to the different aspects of managing events, people, and looking into the other aspects of business management. Most colleges in India offer a BBA degree. After completing BBA, you can opt for a higher degree in Event Management.",
      },
      {
        titlename: "Bachelors in Arts",
        description:
          "You can pursue a career in Event Management by taking up a Bachelor’s of Arts degree for your graduation. With a BA degree, you can get exposed to the creative side of event management where you can contribute towards Social Media, Content, Editorials, Videos, and more! Post a BA degree, you can move to a more specialised course in Event Management",
      },
    ],
  };
  const CartoonistData = {
    title: "Cartoonist",
    desc: "Humour. Sarcasm. Creativity. Art. All of these are what a Cartoonist thrives on. Being a cartoonist isn’t just limited to drawing cartoons on a pen and paper. While that is the foundation of this career, today, everything has shifted online. Cartoonists can work on illustrations, design, visual art, digital art, and much more with the popularity of social media increasing on a daily basis. By opting for this career, you can use your creativity and artistic skills to portray humour, address social issues, or just communicate with your audience.",
    name1: "Animator",
    name2: "Illustrator",
    name3: "Digital Artist",
    itemData: [
      {
        titlename: "Bachelor of Fine Arts",
        description:
          "To pursue a career as a Cartoonist, you can opt for a Bachelor of Fine Arts degree. A Bachelor of Fine Arts degree is much more targeted towards understanding pure art fields. Being a student of Fine Arts, you will get exposed to not only creating ideas and concepts, but also implementing them in a digital or print platform. It offers you a platform to explore the ever growing world of Multimedia Art.",
      },
    ],
  };
  return (
    <div style={{ marginTop: "100px" }}>
      <div className="career-trendz-tab">
        <div
          className="page-section d-flex fade show"
          id="Last10Years"
          role="tabpanel"
          ref={pageRef}
          aria-labelledby="Last10Years-tab"
        >
          <CareerTrendsSidebar
            onItemClick={handleMenuItemClick}
            item={item}
          />

          <div
            className="rightPane"
            style={{ flexDirection: "column", width: "calc(100% - 270px)" }}
          >
            <div
              className="card-container"
              ref={ref1}
              style={{ width: "100%" }}
            >
              <CareerCard data={MuseologyData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref2}
              style={{ width: "100%" }}
            >
              <CareerCard data={RehabilitationData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref3}
              style={{ width: "100%" }}
            >
              <CareerCard data={EducationData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref4}
              style={{ width: "100%" }}
            >
              <CareerCard data={GameData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref5}
              style={{ width: "100%" }}
            >
              <CareerCard data={SportsData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref6}
              style={{ width: "100%" }}
            >
              <CareerCard data={ActingData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref7}
              style={{ width: "100%" }}
            >
              <CareerCard data={ComicData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref8}
              style={{ width: "100%" }}
            >
              <CareerCard data={imageData} />
            </div>
            <div
              className="card-container  mt-3"
              ref={ref9}
              style={{ width: "100%" }}
            >
              <CareerCard data={EventData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref10}
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={CartoonistData} />
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 invite_frnds max-170 height-45">
                  <span>Activity Completed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffbeatCareer;
