import React from "react";
import { FaUser } from "react-icons/fa";
import { MdGroups2, MdRocketLaunch } from "react-icons/md";
import { BiLogIn } from "react-icons/bi";
import HomeCard from "../../../Components/HomeCard/home-card";
import { useAuth } from "../../../Contexts/auth-context";

const Home = () => {
  const { user } = useAuth();

  const homeData = [
    {
      title: "Setup Your Profile",
      sub_title: "2 out of 5 activities completed",
      totalRate: 5,
      rate: 2,
      icon: <FaUser className="icons-name" />,
      link: "/user/platform-setup",
      hoverData: [
        "Verify your account details",
        "Upload Image",
        "Complete basic profile",
        "Start your first module in Learning & Training",
        "Verify your account details",
        "Upload Image",
        "Complete basic profile",
        "Start your first module in Learning & Training",
      ],
    },
    {
      title: "Student & Parent Launch",
      sub_title: "0 out of 4 activities completed",
      totalRate: 4,
      rate: 0,
      icon: <MdRocketLaunch className="icons-name" />,
      link: "/user/student-onboarding",
      hoverData: [
        "Verify your account details",
        "Upload Image",
        "Complete basic profile",
        "Start your first module in Learning & Training",
        "Verify your account details",
        "Upload Image",
        "Complete basic profile",
        "Start your first module in Learning & Training",
      ],
    },
    {
      title: "Engage with Groups",
      sub_title: "1 out of 4 activities completed",
      totalRate: 4,
      rate: 2,
      icon: <MdGroups2 className="icons-name" />,
      link: "/user/engage-students",
    },
    {
      title: "Access Resources & Research",
      sub_title: "5 out of 5 activities completed",
      totalRate: 4,
      rate: 0,
      icon: <BiLogIn className="icons-name" />,
      link: "/user/access-resources",
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-start pt-5">
        <div className="welcome-title">
          <h1>Welcome to {user?.organization}</h1>
          <p>We recommend below activities to get you started.</p>
        </div>
        <div className="dashboard">
          <span className="btn-green">
            <span>Jump to Dashboard</span>
          </span>
        </div>
      </div>
      <div className="container my-5">
        <div className="row justify-content-center">
          {homeData.map((res, index) => {
            return (
              <div key={index} className="col-md-6 col-sm-6 mb-5">
                <HomeCard data={res} index={index} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Home;
