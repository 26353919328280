import React, { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Header from "../CounsellerHeader/header"
import Sidebar from "../CounsellerSideBar/sidebar"
import { Container } from "react-bootstrap"
import { getCompanyByAlias } from "../../services/util/util-service"
import AskMe from "../StickyFooter/AskMe"

const CounsellerLayout = () => {
  const location = useLocation()
  // const [companyDetail, setCompanyDetail] = useState([]);

  // const fetchCompanyByAlias = async (alias) => {
  //   await getCompanyByAlias(alias).then((res) => {
  //     if (res && res?.data?.data) {
  //       setCompanyDetail(res?.data?.data);
  //       localStorage.setItem("companyDetails", JSON.stringify(res?.data?.data));
  //     }
  //   });
  // };

  // useEffect(() => {
  //   fetchCompanyByAlias(window.location.hostname);
  // }, []);

  return (
    <React.Fragment>
      {window.location.pathname === "/user-login" || window.location.pathname === "/" ? (
        <div className="flex100vh">
          <Outlet />
        </div>
      ) : (
        <div className="horizontalMenucontainer">
          <div className="page">
            <div className="page-main">
              <Header />

              <div className="main sideBarEnable flex-1">
                <Container>
                  <div className="d-flex h-100">
                    {/* <Sidebar /> */}
                    <div className="flex-1 d-flex flex-column">
                      <div className="flex-1">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            <AskMe></AskMe>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default CounsellerLayout
