/*
 * Install the Generative AI SDK
 *
 * $ npm install @google/generative-ai
 */

const { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } = require("@google/generative-ai")

const apiKey = process.env.REACT_APP_GOOGLE_GEMINI_API
const genAI = new GoogleGenerativeAI(apiKey)

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
})
let chatHistory = []

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
}

async function runGimini(prompt, history) {
  const chatSession = model.startChat({
    generationConfig,
    // safetySettings: Adjust safety settings
    // See https://ai.google.dev/gemini-api/docs/safety-settings
    history: chatHistory,
  })

  const result = await chatSession.sendMessage(prompt)
  console.log(result.response.text())
  chatHistory.push({ role: "user", parts: [{ text: prompt }] })
  chatHistory.push({ role: "model", parts: [{ text: result.response.text() }] })
  return result.response.text()
}

export default runGimini
