import React from "react";
import { Modal } from "react-bootstrap";

const Deadline = ({ setShowDeadlines, showDeadlines }) => {
  return (
    <Modal
      centered={true}
      show={showDeadlines.length > 0 ? true : false}
      className="modal fade"
      dialogClassName="modal-90w"
      onHide={() => setShowDeadlines(false)}
    >
      {/* <div class="modal-dialog modal-dialog-centered mx-auto"> */}
      <div class="modal-content">
        <div class="modal-header bg-gray-de">
          <h5
            class="modal-title font-24 text-dark-blue"
            id="staticBackdropLabel"
          >
            Test Deadlines Date
          </h5>
          <button
            type="button"
            onClick={() => setShowDeadlines(false)}
            class="btn-close btn-close-small position-static cursor-pointer"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="deadline-date-list">
            <ul>
              {showDeadlines?.map((x) => {
                return (
                  <li>
                    <div
                      class="college-name cursor-pointer"
                      onClick={() =>
                        x?.application_url && window.open(x.application_url)
                      }
                    >
                      {x.label}
                    </div>
                    <div class="college-date">
                      {new Date(x.date_to).toLocaleDateString()}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default Deadline;
