import React from "react";
import { Modal } from "react-bootstrap";

const QuizSubmitModal = ({
  quizOpenModal,
  setQuizOpenModal,
  handleSubmit,
  quiz,
}) => {
  return (
    <Modal show={quizOpenModal} centered onHide={() => setQuizOpenModal(false)}>
      <div className="modal-body p-5">
        <div className="d-flex flex-column justify-content-center align-items-center px-4">
          <span className="d-flex justify-content-center align-items-center mb-4">
            <img src="/assets/images/group-40901.svg" width="72" />
          </span>
          <h3 className="font-24 font-600 mb-4">
            Are you sure of your answers?
          </h3>
          <div className="font-18 font-400 text-center">
            The quiz requires at least {quiz?.pass_percentage}% marks in order
            to successfully complete it. If you score below it, you can retake
            the quiz after 12 hours
          </div>
          <div className="d-flex justify-content-center align-items-center margin-top-40 w-100">
            <button
              className="btn-blue max-170 d-flex text-center align-items-center font-14 font-500 mx-2"
              onClick={() => setQuizOpenModal(false)}
            >
              Let Me Recheck
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn radius max-170 height-40 w-100 d-block px-4 mx-2 font-14 font-500"
              style={{
                background: "#5cae48",
                color: "#fff",
                zIndex: "2",
                position: "relative",
                borderRadius: "25px",
                webkitBorderRadius: "25px",
              }}
            >
              Yes I’m Sure
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuizSubmitModal;
