import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router";

const CareerPreferences = () => {
  const [inputList, setInputList] = useState([{ inputs: "" }]);
  const [selected, setIsSelected] = useState();
  const navigate = useNavigate();
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { inputs: "" }]);
  };
  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-4 mt-3 pb-1">
          <div className="welcome-title">
            <h1>Tell us about your Career Preferences</h1>
            <p>
              Your career preferences help us provide you with the most relevant
              and updated information!
            </p>
          </div>
        </div>
        <div className="my-3 profile_setup_cont">
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-8 col-sm-12 profile_setup p-0 ">
              <div className="carrer-left-part formBody">
                <div className="formSection">
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <div className="formRow" key={i}>
                          <div className="icon">
                            <img
                              src="/assets/images/world.svg"
                              className="img-fluid"
                              width="24"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label htmlFor="COUNTRY_PREFERENCE">
                                What Colleges are you targeting to join after
                                Grade 12?
                                <sup className="astric">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control form-modal form-search"
                                id="COUNTRY_PREFERENCE"
                                placeholder="1st College Preference"
                                required=""
                                name="inputs"
                                value={x.inputs}
                                onChange={(e) => handleInputChange(e, i)}
                              />

                              <div className="form-group formRowcheck">
                                <div className="form-check flex-1">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="countrychoices"
                                    name="inputs"
                                    value={x.inputs}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="countrychoices"
                                  >
                                    I haven’t finalized the countries yet
                                  </label>
                                </div>
                                {inputList.length !== 1 && (
                                  <button
                                    className="btn-add"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    <AiOutlineDelete />
                                    Remove
                                  </button>
                                )}
                                {inputList.length - 1 === i && (
                                  <button
                                    className="btn-add"
                                    onClick={handleAddClick}
                                  >
                                    <BsPlusLg />
                                    Add More
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="formRow">
                          <div className="icon">
                            <img
                              src="/assets/images/world.svg"
                              className="img-fluid"
                              width="24"
                            />
                          </div>
                          <div className="form-field">
                            <div className="form-group">
                              <label htmlFor="Country-Preference-1">
                                What is your preferred country for your
                                graduation?
                                <sup className="astric">*</sup>
                              </label>
                              <select
                                className="form-select"
                                id="Country-Preference-1"
                                aria-label="Default select example"
                              >
                                <option selected="">
                                  1st Country Preference
                                </option>
                                <option value="1">Select 1</option>
                                <option value="2">Select 2</option>
                                <option value="3">Select 3</option>
                              </select>
                            </div>
                            <div className="form-group formRowcheck">
                              <div className="form-check flex-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="countrychoices"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="countrychoices"
                                >
                                  I haven’t finalized the countries yet
                                </label>
                              </div>
                              <button className="btn-add">
                                <i className="plus"></i>
                                Add More
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="formRow">
                    <div className="icon opacity-0">
                      <img
                        src="/assets/images/world.svg"
                        className="img-fluid"
                        width="24"
                      />
                    </div>
                    <div className="form-field">
                      <div className="form-group">
                        <label htmlFor="Country-Preference-1">
                          What would be your preferred program type?
                        </label>
                        <div className="row my-2">
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                              <input
                                type="checkbox"
                                name="gender"
                                id="preferred_program_mba"
                                value="MBA"
                                className="form-check-input custom-border-84-20 m-0"
                              />
                              <label
                                htmlFor="preferred_program_mba"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                MBA
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                              <input
                                type="checkbox"
                                name="gender"
                                id="preferred_program_mtech"
                                value="MTech"
                                className="form-check-input custom-border-84-20 m-0"
                              />
                              <label
                                htmlFor="preferred_program_mtech"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                MTech
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                              <input
                                type="checkbox"
                                name="gender"
                                id="preferred_program_ms"
                                value="MS"
                                className="form-check-input custom-border-84-20 m-0"
                              />
                              <label
                                htmlFor="preferred_program_ms"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                MS
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                              <input
                                type="checkbox"
                                name="gender"
                                id="other_masters"
                                value="Other Masters"
                                className="form-check-input custom-border-84-20 m-0"
                              />
                              <label
                                htmlFor="other_masters"
                                className="form-check-label font-16 font-500 ms-2"
                              >
                                Other Masters
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="icon opacity-0">
                      <img
                        src="/assets/images/world.svg"
                        className="img-fluid"
                        width="24"
                      />
                    </div>
                    <div className="form-field">
                      <div className="form-group">
                        <label htmlFor="Country-Preference-1">
                          Prospective Sessions to join college (optional)
                        </label>
                        <select
                          className="form-select"
                          id="Country-Preference-1"
                          aria-label="Default select example"
                        >
                          <option>Select</option>
                          <option value="1">Select 1</option>
                          <option value="2">Select 2</option>
                          <option value="3">Select 3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btnrow d-flex flex-wrap justify-content-center my-4 py-2">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#congratulations_3"
                  className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-16 font-500 invite_frnds max-170 height-45"
                  style={{ backgroundColor: "#5cae48" }}
                  onClick={() => navigate("")}
                >
                  <span>Next</span>
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 profile_setup_help">
              <p className="font-18 font-600 mb-3">
                How can we help you in your career planning?
              </p>
              <img
                className="mb-3 img-fluid float_horizontal_animate"
                src="/assets/images/profile_setup_img.png"
                alt="Profile Setup"
              />
              <p className="font-16 font-400 m-0">
                Have big career plans ahead? Even if you don’t - it’s okay! Tell
                us about your thoughts and preferences for the road ahead.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CareerPreferences;
