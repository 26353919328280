import React, { useState } from "react";
import Deadline from "../Modals/Student/deadline";

const DeadlinesBox = ({ data }) => {
  const [showColleges, setShowColleges] = useState(false);
  const [showDeadlines, setShowDeadlines] = useState(false);

  const handleToggleClick = () => {
    setShowColleges(!showColleges);
  };

  return (
    <div className="college_blk">
      <div className="collegeName">
        <h3
          className="cursor-pointer"
          onClick={() => data?.website_url && window.open(data.website_url)}
        >
          {data?.name}
        </h3>
        <h5 className="font-14 font-500 text-dark-blue">{data?.short_name}</h5>
        {/* <label className="tick font-14 font-500">
          <i className="icon-correct-invert blue-text me-1"></i>
          21 Students Shortlisted
        </label> */}
      </div>
      <div className="college_detail">
        <div className="college_detail_price_deadline">
          {data?.courses_count == "0" ? null : (
            <a
              className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-2 max-170"
              onClick={handleToggleClick}
            >
              <span className="font-14 font-500 d-flex align-items-center justify-content-center viewAllCourses">
                {data?.courses_count} Courses
              </span>
            </a>
          )}
          {data.sessions.length > 0 && (
            <a
              aria-expanded="false"
              onClick={() => setShowDeadlines(data.sessions)}
              class="btn btn-border-gray height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-2"
            >
              <span class="font-14 font-500 d-flex align-items-center justify-content-center viewAllCourses">
                Date
              </span>
            </a>
          )}
        </div>
        {data?.courses?.length ? (
          <>
            {showColleges && (
              <>
                <div className="college-list mt-3">
                  {data?.courses?.map((b, i) => {
                    return (
                      <div className="college" key={i}>
                        <div className="college-brife">
                          <label className="font-16 font-500 text-white">
                            {b?.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="college">
        <div className="college-brife">
          <label className="font-16 font-500 text-white">
            Bio Sciences & Biotechnology
          </label>
        </div>
      </div>
      <div className="college">
        <div className="college-brife">
          <label className="font-16 font-500 text-white">
            Engineering & Technology
          </label>
        </div>
      </div>
      <div className="college">
        <div className="college-brife">
          <label className="font-16 font-500 text-white">Fashion</label>
        </div>
      </div>
      <div className="college">
        <div className="college-brife">
          <label className="font-16 font-500 text-white">
            Healthcare
          </label>
        </div>
      </div> */}

                  {/* <div className="w-100 text-center">
        <a className="btn btn-viewdetail">
          <span className="font-14 font-500 d-flex align-items-center justify-content-center">
            View All Details
          </span>
        </a>
      </div> */}
                </div>
              </>
            )}
          </>
        ) : null}
        {showDeadlines.length > 0 && (
          <Deadline
            showDeadlines={showDeadlines}
            setShowDeadlines={setShowDeadlines}
          />
        )}
      </div>
    </div>
  );
};

export default DeadlinesBox;
