import React from "react";
import { useNavigate } from "react-router-dom";

const AddtionalInfo = ({ infoData }) => {
  const navigate = useNavigate();
  let day = new Date(infoData?.dob).getDate();
  let month = new Date(infoData?.dob).getMonth();
  let year = new Date(infoData?.dob).getFullYear();
  let format2 = `${day}/${month + 1}/${year}`;
  return (
    <div className="InterestsPreferences p-4 pb-4 position-relative">
      <a
        onClick={() => navigate("/user/edit-additionalinfo")}
        className="btn-edit-pop ms-4 position-absolute top-40 right-40 text-decoration-none cursor-pointer"
      >
        <i className="icon-edit-pencil text-gray-64"></i>
      </a>
      <div className="row">
        <div className="col-12 mb-4">
          <h2 className="font-24 border-bottom pb-3">Additional Information</h2>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Working with school since
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {new Date(infoData?.business_started_date).getMonth() + 1}/
            {new Date(infoData?.business_started_date).getFullYear()}
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Date of Birth
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">{format2 ? format2 : "-"}</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Office Address (Building/ Street Name)
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {infoData?.office_address?.line_1
              ? infoData?.office_address?.line_1
              : "-"}
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Office Address (Locality/ Area)
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {infoData?.office_address?.line_2
              ? infoData?.office_address?.line_2
              : "-"}
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Pin Code/Zip Code
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {infoData?.office_address?.pincode
              ? infoData?.office_address?.pincode
              : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddtionalInfo;
