import apiHeader from "../../apiHeader";
import api from "../../axiosService";

export const getAllStudentForCounseller = async (filters) => {
  const res = await api.post("student/getAllStudentsForCounsler", filters, {
    headers: apiHeader(),
  });
  return res;
};
export const updateStudentPassword = async (payload) => {
  const res = await api.post("student/updateStudentPassword", payload, {
    headers: apiHeader(),
  });
  return res;
};
