import React from "react";
import { useNavigate } from "react-router-dom";

const ProductTable = ({ tableData }) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="tablesection dashboard_blue_table">
        <div className="table">
          <table className="table" style={{ minWidth: "452px" }}>
            <thead>
              <tr>
                <th scope="col" className="text-white">
                  Product
                </th>
                <th scope="col" className="text-start text-white">
                  Consumed
                </th>
                <th scope="col " className="text-start text-white">
                  Assigned
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((x) => {
                return (
                  <>
                    <tr>
                      <th scope="row">
                        <a className="font-20 text-dark-blue">
                          {x.product_name}
                        </a>
                      </th>

                      <td className="text-start">
                        <div className="font-18 font-600">
                          {x.consumed_count} Units
                        </div>
                      </td>
                      <td className="text-start">
                        <div className="font-18 font-600">
                          {x.assigned_count} Units
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="text-center mb-4">
        <a
          onClick={() => navigate("/user/inventory")}
          className="btn-gray-border"
        >
          <span>View All</span>
        </a>
      </div>
    </div>
  );
};

export default ProductTable;
