import React, { useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import {
  filterEvents,
  filterEventsThemes,
  getAllEvents,
} from "../../../services/meeting-events/meeting-events";
import EmptyEvents from "./empty-events";
import TopRankedColleges from "../../../Components/Modals/Student/topranked-colleges";
import FilterCard from "./filter-card";
import MeetingCard from "./meeting-card";
import { useLocation } from "react-router-dom";

const MeetingsEvents = () => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("upcoming");
  const [isMobile, setIsMobile] = useState(false);
  const [filters, setFilters] = useState(false);
  const [filterEventType, setFilterEventType] = useState([]);
  const [themeTopics, setThemeTopics] = useState([]);
  const [eventsData, setEventsData] = useState({
    past: [],
    upcoming: [],
  });
  const activeTab = new URLSearchParams(useLocation().search).get("activeTab");
  const [Data, setData] = useState();
  const [showColleges, setShowColleges] = useState(false);
  const [filterEvent, SetFilterEvent] = useState({
    req_from: "student",
    school_ref_id: "5493",
    login_ref_id: "446350",
    upcoming_past: 1,
    filtername: "get_events_by_type",
  });
  const [eventReq, setEventReq] = useState({
    req_from: "student",
    school_ref_id: "5493",
    login_ref_id: "123456",
    class: "9",
    board_ref_id: "1",
    upcoming_past: "1",
    eve_types: "",
    eve_presenter: "",
    eve_themes: "",
    eve_test: "",
    eve_skill: "",
    eve_country: "",
    eve_university: "",
    eve_career: "",
    eve_department: "",
    start: "0",
    limit: 6,
  });

  useEffect(() => {
    if (activeTab && activeTab === "past") {
      setActiveKey("past");
      SetFilterEvent({
        ...filterEvent,
        upcoming_past: 2,
      });
      setEventReq({
        ...eventReq,
        upcoming_past: 2,
        eve_types: "",
        eve_themes: "",
      });
    }
  }, [activeTab]);
  const allEvents = async () => {
    await getAllEvents(eventReq).then((res) => {
      setLoading(false);
      if (res?.data?.data?.response?.result) {
        setEventsData({
          upcoming:
            eventReq.upcoming_past == "1"
              ? res.data?.data?.response?.result?.upcoming_events
              : "",
          past:
            eventReq.upcoming_past == "2"
              ? res.data?.data?.response?.result?.past_events
              : "",
        });
      }
    });
  };
  const filterListEvents = async () => {
    await filterEvents(filterEvent).then((res) => {
      if (res.data?.data?.response?.result) {
        let temp = res.data?.data?.response?.result.map((f) => {
          f["isChecked"] = false;
          return f;
        });
        setFilterEventType(temp);
      }
    });
  };

  const filterThemes = async () => {
    await filterEventsThemes(filterEvent).then((res) => {
      if (res.data?.data?.response?.result) {
        let temp = res.data?.data?.response?.result.map((f) => {
          f["isChecked"] = false;
          return f;
        });
        setThemeTopics(temp);
      }
    });
  };

  const clearFilter = () => {
    setEventReq({
      req_from: "student",
      school_ref_id: "5493",
      login_ref_id: "123456",
      class: "9",
      board_ref_id: "1",
      upcoming_past: "1",
      eve_types: "",
      eve_presenter: "",
      eve_themes: "",
      eve_test: "",
      eve_skill: "",
      eve_country: "",
      eve_university: "",
      eve_career: "",
      eve_department: "",
      start: "0",
      limit: 6,
    });
  };

  useEffect(() => {
    filterListEvents();
    filterThemes();
  }, [filterEvent]);

  useEffect(() => {
    allEvents();
  }, [eventReq]);

  return (
    <React.Fragment>
      <div className="main sideBarEnable flex-1">
        <div className="container flex-1 d-flex flex-column">
          <div className="flex-1">
            <div className="mt-4 pb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="m-w-90 d-flex justify-content-between align-items-center mt-3 mb-3">
                      <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                        Meeting and Events
                      </h3>
                    </div>
                    <Card style={{ border: "none" }}>
                      <Nav
                        variant="pills"
                        className="job_internship_tab polartabsec"
                      >
                        <Nav.Item eventKey="upcoming">
                          <ul className="nav nav-tabs">
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => {
                                  setActiveKey("upcoming");
                                  SetFilterEvent({
                                    ...filterEvent,
                                    upcoming_past: 1,
                                  });
                                  setEventReq({
                                    ...eventReq,
                                    upcoming_past: 1,
                                    eve_types: "",
                                    eve_themes: "",
                                  });
                                }}
                                className={
                                  activeKey === "upcoming"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="UpcomingEvents-tab"
                              >
                                Upcoming
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={
                                  activeKey === "past"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="past-tab"
                                onClick={() => {
                                  setActiveKey("past");
                                  SetFilterEvent({
                                    ...filterEvent,
                                    upcoming_past: 2,
                                  });
                                  setEventReq({
                                    ...eventReq,
                                    upcoming_past: 2,
                                    eve_types: "",
                                    eve_themes: "",
                                  });
                                }}
                              >
                                Past
                              </button>
                            </li>
                          </ul>
                        </Nav.Item>
                      </Nav>
                    </Card>
                    <div className="row m-0 pt-4 pb-3 JobInternshipListing">
                      <div className="col-lg-12">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="upcoming"
                          activeKey={activeKey}
                          onSelect={(k) => setActiveKey(k)}
                        >
                          <Tab.Content>
                            <Tab.Pane eventKey="upcoming">
                              {/* {!eventsData?.upcoming?.upcoming_events?.length ? (
                                <p>No Records Found.</p>
                                // <EmptyEvents setActiveKey={()=>setActiveKey("past")}/>
                              ) : ( */}
                                {/* <> */}
                                  <div className="row EventsBlk">
                                    <div
                                      className="col-lg-3  col-sm-12 ji_filtersec ps-0"
                                      data-aos="fade-right"
                                      data-aos-delay="1200"
                                    >
                                      <div className="mobileFilter mobileonly text-end">
                                        <button
                                          className="btn-filter btn-unscroll font-18 font-500"
                                          type="button"
                                          onClick={() => setIsMobile(!isMobile)}
                                        >
                                          <span className="icon-filter"></span>
                                          Filters
                                        </button>
                                      </div>

                                      <FilterCard
                                        isMobile={isMobile}
                                        filters={filters}
                                        setFilters={setFilters}
                                        clearFilter={clearFilter}
                                        filterEventType={filterEventType}
                                        eventReq={eventReq}
                                        setFilterEventType={setFilterEventType}
                                        setEventReq={setEventReq}
                                        themeTopics={themeTopics}
                                        setThemeTopics={setThemeTopics}
                                        setIsMobile={setIsMobile}
                                      />
                                    </div>
                                    <div className="col-lg-9  col-sm-12 mt-5">
                                      <div className="row">
                                        {eventsData?.upcoming?.length ?
                                          eventsData?.upcoming?.map(
                                            (val, index) => {
                                              return (
                                                <>
                                                  <div
                                                    className="col-lg-6 album_blk_parent"
                                                    key={index}
                                                  >
                                                    <MeetingCard
                                                      val={val}
                                                      setData={setData}
                                                      setShowColleges={
                                                        setShowColleges
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              );
                                            }
                                          ):<h4> No Records Found.</h4>}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-3"></div>
                                      <div className="col-lg-6">
                                        {loading && (
                                          <button
                                            className="load_more_btn"
                                            style={{
                                              position: "relative",
                                              left: "140px",
                                            }}
                                          >
                                            <img
                                              src="/assets/images/load_more_icon.jpg"
                                              alt="load_more-icon"
                                            />
                                            Load More
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-lg-3"></div>
                                    </div>
                                  </div>
                                {/* </>
                              )} */}
                            </Tab.Pane>

                            <Tab.Pane eventKey="past">
                              {/* {eventsData?.past?.past_events === "" ? (
                                <EmptyEvents />
                              ) : (
                                <> */}
                                  <div className="row EventsBlk">
                                    <div
                                      className="col-lg-3 col-sm-12 ji_filtersec ps-0"
                                      data-aos="fade-right"
                                      data-aos-delay="1200"
                                    >
                                      <div className="mobileFilter mobileonly text-end">
                                        <button
                                          className="btn-filter btn-unscroll font-18 font-500"
                                          type="button"
                                        >
                                          <span className="icon-filter"></span>
                                          Filters
                                        </button>
                                      </div>

                                      <FilterCard
                                        isMobile={isMobile}
                                        filters={filters}
                                        setFilters={setFilters}
                                        clearFilter={clearFilter}
                                        filterEventType={filterEventType}
                                        setFilterEventType={setFilterEventType}
                                        eventReq={eventReq}
                                        setEventReq={setEventReq}
                                        themeTopics={themeTopics}
                                        setThemeTopics={setThemeTopics}
                                        setIsMobile={setIsMobile}
                                      />
                                    </div>
                                    <div className="col-lg-9 col-sm-12 mt-5">
                                      <div className="row">
                                        {eventsData?.past?.length ?
                                          eventsData?.past?.map(
                                            (val, index) => {
                                              return (
                                                <>
                                                  <div
                                                    className="col-lg-6 album_blk_parent"
                                                    key={index}
                                                  >
                                                    <MeetingCard
                                                      val={val}
                                                      setShowColleges={
                                                        setShowColleges
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              );
                                            }
                                          ):<h4> No Records Found.</h4>}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-3"></div>
                                      <div className="col-lg-6">
                                        {loading && (
                                          <button
                                            className="load_more_btn"
                                            style={{
                                              position: "relative",
                                              left: "140px",
                                            }}
                                          >
                                            <img
                                              src="/assets/images/load_more_icon.jpg"
                                              alt="load-more"
                                            />
                                            Load More
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-lg-3"></div>
                                    </div>
                                  </div>
                                {/* </>
                              )} */}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopRankedColleges
        Data={Data}
        showColleges={showColleges}
        setShowColleges={setShowColleges}
      />
    </React.Fragment>
  );
};

export default MeetingsEvents;
