import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById } from "../../../services/products/product-service";
import AddProduct from "../../../Modals/add-product";

const ProductDetails = () => {
  const { id } = useParams();
  const [productDetail, setProductDetail] = useState({});
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  const getProductsById = async () => {
    const res = await getProductById({ id: id });
    if (res?.data?.meta?.status) {
      setProductDetail(res?.data?.data);
    }
  };

  useEffect(() => {
    if (id) {
      getProductsById();
    }
  }, [id]);

  const addtoCart = () => {
    let cart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
    if (cart && cart.length > 0) {
      if (cart.findIndex((x) => x.id === id) > -1) {
        cart[cart.findIndex((x) => x.id === id)].quantity = quantity;
      } else {
        cart.push({
          product: productDetail,
          quantity: quantity,
          total:
            parseInt(productDetail?.price) -
            (productDetail.discount_type === "flat"
              ? productDetail.discount
              : (productDetail.discount / 100) * productDetail.price) *
              quantity,
          tax: productDetail.tax_percentage
            ? ((parseInt(productDetail?.price) -
                (productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price)) *
                productDetail.tax_percentage) /
              100
            : 0,
        });
      }
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      cart = [];
      cart.push({
        product: productDetail,
        quantity: quantity,
        total:
          parseInt(productDetail?.price) -
          (productDetail.discount_type === "flat"
            ? productDetail.discount
            : (productDetail.discount / 100) * productDetail.price) *
            quantity,
        tax: productDetail.tax_percentage
          ? ((parseInt(productDetail?.price) -
              (productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price)) *
              productDetail.tax_percentage) /
            100
          : 0,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    setShowAddProduct(false);
    navigate("/user/product-checkout/" + id);
  };

  return (
    <div className="">
      <div className="product-brife-wrap bg-grd-blue">
        <div className="back-wrap">
          <a onClick={() => navigate("/user/products")}>
            <i></i>
            <span>Back</span>
          </a>
        </div>
        <div className="product-brife ">
          <div className="product-brife-left">
            <h1>{productDetail?.name}</h1>
            <ul className="green-tick">
              {productDetail?.instruction?.map((x, i) => {
                return <li key={i}>{x?.description}</li>;
              })}
            </ul>
            <div className="btn_section_rating">
              <div className="btn_section">
                <a
                  className="video-btn btn manualbtnxl btn-watch-intro"
                  data-bs-toggle="modal"
                  data-src="https://www.youtube.com/embed/6EB1k2GTeDA"
                  data-bs-target="#video_pop"
                >
                  <b>#</b>
                  Watch Intro
                </a>
                <a className="btn-download">
                  <i className="icon-download"></i>
                  Download Brochure
                </a>
              </div>
              <div className="rating_section">
                <div className="rating_review">
                  <ul className="excellent">
                    <li>
                      <a className="star"></a>
                    </li>
                    <li>
                      <a className="star"></a>
                    </li>
                    <li>
                      <a className="star"></a>
                    </li>
                    <li>
                      <a className="star"></a>
                    </li>
                    <li>
                      <a className="star"></a>
                    </li>
                  </ul>
                </div>
                <span className="rating_result">5 star, 6 Reviews</span>
              </div>
            </div>
          </div>
          <div className="product-brife-right ">
            <img src="/assets/images/showcase_1.png" />
            <div className="tab_mobile_links">
              <div className="shareProductDetails">
                <h3>Share Product Details</h3>
                <div className="shareProductDetails-links">
                  <a
                    className="btn-share"
                    data-bs-toggle="modal"
                    data-bs-target="#social_platform"
                  >
                    <i></i>
                    <span>Share Link</span>
                  </a>
                  <a
                    className="btn-lead"
                    data-bs-toggle="modal"
                    data-bs-target="#refer_lead"
                  >
                    <i></i>
                    <span>Refer a Lead</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-detail-wrap pt-5">
        <div className="row g-4">
          <div
            className="col-md"
            id="myTabContent"
            data-aos="fade-left"
            data-aos-delay="1200"
          >
            <h2>Overview</h2>

            <p>{productDetail?.overview}</p>

            <h2>Benefits</h2>
            <ul className="red-tick">
              {productDetail?.benifits?.map((x, i) => {
                return (
                  <li key={i}>
                    <b>{x.title}</b>
                    <span>{x.description}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-md-auto">
            <div className="rightSticky">
              <div className="addToInventorySection">
                <div className="addToInventory-discount">
                  {" "}
                  {productDetail?.discount_type === "flat"
                    ? "Flat ₹" + productDetail?.discount + " Off"
                    : productDetail?.discount + "% Off"}
                </div>
                <div className="addToInventory-price">
                  <div className="webprice">
                    <span>₹</span>
                    {parseInt(productDetail?.price) -
                      (productDetail.discount_type === "flat"
                        ? productDetail.discount
                        : (productDetail.discount / 100) * productDetail.price)}
                  </div>
                  <div className="actualprice">
                    <span>₹</span>
                    {productDetail.price}
                  </div>
                </div>
                <button
                  className="btn-addToInventory"
                  onClick={() => setShowAddProduct(true)}
                >
                  Add to Inventory
                </button>
              </div>

              <div className="shareProductDetails">
                <h3>Share Product Details</h3>
                <div className="shareProductDetails-links">
                  <a
                    className="btn-share"
                    data-bs-toggle="modal"
                    data-bs-target="#social_platform"
                  >
                    <i></i>
                    <span>Share Link</span>
                  </a>
                  <a
                    className="btn-lead"
                    data-bs-toggle="modal"
                    data-bs-target="#refer_lead"
                  >
                    <i></i>
                    <span>Refer a Lead</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddProduct
        setShowAddProduct={setShowAddProduct}
        showAddProduct={showAddProduct}
        product={productDetail}
        quantity={quantity}
        setQuantity={setQuantity}
        addtoCart={addtoCart}
      />
    </div>
  );
};

export default ProductDetails;
