import React from 'react'
import CareerTrands from '../../../Components/Career-trends/career-trends'
import CareerDeepDive from './careerDeepDive';
import CareerIntrestMatch from './careerIntrestMatch';

const CareerList = () => {
  const powerd = `${window.location.origin}/images/dark-logo.svg`;
  
  return (
    <div className="main sideBarEnable flex-1">
      <div className="max-1140 mx-auto d-flex h-100">
    
        <div className="flex-1 d-flex flex-column">
          <div className="pageContent flex-1">
            <div className="mt-4 pb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                      <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                        Choose Your Career
                      </h3>
                      <p className="font-16 font-400 mb-2">
                        Use the college finder tool to find the best colleges!
                        Choose from the following options.
                      </p>
                    </div>
                    <div className="job_internship_tab polartabsec">
                      <div className="xscroll p-0">
                        <ul className="nav nav-tabs aos-init aos-animate" id="JobInternshipTab" role="tablist" data-aos="fade-left" data-aos-delay="900">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="careerDeepDive-tab" data-bs-toggle="tab" data-bs-target="#careerDeepDive" type="button" role="tab" aria-controls="careerDeepDive" aria-selected="true">
                              Career Deep Dive
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="careerInterestMatch-tab" data-bs-toggle="tab" data-bs-target="#careerInterestMatch" type="button" role="tab" aria-controls="careerInterestMatch" aria-selected="false">
                              Career Interest Match
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                 
                 
                    <div className="tab-content aos-init aos-animate" id="myTabContent" data-aos="fade-up" data-aos-delay="600">
                      <CareerDeepDive />

                      <CareerIntrestMatch />
                      
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>

 
          <div className="footer">
            <div className="copyRight">
              International Educational Gateway Pvt. Ltd. © 2024. All Rights
              Reserved. Univariety
            </div>
            <div className="powerby">
              <span>Powered By</span>
              <img src="https://www.univariety.xyz/prototype/super-cousellor/images/dark-logo.svg" alt=""/>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default CareerList