import React, { useEffect, useRef, useState } from "react";
import DeadlinesBox from "../../../Components/TestDeadlineComponents/deadlines-box";
import {
  getAllParentCourse,
  getColleges,
  getCountries,
} from "../../../services/career/career-service";
import { Accordion } from "react-bootstrap";
import { getExamsList } from "../../../services/user-exam/exams";
import ReactPaginate from "react-paginate";
import { IconContext } from "react-icons";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

const TestDeadlines = () => {
  const [departmentData, setDepartmentData] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [fromDate, setFromData] = useState(new Date());
  const [toDate, setToData] = useState(new Date());
  const [loading, setLoading] = useState();
  const [examsData, setExamsData] = useState([]);
  const [searchCountryQuery, setSearchCountryQuery] = useState("");
  const [searchDepartmentQuery, setSearchDepartmentQuery] = useState("");
  const [searchCollegeQuery, setSearchCollegeQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [page, setPage] = useState(0);
  const timerRef = useRef();
  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 10,
    country_id: "",
    dept_id: "",
    college_id: "",
    deadline_from: "",
    deadline_to: "",
    search: "",
  });
  const getAllExamsData = async () => {
    setLoading(true);
    getExamsList(filterData).then((res) => {
      setLoading(false);
      if (res && res.data?.data) {
        setExamsData(res.data?.data);
        let getData = res.data?.data?.data?.filter((item, index) => {
          return (
            (index >= page * filterData?.limit) &
            (index < (page + 1) * filterData?.limit)
          );
        });
        setFilteredData(getData);
      }
    });
  };
  const getAllCountries = async () => {
    await getCountries({ search: searchCountryQuery }).then((res) => {
      if (res.data?.data?.records) {
        let getCountry = res.data?.data?.records?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setCountriesData(getCountry);
      }
    });
  };
  const getAllCourse = async () => {
    await getAllParentCourse({ search: searchDepartmentQuery }).then((res) => {
      if (res.data?.data) {
        let getCource = res.data?.data?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setDepartmentData(getCource);
      }
    });
  };
  const GetAllCollegeList = async () => {
    getColleges({ search: searchCollegeQuery }).then((res) => {
      if (res && res?.data?.data) {
        let getColleges = res.data?.data?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setColleges(getColleges);
      }
    });
  };
  useEffect(() => {
    getAllCountries();
    getAllCourse();
    GetAllCollegeList();
  }, []);
  useEffect(() => {
    getAllExamsData();
  }, [filterData, page]);
  useEffect(() => {
    if (searchCountryQuery.trim()) {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        getAllCountries();
      }, 500);
    } else {
      getAllCountries();
    }
  }, [searchCountryQuery]);
  useEffect(() => {
    GetAllCollegeList();
  }, [searchCollegeQuery]);
  useEffect(() => {
    getAllCourse();
  }, [searchDepartmentQuery]);

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="mt-4 pb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                  <h3 className="font-35 font-600 mb-2 block-title aos-init aos-animate">
                    Entrance Exam
                  </h3>
                  <p className="font-16 font-400 mb-2">
                    Keep track of the tests you need to take and make your plans
                    accordingly.
                  </p>
                  <p className="font-16 font-400 mb-2">
                    Different countries like US, UK, Canada, India and so on
                    have specific entrance test requirements for admission to
                    various departments. Finalize your application by finding
                    out which admission tests you need to prepare for
                  </p>
                </div>

                <div className="row m-0 pb-5 JobInternshipListing">
                  <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate">
                    <div className="mobileFilter mobileonly text-end">
                      <button
                        className="btn-filter btn-unscroll font-18 font-500"
                        type="button"
                      >
                        <span className="icon-filter"></span>
                        Filters
                      </button>
                    </div>
                    <div
                      className="filters leftPaneFilter collapse show"
                      id="filter_collapse"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <button
                            className="btn-filter btn-unscroll mobileonly"
                            type="button"
                          >
                            Filters
                          </button>
                          <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
                            <span className="icon-filter"></span>
                            Filters
                          </h3>
                        </div>
                        <span
                          className="font-14 font-500 cursor-pointer text-burgandi text-decoration-underline"
                          onClick={() => {
                            let departmentFilter = Object.assign(
                              [],
                              departmentData
                            );
                            departmentFilter = departmentFilter.map((t) => {
                              t["isChecked"] = false;
                              return t;
                            });
                            setDepartmentData(departmentFilter);
                            let collegeFilter = Object.assign([], colleges);
                            collegeFilter = collegeFilter?.map((x) => {
                              x["isChecked"] = false;
                              return x;
                            });
                            setColleges(collegeFilter);
                            setCountriesData(pre => pre.map(v=>({...v,isChecked:false})))
                            setFilterData({
                              offset: 0,
                              limit: 10,
                              country_id: "",
                              dept_id: "",
                              college_id: "",
                              deadline_from: "",
                              deadline_to: "",
                            });
                            setFromData("");
                            setToData("");
                          }}
                        >
                          Clear
                        </span>
                      </div>

                      <div className="college-filter-box">
                        <Accordion
                          alwaysOpen
                          defaultActiveKey={["1"]}
                          className="filterGroup"
                        >
                          <Accordion.Item
                            eventKey="0"
                            className="fiterItem mb-3"
                          >
                            <Accordion.Header className="filterHeader p-0">
                              <div
                                className="d-flex justify-content-between align-items-center position-relative"
                                style={{ width: "100%" }}
                              >
                                <button
                                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                                  type="button"
                                >
                                  <span className="font-18 font-600">
                                    Country
                                  </span>
                                  <span className="toggle"></span>
                                </button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="p-0"
                              id="collapseDepartment"
                            >
                              <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                  <input
                                    className="form-control flex-1 font-14 font-500 w-100"
                                    type="search"
                                    placeholder="Search"
                                    value={searchCountryQuery}
                                    onChange={(e) => {
                                      setSearchCountryQuery(e.target.value)


                                    }
                                    }
                                  />
                                  <button
                                    className="btn icon-search"
                                    type="submit"
                                  ></button>
                                </form>
                              </div>
                              <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                  <div className="scrollMe">
                                    {countriesData?.map((data, i) => {
                                      return (
                                        <div
                                          className="form-check d-flex flex-wrap"
                                          key={i}
                                        >
                                          <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="country"
                                            id={"Department_Architecture" + i}
                                            value={data.isChecked}
                                            checked={data.isChecked}
                                            onChange={(e) => {
                                              let temp = Object.assign(
                                                [],
                                                countriesData
                                              );
                                              temp = temp.map((t) => {
                                                t["isChecked"] = false;
                                                return t;
                                              });
                                              temp[i].isChecked =
                                                e.target.checked;
                                              setCountriesData(temp);
                                              setFilterData({
                                                ...filterData,
                                                country_id: data.id,
                                              });
                                            }}
                                          />
                                          <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                                            {data?.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="fiterItem mb-3"
                          >
                            <Accordion.Header className="filterHeader p-0">
                              <div
                                className="d-flex justify-content-between align-items-center position-relative"
                                style={{ width: "100%" }}
                              >
                                <button
                                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                                  type="button"
                                >
                                  <span className="font-18 font-600">
                                    Department
                                  </span>
                                  <span className="toggle"></span>
                                </button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="p-0"
                              id="collapseDepartment"
                            >
                              <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                  <input
                                    className="form-control flex-1 font-14 font-500 w-100"
                                    type="search"
                                    placeholder="Search"
                                    value={searchDepartmentQuery}
                                    onChange={(e) =>
                                      setSearchDepartmentQuery(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn icon-search"
                                    type="submit"
                                  ></button>
                                </form>
                              </div>
                              <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                  <div className="scrollMe">
                                    {departmentData?.map((data, i) => {
                                      return (
                                        <div
                                          className="form-check d-flex flex-wrap"
                                          key={i}
                                        >
                                          <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="course"
                                            key={i}
                                            id={"Department_Architecture" + i}
                                            value={data.isChecked}
                                            checked={data.isChecked}
                                            onChange={(e) => {
                                              let temp = Object.assign(
                                                [],
                                                departmentData
                                              );
                                              temp = temp.map((t) => {
                                                t["isChecked"] = false;
                                                return t;
                                              });
                                              temp[i].isChecked =
                                                e.target.checked;
                                              setDepartmentData(temp);
                                              setFilterData({
                                                ...filterData,
                                                dept_id: data.id,
                                              });
                                            }}
                                          />
                                          <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                                            {data?.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            className="fiterItem mb-3"
                          >
                            <Accordion.Header className="filterHeader p-0">
                              <div
                                className="d-flex justify-content-between align-items-center position-relative"
                                style={{ width: "100%" }}
                              >
                                <button
                                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                                  type="button"
                                >
                                  <span className="font-18 font-600">
                                    College
                                  </span>
                                  <span className="toggle"></span>
                                </button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="p-0"
                              id="collapseDepartment"
                            >
                              <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                  <input
                                    className="form-control flex-1 font-14 font-500 w-100"
                                    type="search"
                                    placeholder="Search"
                                    value={searchCollegeQuery}
                                    onChange={(e) =>
                                      setSearchCollegeQuery(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn icon-search"
                                    type="submit"
                                  ></button>
                                </form>
                              </div>
                              <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                  <div className="scrollMe">
                                    {colleges?.map((data, i) => {
                                      return (
                                        <div
                                          className="form-check d-flex flex-wrap"
                                          key={i}
                                        >
                                          <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="college"
                                            key={i}
                                            id={"Department_Architecture" + i}
                                            value={data.isChecked}
                                            checked={data.isChecked}
                                            onChange={(e) => {
                                              let temp = Object.assign(
                                                [],
                                                colleges
                                              );
                                              temp = temp.map((t) => {
                                                t["isChecked"] = false;
                                                return t;
                                              });
                                              temp[i].isChecked =
                                                e.target.checked;
                                              setColleges(temp);
                                              setFilterData({
                                                ...filterData,
                                                college_id: data.id,
                                              });
                                            }}
                                          />
                                          <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                                            {data?.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="3"
                            className="fiterItem mb-3"
                          >
                            <Accordion.Header className="filterHeader p-0">
                              <div
                                className="d-flex justify-content-between align-items-center position-relative"
                                style={{ width: "100%" }}
                              >
                                <button
                                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                                  type="button"
                                >
                                  <span className="font-18 font-600">
                                    Deadline
                                  </span>
                                  <span className="toggle"></span>
                                </button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="p-0"
                              id="collapseDepartment"
                            >
                              <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative"></form>
                              </div>
                              <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                  <label className="form-check-label font-13 font-500 flex-1 pt-1">
                                    From Date
                                  </label>
                                  <div className="d-flex flex-wrap border-bottom align-items-center mb-2 position-relative">
                                    <input
                                      className="form-control flex-1 border-0"
                                      type="date"
                                      name="fromdate"
                                      style={{ transition: "unset" }}
                                      value={fromDate}
                                      onChange={(e) => {
                                        setFromData(e.target.value);
                                        setFilterData({
                                          ...filterData,
                                          deadline_from: fromDate,
                                        });
                                      }}
                                    />
                                  </div>
                                  <label className="form-check-label font-13 font-500 flex-1 pt-1">
                                    To Date
                                  </label>
                                  <div className="d-flex flex-wrap border-bottom align-items-center mb-2 position-relative">
                                    <input
                                      className="form-control flex-1 border-0"
                                      type="date"
                                      name="todate"
                                      value={toDate}
                                      onChange={(e) => {
                                        setToData(e.target.value);
                                        setFilterData({
                                          ...filterData,
                                          deadline_to: toDate,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 polarcontsec tab-content">
                    <div className="ActiveListings_present">
                      <div className="d-flex justify-content-between flex-wrap align-items-center my-2 w-100">
                        <div className="d-flex flex-wrap align-items-center">
                          <h3 className="font-18 font-500 mb-0">
                            {examsData?.total_count ? (
                              <> {examsData?.total_count} Exams found</>
                            ) : null}
                          </h3>
                        </div>
                        <div className="filterSearchblk p-0 w-50">
                          <form className="d-flex align-items-center filterSearch position-relative">
                            <input
                              className="form-control flex-1 font-14 font-500 w-100 bg-white border mt-1"
                              type="search"
                              placeholder="Search"
                              value={filterData.search}
                              onChange={(e) =>
                                setFilterData({
                                  ...filterData,
                                  search: e.target.value,
                                })
                              }
                            />
                            <button
                              className="btn icon-search"
                              style={{ top: "11px", right: "19px" }}
                              type="submit"
                            ></button>
                          </form>
                        </div>
                      </div>
                      <div className="row g-4 college-details deadlines-list">
                        {filteredData?.map((x, i) => {
                          return (
                            <>
                              <div
                                className="col-lg-6 col-md-6 col-sm-12"
                                key={i}
                              >
                                <div className="">
                                  <DeadlinesBox data={x} />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {loading && (
                        <a className="load_more_btn">
                          <img src="/assets/images/load_more_icon.jpg" />
                          Load More
                        </a>
                      )}
                      <div className="pagination-div mt-3">
                        <ReactPaginate
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageClassName={"page-item"}
                          onPageChange={(event) => {
                            setFilterData({
                              ...filterData,
                              offset: parseInt(event.selected) * 10,
                            });
                          }}
                          breakLabel="..."
                          pageCount={Math.ceil(examsData?.total_pages)}
                          previousLabel={
                            <IconContext.Provider
                              value={{ color: "#B8C1CC", size: "36px" }}
                            >
                              <AiFillLeftCircle />
                            </IconContext.Provider>
                          }
                          nextLabel={
                            <IconContext.Provider
                              value={{ color: "#B8C1CC", size: "36px" }}
                            >
                              <AiFillRightCircle />
                            </IconContext.Provider>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TestDeadlines;
