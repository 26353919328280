import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    // "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  function (response) {
    if (response.data?.meta.code && response.data?.meta.code === 401) {
      console.log(response);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("companyDetails");
      if (
        window.location.pathname != "/" ||
        window.location.pathname != "/user-login" ||
        window.location.pathname != "/student-login"
      ) {
        window.location = "/";
      }
    } else {
      if (!response.data.meta.status) {
        toast(response.data.meta.message);
        return response;
      } else {
        return response;
      }
    }
  },
  function (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      console.log(error);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("companyDetails");
      if (
        window.location.pathname != "/" ||
        window.location.pathname != "/user-login" ||
        window.location.pathname != "/student-login"
      ) {
        window.location = "/";
      }
    }
    if (!toast.isActive("error")) {
      toast(error.message, {
        toastId: "error",
      });
    }
  }
);

export default api;
