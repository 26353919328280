import React, { useState } from 'react';  // Correctly import useState
import CareerTrands from '../../../Components/Career-trends/career-trends';
import CareerFilter from './careerFilter';
import CareerDeepList from './careerDeepList';
import { useNavigate } from 'react-router-dom';

const CareerDeepDive = () => {
  // State to store the selected filter values
  const [selectedFilters, setSelectedFilters] = useState({
    departments: [],
    likes: [],
    abilities: [],
  });

  // Function to handle changes in the filters
  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  const navigate = useNavigate();

  const handleClearFilters = () => {
    const currentUrl = window.location.href;
      let pathPrefix = "";
  
      if (currentUrl.includes("user/")) {
        pathPrefix = "user";
      } else if (currentUrl.includes("student")) {
        pathPrefix = "student";
      }
  
      // Redirect to the appropriate URL
      window.location.href = `/${pathPrefix}/career-list`;
  };

  return (
    <div className="tab-pane fade show active" id="careerDeepDive" role="tabpanel" aria-labelledby="careerDeepDive-tab">
      <div className="row m-0 pt-4 pb-5 JobInternshipListing">
        <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate" data-aos="fade-right" data-aos-delay="1200">
          <div className="mobileFilter mobileonly text-end">
            <button
              className="btn-filter btn-unscroll font-18 font-500"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filter_collapse"
              aria-expanded="true"
              aria-controls="filter_collapse"
            >
              <span className="icon-filter"></span> Filters
            </button>
          </div>
          <div className="filters leftPaneFilter collapse show" id="filter_collapse">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-filter btn-unscroll mobileonly"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#filter_collapse"
                  aria-expanded="false"
                  aria-controls="filter_collapse"
                >
                  Filters
                </button>
                <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate" data-aos="fade-left" data-aos-delay="1200">
                  <span className="icon-filter"></span> Filters
                </h3>
              </div>
              <button
                onClick={handleClearFilters}
                className="font-14 font-500 text-burgandi text-decoration-underline btn-clear-filters"
                aria-label="Clear all filters"
                style={{ border: 'none', background: 'none', padding: 0, margin: 0, cursor: 'pointer' }}
              >
                Clear
              </button>

            </div>

            <CareerFilter onFilterChange={handleFilterChange} />
            
          </div>
        </div>

        {/* Main Content */}
        <CareerDeepList filters={selectedFilters} />
        
      </div>
    </div>
  );
};

export default CareerDeepDive;
