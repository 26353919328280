import React, { useState } from "react";
import ScoreModal from "../../../Components/Modals/Student/score-modal";

const CheckScore = () => {
  const [isScoreShow, setIsScoreShow] = useState(false);
  return (
    <>
      <div className="main flex-1">
        <div className="max-1140 mx-auto d-flex h-100">
          <div className="w-100 d-flex flex-column">
            <div className="flex-1">
              <div className="row overflow-hidden justify-content-center border-box-color border-radius-10">
                <div className="col-md-12">
                  <div className="relevance_score_section">
                    <div className="row overflow-hidden justify-content-center border-box-color border-radius-10">
                      <div className="col-md-12">
                        <div className="max-980 mx-auto margin-top-40">
                          <div className="d-flex align-items-center justify-content-center flex-column text-center pb-4">
                            <p className="font-26 font-600 lineHeight-30 mb-2 pb-1">
                              Is Your Current Profile Good Enough to Get
                              <br />
                              Admission in Your Dream College ?
                            </p>
                            <span className="btn-find">Check your Score</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 bg-lighter-blue">
                        <div className="max-920 mx-auto d-flex flex-wrap py-3">
                          <div className="Activity-type flex-1">
                            <div className="Activity-type-title fst-italic font-16 font-500 text-blue-54">
                              Activity type
                            </div>
                          </div>
                          <div className="achievement-level">
                            <div className="achievement-level-title fst-italic font-16 font-500 text-blue-54 text-end">
                              Choose your achievement level
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="max-920 mx-auto">
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                AP Courses
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6"></div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="AP-Courses"
                                      id="Completed"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Completed"
                                    >
                                      <span>Completed</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                Olympiads
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Olympiads"
                                      id="Olympiads-Participant"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Olympiads-Participant"
                                    >
                                      <span>Participant</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Olympiads"
                                      id="Olympiads-winner"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Olympiads-winner"
                                    >
                                      <span>Winner</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                Pre College / Foundation Courses
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6"></div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Foundation-Courses"
                                      id="Foundation-Courses-Completed"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Foundation-Courses-Completed"
                                    >
                                      <span>Completed</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                Online Courses (eg.. Python, Computer Science,
                                Finance, Biology)
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Online-Courses"
                                      id="Online-Courses-Introductory"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Online-Courses-Introductory"
                                    >
                                      <span>Introductory</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Online-Courses"
                                      id="Online-Courses-Intermediate"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Online-Courses-Intermediate"
                                    >
                                      <span>Intermediate</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                Competitions (STEM, Science Fair, KVPY etc)
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Competitions"
                                      id="Competitions-Participant"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Competitions-Participant"
                                    >
                                      <span>Participant</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Competitions"
                                      id="Competitions-Winner"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="Competitions-Winner"
                                    >
                                      <span>Winner</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ActivityachievementRow">
                            <div className="Activity-type">
                              <div className="Activity-type-title">
                                Model United Nations (MUN)
                              </div>
                            </div>
                            <div className="achievement-level">
                              <div className="row g-3">
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Model-United-Nations"
                                      id="mun-Participant"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="mun-Participant"
                                    >
                                      <span>Participant</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group check-block flex-1">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      name="Model-United-Nations"
                                      id="mun-Winner"
                                    />
                                    <label
                                      className="check-block-label max-w-100per"
                                      htmlFor="mun-Winner"
                                    >
                                      <span>Winner</span>
                                      <i className="icon-checked"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn-green px-5 height-45 margin-bottom-40 margin-top-40 mx-auto"
                            data-bs-toggle="modal"
                            data-bs-target="#congratulations_3"
                            onClick={() => setIsScoreShow(true)}
                          >
                            <span>Calculate my Promap Score</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScoreModal isScoreShow={isScoreShow} setIsScoreShow={setIsScoreShow} />
    </>
  );
};

export default CheckScore;
