import React, { useState } from "react";

const CareerFilters = () => {


  return (
    <div className="filters leftPaneFilter">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <button className="btn-filter btn-unscroll mobileonly" type="button">
            Filters
          </button>
          <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
            <span className="icon-filter"></span>
            Filters
          </h3>
        </div>
        <a className="font-14 font-500 text-burgandi text-decoration-underline">
          Clear
        </a>
      </div>

      <div className="filterGroup" id="accordionFilters">
        <div className="fiterItem mb-3">
          <div className="filterHeader">
            <div className="d-flex justify-content-between align-items-center position-relative">
              <button
                className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                type="button"
              >
                <span className="font-18 font-600">Department</span>
                <span className="toggle"></span>
              </button>
            </div>
          </div>
          <div className="">
            <div className="card card-body mt-2">
              <div className="overflow-hidden">
                <div className="scrollMe">
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Art &amp; Design
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Architecture
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Science
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Business Management
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Computer Science &amp; Information Technology
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Education &amp; Teaching
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Engineering &amp; Technology
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Healthcare
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Hospitality, Tourism &amp; Events
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Humanities
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Journalism, Media, PR &amp; Communication
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label
                      className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                      for="ArtDesign"
                    >
                      Law
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Medicine
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Bio Sciences &amp; Biotechnology
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Computer Graphics &amp; Animation
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Economics &amp; Commerce (B Com/H)
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Fashion
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Linguistics (English Lit. &amp; Hons)
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Mathematics &amp; Statistics
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Music &amp; Dance
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Psychology
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Sports
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fiterItem mb-3">
          <div className="filterHeader">
            <div className="d-flex justify-content-between align-items-center position-relative">
              <button
                className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                type="button"
              >
                <span className="font-18 font-600">What all do you like?</span>
                <span className="toggle"></span>
              </button>
            </div>
          </div>
          <div className="">
            <div className="filterSearchblk mb-3">
              <form className="d-flex align-items-center filterSearch position-relative">
                <input
                  className="form-control flex-1 font-14 font-500 w-100"
                  type="search"
                />
                <button className="btn icon-search" type="submit"></button>
              </form>
            </div>
            <div className="card card-body">
              <div className="overflow-hidden">
                <div className="scrollMe">
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Being Creative &amp; Innovative
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Communicating With People
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Designing And Styling
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Helping &amp; Guiding People
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Media, Glamour And Entertainment
                    </label>
                  </div>

                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Being Creative &amp; Innovative
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Communicating With People
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Designing And Styling
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      UniversityofNewSouth Wales
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      The University of Newcastle
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fiterItem mb-3">
          <div className="filterHeader">
            <div className="d-flex justify-content-between align-items-center position-relative">
              <button
                className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                type="button"
              >
                <span className="font-18 font-600">
                  What you are naturally good at?
                </span>
                <span className="toggle"></span>
              </button>
            </div>
          </div>
          <div className="">
            <div className="filterSearchblk mb-3">
              <form className="d-flex align-items-center filterSearch position-relative">
                <input
                  className="form-control flex-1 font-14 font-500 w-100"
                  type="search"
                />
                <button className="btn icon-search" type="submit"></button>
              </form>
            </div>
            <div className="card card-body">
              <div className="overflow-hidden">
                <div className="scrollMe">
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Communicating With People
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Designing And Styling
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Media, Glamour And Entertainment
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Nature And Outdoors
                    </label>
                  </div>
                  <div className="form-check d-flex flex-wrap">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                      Physical Activity
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFilters;
