import React from "react";
import { Modal } from "react-bootstrap";

const AddProduct = ({
  showAddProduct,
  setShowAddProduct,
  product,
  quantity,
  setQuantity,
  addtoCart,
}) => {
  return (
    <Modal
      show={showAddProduct}
      centered
      onHide={() => setShowAddProduct(false)}
    >
      <div
        role="document"
        className=" modal-dialog-centered radius-6  add_units"
      >
        <div className="modal-content overflow-hidden">
          <div className="modal-header border-0">
            <h2>
              Curriculum Evaluator
              <i className="icon-right-arrow"></i>
              <span>Add Units</span>
            </h2>
            <button
              type="button"
              className="btn-close m-2 mt-2 me-1 p-0 position-absolute right20 top20 z9"
              onClick={() => setShowAddProduct(false)}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0 text-capitalize">
            <div className="add_unit_section">
              <p>Units to be added</p>
              <div className="add_qty">
                <a
                  className="add_count"
                  onClick={() =>
                    setQuantity(quantity != 0 ? quantity - 1 : quantity)
                  }
                ></a>
                <input className="quantity_number" value={quantity} readOnly />
                <a
                  className="del_count"
                  onClick={() => setQuantity(quantity + 1)}
                ></a>
              </div>
            </div>
            <div className="add_units_values">
              <div className="add_units_values_row">
                <div className="label">Total Price:</div>
                <div className="labelprice">
                  <b>₹</b>
                  <span>{(product.price * quantity).toFixed(2)}</span>
                </div>
              </div>
              <div className="add_units_values_row">
                <div className="label">Offer Price:</div>
                <div className="labelprice">
                  <b>₹</b>
                  <span>
                    {(
                      (parseInt(product?.price) -
                        (product.discount_type === "flat"
                          ? product.discount
                          : (product.discount / 100) * product.price)) *
                      quantity
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="add_units_values_row">
                <div className="label">Taxes:</div>
                <div className="labelprice">
                  <b>₹</b>
                  <span>
                    {product.tax_percentage
                      ? (
                          (((parseInt(product?.price) -
                            (product.discount_type === "flat"
                              ? product.discount
                              : (product.discount / 100) * product.price)) *
                            product.tax_percentage) /
                            100) *
                          quantity
                        ).toFixed(2)
                      : 0}
                  </span>
                </div>
              </div>
              <div className="add_units_values_row">
                <div className="label">Final Price:</div>
                <div className="labelprice">
                  <b>₹</b>
                  <span>
                    {(
                      (parseInt(product?.price) -
                        parseInt(
                          product.discount_type === "flat"
                            ? product.discount
                            : (product.discount / 100) * product.price
                        ) +
                        (product.tax_percentage
                          ? ((parseInt(product?.price) -
                              (product.discount_type === "flat"
                                ? product.discount
                                : (product.discount / 100) * product.price)) *
                              product.tax_percentage) /
                            100
                          : 0)) *
                      quantity
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
              <button className="btn-addToCart" onClick={() => addtoCart()}>
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddProduct;
