import apiHeader from "../apiHeader";
import api from "../axiosService";

export const getStudentActivities = async () => {
  const res = await api.post(
    "student/getStudentActivities",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getStudentProducts = async () => {
  const res = await api.post(
    "student/getAllStudentsProducts",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getProductToken = async (params) => {
  const res = await api.get(
    "products/getStudentToken",

    {
      params: params,
      headers: apiHeader(),
    }
  );
  return res;
};

export const getStudentReports = async (payload) => {
  const res = await api.post(
    "reports/getStudentsPreferredCountries",
    payload,

    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getStudentCourseReports = async (payload) => {
  const res = await api.post(
    "reports/getStudentsPreferredCourses",
    payload,

    {
      headers: apiHeader(),
    }
  );
  return res;
};
