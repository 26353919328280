import React, { useEffect, useState } from "react";
import EditBasicInfo from "./edit-basicInfo";
import { useParams } from "react-router";
import { getStudentDetails } from "../../../services/student/student-profile";

const BasicInformation = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const [details, setDetails] = useState();

  const StudentProfileDetail = async () => {
    await getStudentDetails(id && { student_id: id }).then((res) => {
      if (res && res.data?.data) {
        setDetails(res.data?.data);
      }
    });
  };

  useEffect(() => {
    StudentProfileDetail();
  }, []);
  return (
    <div id="basicinformation-content" className="AboutBox">
      <p
        style={{ float: "right", cursor: "pointer" }}
        onClick={() => setIsEdit(!isEdit)}
        className="icon-edit-pencil btn-gray-edit"
      ></p>
      {isEdit ? (
        <EditBasicInfo
          details={details}
          setIsEdit={setIsEdit}
          refreshData={StudentProfileDetail}
        />
      ) : (
        <div className="accordion-body">
          <div className="row g-0">
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>First Name</label>
                <span className="name">{details?.fname || "-"} </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Last Name</label>
                <span className="name">{details?.lname || "-"}</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Gender</label>
                <span className="name">{details?.gender || "-"}</span>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-4 col-md-6 col-sm-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Contact Mobile</label>
                <span className="name">{details?.phone || "-"}</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Contact Email</label>
                <span className="name">{details?.email || "-"}</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Current Location</label>
                <span className="name">{details?.city_name || "-"}</span>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-4 col-md-6 col-sm-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Address</label>
                <span className="name pe-3">
                  {details?.address_line_1 || "-"} , {details?.address_line_2}
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Citizenship</label>
                <span className="name">
                  {details?.secondary_citizenship_name || "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Class &amp; Board</label>
                <span className="name">
                  {details?.standard_name}
                  {" & "}
                  {details?.board_name ? details?.board_name : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicInformation;
