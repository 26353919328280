import React from "react";

const FilterCard = ({
  isMobile,
  filters,
  setFilters,
  filterEventType,
  setThemeTopics,
  setEventReq,
  eventReq,
  setFilterEventType,
  themeTopics,
  setIsMobile,
  clearFilter,
}) => {
  return (
    <div>
      {" "}
      <div className="filters leftPaneFilter">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn-filter btn-unscroll mobileonly"
              type="button"
            >
              Filters
            </button>
            <h3
              className="font-18 font-500 mb-2 mt-2"
              data-aos="fade-left"
              data-aos-delay="1200"
            >
              <span className="icon-filter"></span>
              Filters
            </h3>
          </div>
          <span
            onClick={() => {
              let eventTypeFilter = Object.assign([], filterEventType);
              eventTypeFilter = eventTypeFilter?.map((x) => {
                x["isChecked"] = false;
                return x;
              });
              setFilterEventType(eventTypeFilter);
              let themeFilter = Object.assign([], themeTopics);
              themeFilter = themeFilter?.map((x) => {
                x["isChecked"] = false;
                return x;
              });
              setThemeTopics(themeFilter);
              setEventReq({
                ...eventReq,
                eve_types: "",
                eve_themes: "",
              });
            }}
            className="font-14 font-500 cursor-pointer text-burgandi text-decoration-underline"
          >
            Clear
          </span>
        </div>

        <div className="filterGroup">
          <div className="fiterItem mb-3">
            <div className="filterHeader">
              <div className="d-flex justify-content-between align-items-center position-relative">
                <button
                  className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                  type="button"
                >
                  <span className="font-18 font-600">Event Type</span>
                  <span className="toggle"></span>
                </button>
              </div>
            </div>
            <div className="collapse show mt-2" id="collapseEventType">
              <div className="card card-body">
                <div className="overflow-hidden">
                  <div className="scrollMe">
                    {filterEventType?.map((x, index) => {
                      return (
                        <>
                          <div className="form-check d-flex flex-wrap">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              name="type_name"
                              id={"event_type_name" + index}
                              key={index}
                              checked={x.isChecked}
                              onChange={(e) => {
                                let temp = Object.assign([], filterEventType);
                                temp = temp.map((t) => {
                                  t["isChecked"] = false;
                                  return t;
                                });
                                temp[index].isChecked = e.target.checked;
                                setFilterEventType(temp);
                                setEventReq({
                                  ...eventReq,
                                  eve_types: x.event_type_id,
                                });
                              }}
                            />
                            <label
                              className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                              htmlFor="1to1Session"
                            >
                              {x.event_type_name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fiterItem mb-3">
            <div className="filterHeader">
              <div className="d-flex justify-content-between align-items-center position-relative">
                <button
                  className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                  type="button"
                >
                  <span className="font-18 font-600">Theme/Topic</span>
                  <span className="toggle"></span>
                </button>
              </div>
            </div>
            <div className="collapse show mt-2">
              <div className="card card-body">
                <div className="overflow-hidden">
                  <div className="scrollMe">
                    {themeTopics.map((x, index) => {
                      return (
                        <>
                          <div className="form-check d-flex flex-wrap">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              name="theme_name"
                              id={"evant_theme_name" + index}
                              key={index}
                              checked={x.isChecked}
                              onChange={(e) => {
                                let temp = Object.assign([], themeTopics);
                                temp = temp.map((t) => {
                                  t["isChecked"] = false;
                                  return t;
                                });
                                temp[index].isChecked = e.target.checked;
                                setThemeTopics(temp);
                                setEventReq({
                                  ...eventReq,
                                  eve_themes: x.event_theme_id,
                                });
                              }}
                            />
                            <label
                              className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                              htmlFor="CareerGuidance"
                            >
                              {x.event_theme}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterCard;
