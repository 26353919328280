import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../services/axiosService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function loadUserFromStorage() {
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      if (token) {
        console.log("Got a token in the cookies, let's see if it is valid");
        api.defaults.headers.Authorization = `${token}`;
        if (user) setUser(user);
      }
      setLoading(false);
    }
    loadUserFromStorage();
  }, []);

  const login = async (payload) => {
    const res = await api.post("student/studentLogin", payload);
    console.log(res);
    if (res && res.data.meta.status && res.data.token) {
      console.log("Got token");
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.data));
      api.defaults.headers.Authorization = `${res.data.token}`;
      setUser(res.data.data);
      console.log("Got user", user);
      return res;
    }
  };

  const userForgotPassword = async (payload) => {
    const res = await api.post("user/sendForgotPasswordLink", payload);
    console.log(res);
    if (res && res.data.meta.status) {
      return res;
    } else {
      return undefined;
    }
  };

  const userResetPassword = async (payload) => {
    const res = await api.post("user/resetPassword", payload);
    console.log(res);
    if (res && res.data.meta.status) {
      return res;
    }
  };
  const userLogin = async (payload) => {
    const res = await api.post("user/userLogin", payload);
    console.log(res);
    if (res && res.data.meta.status && res.data.token) {
      console.log("Got token");
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.data));
      api.defaults.headers.Authorization = `${res.data.token}`;
      setUser(res.data.data);
      console.log("Got user", user);
      return res;
    }
  };

  const register = async (payload) => {
    const res = await api.post("student/studentRegister", payload);
    console.log(res);
    if (res && res.data.meta.status) {
      console.log(res);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.data));
      api.defaults.headers.Authorization = `${res.data.token}`;
      setUser(res.data.data);
      return res;
    }
  };

  const registerCounsellor = async (payload) => {
    const res = await api.post("student/studentRegister", payload);
    console.log(res);
    if (res && res.data.meta.status) {
      return res;
    }
  };

  const logout = (email, password) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    delete api.defaults.headers.Authorization;
    window.location.pathname = "/";
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        login,
        setUser,
        userLogin,
        registerCounsellor,
        loading,
        logout,
        userForgotPassword,
        userResetPassword,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// export const ProtectRoute = ({ children }) => {
//   const { isAuthenticated, isLoading } = useAuth();
//   if (
//     isLoading ||
//     (!isAuthenticated && window.location.pathname !== "/login")
//   ) {
//     // return <LoadingScreen />;
//   }
//   return children;
// };
