import React, { useEffect, useState } from "react";
import {
  getAllBoards,
  getAllSchools,
  getAllStandards,
  getStudentEducation,
  updateEducationDetail,
} from "../../../services/student/student-profile";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import EditEducations from "./edit-educations";

const EducationDetails = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [eduDetails, setEduDetails] = useState([]);
  const [schools, setSchools] = useState();
  const [boards, setBoards] = useState();
  const [standards, setStandards] = useState();
  const [education, setEducation] = useState([
    { standard_id: 4, school_id: "", grades: "", completion_year: "",board_id:"" },
    { standard_id: 3, school_id: "", grades: "", completion_year: "",board_id:"" },
  ]);
  const StudentEducationDetails = async () => {
    await getStudentEducation({ student_id: id }).then((res) => {
      if (res && res.data?.data?.length === 0) {
        setIsEdit(true);
      } else if (res && res.data?.data?.education) {
        setEduDetails(res.data?.data?.education);
        if (res.data?.data?.education.length === 2) {
          let temp = [];
          const twelve = res.data?.data?.education?.find(
            (x) => x.standard_id === 4
          );
          temp.push(
            twelve
              ? twelve
              : {
                  standard_id: 4,
                  school_id: "",
                  grades: "",
                  completion_year: "",
                  board_id:""
                }
          );
          const ten = res.data.data.education.find((x) => x.standard_id === 3);
          temp.push(
            ten
              ? ten
              : {
                  standard_id: 3,
                  school_id: "",
                  grades: "",
                  completion_year: "",
                  board_id:""
                }
          );
          setEducation(temp);
          setEduDetails(temp)

        } else {
          let temp = [];
          const twelve = res.data?.data?.education?.find(
            (x) => x.standard_id == 4
          );
          temp.push(
            twelve
              ? twelve
              : {
                  standard_id: 4,
                  school_id: "",
                  grades: "",
                  completion_year: "",
                  board_id:""
                }
          );
          const ten = res.data?.data?.education?.find(
            (x) => x.standard_id == 3
          );
          temp.push(
            ten
              ? ten
              : {
                  standard_id: 3,
                  school_id: "",
                  grades: "",
                  completion_year: "",
                  board_id:""
                }
          );
          setEducation(temp);
          setEduDetails(temp)
        }
      }
    });
  };
  const getSchools = async () => {
    await getAllSchools().then((res) => {
      if (res && res.data?.data) {
        setSchools(res.data?.data);
      }
    });
  };

  const getBoards = async () => {
    await getAllBoards().then((res) => {
      if (res && res.data?.data) {
        setBoards(res.data?.data?.records);
      }
    });
  };

  const getStandards = async () => {
    await getAllStandards().then((res) => {
      if (res && res.data?.data?.records) {
        setStandards(res.data?.data?.records);
      }
    });
  };

  useEffect(() => {
    StudentEducationDetails();
    getSchools();
    getBoards();
    getStandards();
  }, []);
  const handleSubmit = async () => {
    let data = {
      education: education?.map((e) => {
        let t = {
          standard_id: e.standard_id,
          school_id: e.school_id,
          completion_year: e.completion_year,
          grades: e.grades,
          board_id: e.board_id,
       
        };
        return t;
      }),
      student_id: id,
    };
    await updateEducationDetail(data).then((res) => {
      const { status, message } = res?.data?.meta;
      if (status) {
        toast.success(message);
        StudentEducationDetails();
        setIsEdit(false);
      }
    });
  };
  return (
    <div id="educationdetails-content" className="AboutBox">
      <span
        style={{ float: "right", cursor: "pointer" }}
        onClick={() => setIsEdit(!isEdit)}
        className="icon-edit-pencil btn-gray-edit"
      ></span>
      {isEdit ? (
        <EditEducations
          schools={schools}
          boards={boards}
          standards={standards}
          education={education}
          setEducation={setEducation}
          handleSubmit={handleSubmit}
          setIsEdit={setIsEdit}
        />
      ) : (
        <div className="accordion-body">
          <div className="row g-2">
            <div className="col-lg-12 mb-1">
              <label className="subheading">12th Class</label>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Expected Completion Year</label>
                <span className="name">
                  {eduDetails[0]?.completion_year
                    ? eduDetails[0]?.completion_year
                    : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>School & Board</label>
                <span className="name">
                  {eduDetails[0]?.school_name
                    ? eduDetails[0]?.school_name
                    : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Marks</label>
                <span className="name">
                  {eduDetails[0]?.grades ? eduDetails[0]?.grades : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label> Board Name</label>
                <span className="name">
                  {eduDetails[0]?.board_name
                    ? eduDetails[0]?.board_name
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-lg-12 mb-1">
              <label className="subheading">10th Class</label>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>Completion Year</label>
                <span className="name">
                  {" "}
                  {eduDetails[1]?.completion_year
                    ? eduDetails[1]?.completion_year
                    : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>School Name</label>
                <span className="name">
                  {eduDetails[1]?.school_name
                    ? eduDetails[1]?.school_name
                    : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label>10th Marks/Grades</label>
                <span className="name">
                  {" "}
                  {eduDetails[1]?.grades ? eduDetails[1]?.grades : "-"}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 my-2 my-md-2 my-md-2 my-lg-0">
              <div className="feild">
                <label> Board Name</label>
                <span className="name">
                  {eduDetails[1]?.board_name
                    ? eduDetails[1]?.board_name
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EducationDetails;
