import React, { useEffect, useState } from "react";
import EditEducations from "../../../Components/ProfileComponents/EducationDetails/edit-educations";
import {
  getAllBoards,
  getAllSchools,
  getAllStandards,
  getStudentEducation,
  updateEducationDetail,
} from "../../../services/student/student-profile";
import { toast } from "react-toastify";

const EducationField = ({ label, value }) => (
  <div className="col-md-3">
    <div className="d-flex flex-column">
      <div className="font-16 font-400 lineHeight-22 mb-1">{label}</div>
      <div className="font-20 font-500 lineHeight-22">{value || "-"}</div>
    </div>
  </div>
);

const Education = ({ details }) => {
  let { standard_name } = details || {};
  const [isEdit, setIsEdit] = useState(false);
  const [eduDetails, setEduDetails] = useState([]);
  const [schools, setSchools] = useState([]);
  const [boards, setBoards] = useState([]);
  const [standards, setStandards] = useState([]);
  const [education, setEducation] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        { data: eduData },
        { data: schoolsData },
        { data: boardsData },
        { data: standardsData },
      ] = await Promise.all([
        getStudentEducation(),
        getAllSchools(),
        getAllBoards(),
        getAllStandards(),
      ]);

      setEduDetails(eduData?.data?.education || []);
      setSchools(schoolsData?.data || []);
      setBoards(boardsData?.data?.records || []);
      setStandards(standardsData?.data?.records || []);

      // Get the relevant standards based on the current standard_name
      const relevantStandards = getRelevantStandards(standardsData?.data?.records || [], standard_name);

      const tempEducation = relevantStandards.map((std) =>
        eduData?.data?.education.find((edu) => edu.standard_id === std.id) || {
          standard_id: std.id,
          school_id: "",
          grades: "",
          completion_year: "",
          board_id: "",
        }
      );
      setEducation(tempEducation || []);
    };

    fetchData();
  }, [standard_name]);

  const getRelevantStandards = (allStandards, currentStandard) => {
    const standardMap = {
      "8th": ["8th"],
      "9th": ["8th"],
      "10th": ["8th", "9th"],
      "11th": ["8th", "9th", "10th"],
      "12th": ["8th", "9th", "10th", "11th"],
      "Pursuing UG": ["8th", "9th", "10th", "11th",'12th'],
      // "Completed UG": ["8th", "9th", "10th", "11th",'12th',"Completed UG"],
    };
    const relevantStandardNames = standardMap[currentStandard] || [];
    return allStandards.filter(std => relevantStandardNames.includes(std.name));
  };

  const handleSubmit = async () => {
    const payload = {
      education: education.map(({ standard_id, school_id, completion_year, grades, board_id }) => ({
        standard_id,
        school_id,
        completion_year,
        grades,
        board_id,
      })),
    };

    const { data: { meta } } = await updateEducationDetail(payload);

    if (meta?.status) {
      toast.success(meta.message);
      setIsEdit(false);
      const { data: eduData } = await getStudentEducation();
      setEduDetails(eduData?.data?.education || []);
    }
  };

  return (
    <div>
      <div className="educationDetails-detail tab-white-block">
        <p
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setIsEdit(!isEdit)}
          className="pencil-edit"
        ></p>
        {isEdit ? (
          <EditEducations
            schools={schools}
            boards={boards}
            standards={standards}
            education={education}
            setEducation={setEducation}
            handleSubmit={handleSubmit}
            setIsEdit={setIsEdit}
          />
        ) : (
          eduDetails.map((std) => (
            <div className="customRow" key={std.id}>
              <div className="customRow-header">
                <div className="icon icon-college"></div>
                <div className="customRow-header-title">
                  <span className="font-20 font-500">{std.standard_name=="Completed UG"?"UG College information":std.standard_name} Class</span>
                </div>
              </div>
              <div className="customRow-detail">
                <div className="row g-3">
                  <EducationField
                    label="Completion Year"
                    value={std.completion_year}
                  />
                  <EducationField
                    label="School Name"
                    value={std.school_name}
                  />
                  <EducationField
                    label="Marks/Grades"
                    value={std.grades}
                  />
                  <EducationField
                    label="Board Name"
                    value={std.board_name}
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Education;
