import React from "react"

export default function DefaultPrmopt({ handleDefaultPromptSelection }) {
  // const defaultPrompt = ["Visa Consulting", "Research Courses", "Foreign Exam", "Choose Countries", "Find Courses", "Foreign Universities", "Explore Scholarship", "Career Counselling"]

  const defaultPrompts = {
    "Career Counseling": "I would like to explore potential career options based on my skills and interests. Please provide information on job market trends in various fields.",
    "Foreign Universities": "I am considering studying abroad and would like to know more about foreign universities. Can you provide a list of top universities and highlight their unique programs? I would also appreciate insights on campus life and support services for international students.",
    "Visa Consulting": "I would like guidance on the visa application process for studying abroad. Can you explain the steps required to apply for a student visa? What documents are needed, and are there any common pitfalls to avoid?",
    "Research Courses": "I am interested in pursuing research as part of my studies. Can you help me find research-based courses at foreign universities? Additionally, what skills or qualifications are necessary to succeed in a research program?",
    "Find Courses": "I want to enhance my skills and knowledge through additional courses. Can you recommend platforms or specific courses? How can I evaluate which course will be most beneficial for my career goals?",
    "Foreign Exam": "I would like to know about the foreign exams required for studying abroad. Can you explain the key exams to consider and the best strategies for preparing for them? What resources would you recommend?",
    "Choose Countries": "I am exploring different countries to study in and would like to evaluate them based on factors like education quality, cost of living, and cultural experiences.",
    "Explore Scholarship": "I am looking for scholarships available for international students. Can you provide information on the eligibility criteria, application deadlines, and tips for writing a strong scholarship essay?",
  }

  return (
    <div className="get_started_prompt pt-4">
      <div className="row g-2 g-sm-3 justify-content-center">
        {Object.keys(defaultPrompts).map((promptKey, index) => (
          <div key={index} className="col-auto">
            <a onClick={() => handleDefaultPromptSelection(defaultPrompts[promptKey])} href="javascript:void(0);">
              {promptKey}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
