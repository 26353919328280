import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const QuizNextTimeModal = ({
  quizNextTimeModal,
  setQuizNextTimeModal,
  qres,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={quizNextTimeModal}
      centered
      onHide={() => setQuizNextTimeModal(false)}
    >
      <div class="modal-body p-5">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <span class="d-flex justify-content-center align-items-center mb-4">
            <img src="/assets/images/group-40899.svg" width="130" />
          </span>
          <h4 class="font-22 font-600 mb-4">
            Don't worry, there is always a next time!
          </h4>
          <div class="font-18 font-400 text-center">
            You scored {qres?.percentage?.toFixed(2)}% in the quiz.
            <br /> You can retake the quiz after 12 hours
          </div>
          <div class="d-flex justify-content-center align-items-center margin-top-40 w-100">
            <button
              class="btn-blue max-200 w-100 d-flex text-center align-items-center font-14 font-500 mx-2"
              onClick={() => {
                navigate("/student/life-skills");
                setQuizNextTimeModal(false);
              }}
            >
              Go Back To Skills
            </button>
            {/* <button
              class="btn radius max-200 height-40 d-block w-100 px-4 mx-2 font-14 font-500"
              onClick={() => setQuizNextTimeModal(false)}
              style={{
                background: "#5cae48",
                color: "#fff",
                zIndex: "2",
                position: "relative",
                borderRadius: "25px",
                webkitBorderRadius: "25px",
              }}
            >
              Move To Next Activity
            </button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuizNextTimeModal;
