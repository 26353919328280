import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import StudentHeader from "../StudentHeader/student-header"
import { useAuth } from "../../Contexts/auth-context"
import { getCompanyByAlias } from "../../services/util/util-service"
import AskMe from "../StickyFooter/AskMe"

const StudentLayout = () => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [companyDetail, setCompanyDetail] = useState([])

  const fetchCompanyByAlias = async (alias) => {
    await getCompanyByAlias(alias).then((res) => {
      if (res && res?.data?.data) {
        setCompanyDetail(res?.data?.data)
        localStorage.setItem("companyDetails", JSON.stringify(res?.data?.data))
      }
    })
  }
  useEffect(() => {
    fetchCompanyByAlias(window.location.hostname)
  }, [])

  useEffect(() => {
    if (location && location.pathname) {
    }
  }, [location])

  return (
    <React.Fragment>
      {!isAuthenticated ? (
        <div className="flex100vh">
          {/* <Outlet /> */}
          {navigate("/student-login")}
        </div>
      ) : (
        <div className="horizontalMenucontainer">
          <div className="page">
            <div className="page-main">
              <StudentHeader />

              <div className="jumps-prevent"></div>
              <div className="main-content app-content mt-0">
                <div className="side-app">
                  <div className="p-0 container-fluid">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
            <AskMe></AskMe>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default StudentLayout
