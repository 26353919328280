import React from "react";
import { Card } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { LiaUserClockSolid } from "react-icons/lia";

const UserPending = () => {
  return (
    <div
      className="user-success-main-div w-100 d-flex justify-content-center align-items-center"
      style={{ height: "calc(100vh - 170px)" }}
    >
      <Card
        className="d-flex justify-content-center "
        style={{
          width: "490px",
          height: "475px",
          border: "none",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
      >
        <div className="icon-div d-flex justify-content-center mt-4">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgb(255 233 206)",
              borderRadius: "50%",
              height: "150px",
              width: "150px",
            }}
          >
            <FaRegClock
              style={{
                color: "hsl(33.3deg 79.82% 55.29%)",
                height: "70px",
                width: "70px",
              }}
            />
          </div>
        </div>

        <div className="mt-4">
          <h1
            className="text-center font-bold"
            style={{ color: "hsl(33.3deg 79.82% 55.29%)", fontWeight: "bold" }}
          >
            Pending...
          </h1>
          <h4
            className="text-center mt-3"
            style={{ color: "rgb(121 125 128)" }}
          >
            Your payment is in progress.
          </h4>
        </div>
        <button
          className="mt-4"
          style={{
            margin: "15px",
            padding: "10px 10px",
            color: "#FFF",
            backgroundColor: "rgb(201 131 44)",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Please Wait …
        </button>
      </Card>
    </div>
  );
};

export default UserPending;
