import React from "react";
import { Modal } from "react-bootstrap";

const VerifyContactDetail = (props) => {
  const { setVerifyContactModal, verifyContactModal, onMobile, onEmail } =
    props;

  return (
    <Modal
      show={verifyContactModal}
      size="lg"
      centered
      onHide={() => setVerifyContactModal(false)}
    >
      <div>
        <div className="modal-header border-0 pt-5">
          <h5
            className="modal-title font-24 text-dark-blue text-center w-100"
            id="staticBackdropLabel"
          >
            Verify your contact details
          </h5>
          <button
            type="button"
            className="btn-close btn-close-small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setVerifyContactModal(false)}
          ></button>
        </div>
        <div className="modal-body pt-0">
          <div className="px-0 pb-4 pt-4">
            <div className="d-flex justify-content-center mb-4">
              <img src="/assets/images/varify.png" alt="" />
            </div>
            <p className="text-center max-360 mx-auto mb-4 pb-3 font-16">
              While you’re creating your account, you’ll get an email from
              Univareity. Open the email and find the verification code.
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn-green max-190 mx-1 text-nowrap"
                onClick={onEmail}
              >
                <span>Verify Email</span>
              </button>
              <button
                className="btn-green max-190 mx-1 text-nowrap"
                onClick={onMobile}
              >
                <span>Verify Mobile</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyContactDetail;
