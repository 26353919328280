import React, { useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { toast } from "react-toastify";
import { useAuth } from "../../../Contexts/auth-context";

const UserResetPassword = () => {
  const {userResetPassword} = useAuth()

  const [activeTab, setActiveTab] = useState("tab1");
  const [validated, setValidated] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    password: "",
  });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
let data={
  token: token,
  new_password:payload.password
}
  if(token) {

    const res = await userResetPassword(data);
    setValidated(true);
    if (res && res.data?.meta?.status) {
      navigate("/student-login")
    }
  } else {
    toast("Invalid Url!")
  }
  };

  return (
    <div
      className="container-fluid bg_side_blue landing_login_page d-flex flex-1"
      style={{ paddingRight: "1px", height: "100vh" }}
    >
      <div className="container max-980 flex-1" style={{ maxWidth: "100vw" }}>
        <div className="row h-100 z1">
          <div className="col-lg-6 col-md-6  col-sm-12 d-none d-md-flex flex-column justify-content-center align-items-center desktoponly">
            <div className="custom_logo d-flex justify-content-center mt-5 mb-4">
              <img
                src="/assets/images/miles.jpg"
                width="160"
                className="img-fluid"
                alt="miles"
              />
            </div>
            <OwlCarousel
              className="owl-theme"
              items={1}
              slideBy={1}
              dots={true}
              loop
              margin={10}
              nav
            >
              <div className="item">
                <div className="innercover">
                  <div className="img img mb-4 text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/self-discovery.svg"
                      alt=""
                    />
                  </div>
                  <h3 className="font-24 font-600 text-center">
                    Self-discovery
                    <br />
                    with Univariety
                  </h3>
                  <p className="font-14 text-center">
                    Students get to know themselves better and improve their
                    skills using Univariety’s scientific tools and courses.
                    Start now for an early advantage.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="innercover">
                  <div className="img img mb-4 text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/critical-career-insights.svg"
                      alt=""
                    />
                  </div>
                  <h3 className="font-24 font-600 text-center">
                    Critical Career
                    <br />
                    Insights
                  </h3>
                  <p className="font-14 text-center">
                    Online Psychometric tests for career guidance are the first
                    step in Career Planning for Students to help them discover
                    their Aptitude, Interest and Personality.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="innercover">
                  <div className="img img mb-4 text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/personalised-guidance.svg"
                      alt=""
                    />
                  </div>
                  <h3 className="font-24 font-600 text-center">
                    Personalised
                    <br />
                    Guidance
                  </h3>
                  <p className="font-14 text-center">
                    Our one-on-one counselling sessions help students and
                    parents explore all their options and create a customised
                    career roadmap
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ paddingRight: "0" }}
          >
            <div className="mibile-logo d-block d-md-none p-4 text-center">
              <img
                src="/assets/images/miles.jpg"
                width="160"
                className="img-fluid"
                alt="miles"
              />
            </div>
            <div
              className="signup h-100 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="tab-pane nav-link" style={{ width: "70%" }}>
                <h2
                  className="text-white text-center mb-4"
                  style={{ position: "relative", zIndex: "1" }}
                >
                  Reset Password
                </h2>
             
                <div>
                  <Form
                    noValidate
                    className="needs-validation"
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                   
                    <div className="form-group mb-2 form-control-password">
                      <Form.Control
                        type={passwordType}
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={payload.password}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            password: e.target.value,
                          })
                        }
                        required
                      />
                      <span
                        className="viewpassword"
                        onClick={() =>
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          )
                        }
                      >
                        <img src="/assets/images/view.svg" alt="view" />
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            className="text-white font-14 font-500 cursor-pointer"
                            onClick={() => navigate("/user-login")}
                            style={{ zIndex: 1, position: "relative" }}
                          >
                            Back to login?
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-14 font-500"
                      style={{ background: "#5cae48" }}
                    >
                      <span>Reset</span>
                    </button>
                  </Form>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserResetPassword;
