import React, { useState } from "react";

const CollegeBlog = ({ removeCollege, data, cityData, index }) => {
  //   const [isRemove, setisRemove] = useState(false)
  // const removeData =()=>{
  // setisRemove(true)
  // removeCollege()
  // }

  return (
    <div className="block collegeBlock firstCol">
      <div className="heading pos-rel">
        <a
          className="btn-close btn-close-white btn-close-small cursor-pointer"
          onClick={() => removeCollege(index)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </a>

        <h6 className="mb-1 d-flex">
          <small className="me-2">
            <span style={{ cursor: "pointer" }} className="compare_add">
              <i className="fa fa-check-circle fa-lg"></i>
            </span>
          </small>
          <span className="flex-1 d-flex flex-column">
            <span className="font-small font-18 font-500">
              {data?.name ? data?.name : "-"}
            </span>
            <span className="font-small font-14 mt-1">
              {cityData?.find((x) => x.id === data.city_id)?.name},
              {cityData?.find((x) => x.id === data.city_id)?.state_name},
              {cityData?.find((x) => x.id === data.city_id)?.country_name}
            </span>
          </span>
        </h6>
      </div>
      <div className="content">
        <span>{data?.establish_year ? data?.establish_year : "-"}</span>
      </div>
      <div className="content">
        <span>{"-"}</span>
      </div>
      <div className="content">
        <span>{data?.institutuion_type ? data?.institutuion_type : "-"}</span>
      </div>
      <div className="content">
        <span>
          <a target="_blank">{data?.campus_size ? data?.campus_size : "-"}</a>
        </span>
      </div>
      {/* <div className="content">
        <span>Public </span>
      </div> */}
      <div className="content">
        <span>{data?.college_rank ? data?.college_rank : "-"} </span>
      </div>
      <div className="content">
        <span>{data?.student_count ? data?.student_count : "-"}</span>
      </div>
      <div className="content ranking">
        <span>{data?.scholarship_count ? data?.scholarship_count : "-"} </span>
      </div>
      <div className="content">
        <span>{data?.campus_size ? data?.campus_size : "-"} </span>
      </div>
      <div className="content">
        <span>
          <a target="_blank">
            {data?.percent_international_student
              ? data?.percent_international_student
              : "-"}
          </a>
        </span>
      </div>
      <div className="content">
        <span>{data?.acceptance_ratio ? data?.acceptance_ratio : "-"}</span>
      </div>
      <div className="content">
        <span>--</span>
      </div>
      <div className="content">
        <span>{data?.avg_cost_living ? data?.avg_cost_living : "-"}</span>
      </div>
      {/* <div className="content">
        <span>$ 18,000</span>
      </div>
      <div className="content">
        <span>$ 9,000 </span>
      </div> */}
    </div>
  );
};

export default CollegeBlog;
