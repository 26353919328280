import React, { useEffect, useState } from "react";
import Chart from "../../../../Components/DashboardComponents/Chart/chart";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import UgUniversities from "../../../../Components/DashboardComponents/UgUniversities/ug-universities";
import PsychometricProgress from "../../../../Components/DashboardComponents/PsychometricProgress/psychometric-progress";

const UgAspirants = ({ reports, courseReport }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [data, setData] = useState([]);
  const [cData, setCData] = useState([]);

  useEffect(() => {
    if (reports?.length) {
      setData([
        {
          labels: reports.map((y) => {
            return y.country ? y.country : y.course;
          }),
          datasets: [
            {
              label: "Preference",
              data: reports.map((y) => {
                return parseInt(y.preferences);
              }),
              backgroundColor: [
                "#192d45",
                "#419cd2",
                "#46d6be",
                "#f7d76f",
                "#ef7f62",
                "#b996f6",
                "#b9cef2",
                "#dce7f8",
              ],
              borderWidth: 1,
            },
          ],
        },
      ]);
    }
  }, [reports]);

  useEffect(() => {
    if (courseReport?.length) {
      setCData([
        {
          labels: courseReport.map((y) => {
            return y.country ? y.country : y.course;
          }),
          datasets: [
            {
              label: "Preference",
              data: courseReport.map((y) => {
                return parseInt(y.preferences);
              }),
              backgroundColor: [
                "#192d45",
                "#419cd2",
                "#46d6be",
                "#f7d76f",
                "#ef7f62",
                "#b996f6",
                "#b9cef2",
                "#dce7f8",
              ],
              borderWidth: 1,
            },
          ],
        },
      ]);
    }
  }, [courseReport]);

  const uniData = [
    { uniName: "IIT Bombay India", country: "USA", count: "500" },
    { uniName: "IIT Bombay India", country: "USA", count: "500" },
    { uniName: "IIT Bombay India", country: "USA", count: "500" },
  ];
  const psychometric = [
    { testName: "ProMap", count: "43" },
    { testName: "ProMap", count: "43" },
    { testName: "ProMap", count: "43" },
  ];
  return (
    <div className="flex-1 polarcontsec">
      <div className="row g-3">
        {data?.map((val) => {
          return (
            <>
              <div className="col-md-6">
                <Chart data={val} label={"Country"} />
              </div>
            </>
          );
        })}
        {cData?.map((val) => {
          return (
            <>
              <div className="col-md-6">
                <Chart data={val} label={"Course"} />
              </div>
            </>
          );
        })}

        {/* <div className="col-md-12">
          <UgUniversities uniData={uniData} />
        </div>

        <div className="col-md-12">
          <PsychometricProgress psychometric={psychometric} />
        </div> */}
      </div>
    </div>
  );
};

export default UgAspirants;
