import React, { useEffect, useState } from "react";
import EditBasicInfo from "../../../Components/ProfileComponents/BasicInformation/edit-basicInfo";
import { getStudentDetails } from "../../../services/student/student-profile";

const BasicInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [details, setDetails] = useState();
  const StudentProfileDetail = async () => {
    await getStudentDetails().then((res) => {
      if (res && res.data?.data) {
        setDetails(res.data?.data);
      }
    });
  };

  useEffect(() => {
    StudentProfileDetail();
  }, []);
  return (
    <div>
      <div class="parentInformation-detail tab-white-block">
        <p
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setIsEdit(!isEdit)}
          className="pencil-edit"
        ></p>
        {isEdit ? (
          <EditBasicInfo
            details={details}
            setIsEdit={setIsEdit}
            refreshData={StudentProfileDetail}
          />
        ) : (
          <>
            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-father"></div>
                <div class="customRow-header-title">
                  <span class="font-20 font-500">Student</span>
                </div>
              </div>
              <div class="customRow-detail">
                <div class="row g-3">
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        First Name
                      </div>
                      <div class="font-20 font-500 lineHeight-22">
                        {details?.fname || "-"}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Last Name
                      </div>
                      <div class="font-20 font-500 lineHeight-22">
                        {details?.lname || "-"}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Gender
                      </div>
                      <div class="font-20 font-500 lineHeight-22">
                        {details?.gender || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-exam-note"></div>
                <div class="customRow-header-title">
                  <span class="font-20 font-500">Contact </span>
                </div>
              </div>
              <div class="customRow-detail">
                <div class="row g-3">
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Mobile
                      </div>
                      <div class="font-20 font-500 lineHeight-22">
                        {details?.phone || "-"}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Email
                      </div>
                      <div class="font-20 font-500 lineHeight-22">
                        {details?.email || "-"}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Class &amp; Board
                      </div>
                      <div class="font-20 font-500 lineHeight-22">{details?.standard_name}{details?.board_name && "," + details?.board_name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="customRow">
              <div class="customRow-header">
                <div class="icon icon-global"></div>
                <div class="customRow-header-title">
                  <span class="font-20 font-500">location</span>
                </div>
              </div>
              <div class="customRow-detail">
                <div class="row g-3">
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Address
                      </div>
                      <div class="font-18 font-500 lineHeight-22">
                        {details?.address_line_1 || "-"}
                        {details?.address_line_2 && "," +details?.address_line_2}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Citizenship
                      </div>
                      <div class="font-18 font-500 lineHeight-22">
                        {details?.primary_citizenship_name || "-"}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex flex-column">
                      <div class="font-16 font-400 lineHeight-22 mb-1">
                        Current Location
                      </div>
                      <div class="font-18 font-500 lineHeight-22">
                        {details?.city_name || "-"}
                        {details?.country_name && "," + details?.country_name}{" "}
                        {details?.pincode && "- " + details?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
