import React, { useRef } from "react";
import CollegeBlog from "../../college-components/college-blog";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const CompareColleges = ({
  colleges,
  setCompareCollegeData,
  collegeList,
  setCollegeList,
  compareCollegeData,
  cityData,
  loadOptions,
}) => {
  const asyncRef = useRef(null);

  const collegeId = (i) => {
    let temp1 = Object.assign([], compareCollegeData);
    temp1[i] = {};
    setCompareCollegeData(temp1);
  };
  const removeCollege = (index) => {
    let temp = Object.assign([], collegeList);
    temp[index] = {};
    collegeId(index);
    setCollegeList(temp);
  };

  return (
    <div className=" ">
      <div className="m-0 pt-4 pb-5 mt-2">
        <p className="font-16 mb-4">
          Confused about which college to choose? Add up to 3 universities for
          college comparison on various points
        </p>
        <div className="position-relative compareCollegeswrap">
          <div className="tabular-data">
            <div id="content">
              <div className="row">
                <div className="col-md-3 col-sm-3 col-4"></div>
                <div className="col-md-9 col-sm-9 col-8">
                  <div className="mobile-scroll mt-4">
                    <div className="row">
                      {compareCollegeData.map((c, i) => {
                        return (
                          <div
                            className="col-sm-4 col-4"
                            // style={{ height: "200px " }}
                            key={i}
                          >
                            {c.collegeId ? null : (
                              <div className="colleges-div-1" 
                              style={{height:"200px"}}
                              >
                                <AsyncSelect
                                  defaultOptions={colleges}
                                  placeholder="Select"
                                  value={compareCollegeData[i]?.collegeId}
                                  onChange={(e) => {
                                    console.log(e);
                                    let temp = Object.assign(
                                      [],
                                      compareCollegeData
                                    );
                                    temp[i]["collegeId"] = e.value ? e.value : undefined;
                                    setCompareCollegeData(temp);
                                  }}
                                  cacheOptions
                                  ref={asyncRef}
                                  isSearchable={(e) => console.log(e)}
                                  isDisabled={
                                    i != 0
                                      ? compareCollegeData[i - 1].collegeId
                                        ? false
                                        : true
                                      : false
                                  }
                                  loadOptions={loadOptions}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-md-3 col-sm-3 col-4">
                  <div className="mobile-scroll mt-4">
                    <div className="heading"></div>

                    <div className="content">
                      <span className="lead">Establishment Year</span>
                    </div>
                    <div className="content">
                      <span className="lead">Undergraduate Courses</span>
                    </div>
                    <div className="content">
                      <span className="lead">Institute Type </span>
                    </div>
                    <div className="content">
                      <span className="lead">Campus Size (in Acres) </span>
                    </div>
                    {/* <div className="content">
                      <span className="lead"> Calendar System </span>
                    </div> */}
                    <div className="content ranking">
                      <span className="lead"> Ranking</span>
                    </div>
                    <div className="content">
                      <span className="lead"> Total Students</span>
                    </div>
                    <div className="content">
                      <span className="lead">Number of Scholarships </span>
                    </div>
                    <div className="content">
                      <span className="lead"> Number of Campuses </span>
                    </div>
                    <div className="content">
                      <span className="lead">International Students </span>
                    </div>
                    <div className="content">
                      <span className="lead"> Acceptance Ratio </span>
                    </div>
                    <div className="content">
                      <span className="lead"> Cost of living on Campus </span>
                    </div>
                    <div className="content">
                      <span className="lead">Cost of living in City </span>
                    </div>
                    {/* <div className="content">
                      <span className="lead"> Average Tuition Fees </span>
                    </div>
                    <div className="content" id="admChances">
                      <span className="lead">Admission Chances</span>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-9 col-sm-9 col-8">
                  <div className="mobile-scroll mt-4">
                    <div className="row">
                      {collegeList &&
                        collegeList?.map((x, i) => {
                          return (
                            <div className="col-sm-4 col-4" key={i}>
                              <CollegeBlog
                                data={x}
                                removeCollege={removeCollege}
                                cityData={cityData}
                                index={i}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="border rounded shadow p-3 ml-2 overflow-hidden"
              style={{ display: "none" }}
            >
              <div className="row">
                <div className="col-md-8">
                  <p className="d-flex align-items-center h-100">
                    Displaying chances for:{" "}
                    <span>
                      <strong id="major">Choose a Department</strong>{" "}
                      (Department) <em>|</em> <strong id="board">CBSE</strong>{" "}
                      (Board) <em>|</em> <strong id="marks">90.00</strong>{" "}
                      (Overall)
                    </span>
                  </p>
                </div>
                <div className="col-md-4 text-right d-flex justify-content-start justify-content-lg-end justify-content-md-end">
                  <a className="btn btn-green d-flex align-items-center font-14 font-500 hvr-icon-pulse my-auto video-btn border-2 me-3 border-radius-25 height-40 px-4">
                    <span>Change</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              id="chanceBlockDiv"
              style={{ display: "none" }}
              className="border rounded shadow p-5 ml-2 overflow-hidden"
            >
              <div id="chanceBlock" className="row">
                <form method="post" className="ng-pristine ng-valid">
                  <div className="col-sm-8 mx-auto">
                    <p className="font-18 font-600 text-center m-0">
                      Choose Department
                      <span
                        id="conditional"
                        style={{ display: "inline-block" }}
                      ></span>
                      to view chances of admission.
                    </p>

                    <div className="row pt-4 pb-4">
                      <div className="col-sm-3 text-center d-flex align-items-center font-16 font-500">
                        Department
                      </div>
                      <div className="col-sm-9">
                        <select
                          name="ChanceData[department_pref]"
                          id="ChanceData_department_pref"
                          className="form-select form-control flex-1"
                        >
                          <option value="">Choose a Department</option>
                          <option value="13">Architecture</option>
                          <option value="1">Art &amp; Design</option>
                          <option value="253">
                            Bio Sciences &amp; Biotechnology
                          </option>
                          <option value="37">Business Management</option>
                          <option value="254">
                            Computer Graphics &amp; Animation
                          </option>
                          <option value="55">
                            Computer Science &amp; Information Technology
                          </option>
                          <option value="265">Defence</option>
                          <option value="255">
                            Economics &amp; Commerce (B Com/H)
                          </option>
                          <option value="67">Education &amp; Teaching</option>
                          <option value="74">
                            Engineering &amp; Technology
                          </option>
                          <option value="256">Fashion</option>
                          <option value="100">Healthcare</option>
                          <option value="119">
                            Hospitality, Tourism &amp; Events
                          </option>
                          <option value="129">Humanities</option>
                          <option value="143">
                            Journalism, Media, PR &amp; Communication
                          </option>
                          <option value="150">Law</option>
                          <option value="257">
                            Linguistics (English Lit. &amp; Hons)
                          </option>
                          <option value="258">
                            Mathematics &amp; Statistics
                          </option>
                          <option value="159">Medicine</option>
                          <option value="259">Music &amp; Dance</option>
                          <option value="267">Not Sure</option>
                          <option value="260">Psychology</option>
                          <option value="266">
                            Public Administration &amp; Government
                          </option>
                          <option value="23">Science</option>
                          <option value="261">Sports</option>
                        </select>
                        <div
                          className="pink-text"
                          id="ChanceData_department_pref_em_"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        value="Show Chances"
                        type="submit"
                        name="yt0"
                        className="btn btn-green d-flex align-items-center font-14 font-500 hvr-icon-pulse my-auto video-btn border-2 mx-auto border-radius-25 height-40 px-4"
                      />
                      <span>Show Chances</span>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareColleges;
