import React, { useState } from "react";
import CareerFilters from "./career-filters";
import CareerBox from "./career-box";
import { Card, Nav, Tab } from "react-bootstrap";
import SkillCard from "../../../Components/SkillsCard/skill-card";
import { useNavigate } from "react-router-dom";

const ChooseCareer = () => {
  const [activeKey, setActiveKey] = useState("deep-dive");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const skillsData = [
    {
      id: 1,
      title: "Time Management",
      icon: "icon-time-management",
    },
    {
      id: 2,
      title: "Concentration",
      icon: "icon-concentration",
    },
    {
      id: 3,
      title: "Communication",
      icon: "icon-communication",
    },
    {
      id: 4,
      title: "Leadership",
      icon: "icon-leadership",
    },
    {
      id: 5,
      title: "Creative Thinking",
      icon: "icon-creative-thinking",
    },
    {
      id: 6,
      title: "Self - Awareness",
      icon: "icon-self-awareness",
    },
    {
      id: 7,
      title: "Negotiation",
      icon: "icon-negotiation",
    },
    {
      id: 8,
      title: "Critical Thinking",
      icon: "icon-critical-thinking",
    },
    {
      id: 9,
      title: "Decision Making",
      icon: "icon-decision-making",
    },
    {
      id: 10,
      title: "Assertiveness",
      icon: "icon-assertiveness",
    },
    {
      id: 11,
      title: "Assertiveness",
      icon: "icon-assertiveness",
    },
    {
      id: 12,
      title: "Assertiveness",
      icon: "icon-assertiveness",
    },
    {
      id: 13,
      title: "Assertiveness",
      icon: "icon-assertiveness",
    },
  ];
  const data = [
    {
      eventName: "UniReach Fair - USA Chapter",
      eventDesc: `A pilot is a professional who is responsible for flying planes,
        helicopters or rockets. Pilots undergo extensive aviation
        training`,
    },
    {
      eventName: "UniReach Fair - USA Chapter",
      eventDesc: `A pilot is a professional who is responsible for flying planes,
          helicopters or rockets. Pilots undergo extensive aviation
          training`,
    },
    {
      eventName: "UniReach Fair - USA Chapter",
      eventDesc: `A pilot is a professional who is responsible for flying planes,
          helicopters or rockets. Pilots undergo extensive aviation
          training`,
    },
    {
      eventName: "UniReach Fair - USA Chapter",
      eventDesc: `A pilot is a professional who is responsible for flying planes,
          helicopters or rockets. Pilots undergo extensive aviation
          training`,
    },
  ];
  return (
    <div className="flex-1 container">
      <div className="mt-4 pb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="h-100 p-0">
              <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                  Choose Your Career
                </h3>
                <p className="font-16 font-400 mb-2">
                  Use the college finder tool to find the best colleges! Choose
                  from the following options.
                </p>
              </div>
              <Card style={{ border: "none" }}>
                <Nav variant="pills" className="job_internship_tab polartabsec">
                  <Nav.Item eventKey="deep-dive">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveKey("deep-dive")}
                          className={
                            activeKey === "deep-dive"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ExploreCollege-tab"
                        >
                          Career Deep Dive
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            activeKey === "intrest-match"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="intrest-match-tab"
                          onClick={() => setActiveKey("intrest-match")}
                        >
                          Career Intrest Match
                        </button>
                      </li>
                    </ul>
                  </Nav.Item>
                </Nav>
              </Card>

              <div className="row m-0 pt-4 pb-3 JobInternshipListing">
                <div className="col-lg-12">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="deep-dive"
                    activeKey={activeKey}
                    onSelect={(k) => setActiveKey(k)}
                  >
                    <Tab.Content>
                      <Tab.Pane eventKey="deep-dive">
                        <div className="tab-pane fade show active">
                          <div className="row m-0 pt-4 pb-5 JobInternshipListing">
                            <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate">
                              <div className="mobileFilter mobileonly text-end">
                                <button
                                  className="btn-filter btn-unscroll font-18 font-500"
                                  type="button"
                                >
                                  <span className="icon-filter"></span>
                                  Filters
                                </button>
                              </div>
                              <CareerFilters />
                            </div>

                            <div className="flex-1 polarcontsec tab-content">
                              <div className="ActiveListings_present">
                                <div className="d-flex flex-wrap align-items-end my-2 w-100">
                                  <div className="d-flex flex-wrap align-items-center">
                                    <h3 className="font-18 font-500 mb-0">
                                      69 Careers found
                                    </h3>
                                  </div>
                                </div>
                                <div className="row EventsBlk">
                                  {data?.map((x, i) => {
                                    return (
                                      <>
                                        <div
                                          className="col-lg-6 col-md-12 col-sm-12 album_blk_parent"
                                          key={i}
                                        >
                                          <CareerBox data={x} />
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                                {loading && (
                                  <a className="load_more_btn">
                                    <img src="/assets/images/load_more_icon.jpg" />
                                    Load More
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="intrest-match">
                        <div className="">
                          <div className="skill-wrap">
                            <div className="skill-range">
                              <h2 className="font-24 font-500 text-center mb-0">
                                Select the skill that you are naturally good at
                              </h2>
                            </div>
                            <form action="super-counsellorlife-life-skills-communication.html">
                              <div className="select-skill row">
                                <div className="d-flex justify-content-center flex-wrap text-center select-skill-container p-0">
                                  {skillsData?.map((val, index) => {
                                    return (
                                      <div className="select" key={index}>
                                        <SkillCard data={val} />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-16 font-500"
                                onClick={() =>
                                  navigate("/student/communication")
                                }
                              >
                                <span>Procced</span>
                              </button>
                            </form>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCareer;
