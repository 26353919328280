import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const MeetingCard = ({ val, setShowColleges, setData }) => {
  const [copied, setCopied] = useState(false);
  const otherCopy = () => setCopied(true);
  return (
    <div className="album_blk">
      <div className="album_imgblk position-relative">
        <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute university">
          {val.event_type_name}
        </span>
        <img src={val.event_image} alt="" />
      </div>
      <div className="album_contblk eventsbox">
        <div className="d-flex flex-wrap justify-content-between align-items-start">
          <div className="flex-1">
            <h3 className="font-20 font-500 mb-2">{val.EventTitle}</h3>
          </div>
          <div className="album_shareblk">
            <CopyToClipboard onCopy={otherCopy} text={val.RegistrationLink}>
              <span
                className="btn btnicon circle-effect btnnoborder"
                onCopy={otherCopy}
              >
                <span className="icon-share font-18 icon"></span>
              </span>
            </CopyToClipboard>
          </div>
        </div>
        <p className="d-flex align-items-center justify-content-start mContCenter mb-0">
          <span className="icon_bluecir me-2">
            <span className="icon-calender-time font-18 text-dark-blue icon"></span>
          </span>
          <span className="font-16 font-500 text-dark-blue">
            {val.FromDateTime}
          </span>
        </p>

        <div className="d-flex align-items-center justify-content-start mContCenter flex-wrap mb-3">
          <p className="d-flex align-items-center justify-content-start mt-2 mb-0">
            <span className="icon_bluecir me-2">
              <img src="/assets/images/speech_icon.jpg" alt="speech-icon" />
            </span>
            <span className="font-16 line-height-16 font-500 text-dark-blue me-2 me-sm-3 m-max-105">
              {val.event_universities.length} Univarsity
            </span>
          </p>

          <div className="text-center mt-2">
            <span
              className="btn btn-border-gray height-28 line-height-26 d-inline-flex font-500 font-14 text-gray-41 text-decoration-none justify-content-center"
              onClick={() => {
                setShowColleges(true);
                setData(val.event_universities);
              }}
            >
              <span>View List</span>
            </span>
          </div>
        </div>

        <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
          <a
            rel="noreferrer"
            target="_blank"
            onClick={() => val?.video_link && window.open(val.video_link)}
            className="btn btn-green height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
          >
            <span>Watch</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default MeetingCard;
