import React, { useEffect, useRef } from "react";
import CareerCard from "./career-card";
import CareerTrendsSidebar from "./career-trends-sidebar";

const PopularCareer = () => {
  const pageRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);

  const handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        ref1.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        ref2.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        ref3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        ref4.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        ref5.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 5:
        ref6.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 6:
        ref7.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 7:
        ref8.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 8:
        ref9.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 9:
        ref10.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset || window.scrollY;
    const elementOffsetTop = pageRef.current?.offsetTop - 30;
    const elementOffsetHeight = pageRef.current?.offsetHeight + 30;
    const ptabs = pageRef.current?.querySelector(".nav-tabs");
    const ptabsLink = ptabs?.querySelectorAll(".nav-link");
    const otabs = pageRef.current?.querySelectorAll(".card-container");
    for (let i = 0; i < otabs?.length; i++) {
      let ot = otabs[i].offsetTop - ptabs?.offsetHeight * 2;
      if (
        position >= ot &&
        position < otabs[i + 1]?.offsetTop - ptabs?.offsetHeight * 2
      ) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position >= ot && i === otabs?.length - 1) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position < otabs[1].offsetTop - ptabs?.offsetHeight * 2) {
        ptabsLink[0]?.classList?.add("active");
        ptabsLink[1]?.classList?.remove("active");
      } else {
        ptabsLink[i]?.classList?.remove("active");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const item = [
    "Digital Marketing",
    "Data Science",
    "Artificial Intelligence",
    "Project Management",
    "UI/UX Design",
    "Renewable Energy Specialist",
    "Mental Health Professional",
    "Social Media Influencer",
    "Cyber Law",
    "Sustainable Architecture",
  ];
  const MarketingData = {
    title: "Digital Marketing",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Among the top popular careers for the next 10 years, the trajectory of Digital Marketing is only said to go upward. The world is going digital, so is Marketing. By 2026, the Digital Marketing industry could see a rise in job demand by 10%.</p>
    <p style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0.5rem !important; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">This field has seen tremendous growth in the last 2 years and requires many skilled professionals. It involves working with search engine tools, marketing analytics, content, and visual media!</p>`,
    name1: "SEO Specialist",
    name2: "Digital Marketer",
    name3: "Video Editor",
    itemData: [
      {
        titlename: "Bachelor of Arts",
        description:
          "A Bachelor of Arts course is one of the most popularly chosen courses to get into the Digital Marketing industry. With the opportunity to specialise in multiple subjects such, a Bachelor of Arts degree offers an in-depth grasp on trends in media and understanding of people.",
      },
      {
        titlename: "Bachelor of Business Administration",
        description:
          "Another course that is becoming popular to thrive in the Digital Marketin industry is the Bachelors of Business Administration. With a more quantitative approach, a BBA with a specialisation helps in the other spheres of the industry such as Performance and growth.",
      },
    ],
  };
  const scienceData = {
    title: "Data Science",
    desc: "Without data, there is no future. Today, more industries are analyzing and using their data to improve businesses. Due to this, Data Science is a career of the future with an expected growth rate of 33.8% by 2026. Data science has a wide range of career prospects ranging from Data Scientist, to Data Mining.",
    name1: "Data Scientist",
    name2: "Data Engineer",
    name3: "Business Intelligence Analyst",
    itemData: [
      {
        titlename: "Bachelor of Computer Applications",
        description:
          "This 3-year course includes all the aspects of computer applications. From software programming, to language studies, along with the study and application of data science subjects, the course offers an in-depth understanding of the subject and its scope.",
      },
      {
        titlename: "Bachelor of Science",
        description:
          "The Bachelor of Science course takes you through subjects of software engineering, machine learning, big data analytics and artificial intelligence. Being an interdisciplinary course, its main objective is to provide both theoretical and practical knowledge in the field of data science.",
      },
    ],
  };
  const IntelligenceData = {
    title: "Artificial Intelligence",
    desc: "Artificial Intelligence is one of the fastest-growing sectors in the tech sector. The scope of AI has expanded into many sectors, including healthcare, transport, and security. By the end of 2025, Artificial Intelligence as an industry is expected to create 12 million jobs!",
    name1: "Research Scientist",
    name2: "Machine Learning Engineer",
    name3: "Big Data Engineer",
    itemData: [
      {
        titlename: "Bachelor of Science",
        description:
          "The Bachelor of Science course of Artificial Intelligence is an extensive 3-year program that offers an in-depth understanding of Artificial Intelligence and its applications. This course is highly theoretical and research-oriented.",
      },
      {
        titlename: "Bachelor of Technology",
        description:
          "The Bachelor of Technology degree in Artificial Intelligence is designed to help students understand machines, computer software, or applications with a mix of machine learning. It is a 4-year program that offers practical knowledge to thrive in the ever growing AI industry.",
      },
    ],
  };
  const ProjectData = {
    title: "Project Management",
    desc: "Project Management is planning, organising, and managing the completion of a project, while ensuring results, budget, and within scope. According to the Project Management Institute the demand for Project Managers will reach up to 22 million by 2027.",
    name1: "Project Manager",
    name2: "Program Manager",
    name3: "Project Director",
    itemData: [
      {
        titlename: "Bachelor of Business Administration",
        description:
          "The Bachelor of Business Administration is a formal 3-year degree program that helps pursue a career in Project Management. The course offers an insight into the business world and the different projects that will be a part of it, along with how to understand, analyse, and offer solutions.",
      },
    ],
  };
  const UiUxData = {
    title: "UI/UX Design",
    desc: "UI/UX design offers interactive experiences that solve user problems in a fun and pleasing format. With businesses going digital, services and products must be offered in a simple, yet aesthetic way. This is why UI/UX Design as an industry is among the most popular for the next decade.",
    name1: "UI Designer",
    name2: "UX Designer",
    name3: "Visual Designer",
    itemData: [
      {
        titlename: "Bachelor of Science in Game Design and Animation",
        description:
          "One of the most sought after courses to pursue UI/UX Design is Bachelor of Science. With a specialisation in Game Design or Animation, you can hone the skills to become a UI/UX designer.",
      },
      {
        titlename: "Bachelor of Science in Web Development",
        description:
          " You can also opt for the B.Sc. degree with a specialisation in Web Development or Computer Science. This offers an in-depth technical understanding of the making of a product or a service, and how it can be made to look more appealing.",
      },
    ],
  };

  const RenewableData = {
    title: "Renewable Energy Specialist",
    desc: "A Renewable Energy Specialist could be a policy advisor, or an energy science official, or even a scientist or a lawyer. No matter what the job role is, your job would be to use renewable energy as a solution to real-world problems.",
    name1: "Engineer",
    name2: "Farmer",
    name3: "Windfarm Developer",
    itemData: [
      {
        titlename: "Bachelors of Science",
        description:
          "B.Sc. Renewable Energy is a 3-year undergraduate program with the main motive of the program is to provide students a brief knowledge about the usage and implementation of renewable sources to encounter the rapid demand of the generation.",
      },
    ],
  };
  const HealthData = {
    title: "Mental Health Professional",
    desc: "With the rising importance of mental health in the world, just being physically fit is not enough. A Mental Health Professional comes in here to diagnose, understand, and treat people with mental disorders. They help them cope with their condition, and have a good quality of life.",
    name1: "Psychiatrist",
    name2: "Psychologist",
    name3: "Social Workers",
    itemData: [
      {
        titlename: "Bachelor of Arts",
        description:
          "A Bachelor of Arts degree is among the most common courses opted for to become a Mental Health Professional. With specialisations in Psychology, Sociology, and even English, you can opt to pursue a career in Mental Health as a counsellor or in social work.",
      },
      {
        titlename: "Bachelor of Science",
        description:
          "If you aim to be a Psychologist or a Psychiatrist, a Bachelor of Science degree would be the ideal choice. It offers a research-based curriculum and the theoretical approach offers in-depth understanding of psychology and psychiatry, offering a stepping stone for future research.",
      },
    ],
  };
  const InfluencerData = {
    title: "Social Media Influencer",
    desc: "Social media is filled with influencers. But what are they? Social Media Influencer is a person with a large audience of online followers who has established credibility in a specific area or industry. As per data, influencer marketing is 18% more effective in driving sales than other channels.",
    name1: "Social Media Manager",
    name2: "YouTuber",
    name3: "Social Media Marketer",
    itemData: [
      {
        titlename: "Bachelor of Arts",
        description:
          "A Bachelor of Arts in Media and Communication, Journalism, English or Psychology could nurture your skills to becoming a Social Media Influencer. With exposure to how the media industry is, and how people behave and think, you can understand in detail how you can use social media to market yourself or your business.",
      },
    ],
  };
  const cyberlawData = {
    title: "Cyber Law",
    desc: "Data privacy is among the most important issues that we are facing today. Cyber Law also called IT Law is the law regarding Information-technology that concerns computers and the internet. As a Cyber Lawyer, you will be responsible for safeguarding information and crimes against it.",
    name1: "Cyber Lawyer",
    name2: "Lecturer of Cyber Law",
    name3: "In-House Counsel",
    itemData: [
      {
        titlename: "LLB",
        description:
          "The Bachelors in Law degree is the base course that you must complete to pursue a career in Cyber Law. You can opt for a LLM in Cyber Law that will provide you with the knowledge and skills to be a Cyber Lawyer",
      },
      {
        titlename: "Certificate Courses",
        description:
          "Many colleges and universities in India and abroad offer Diploma, Post Graduate Diploma, and Certificate courses in Cyber Law. Students can opt for these certificate courses after completing the Bachelor's degree in law or after 12th Grade.",
      },
    ],
  };
  const SustainableData = {
    title: "Sustainable Architecture",
    desc: "As we over utilize our non-renewable resources, Sustainable Architecture, as a career offers a chance to change. Through moderating the use of materials, energy, and development space, sustainable architects offer a solution to develop, and take care of the environment.",
    name1: "Project Architect",
    name2: "Project Designer",
    name3: "Preservation Architect",
    itemData: [
      {
        titlename: "Bachelors in Architecture",
        description:
          "The five-year Bachelors in Architecture (B.Arch) course is a blend of theoretical and practical knowledge for students. The curriculum covers the art of planning, designing and constructing physical structures of various kinds.",
      },
    ],
  };
  return (
    <div style={{ marginTop: "100px" }}>
      <div className="career-trendz-tab">
        <div
          className="page-section d-flex fade show"
          id="Last10Years"
          role="tabpanel"
          ref={pageRef}
          aria-labelledby="Last10Years-tab"
        >
          <CareerTrendsSidebar item={item} onItemClick={handleMenuItemClick} />

          <div
            className="rightPane"
            style={{ flexDirection: "column", width: "calc(100% - 270px)" }}
          >
            <div
              className="card-container"
              ref={ref1}
              style={{ width: "100%" }}
            >
              <CareerCard data={MarketingData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref2}
              style={{ width: "100%" }}
            >
              <CareerCard data={scienceData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref3}
              style={{ width: "100%" }}
            >
              <CareerCard data={IntelligenceData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref4}
              style={{ width: "100%" }}
            >
              <CareerCard data={ProjectData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref5}
              style={{ width: "100%" }}
            >
              <CareerCard data={UiUxData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref6}
              style={{ width: "100%" }}
            >
              <CareerCard data={RenewableData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref7}
              style={{ width: "100%" }}
            >
              <CareerCard data={HealthData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref8}
              style={{ width: "100%" }}
            >
              <CareerCard data={InfluencerData} />
            </div>
            <div
              className="card-container mt-3"
              ref={ref9}
              style={{ width: "100%" }}
            >
              <CareerCard data={cyberlawData} />
            </div>
            <div
              ref={ref10}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={SustainableData} />
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 invite_frnds max-170 height-45">
                  <span>Activity Completed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCareer;
