import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CareerProspects = () => {
  const [careerProspects, setCareerProspects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  // Fetch career prospects data from API when component mounts
  useEffect(() => {
    const fetchCareerProspects = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}career/fetchCareerProspects`,
          { career_id: id },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        // Check if the data is null or empty
        if (response.data.success && response.data.data) {
          if (response.data.data.length > 0) {
            setCareerProspects(response.data.data);
          } else {
            setCareerProspects([]); // Handle empty array case
          }
        } else {
          setCareerProspects(null); // Set null if the response data is null
        }
      } catch (error) {
        setError('Error fetching career prospects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCareerProspects();
  }, [id]);

  // Only render the section if careerProspects is not null
  if (careerProspects === null) {
    return null; // Do not render anything if no data is available
  }

  return (
    <>
      <section className="container-fluid py-5 position-relative z-0" id="careerProspects">
        <div className="max-1140 mx-auto">
          <h1 className="font-32 font-900 mb-3 text-dark-blue">Career Prospects</h1>
          <div className="basic-requirements">
            <div className="accordion" id="accordionExample">
              {loading ? (
                <p>Loading career prospects...</p>
              ) : error ? (
                <p>{error}</p>
              ) : careerProspects.length > 0 ? (
                careerProspects.map((prospect) => (
                  <div className="accordion-item" key={prospect.id}>
                    <h2 className="accordion-header" id={`heading${prospect.id}`}>
                      <button
                        className="accordion-button font-18 font-500 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${prospect.id}`}
                        aria-expanded="false"
                        aria-controls={`collapse${prospect.id}`}
                      >
                        {prospect.heading}
                      </button>
                    </h2>
                    <div
                      id={`collapse${prospect.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${prospect.id}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">{prospect.description}</div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No career prospects available at the moment.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerProspects;
