import React, { useEffect, useRef } from "react";
import CareerTrendsSidebar from "./career-trends-sidebar";
import CareerCard from "./career-card";

const EverGreen = () => {
  const pageRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);

  const handleMenuItemClick = (index) => {
    switch (index) {
      case 0:
        ref1.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        ref2.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        ref3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        ref4.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        ref5.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 5:
        ref6.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 6:
        ref7.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 7:
        ref8.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 8:
        ref9.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 9:
        ref10.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset || window.scrollY;
    const elementOffsetTop = pageRef.current?.offsetTop - 30;
    const elementOffsetHeight = pageRef.current?.offsetHeight + 30;
    const ptabs = pageRef.current?.querySelector(".nav-tabs");
    const ptabsLink = ptabs?.querySelectorAll(".nav-link");
    const otabs = pageRef.current?.querySelectorAll(".card-container");
    for (let i = 0; i < otabs?.length; i++) {
      let ot = otabs[i].offsetTop - ptabs?.offsetHeight * 2;
      if (
        position >= ot &&
        position < otabs[i + 1]?.offsetTop - ptabs?.offsetHeight * 2
      ) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position >= ot && i === otabs?.length - 1) {
        ptabsLink[i]?.classList?.add("active");
      } else if (position < otabs[1].offsetTop - ptabs?.offsetHeight * 2) {
        ptabsLink[0]?.classList?.add("active");
        ptabsLink[1]?.classList?.remove("active");
      } else {
        ptabsLink[i]?.classList?.remove("active");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const item = [
    "Medicine",
    "Engineering",
    "Law",
    "Civil Services",
    "Business Management",
    "Banking and Insurance",
    "Architecture",
    "Pilot",
    "Hotel Management",
    "Fine Arts",
  ];

  const medicineData = {
    title: "Medicine",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Among the noblest professions of all, a career as a doctor is most chosen by students in India aspiring to be in the medical field. There is immense scope in this field especially after the COVID-19 pandemic, with doctors, nurses, surgeons, and other
        medical professionals being in demand all over the world.</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">In order to pursue a career in medicine, an extensive period of study is required with a 5 and half-year MBBS, and more with Masters. However, on the bright side, you get to save lives!</p>`,
    name1: "Doctor",
    name2: "Surgeon",
    name3: "Nurse",
    itemData: [
      {
        titlename: "MBBS",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
          id="isPasted">An MBBS degree is a must to get into Medicine. It is a professional undergrad degree in the surgical and medical field, offered by colleges and universities. However, these two degrees - Bachelor of Medicine and Bachelor of Surgery are merged in one discipline and presented together in practice. A 5 and a half year MBBS degree includes an internship. To appear for MBBS, you must have a good grasp of Physics, Chemistry, Biology, and English.</span></p>`,
      },
      {
        titlename: "BDS",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
          id="isPasted">A Bachelor of Dental Surgery is an undergraduate level degree programme in the field of oral healthcare. In this course, you get theoretical and practical knowledge on tooth extraction, tooth fillings, scaling, and smile design through various procedures of tooth alignment. BDS graduates can also perform anaesthesia to avoid extreme pain while extraction of teeth.</span></p>`,
      },
    ],
  };

  const engineeringData = {
    title: "Engineering",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Another noble pursuit, without Engineers, the world wouldn&rsquo;t develop as much as it is everyday. Engineering has a wide range of scope when it comes to pursuing it as a career with its divisions that include Computer Science, Electronics, Civil,
        Mechanical, etc.</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">On the road to becoming an engineer, the first pitstop would be getting a Bachelor&#39;s degree for which you would need to write a certain set of exams that includes AIEEE, JEE, etc. An engineer must have strong knowledge of the sciences and language
        skills to thrive.</p>`,
    name1: "Electrical Engineer",
    name2: "Mechanical Engineer",
    name3: "Civil Engineer",
    itemData: [
      {
        titlename: "Bachelors of Science",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
          id="isPasted">A Bachelors of Science is the most popular and commonly opted degree course for students who wish to pursue a career in engineering. Even in a B.Sc. degree, there are certain specialisations that you can opt for to focus on your field of study.</span></p>
      <p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">With equal importance on various subjects such as Physics, Chemistry, Mathematics, and Biology, a B.Sc. degree provides a foundation and offers you a stepping stone into the world of engineering.</span></p>`,
      },
    ],
  };

  const lawData = {
    title: "Law",
    desc: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
    id="isPasted">Law is a discipline and profession concerned with the customs, practices, and rules of conduct of a community that are recognized as binding by the community. A lawyer is a professional who is licensed to prepare, manage, and either prosecute or defend a court action. As a lawyer, you work as an agent for your fellow citizens and offer advice on other legal matters.</span></p>`,
    name1: "Lawyer",
    name2: "Professor",
    name3: "Paralegal",
    itemData: [
      {
        titlename: "BA LLB",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Among the courses you can take up to make a career in Law, a Bachelor of Arts and Bachelor of Legislative Law (BA LLB) is the most common.</span></p>
    <p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">A BA LLB is a bachelor&rsquo;s level Administrative Law degree programme. Encompassing as an interdisciplinary study of Arts and Law, the BA LLB is offered as a five-year integrated programme. In this course you get exposed to Arts subjects such as Sociology, History, etc. along with the study of law and legislature.</span></p>`,
      },
      {
        titlename: "B.Com LLB",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">B.Com. LLB combines both B.Com. and LLB academic programmes. Individuals who have successfully completed an undergraduate degree programme are eligible for enrolling in an LLB course. As B.Com LLB graduates, students can work as corporate lawyers, law reporters, legal advisors, law officers, and more. Best of all, students can complete two undergraduate degrees in a period of five years.</span></p>`,
      },
    ],
  };

  const civilServices = {
    title: "Civil Services",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">The Union Public Service Commission has defined Civil Services as a job position in any or all state and central government offices. This not only includes prominent Civil Service bodies such as IAS or IPS, but also Indian Railways, Customs, etc.</p>
    <p
    style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Civil Services are among the highly sought after professions in India today due to their scope, growth, salary, and job satisfaction. Civil Service professionals are not politically or judicially affiliated, hence making them upholders civic duties in
        citizens.</p>`,
    name1: "IAS",
    name2: "IPS",
    name3: "Indian Railway Personnel",
    itemData: [
      {
        titlename: "Bachelors of Arts",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">To pursue a career in Civil Services, it is important to have analytical skills, but also theoretical. When you deal with the workings of the public service, each code, law, section, sub-section is critical to its functioning. A Bachelor of Arts degree in Public Administration, History, or Political Science can pave your way to becoming a part of the Civil Services in India.</span></p>`,
      },
      {
        titlename: "Bachelors in Technology",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">One of the most sought after courses for a career in Civil Services is the Bachelor in Technology. A B.Tech. degree in a specialisation such as Civil Engineering may open a number of doors when you pursue a career in Civil Services. A B.Tech. degree offers an in-depth understanding and knowledge of the workings in different fields of Civil Services such as civil engineering, Mechanical engineering, Electrical engineering, and more!</span></p>`,
      },
    ],
  };

  const businessData = {
    title: "Business Management",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Often confused with Business Administration, Business Management revolves around organising and managing a company&#39;s resources, making it a people-centric career.</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">As a Business Manager you would be managing a team, along with undertaking and executing various projects within your team. Skills such as team management, leadership, motivation, and communication are key to be successful as a Business Manager.</p>`,
    name1: "Project Manager",
    name2: "Business Analyst",
    itemData: [
      {
        titlename: "BBM",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Are you ready to take on the business world? You will need a BBM degree! A Bachelor of Business Management or BBM degree is an undergraduate program of 3 years and is specially curated to help students hone the necessary skills to lead in the corporate and business world.</span></p>
    <p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">A BBM course offers in-depth knowledge and hands on experience in 4 majors that are -</span></p>`,
        tag: [
          "Marketing Management",
          "Financial Management",
          "Operational Management",
          "Human Resource Development Management",
        ],
      },
      {
        titlename: "BBA",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Apart from the traditional BBM degree, many business management aspirants opt for a BBA or a Bachelors in Business Administration degree. Even if Business Management and Business Administration are not the same, there are a number of overlaps.</span></p>
    <p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">With a BBA degree, and what you will learn in 3 years, you can apply that in your work and make your way to get into Business Management from Business Administration. Many colleges all over India and abroad offer a BBA degree, compared to a BBM degree.</span></p>`,
      },
    ],
  };

  const bankingData = {
    title: "Banking and Insurance",
    desc: `<p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
    id="isPasted">Every time someone says that they are a Banker, the first thought is - &lsquo;Money man&rsquo;</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">From safeguarding money, to loan qualification and disbursement, providing payment services, facilitating the smooth financial transitioning, and offering multiple financial instruments, a Banker does it all!</p>
    <p style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 1.5rem !important; margin-left: 0px; font-family: Jost, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">Being a banker doesn&rsquo;t mean you only have to be good with money, but also with Mathematics, problem solving, and analytics.</p>`,
    name1: "Commercial bankers",
    name2: "Investment Bankers",
    name3: "Retail Bankers",
    itemData: [
      {
        titlename: "Bachelors in Commerce",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Among the many courses available to pursue a career in Banking, a Bachelor&rsquo;s degree in Commerce is the most commonly chosen. By choosing to pursue a Bachelors in Commerce degree, you can get exposed to the foundations of Banking and Finance that are crucial to excel as a Banker.</span></p>`,
      },
      {
        titlename: "Bachelors in Banking and Finance",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">The most opted for degree to pursue a career in Banking is the Bachelor&rsquo;s in Banking and Finance. This course and its module is specifically curated for banking aspirants, to help them with the knowledge, foundations, and provide them with a practical learning experience of how bankers do their job and how well they must do it.</span></p>`,
      },
    ],
  };

  const architecture = {
    title: "Architecture",
    desc: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
    id="isPasted">The world can never have enough Architects. This profession is responsible for all the buildings and complexes that we see around us. Architects are the professionals who visualise, plan, and execute the building process of various structures. This is a popular career and will always be in demand due to the real-estate sector.</span></p>`,
    name1: "Architectural Engineer",
    name2: "Civil Engineer",
    name3: "Architectural Manager",
    itemData: [
      {
        titlename: "Bachelors in Architecture",
        description: `<p><strong><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;" id="isPasted">The most common course that is opted to pursue a career in Architecture is the Bachelors of Architecture. A B.Arch. degree is a 5-year extensive program that offers everything that an individual needs to excel in a career in Architecture. From building the foundations to understanding different job roles and functions, to working for a firm, the course offers it all.</span></strong></p>`,
      },
    ],
  };
  const pilot = {
    title: "Pilot",
    desc: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
    id="isPasted">From flying a commercial plane, to facing combat in a fighter jet, if you belong in the sky, a career as a pilot could be for you! However, pursuing a career as a pilot is not easy. It requires not only technical expertise and knowledge but also mental and physical tenacity to not only fly the plane, but also ensure safety of the passengers or co-pilots.</span></p>`,
    name1: "Commercial Pilot",
    name2: "Armed Forces Pilot",
    name3: "Aerosports Pilot",
    itemData: [
      {
        titlename: "Bachelor of Science",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Most popular amongst the flying enthusiasts, a Bachelor of Science degree in Aviation provides an overall knowledge and understanding about all aspects of flying a plane. However, just education is not enough. To become a pilot you must have a certain number of flying hours to qualify as one and get a licence to fly commercially.</span></p>`,
      },
    ],
  };
  const hotel = {
    title: "Hotel Management",
    desc: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
    id="isPasted">Hotel Management is an evergreen career. Why? The Hospitality industry is one which is always in demand, no matter which part of the world you go to. From hotels, restaurants, resorts, and curated experiences, the hotel industry has evolved with time. Hotel Management as a career has evolved too. The industry is highly competitive and demands a lot, however, offers a lot too!</span></p>`,
    name1: "Restaurant Manager",
    name2: "Floor Manager",
    name3: "Head Chef",
    itemData: [
      {
        titlename: "Bachelors in Hotel Management",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Among the many courses that you can opt for to pursue Hotel Management, a Bachelors in Hotel Management or BHM is the most popular. Most hotel management colleges offer a BHM degree of 4 years where you get hands-on experience and theoretical knowledge to excel in the field and get opportunities to work all over the world.</span></p>`,
      },
      {
        titlename: "BBA in Hospitality Management",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">Apart from a BHM, another highly regarded course for a career in Hotel Management is a BBA in Hospitality Management. In this course, you get exposed to the business side of hospitality. From inventory management to understanding how revenue is generated, all of this would be covered in this course.</span></p>`,
      },
    ],
  };

  const arts = {
    title: "Fine Arts",
    desc: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
    id="isPasted">From Painting to Poetry, the five main fine arts were painting, sculpture, architecture, music, and poetry, with performing arts including theatre and dance. If you are passionate about all things art, a degree in Fine Arts would be the right choice to pave a career path in this field. A career in Fine Art never goes out of demand as you are protecting history and preserving it for the future!</span></p>`,
    name1: "Painter",
    name2: "Sculptor",
    name3: "Writer",
    itemData: [
      {
        titlename: "Bachelor in Fine Arts",
        description: `<p><span style="color: rgb(33, 37, 41); font-family: Jost, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;"
        id="isPasted">To pursue a career in Fine Arts, there are a number of courses that one can opt for, and it doesn&rsquo;t necessarily be of an Art background. However, a Bachelor of Fine Arts degree is among the most popular courses opted for to pursue this career. The course offers an in-depth understanding of all the different art, its depiction, and its relevance over a period of 3 years.</span></p>`,
      },
    ],
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <div className="career-trendz-tab">
        <div
          className="page-section d-flex fade show"
          id="Last10Years"
          role="tabpanel"
          ref={pageRef}
          aria-labelledby="Last10Years-tab"
        >
          <CareerTrendsSidebar
            onItemClick={handleMenuItemClick}
            item={item}
          />
          <div
            className="rightPane"
            style={{ flexDirection: "column", width: "calc(100% - 270px)" }}
          >
            <div
              ref={ref1}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={medicineData} />
            </div>
            <div
              ref={ref2}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={engineeringData} />
            </div>
            <div
              ref={ref3}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={lawData} />
            </div>
            <div
              ref={ref4}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={civilServices} />
            </div>
            <div
              ref={ref5}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={businessData} />
            </div>
            <div
              ref={ref6}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={bankingData} />
            </div>
            <div
              ref={ref7}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={architecture} />
            </div>
            <div
              ref={ref8}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={pilot} />
            </div>
            <div
              ref={ref9}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={hotel} />
            </div>

            <div
              ref={ref10}
              className="card-container mt-3"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <CareerCard data={arts} />

              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-completed radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 invite_frnds max-170 height-45">
                  <span>Activity Completed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EverGreen;
