import React, { useState } from "react";
import CollegeFilterBox from "../../college-components/college-filterbox";
import TopCollegeAccordion from "../../college-components/topcollege-accordion";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";

const TopColleges = ({
  collegeData,
  courseData,
  subCourseData,
  countryData,
  cityData,
  loading,
  stateData,
  // testData,
  setCourseData,
  setSubCourseData,
  setCountryData,
  setCityData,
  setStateData,
  // setTestData,
  filterData,
  setFilterData,
  searchCityQuery,
  setSearchCityQuery,
  searchStateQuery,
  setSearchStateQuery,
  searchCountryQuery,
  setSearchCountryQuery,
  searchCourseQuery,
  setSearchCourseQuery,
  searchSubCourseQuery,
  setSearchSubCourseQuery,
  filteredData,
  searchCollegeQuery,
  setSearchCollegeQuery,
}) => {
  return (
    <div className="">
      <div className="row m-0 pt-4 mt-2 JobInternshipListing">
        <div className="col-md-12 p-0">
          <p className="font-16 mb-4">
            Please choose country and department to take a look at top ranking
            colleges/universities in India, USA, UK and other countries of the
            world.
          </p>
        </div>
        <div className="col-md-auto ji_filtersec ps-0 aos-init aos-animate">
          <div className="mobileFilter mobileonly text-end">
            <button
              className="btn-filter btn-unscroll font-18 font-500"
              type="button"
            >
              <span className="icon-filter"></span>Filters
            </button>
          </div>
          <div
            className="filters leftPaneFilter collapse show"
            id="filter_collapse"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-filter btn-unscroll mobileonly"
                  type="button"
                >
                  Filters
                </button>
                <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
                  <span className="icon-filter"></span>Filters
                </h3>
              </div>
              <span
                className="font-14 font-500 text-burgandi text-decoration-underline cursor-pointer"
                onClick={() => {
                  setCourseData((pre) =>
                    pre.map((v) => ({ ...v, isChecked: false }))
                  );

                  setSubCourseData((pre) =>
                    pre.map((v) => ({ ...v, isChecked: false }))
                  );

                  setCountryData((pre) =>
                    pre.map((v) => ({ ...v, isChecked: false }))
                  );

                  setCityData((pre) =>
                    pre.map((v) => ({ ...v, isChecked: false }))
                  );

                  setStateData((pre) =>
                    pre.map((v) => ({ ...v, isChecked: false }))
                  );
                  setSearchCourseQuery("");
                  setSearchSubCourseQuery("");
                  setSearchCityQuery("");
                  setSearchStateQuery("");
                  setSearchCollegeQuery("");
                  setFilterData({
                    offset: 0,
                    limit: 10,
                    course_id: "",
                    city_id: "",
                    state_id: "",
                    country_id: "",
                    has_ranking: 0,
                  });
                }}
              >
                Clear
              </span>
            </div>

            <CollegeFilterBox
              courseData={courseData}
              setCourseData={setCourseData}
              subCourseData={subCourseData}
              setSubCourseData={setSubCourseData}
              countryData={countryData}
              setCountryData={setCountryData}
              cityData={cityData}
              setCityData={setCityData}
              stateData={stateData}
              setStateData={setStateData}
              // testData={testData}
              // setTestData={setTestData}
              filterData={filterData}
              setFilterData={setFilterData}
              searchCityQuery={searchCityQuery}
              setSearchCityQuery={setSearchCityQuery}
              searchStateQuery={searchStateQuery}
              setSearchStateQuery={setSearchStateQuery}
              searchCountryQuery={searchCountryQuery}
              setSearchCountryQuery={setSearchCountryQuery}
              searchCourseQuery={searchCourseQuery}
              setSearchCourseQuery={setSearchCourseQuery}
              searchSubCourseQuery={searchSubCourseQuery}
              setSearchSubCourseQuery={setSearchSubCourseQuery}
            />
          </div>
        </div>

        <div className="flex-1 polarcontsec tab-content">
          <div className="ActiveListings_present">
            <div className="d-flex justify-content-between flex-wrap align-items-end my-2 w-100">
              <div className="d-flex flex-wrap align-items-center">
                {!collegeData?.data?.length ? (
                  ""
                ) : (
                  <h3 className="font-18 font-600 mb-1">
                    {collegeData?.total_count} Colleges Found
                  </h3>
                )}
              </div>
              <div className="filterSearchblk p-0 w-50 d-flex align-items-center filterSearch position-relative">
                {/* <form className="d-flex align-items-center filterSearch position-relative"> */}
                <input
                  className="form-control flex-1 font-14 font-500 w-100 mt-1"
                  style={{ backgroundColor: "white" }}
                  type="search"
                  placeholder="Search"
                  value={searchCollegeQuery}
                  onChange={(e) => setSearchCollegeQuery(e.target.value)}
                />
                <button
                  className="btn icon-search"
                  style={{ top: "11px", right: "19px" }}
                  type="button"
                ></button>
                {/* </form> */}
              </div>
            </div>
            <div className="row mt-0 college-details">
              {filteredData?.length ? (
                <>
                  {filteredData?.map((x, i) => {
                    return (
                      <div className="col-md-12" key={i}>
                        <div className="">
                          <TopCollegeAccordion data={x} />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>{loading ? "" : <h4 className=""> No Records Found.</h4>}</>
              )}
            </div>
            {loading && (
              <a className="load_more_btn">
                <img src="/assets/images/load_more_icon.jpg" />
                Load More
              </a>
            )}
            {!collegeData?.data?.length ? (
              ""
            ) : (
              <div className="pagination-div">
                <ReactPaginate
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  onPageChange={(event) => {
                    setFilterData({
                      ...filterData,
                      offset: parseInt(event.selected) * 10,
                    });
                  }}
                  breakLabel="..."
                  pageCount={Math.ceil(collegeData.total_pages)}
                  previousLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "36px" }}
                    >
                      <AiFillLeftCircle />
                    </IconContext.Provider>
                  }
                  nextLabel={
                    <IconContext.Provider
                      value={{ color: "#B8C1CC", size: "36px" }}
                    >
                      <AiFillRightCircle />
                    </IconContext.Provider>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopColleges;
