import React from "react";

const CareerTrendsSidebar = ({onItemClick,item }) => {
  return (
    <div className="page-leftPane">
      <div className="sticky-top">
        <ul className="nav nav-tabs mb-3 nav-pills-hor py-3 ">
          {item?.map((a, index) => {
            return (
              <li className="nav-item" key={index}>
                <span
                  className="nav-link font-14 font-500 cursor-pointer"
                  // aria-expanded={
                  //   parseInt(isActive) === parseInt(index) ? true : false
                  // }
                  onClick={() => {
                    onItemClick(index);
                  }}
                >
                  {a}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CareerTrendsSidebar;
