import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getAllProductInventory,
  getAllProducts,
} from "../../../services/products/product-service";
import AddProduct from "../../../Modals/add-product";

const ProductInventory = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(true);
  const [orders, setOrders] = useState([]);
  const [productDetail, setProductDetail] = useState({});
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [productsList, setProductsList] = useState([]);
  const [studentSearchQuery, setStudentSearchQuery] = useState("");

  const getUserOrders = async () => {
    const res = await getAllProductInventory({ search: studentSearchQuery });
    if (res?.data?.meta?.status) {
      setOrders(res?.data?.data?.data);
    }
  };

  const getProducts = async () => {
    const res = await getAllProducts();
    if (res?.data?.meta?.status) {
      setProductsList(res?.data?.data?.records);
    }
  };

  useEffect(() => {
    getUserOrders();
    getProducts();
  }, []);
  useEffect(() => {
    getUserOrders();
  }, [studentSearchQuery]);
  const addtoCart = () => {
    let cart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
    if (cart && cart.length > 0) {
      if (cart.findIndex((x) => x.id === productDetail.id) > -1) {
        cart[cart.findIndex((x) => x.id === productDetail.id)].quantity =
          quantity;
      } else {
        cart.push({
          product: productDetail,
          quantity: quantity,
          total:
            (parseInt(productDetail?.price) -
              parseInt(
                productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price
              ) +
              (productDetail.tax_percentage
                ? ((parseInt(productDetail?.price) -
                    (productDetail.discount_type === "flat"
                      ? productDetail.discount
                      : (productDetail.discount / 100) * productDetail.price)) *
                    productDetail.tax_percentage) /
                  100
                : 0)) *
            quantity,
          tax: productDetail.tax_percentage
            ? (((parseInt(productDetail?.price) -
                (productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price)) *
                productDetail.tax_percentage) /
                100) *
              quantity
            : 0,
        });
      }
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      cart = [];
      cart.push({
        product: productDetail,
        quantity: quantity,
        total:
          (parseInt(productDetail?.price) -
            parseInt(
              productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price
            ) +
            (productDetail.tax_percentage
              ? ((parseInt(productDetail?.price) -
                  (productDetail.discount_type === "flat"
                    ? productDetail.discount
                    : (productDetail.discount / 100) * productDetail.price)) *
                  productDetail.tax_percentage) /
                100
              : 0)) *
          quantity,
        tax: productDetail.tax_percentage
          ? (((parseInt(productDetail?.price) -
              (productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price)) *
              productDetail.tax_percentage) /
              100) *
            quantity
          : 0,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    setShowAddProduct(false);
    navigate("/user/product-checkout/" + productDetail.id);
  };

  return (
    <div>
      <div className="pt-4 mt-2">
        <div className="m-w-90 d-flex flex-wrap">
          <div className="headerTitle w-100">
            <h3 className="font-35 font-600 mb-0 block-title">
              All Products Inventory
            </h3>
          </div>
        </div>

        <div className="row m-0 pt-4 pb-5 JobInternshipListing">
          <div
            className="flex-1 polarcontsec tab-content p-0"
            id="myTabContent"
            data-aos="fade-left"
            data-aos-delay="1200"
          >
            <div
              className="tab-pane fade show active"
              id="applications"
              role="tabpanel"
              aria-labelledby="applications-tab"
            >
              <div className="ActiveListings_present">
                <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                  <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                    <button
                      className="btn-grpfiter me-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => setFilters(!filters)}
                    >
                      <i className="icon-filter font-13 me-2"></i>
                      Filters
                    </button>

                    <div className="search alumniSearch m-0 open position-static flex-1">
                      <form className="form-inline d-flex flex-wrap justify-content-between d-block">
                        <input
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={studentSearchQuery}
                          onChange={(e) =>
                            setStudentSearchQuery(e.target.value)
                          }
                          className="form-control flex-1 font-13 font-500 text-truncate"
                        />
                        <button
                          className="btn btn-search icon icon-search"
                          type="submit"
                        ></button>
                      </form>
                      <span className="btn-search-toggle icon-search"></span>
                    </div>
                  </div>
                </div>
                {/* {filters && (
                  <div className="fitergroup mb-3">
                    <div
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body p-4">
                        <form className="row g-3">
                          <div className="col-md-4">
                            <select
                              className="form-select"
                              name="complete Class 12"
                              aria-label="Default select example"
                            >
                              <option value="">Product</option>
                            </select>
                          </div>
                          <div className="col-md-3">
                            <select
                              className="form-select"
                              name="complete Class 12"
                              aria-label="Default select example"
                            >
                              <option value="" selected="selected">
                                Consumption Count
                              </option>
                              <option value="Completed">Completed</option>
                              <option value="Pending">Pending</option>
                              <option value="Due">Due</option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )} */}
                <div className="table-responsive attendee_dtl">
                  <table className="table" style={{ minWidth: "1010px" }}>
                    <thead>
                      <tr>
                        <th width="250">
                          <div className="d-flex flex-wrap">
                            <input
                              type="checkbox"
                              value=""
                              id="Antony"
                              required=""
                              className="form-check-input me-2 mt-0"
                            />
                            Product
                            <i className="icon-sort-arrow d-flex flex-column ms-2">
                              <i className="icon-invert-down-arrow"></i>
                              <i className="icon-down-arrow-invert"></i>
                            </i>
                          </div>
                        </th>
                        <th>
                          <a className="labelItem justify-content-center">
                            Purchase
                          </a>
                        </th>
                        <th>
                          <a className="labelItem justify-content-center">
                            Assigned
                            <i className="icon-sort-arrow d-flex flex-column ms-1">
                              <i className="icon-invert-down-arrow"></i>
                              <i className="icon-down-arrow-invert"></i>
                            </i>
                          </a>
                        </th>
                        <th className="text-center">
                          <a className="labelItem text-center justify-content-center">
                            Consumed
                          </a>
                        </th>

                        <th className="text-end" width="250">
                          <a className="labelItem justify-content-end">
                            Action
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="d-flex align-items-center checkoption">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  value=""
                                  id="Antony"
                                  required=""
                                />
                                <a className="tablink">
                                  <span
                                    title=""
                                    data-bs-original-title="Curriculum Evaluator"
                                    className="singlelinename font-16 lineHeight-16 w-170"
                                  >
                                    {x.product_name}
                                  </span>
                                </a>
                              </div>
                            </td>
                            <td className="text-center">
                              <a
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/user/inventory-manage/" + x.product_id
                                  )
                                }
                              >
                                {" "}
                                {x.total_purchase}
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/user/inventory-manage/" + x.product_id
                                  )
                                }
                              >
                                {x.assigned_count}
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/user/inventory-manage/" + x.product_id
                                  )
                                }
                              >
                                {x.consumed_count}
                              </a>
                            </td>

                            <td className="text-center">
                              <div className="d-flex flex-wrap mailSetting">
                                <span
                                  className="btn-border-blue cursor-pointer W-110 height-40 me-2"
                                  onClick={() =>
                                    navigate(
                                      "/user/inventory-manage/" + x.product_id
                                    )
                                  }
                                >
                                  <span className="cursor-pointer">Manage</span>
                                </span>
                                <a
                                  className="btn-blue text-white cursor-pointer W-120 height-40"
                                  onClick={() => {
                                    let t = productsList.find(
                                      (z) => z.id == x.product_id
                                    );
                                    setProductDetail(t);
                                    setShowAddProduct(true);
                                  }}
                                >
                                  Add Units
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {productDetail && (
          <AddProduct
            setShowAddProduct={setShowAddProduct}
            showAddProduct={showAddProduct}
            product={productDetail}
            quantity={quantity}
            setQuantity={setQuantity}
            addtoCart={addtoCart}
          />
        )}
      </div>
    </div>
  );
};

export default ProductInventory;
