import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPreferenceDetail } from "../../../../services/user-basicinfo/user-basicinfo";

const Pereference = ({ activeKey }) => {
  const [preference, setPreference] = useState();

  const navigate = useNavigate();
  const getPreference = async () => {
    await getPreferenceDetail().then((res) => {
      if (res?.data?.data) {
        setPreference(res.data?.data?.additional);
      }
    });
  };
  useEffect(() => {
    if (activeKey === "Preferences") {
      getPreference();
    }
  }, [activeKey]);

  return (
    <div className="InterestsPreferences p-4 pb-4 position-relative">
      <a
        onClick={() => navigate("/user/edit-preferencesinfo")}
        className="btn-edit-pop ms-4 position-absolute top-30 right-20 text-decoration-none cursor-pointer"
      >
        <i className="icon-edit-pencil text-gray-64"></i>
      </a>
      <div className="col-12 mb-4">
        <h2 className="font-24 border-bottom pb-3">Preferences</h2>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Preferred time to get calls from Univariety
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">9:30 AM to 6:30 PM</p>
          <div className="days d-flex flex-wrap mt-2">
            <div className="white-block">Monday</div>
            <div className="white-block">Tuesday</div>
            <div className="white-block">Wednesday</div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Do you wish to connect with university representatives on the
            platform?
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {preference?.interested_univ_rep === true ? "Yes" : "No"}
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Interested in upskilling?
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            {preference?.interested_upskills === true ? "Yes" : "No"}
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Updates from Univariety
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">{preference?.updates_interval}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Additional Email
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">
            <a className="innerpageLink">{preference?.additional_email}</a>
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
          <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
            Additional Contact Number
          </label>
          <span>:</span>
        </div>
        <div className="col-sm-7">
          <p className="m-0 font-16 font-500">{preference?.additional_phone}</p>
        </div>
      </div>
    </div>
  );
};

export default Pereference;
