import apiHeader from "../apiHeader";
import api from "../axiosService";
import { convertObjToQueryParams } from "../commonHelper";

export const getAllEvents = async (payload) => {
  const res = await api.get(
    "events/eventList" + convertObjToQueryParams(payload),
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const filterEvents = async (payload) => {
  const res = await api.get(
    "events/filterListEvents" + convertObjToQueryParams(payload),
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const filterEventsThemes = async (payload) => {
  const res = await api.get(
    "events/eventThemes" + convertObjToQueryParams(payload),
    {
      headers: apiHeader(),
    }
  );
  return res;
};
