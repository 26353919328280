import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getSkillsDataId } from "../../../services/skills/skills";
import OwlCarousel from "react-owl-carousel";

const Communication = () => {
  const navigate = useNavigate();
  const [selectedSkillsData, setSelectedSkillsData] = useState(null);
  const now = 60;
  const { id } = useParams();
  const skillData = async () => {
    const res = await getSkillsDataId(id);
    if (res?.data?.meta?.status) {
      setSelectedSkillsData(res?.data?.data);
    }
  };
  useEffect(() => {
    if (id) {
      skillData();
    }
  }, [id]);

  const getSrc = (media) => {
    return media.url;
  };

  return (
    selectedSkillsData && (
      <div key={id} className="main flex-1 h-100">
        <div className="max-1140 mx-auto d-flex">
          <div className="flex-1">
            <div className="mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                      <h3
                        data-aos="fade-left"
                        data-aos-delay="600"
                        className="font-32 font-600 mb-1 block-title aos-init aos-animate"
                      >
                        {selectedSkillsData?.title}
                      </h3>
                      <p className="font-16 font-400 mb-2">
                        Life Skills for everyone, be it a student in school and
                        college or a working professional.
                      </p>
                    </div>

                    <div className="life-skills-course">
                      <div className="skill-range">
                        <h2 className="font-26 font-600 text-center mb-0 ">
                          {selectedSkillsData?.name}
                        </h2>

                        {/* <ProgressBar now={now} label={`${now}%`} /> */}
                      </div>
                      <div className="life-skills-course-detail">
                        <div className="max-930 mx-auto mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="font-16 line-height-26">
                                {selectedSkillsData?.description
                                  ? selectedSkillsData?.description
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <h3 className="font-18 font-600 mb-3 small-line pb-1">
                                Benefits of this skill
                              </h3>
                              <ul className="benefitsBox">
                                {selectedSkillsData?.benefits?.map((a, i) => {
                                  return (
                                    <li
                                      style={{
                                        backgroundColor: a.color
                                          ? a.color
                                          : "#70e070",
                                      }}
                                    >
                                      {a?.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <form
                          className="max-930 mx-auto"
                          action="student-life-skills-communication-questions.html"
                        >
                          <div className="container border-top skillBlock">
                            <div className="row">
                              <div className="col-md-6 mb-lg-0 mb-md-0 mb-4">
                                <h3 className="font-18 font-600 blocktitle mb-4 small-line">
                                  How will this lesson help you?
                                </h3>
                                <ul className="green-right mb-0">
                                  {selectedSkillsData?.help_description}
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <OwlCarousel
                                  className="owl-theme"
                                  items={1}
                                  slideBy={1}
                                  dots={true}
                                  loop
                                  margin={10}
                                  nav
                                >
                                  {selectedSkillsData.media?.map((a, i) => {
                                    console.log(a.media_type);
                                    // return a.media_type;ß
                                    return (
                                      a?.media_type === "video" && (
                                        <div className="item position-relative overflow-hidden" style={{padding:"28% 0",borderRadius:"10px"}} key={i}>
                                            <iframe
                                             className="position-absolute left-0 top-0 right-0 bottom-0"
                                             width="100%" height="100%" src={getSrc(a)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                                        </div>
                                      )
                                    );
                                  })}
                                </OwlCarousel>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center my-5">
                      <button
                        type="submit"
                        disabled={selectedSkillsData?.quizzes?.length === 0}
                        className="btn btn-completed radius height-45 max-200 d-block w-100 mx-3 font-14 font-500"
                        onClick={() => navigate("/student/skill-quiz/" + id)}
                      >
                        <span>Take Quiz</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Communication;
