import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";

const CollegeFilterBox = ({
  courseData,
  subCourseData,
  countryData,
  cityData,
  stateData,
  // testData,
  setCourseData,
  setSubCourseData,
  setCountryData,
  setCityData,
  setStateData,
  // setTestData,
  setFilterData,
  filterData,
  searchCityQuery,
  setSearchCityQuery,
  searchStateQuery,
  setSearchStateQuery,
  searchCountryQuery,
  setSearchCountryQuery,
  searchCourseQuery,
  setSearchCourseQuery,
  searchSubCourseQuery,
  setSearchSubCourseQuery,
}) => {
  return (
    <div className="college-filter-box" style={{ overflow: "scroll" }}>
      <Accordion alwaysOpen defaultActiveKey={["0"]} className="filterGroup">
        <Accordion.Item eventKey="0" className="fiterItem mb-3">
          <Accordion.Header className="filterHeader p-0">
            <div
              className="d-flex justify-content-between align-items-center position-relative"
              style={{ width: "100%" }}
            >
              <button
                className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                type="button"
              >
                <span className="font-18 font-600">Department</span>
                <span className="toggle"></span>
              </button>
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0" id="collapseDepartment">
            <div className="filterSearchblk mb-3">
              <form className="d-flex align-items-center filterSearch position-relative">
                <input
                  className="form-control flex-1 font-14 font-500 w-100"
                  type="search"
                  placeholder="Search"
                  value={searchCourseQuery}
                  onChange={(e) => setSearchCourseQuery(e.target.value)}
                />
                <button className="btn icon-search" type="submit"></button>
              </form>
            </div>
            <div className="card card-body mt-2">
              <div className="overflow-hidden">
                <div className="scrollMe">
                  {courseData?.map((data, i) => {
                    return (
                      <div
                        className="form-check d-flex flex-wrap"
                        key={i + data.name + data.isChecked.toString()}
                      >
                        <input
                          className={
                            "form-check-input me-2 " +
                            (data.isChecked && "input__checked")
                          }
                          type="radio"
                          name="course"
                          id={"Department_Architecture" + i}
                          onChange={(e) => {
                            setCourseData((pre) =>
                              pre.map((v) => ({
                                ...v,
                                isChecked: data.id == v.id,
                              }))
                            );
                            setFilterData({
                              ...filterData,
                              course_id: data.id,
                              sub_course_id: "",
                            });
                          }}
                        />

                        <label
                          htmlFor={"Department_Architecture" + i}
                          className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                        >
                          {data?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {filterData.course_id && (
          <Accordion.Item eventKey="1" className="fiterItem mb-3">
            <Accordion.Header className="filterHeader p-0">
              <div
                className="d-flex justify-content-between align-items-center position-relative"
                style={{ width: "100%" }}
              >
                <button
                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center cursor-pointer"
                  type="button"
                >
                  <span className="font-18 font-600">Course</span>
                  <span className="toggle"></span>
                </button>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0" id="collapseDepartment">
              <div className="filterSearchblk mb-3">
                <form className="d-flex align-items-center filterSearch position-relative">
                  <input
                    className="form-control flex-1 font-14 font-500 w-100"
                    type="search"
                    placeholder="Search"
                    value={searchSubCourseQuery}
                    onChange={(e) => setSearchSubCourseQuery(e.target.value)}
                  />
                  <button className="btn icon-search" type="submit"></button>
                </form>
              </div>
              <div className="card card-body mt-2">
                <div className="overflow-hidden">
                  <div className="scrollMe">
                    {subCourseData?.map((data, i) => {
                      return (
                        <div className="form-check d-flex flex-wrap" key={i}>
                          <input
                            className={
                              "form-check-input me-2 " +
                              (data.isChecked && "input__checked")
                            }
                            type="radio"
                            name="sub-course"
                            key={i + data.name}
                            id={"Sub-Department_Architecture" + i}
                            onChange={(e) => {
                              setSubCourseData((pre) =>
                                pre.map((v) => ({
                                  ...v,
                                  isChecked: data.id == v.id,
                                }))
                              );
                              setFilterData({
                                ...filterData,
                                sub_course_id: data.id,
                              });
                            }}
                          />
                          <label
                            htmlFor={"Sub-Department_Architecture" + i}
                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                          >
                            {data?.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="2" className="fiterItem mb-3">
          <Accordion.Header className="filterHeader p-0">
            <div
              className="d-flex justify-content-between align-items-center position-relative"
              style={{ width: "100%" }}
            >
              <button
                className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                type="button"
              >
                <span className="font-18 font-600">Country</span>
                <span className="toggle"></span>
              </button>
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0" id="collapseDepartment">
            <div className="filterSearchblk mb-3">
              <form className="d-flex align-items-center filterSearch position-relative">
                <input
                  className="form-control flex-1 font-14 font-500 w-100"
                  type="search"
                  placeholder="Search"
                  value={searchCountryQuery}
                  onChange={(e) => setSearchCountryQuery(e.target.value)}
                />
                <button className="btn icon-search" type="submit"></button>
              </form>
            </div>
            <div className="card card-body mt-2">
              <div className="overflow-hidden">
                <div className="scrollMe">
                  {countryData?.map((data, i) => {
                    return (
                      <div className="form-check d-flex flex-wrap" key={i}>
                        <input
                          className={
                            "form-check-input me-2 " +
                            (data.id == filterData.country_id &&
                              "input__checked")
                          }
                          type="radio"
                          name="country"
                          key={i + data.name}
                          id={"country_Architecture" + i}
                          checked={data.id == filterData.country_id}
                          onChange={(e) => {
                            setCountryData((pre) =>
                              pre.map((v) => ({
                                ...v,
                                isChecked: data.id == v.id,
                              }))
                            );
                            setFilterData({
                              ...filterData,
                              country_id: data.id,
                              city_id: "",
                              state_id: "",
                            });
                          }}
                        />
                        <label
                          htmlFor={"country_Architecture" + i}
                          className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                        >
                          {data?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {filterData.country_id && (
          <Accordion.Item eventKey="3" className="fiterItem mb-3">
            <Accordion.Header className="filterHeader p-0">
              <div
                className="d-flex justify-content-between align-items-center position-relative"
                style={{ width: "100%" }}
              >
                <button
                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                  type="button"
                >
                  <span className="font-18 font-600">State</span>
                  <span className="toggle"></span>
                </button>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0" id="collapseDepartment">
              <div className="filterSearchblk mb-3">
                <form className="d-flex align-items-center filterSearch position-relative">
                  <input
                    className="form-control flex-1 font-14 font-500 w-100"
                    type="search"
                    placeholder="Search"
                    value={searchStateQuery}
                    onChange={(e) => setSearchStateQuery(e.target.value)}
                  />
                  <button className="btn icon-search" type="submit"></button>
                </form>
              </div>
              <div className="card card-body mt-2">
                <div className="overflow-hidden">
                  <div className="scrollMe">
                    {stateData?.map((data, i) => {
                      return (
                        <div className="form-check d-flex flex-wrap" key={i}>
                          <input
                            className={
                              "form-check-input me-2 " +
                              (data.isChecked && "input__checked")
                            }
                            type="radio"
                            name="state"
                            key={i + data.name}
                            id={"state_Architecture" + i}
                            onChange={(e) => {
                              setStateData((pre) =>
                                pre.map((v) => ({
                                  ...v,
                                  isChecked: data.id == v.id,
                                }))
                              );
                              setFilterData({
                                ...filterData,
                                state_id: data.id,
                              });
                            }}
                          />
                          <label
                            htmlFor={"state_Architecture" + i}
                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                          >
                            {data?.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {filterData.state_id && (
          <Accordion.Item eventKey="4" className="fiterItem mb-3">
            <Accordion.Header className="filterHeader p-0">
              <div
                className="d-flex justify-content-between align-items-center position-relative"
                style={{ width: "100%" }}
              >
                <button
                  className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center"
                  type="button"
                >
                  <span className="font-18 font-600">City</span>
                  <span className="toggle"></span>
                </button>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0" id="collapseDepartment">
              <div className="filterSearchblk mb-3">
                <form className="d-flex align-items-center filterSearch position-relative">
                  <input
                    className="form-control flex-1 font-14 font-500 w-100"
                    type="search"
                    placeholder="Search"
                    value={searchCityQuery}
                    onChange={(e) => setSearchCityQuery(e.target.value)}
                  />
                  <button className="btn icon-search" type="submit"></button>
                </form>
              </div>
              <div className="card card-body mt-2">
                <div className="overflow-hidden">
                  <div className="scrollMe">
                    {cityData?.map((data, i) => {
                      return (
                        <div className="form-check d-flex flex-wrap" key={i}>
                          <input
                            className={
                              "form-check-input me-2 " +
                              (data.isChecked && "input__checked")
                            }
                            type="radio"
                            name="city"
                            key={i + data.name}
                            id={"city_Architecture" + i}
                            onChange={(e) => {
                              setCityData((pre) =>
                                pre.map((v) => ({
                                  ...v,
                                  isChecked: data.id == v.id,
                                }))
                              );
                              setFilterData({
                                ...filterData,
                                city_id: data.id,
                              });
                            }}
                          />
                          <label
                            htmlFor={"city_Architecture" + i}
                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                          >
                            {data?.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};

export default CollegeFilterBox;
