import React, { useState } from "react";

const ScholarshipBox = ({ data, cityData }) => {
  const [showColleges, setShowColleges] = useState(false);

  const handleToggleClick = () => {
    setShowColleges(!showColleges);
  };

  return (
    <div className="college_blk">
      <div className="collegeName">
        <h3
          className="cursor-pointer"
          onClick={() => data?.website_url && window.open(data.website_url)}
        >
          {data?.name}
        </h3>
        {/* <label className="tick font-14 font-500">
          <i className="icon-correct-invert blue-text me-0"></i>
          21 Students Shortlisted
        </label> */}
      </div>
      <div className="college_detail">
        <div className="college_detail_price_deadline">
          {data?.amount == "0" ? null : (
            <div className="college_detail_price">
              {data?.currency_data?.currency_logo && (
                <div className="icon fee me-2">
                  <img
                    src={data?.currency_data?.currency_logo}
                    className=""
                  ></img>
                </div>
              )}
              <div className="font-13 font-500">
                Amount
                <label className="font-16 d-block text-white">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data?.currency_data?.currency_unicode_character,
                    }}
                  ></span>{" "}
                  {data?.amount}
                </label>
              </div>
            </div>
          )}

          {data?.sessions?.length > 0 && (
            <div className="college_detail_deadline">
              <div className="icon medal me-2">
                <i className="icon-calender-time"></i>
              </div>

              <div className="font-13 font-500">
                Deadline
                <label className="font-16 d-block text-white">
                  {new Date(data?.sessions[0]?.date_to).toDateString()}
                </label>
              </div>
            </div>
          )}
          {data?.colleges?.length > 0 && (
            <a
              className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-2 max-170"
              onClick={handleToggleClick}
            >
              <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                {data?.colleges?.length} Colleges
              </span>
            </a>
          )}
        </div>
        {data?.colleges?.length ? (
          <>
            {showColleges && (
              <>
                {data?.colleges?.map((a, i) => {
                  return (
                    <div className="college-list mt-3">
                      <div className="college">
                        <div className="college-brife">
                          <label>
                            <a
                              onClick={() =>
                                a?.website_url && window.open(a?.website_url)
                              }
                              className="font-16 font-500"
                            >
                              {a?.name}
                            </a>
                          </label>
                          <div className="font-14 font-500 fst-italic text-gray-41">
                            {cityData.find((x) => x.id === a.city_id)?.name}
                          </div>
                        </div>
                        <div className="share">
                          <a
                            className="font-16 font-500"
                            onClick={() =>
                              a?.website_url && window.open(a?.website_url)
                            }
                          >
                            <i className="font-14 icon-anchor"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="w-100 text-center">
                  <a className="btn btn-viewdetail">
                    <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                      View All Details
                    </span>
                  </a>
                </div> */}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ScholarshipBox;
