export const convertObjToQueryParams = (obj) => {
  let query = "";
  let keys = Object.keys(obj);
  for (let i = 0; i < keys?.length; i++) {
    if (parseInt(i) === parseInt(0)) {
      query += `?`;
    } else {
      query += `&`;
    }
    query += `${keys[i]}=${obj[keys[i]]}`;
  }
  return query;
};

export const toBase64 = (file) => {
  const promise = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader?.result?.split(",")[1]);
    };
    reader.onerror = function (error) {
    };
  });
  return promise;
};
