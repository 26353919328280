import React from "react";
import { Pie } from "react-chartjs-2";

const Chart = ({ data, label }) => {
  return (
    <div className="dashboard_card">
      <div className="dashboard_card_header">
        <h2>
          <span>{label} Preferences</span>
          {/* <b>
            <img src="/assets/images/info.svg" width="16" />
          </b> */}
        </h2>
        {/* <div className="custom_select">
          <select className="form-select" name="complete Class 12">
            <option value="">Class</option>
          </select>
        </div> */}
      </div>
      <div className="dashboard_card_body d-flex justify-content-center">
        <div className="mt-4" style={{ width: "280px", height: "280px" }}>
          <Pie data={data} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
