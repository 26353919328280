import apiHeader from "../apiHeader";
import api from "../axiosService";

export const getStudentColleges = async () => {
  const res = await api.post(
    "student/getAllStudentPreferdCollege",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const addExploreFeeData = async (payload) => {
  const res = await api.post("college/exploreFee", payload, {
    headers: apiHeader(),
  });
  return res;
};
export const viewData = async (payload) => {
  const res = await api.get(
    "college/getCollegesWithFee",

    {
      headers: apiHeader(),
      params: payload,
    }
  );
  return res;
};
export const getColleges = async (payload) => {
  const res = await api.post("college/getAllCollege", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getIntakes = async () => {
  const res = await api.post(
    "intake/getAllIntake",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const addPreferedCollege = async () => {
  const res = await api.post(
    "student/addStudentPreferdCollege",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getCountries = async (params) => {
  const res = await api.get(
    "country/getAllCountry",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};
export const getCategory = async () => {
  const res = await api.get(
    "category/getAllCategory",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};
export const getLevels = async (payload) => {
  const res = await api.post("level/getAllLevels", payload, {
    headers: apiHeader(),
  });
  return res;
};

export const getPreferedCountries = async () => {
  const res = await api.post(
    "student/getAllStudentPreferdCountry",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getPreferedIntakes = async () => {
  const res = await api.get("student/getStudentPreferredIntake", {
    headers: apiHeader(),
  });
  return res;
};

export const getPreferedCareers = async () => {
  const res = await api.get(
    "student/getStudentCareer",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const addStudentPreferedCollege = async (data) => {
  const res = await api.post("student/addStudentPreferdCollege", data, {
    headers: apiHeader(),
  });
  return res;
};
export const addStudentPreferedCountries = async (data) => {
  const res = await api.post("student/addStudentPreferdCountry", data, {
    headers: apiHeader(),
  });
  return res;
};

export const addStudentPreferedCareers = async (id) => {
  const res = await api.post("student/addStudentCareer", id, {
    headers: apiHeader(),
  });
  return res;
};

export const addStudentPreferedCourse = async (id) => {
  const res = await api.post("student/addStudentPreferdCourse", id, {
    headers: apiHeader(),
  });
  return res;
};

export const removeCollege = async (id) => {
  const res = await api.post("student/removeStudentPreferdCollege", id, {
    headers: apiHeader(),
  });
  return res;
};
export const removeCountry = async (id) => {
  const res = await api.post("student/removeStudentPreferdCountry", id, {
    headers: apiHeader(),
  });
  return res;
};

export const getCareers = async () => {
  const res = await api.get(
    "career/getAllCareer",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const getAllParentCourse = async (params) => {
// export const getAllParentCourse = async () => {
  const res = await api.get(
    "course/getAllParentCourse",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};

export const getAllSubCourse = async (params) => {
  const res = await api.get(
    "course/getAllSubCourse",

    {
      headers: apiHeader(),
      params: params,
    }
  );
  return res;
};

export const getAllStudentPreferdCourse = async () => {
  const res = await api.post(
    "student/getAllStudentPreferdCourse",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const createCareers = async () => {
  const res = await api.post(
    "career/createCareer",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const createIntakes = async () => {
  const res = await api.post(
    "intake/createIntake",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const addStudentIntake = async (data) => {
  const res = await api.post("student/updateStudentPrefereedIntake", data, {
    headers: apiHeader(),
  });
  return res;
};

export const removeStudentCareers = async (data) => {
  const res = await api.post(
    "student/removeStudentCareer",data,
    {
      headers: apiHeader(),
    }
  );
  return res;
};

export const createColleges = async () => {
  const res = await api.post(
    "college/createCollege",
    {},
    {
      headers: apiHeader(),
    }
  );
  return res;
};
