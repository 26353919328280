import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  getStudentDetails,
  updateProfilePic,
} from "../../services/student/student-profile";
import { fileUpload } from "../../services/util/util-service";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const ProfileSidebar = ({ handleAccordionClick, isActive }) => {
  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const uploadRef = useRef(null);
  const location = useLocation();
  const [details, setDetails] = useState();
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  const StudentProfileDetail = async () => {
    await getStudentDetails(id && { student_id: id }).then((res) => {
      if (res && res.data?.data) {
        setDetails(res.data?.data);
      }
    });
  };
  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData).then((res) => {
      if (res && res.data && res?.data?.data?.fileLink) {
        uploadProfilePic(res?.data?.data?.fileLink);
      }
    });
  };
  const uploadProfilePic = async (link) => {
    const res = await updateProfilePic({
      profile_pic: link,
      student_id: id ? id : "",
    });
    if (res?.data?.meta?.status) {
      user["profile_pic"] = res.data.data;
      setUserData(user);
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  const handleImport = async (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (
      file?.type?.includes("image/jpeg") ||
      file?.type?.includes("image/png")
    ) {
      imageUpload(file);
    } else {
      toast.error("Image must ba valid .jpeg file");
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleAccordionClick(location?.state?.id);
    }
  }, []);

  useEffect(() => {
    if (id) {
      StudentProfileDetail();
    }
  }, [id]);

  return (
    <div className="leftpane sticky-top z-0">
      <div className="accordion disable" id="accordionProfileChange">
        <div className="accordion-item">
          <h2 className="accordion-header" id="ProfileChange">
            <button
              className="accordion-button bg-white d-flex align-items-center p-3 border-0 w-100"
              type="button"
            >
              <div className="profileimg">
                <span className="cursor-pointer">
                  <i className="edit mt-3"></i>
                  {userData?.profile_pic ? (
                    <img
                      src={userData?.profile_pic}
                      className="img-fluid"
                      width="40"
                      height="auto"
                      alt="ellipse"
                    />
                  ) : (
                    <div
                      data-initials={
                        id
                          ? details?.fname[0].toUpperCase()
                          : userData?.fname[0]?.toUpperCase() + id
                          ? details?.lname[0].toUpperCase()
                          : userData?.lname[0]?.toUpperCase()
                      }
                    ></div>
                  )}
                </span>
              </div>
              <div className="nameclassName ms-2">
                <h3 className="font-14 font-500 m-0 text-start">
                  {`${id ? details?.fname : userData?.fname} ${
                    id ? details?.lname : userData?.lname
                  }`}
                </h3>
                <h4 className="font-12 font-500 m-0 text-start">
                  {id ? details?.email : userData?.email}
                </h4>
              </div>
              <span className="d-flex profile-icons">
                <span className="icon icon-left-arrow ms-auto d-flex align-items-center font-9"></span>
                <span className="icon icon-arrow-right ms-auto d-flex align-items-center font-9"></span>
              </span>
            </button>
          </h2>
        </div>
        <p
          style={{
            backgroundColor: "rgb(243, 173, 24)",
            borderRadius: "50%",
            position: "relative",
            zIndex: "9999",
            height: "21px",
            width: "21px",
            top: "12px",
            left: "46px",
          }}
        >
          <span
            style={{
              position: "absolute",
              zIndex: "9999",
              top: "4px",
              left: "5px",
              color: "black",
              fontSize: "12px",
            }}
            className="icon-edit-pencil"
            onClick={(e) => {
              e.preventDefault();
              uploadRef?.current?.click();
            }}
          ></span>
        </p>
      </div>

      <div className="sticky-top">
        <div className="sidelink mt-5">
          <ul className="text-start">
            <li>
              <span
                data-url="#basicinformation"
                className="font-16 radius-20 d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#basicinformation-content"
                aria-expanded={
                  parseInt(isActive) === parseInt(0) ? true : false
                }
                aria-controls="basicinformation-content"
                onClick={() => handleAccordionClick(0)}
              >
                Basic Information
              </span>
            </li>
            <li>
              <span
                data-url="#preferences"
                className="font-16 radius-20 d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#preferences-content"
                aria-expanded={
                  parseInt(isActive) === parseInt(1) ? true : false
                }
                aria-controls="preferences-content"
                onClick={() => handleAccordionClick(1)}
              >
                Preferences
              </span>
            </li>
            <li>
              <span
                data-url="#educationdetails"
                className="font-16 radius-20 d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#educationdetails-content"
                aria-expanded={
                  parseInt(isActive) === parseInt(2) ? true : false
                }
                aria-controls="educationdetails-content"
                onClick={() => handleAccordionClick(2)}
              >
                Education Details
              </span>
            </li>
            <li>
              <span
                data-url="#workexperience"
                className="font-16 radius-20 d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#workexperience-content"
                aria-expanded={
                  parseInt(isActive) === parseInt(3) ? true : false
                }
                aria-controls="workexperience-content"
                onClick={() => handleAccordionClick(3)}
              >
                Entrance Exams
              </span>
            </li>
            <li>
              <span
                data-url="#extracurricularsachievements"
                className="font-16 radius-20 d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#extracurricularsachievements-content"
                aria-expanded={
                  parseInt(isActive) === parseInt(4) ? true : false
                }
                aria-controls="extracurricularsachievements-content"
                onClick={() => handleAccordionClick(4)}
              >
                Extra Curricular & Achievements
              </span>
            </li>
            {id ? (
              <li>
                <span
                  data-url="#manage-access"
                  className="font-16 radius-20 d-flex align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#manage-access-content"
                  aria-expanded={
                    parseInt(isActive) === parseInt(5) ? true : false
                  }
                  aria-controls="manage-access-content"
                  onClick={() => handleAccordionClick(5)}
                >
                  Manage Access
                </span>
              </li>
            ) : null}{" "}
          </ul>
        </div>
      </div>
      <input
        hidden
        ref={uploadRef}
        type="file"
        name="upload"
        id="upload"
        onChange={handleImport}
      />
    </div>
  );
};

export default ProfileSidebar;
