import React from "react";
import { Card } from "react-bootstrap";
import { MdOutlineErrorOutline } from "react-icons/md";

const UserFailed = () => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "calc(100vh - 172px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="mt-4 pb-4">
          <div className="d-flex justify-content-center align-items-center">
            <div className="">
              <Card
                className="text-center"
                style={{
                  height: "475px",
                  width: "490px",
                  border: "none",
                  boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                  padding: "10px",
                }}
              >
                <div className="icon-div d-flex justify-content-center mt-4">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "rgb(244 230 228)",
                      borderRadius: "50%",
                      height: "155px",
                      width: "155px",
                    }}
                  >
                    <MdOutlineErrorOutline
                      style={{
                        height: "95px",
                        width: "95px",
                        color: "red",
                      }}
                    />
                  </div>
                </div>

                <h5 className="text-center mt-5 text-danger">
                  <p className="font-24 mb-4">Payment Failed !</p>
                  Don't worry, your funds are secure! If money was deducted from
                  your account, it will be promptly returned within 5-7 working
                  days.
                </h5>

                <button
                  className="mt-4"
                  style={{
                    margin: "15px",
                    padding: "10px 10px",
                    color: "#FFFF",
                    backgroundColor: "red",
                    border: "none",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                >
                  Try Again
                </button>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFailed;
