import React from "react";
import { Accordion } from "react-bootstrap";

const ExploreAccordion = ({ data }) => {
  return (
    <React.Fragment>
      <div className="accord">
      <div className="college-accord">
        <Accordion className="college_blk mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="collegeName d-flex flex-wrap">
              <div className="d-flex flex-wrap">
                {/* <div className="select-toggle">
                  <a className="btn-add-toggle"></a>
                  <a className="btn-select-toggle"></a>
                </div> */}
                <div className="college_blk_left">
                  <div className="colllegeLogo me-3">
                    <img
                      src="/assets/images/university_melbourne.jpg"
                      alt="university melbourne"
                      width="70"
                      className="img-fluid"
                    />
                  </div>
                  <div className="collegeNameDetail flex-1">
                    <h3 className="mb-0">{data?.uniName}</h3>
                    <label className="tick font-13 font-500 d-block">
                      Rank: #1st India Today
                    </label>
                    <label className="tick font-14 font-500 me-3">
                      <i className="icon-location-pin blue-text mt-1 me-2"></i>
                      Toronto, Ontario, Canada
                    </label>
                    <label className="tick font-14 font-500 me-3">
                      <i className="icon icon-correct-invert blue-text mt-1 me-2"></i>
                      4021
                    </label>
                    <label className="tick font-14 font-500">
                      <i className="icon icon-hat blue-text mt-1 me-2"></i>
                      158 Alumni Joined
                    </label>
                    <div className="college_detail_price_deadline d-block w-100 mt-3">
                      <div className="row g-4 w-auto mb-0">
                        <div className="col-md-auto col-auto">
                          <div className="college_detail_price w-100 d-flex flex-wrap">
                            <div className="icon fee me-2">
                              <i className="icon-dollor"></i>
                            </div>
                            <div className="font-13 font-500">
                              Fees/Year (Full Time)
                              <label className="font-16 d-block">
                                ₹356,000
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-auto col-auto">
                          <div className="college_detail_price w-100 d-flex flex-wrap">
                            <div className="icon medal me-2">
                              <i className="icon-loan"></i>
                            </div>
                            <div className="font-13 font-500">
                              Scholarships
                              <label className="font-16 d-block">#34</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="college_blk_right">
                  <div className="toggle-link">
                    <a
                      className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center align-items-center"
                  
                    >
                      <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                        View Entrance Exam
                      </span>
                      <span className="icon-down-arrow text-white font-13 ms-2"></span>
                    </a>
                    <i></i>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <div className="college-list">
                <div className="college_detail">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <div className="listed-subject">
                        <ul>
                          <li>JEE Main</li>
                          <li>JEE (Advanced)</li>
                          <li>NATA</li>
                        </ul>
                        <div className="cut-off">
                          Admission Based on Cut Offs
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="college">
                        <div className="college-brife m-0">
                          <label>
                            <a className="font-18 font-500">
                              B.Tech. Aerospace Engineering
                            </a>
                          </label>
                          <div className="text-gray-41 d-flex justify-content-between mt-1">
                            <div className="college-name flex-1 me-3 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Fees:
                              </b>
                              ₹149,005 / Yearly
                            </div>
                            <div className="duration flex-1 font-16 font-500">
                              <b className="d-block font-14 font-500 text-dark-blue">
                                Duration:
                              </b>
                              4 years
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-100 text-center">
                    <a className="btn btn-viewdetail">
                      <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                        View All Details
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      </div>
    </React.Fragment>
  );
};

export default ExploreAccordion;
