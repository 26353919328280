import React, { useEffect, useState } from "react";
import ToDoList from "./Dashboard-Components/todo-list";
import RecentActivity from "./Dashboard-Components/recent-activity";
import Psychometric from "./Dashboard-Components/psychometric";
import UpcomingEvents from "./Dashboard-Components/upcoming-events";
import {
  getProductToken,
  getStudentActivities,
  getStudentProducts,
} from "../../../services/dashboard/dashboard-services";
import { getAllEvents } from "../../../services/meeting-events/meeting-events";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";
import { getAllProducts } from "../../../services/products/product-service";

const Dashboard = () => {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [activities, setActivities] = useState([]);
  const [products, setProducts] = useState([]);
  const [testToken, setTestToken] = useState();
  const navigate = useNavigate();
  const [reqData, setReqData] = useState({
    req_from: "student",
    school_ref_id: "5493",
    login_ref_id: "123456",
    class: "9",
    board_ref_id: "1",
    upcoming_past: "1",
    eve_types: "",
    eve_presenter: "",
    eve_themes: "",
    eve_test: "",
    eve_skill: "",
    eve_country: "",
    eve_university: "",
    eve_career: "",
    eve_department: "",
    start: "0",
    limit: 6,
  });

  const getRecentActivity = async () => {
    await getStudentActivities().then((res) => {
      if (res?.data?.data) {
        setActivities(res.data?.data);
      }
    });
  };

  const allEvents = async () => {
    await getAllEvents(reqData).then((res) => {
      if (res?.data?.data?.response?.result) {
        setEvents(res.data?.data?.response?.result);
      }
    });
  };

  const getAllProducts = async () => {
    const res = await getStudentProducts();
    if (res?.data?.data?.records) {
      setProducts(res.data?.data?.records);
    }
  };

  useEffect(() => {
    getRecentActivity();
    getAllProducts();
    allEvents();
  }, []);

  const takeTest = (product) => {
    if (product?.test_url) {
      navigate("/student/test-details/" + product?.user_product_id);
    } else {
      navigate("/student/test-details/" + product?.user_product_id);
    }
  };

  return (
    <React.Fragment>
      <div className="main flex-1">
        <div className="max-1266 mx-auto h-100">
          <div className="">
            <div className="d-flex justify-content-between align-items-start pt-4 pb-4">
              <div className="welcome-title">
                <h1>
                  Welcome to {user?.fname} {user?.lname}
                </h1>
                <p>Here is the platform summary for you!</p>
              </div>
            </div>

            <div className="row g-4 platform_summary">
              <div className="col-md-6">
                <RecentActivity activities={activities} />
              </div>
              <div className="col-md-6">
                <Psychometric
                  products={products}
                  takeTest={(product) => takeTest(product)}
                />
              </div>
              <div className="col-md-12">
                <UpcomingEvents events={events} />
              </div>

              <div className="col-md-12 mb-4 text-center">
                <button
                  onClick={() => navigate("/student/meetings")}
                  className="btn-viewall"
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
