import React, { useState } from "react";
import ScholarshipSearch from "../../../Components/ScholarshipSearch/scholarship-search";

const Scholarship = () => {
  return (
    <React.Fragment>
      <ScholarshipSearch />
    </React.Fragment>
  );
};

export default Scholarship;
