import React, { useEffect, useState } from "react";

import { Card, Nav, Tab } from "react-bootstrap";
import UgAspirants from "./Dashboard-Components/ug-aspirants";
import PgAspirants from "./Dashboard-Components/pg-aspirants";
import SmartApply from "./Dashboard-Components/smart-apply";
import Products from "./Dashboard-Components/dashboard-products";
import {
  getStudentCourseReports,
  getStudentReports,
} from "../../../services/dashboard/dashboard-services";
import { useLocation } from "react-router-dom";

const MyDashboard = () => {
  const [activeKey, setActiveKey] = useState("ug");
  const [reports, setReports] = useState([]);
  const [typeData, setTypeData] = useState("ug");
  const [courseReport, setCourseReport] = useState([]);
  const type = new URLSearchParams(useLocation().search).get("type");
  useEffect(() => {
    if (type && type === "ug") {
      setActiveKey("ug");
    } else if (type && type === "pg") {
      setActiveKey("pg");
      // setFilterData({
      //   ...filterData,
      //   has_ranking: 2,
      // });
    
    }
  }, [type]);
  const getAllReports = async () => {
    let payload = {
      type: typeData,
    };
    const res = await getStudentReports(payload);
    if (res?.data?.data) {
      setReports(res.data.data);
    }
  };

  const studentCourseReport = async () => {
    let payload = {
      type: typeData,
    };
    const res = await getStudentCourseReports(payload);
    if (res?.data?.data) {
      setCourseReport(res.data.data);
    }
  };

  useEffect(() => {
    getAllReports();
    studentCourseReport();
  }, [typeData]);

  return (
    <div className="flex-1">
      <div className="mt-4 pb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="h-100 p-0">
              <div className="m-w-90 d-flex justify-content-between align-items-center mt-3 mb-3">
                <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                  My Dashboard
                </h3>
              </div>

              <Card style={{ border: "none" }}>
                <Nav variant="pills" className="job_internship_tab polartabsec">
                  <Nav.Item eventKey="ug">
                    <ul className="nav nav-tabs">
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveKey("ug");
                            setTypeData("ug");
                          }}
                          className={
                            activeKey === "ug" ? "nav-link active" : "nav-link"
                          }
                          id="Ug-aspirants-tab"
                        >
                          UG Aspirants
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className={
                            activeKey === "pg" ? "nav-link active" : "nav-link"
                          }
                          id="Pg-aspirants-tab"
                          onClick={() => {
                            setActiveKey("pg");
                            setTypeData("pg");
                          }}
                        >
                          PG Aspirants
                        </button>
                      </li> */}
                      {/* <li className="nav-item" role="presentation">
                          <button
                            className={
                              activeKey === "smart"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="Smart-tab"
                            onClick={() => setActiveKey("smart")}
                          >
                            Smart Apply
                          </button>
                        </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            activeKey === "products"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="Product-tab"
                          onClick={() => setActiveKey("products")}
                        >
                          Products
                        </button>
                      </li>
                    </ul>
                  </Nav.Item>
                </Nav>
              </Card>

              <div className="row pt-4 pb-3 JobInternshipListing">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="ug"
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="ug">
                      <UgAspirants
                        reports={reports}
                        courseReport={courseReport}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="pg">
                      <PgAspirants
                        reports={reports}
                        courseReport={courseReport}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="smart">
                      <SmartApply />
                    </Tab.Pane>

                    <Tab.Pane eventKey="products">
                      <Products />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
