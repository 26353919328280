import React from "react";
import { Accordion } from "react-bootstrap";
import { convertUnicode } from "../../services/util/util-service";

const CollegeAccordion = ({ data }) => {
  return (
    <React.Fragment>
      <div className="college-accord">
        <Accordion className="college_blk mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="collegeName d-flex flex-wrap">
              <div className="d-flex flex-wrap">
                <div className="colllegeLogo me-3">
                  <img
                    src={data?.logo}
                    alt="university melbourne"
                    width="84"
                    className="img-fluid"
                  />
                </div>
                <div className="collegeNameDetail flex-1">
                  <h3
                    className="mb-0"
                    onClick={() =>
                      data?.website_url && window.open(data.website_url)
                    }
                  >
                    {data?.name}
                  </h3>

                  <label className="tick font-14 font-500 me-3">
                    <i className="icon-location-pin blue-text  mt-1 me-2"></i>
                    {data?.city},{data?.state},{data?.country}
                  </label>
                </div>
                <div className="college_detail_price_deadline d-block w-100 mt-3">
                  <div className="row w-auto mb-0">
                    <div className="col-md-auto col-sm-6 mb-4 mb-md-0">
                      {data.average_tuition_fee != "0" && (
                        <div className="college_detail_price w-100 d-flex flex-wrap">
                          {data?.currency_logo && (
                            <div className="icon fee me-2">
                              <img src={data?.currency_logo} className=""></img>
                            </div>
                          )}

                          <div className="font-13 font-500">
                            Average Tuition Fee / Year
                            <label className="font-16 d-block">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.currency_unicode_character,
                                }}
                              ></span>{" "}
                              {data.average_tuition_fee}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-md-auto col-sm-6 mb-4 mb-md-0">
                      <div className="college_detail_price w-100 d-flex flex-wrap">
                        <div className="icon medal me-2">
                          <i className="icon-open-book"></i>
                        </div>
                        <div className="font-13 font-500">
                          Admission Chance
                          <label className="font-16 d-block">15%</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <div className="college-list">
                <div className="college_detail">
                  <div className="row g-3">
                    {data?.rankings?.map((z, i) => {
                      return (
                        <div className="col-lg-3 col-md-6 col-6" key={i}>
                          <div className="college px-3 h-100">
                            <div className="college-brife m-0 h-100 d-flex flex-column justify-content-between">
                              <label className="text-center w-100">
                                <a className="font-15 font-500 text-center min-h-45 d-flex align-items-center justify-content-center">
                                  {z.ranking_given_by}
                                </a>
                              </label>
                              <div className="my-3 font-14 font-500 text-center">
                                {z?.year}
                              </div>
                              <div className="font-16 font-500 text-center">
                                {z.ranking_from} {z.ranking_to ? "-" : null}{" "}
                                {z.ranking_to}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row g-3 mt-2">
                    <div className="col-lg-6 col-md-6">
                      <div className="colondetail">
                        <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            Year of Establishment
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data.establish_year ? data.establish_year : "-"}
                          </div>
                        </div>
                        <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            Total Students
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data.student_count ? data.student_count : "-"}
                          </div>
                        </div>
                        <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            No. of relevant Courses
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data?.course_count}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="colondetail">
                        <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            Related Scholarships
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data.scholrship_count
                              ? data.scholrship_count
                              : "-"}
                          </div>
                        </div>
                        <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            Acceptance Rate
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data.acceptance_ratio
                              ? data.acceptance_ratio
                              : "-"}
                          </div>
                        </div>
                        {/* <div className="colondetailRow">
                          <div className="colondetailRowLabel">
                            International Student
                          </div>
                          <span className="colon">:</span>
                          <div className="colondetailRowBrife">
                            {data.percent_international_student}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default CollegeAccordion;
