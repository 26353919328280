import React from "react";

const Psychometric = ({ products, takeTest }) => {
  return (
    <div className="platform_summary">
      <div className="card">
        <div className="card-header">
          <h3>Your Assessments</h3>
        </div>
        <div className="card-body">
          {products.length ? (
            <div className="toDO-list">
              <ul>
                {products?.map((p) => {
                  return (
                    <li>
                      <span>{p?.product_name}</span>
                      {p.completed_at ? (
                        <button
                          onClick={() =>
                            p?.report_url && window.open(p.report_url)
                          }
                          className="btn-viewReport"
                        >
                          View Report
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => takeTest(p)}
                          className="btn-takeTest"
                        >
                          Take Test
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <p>No Assessment Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Psychometric;
