import React from "react";
import { useNavigate } from "react-router-dom";

const HomeCard = ({ data, index }) => {
  const navigate = useNavigate();
  const renderRate = (index) => {
    if (index < data.rate) {
      return <li className="completed"></li>;
    } else {
      return <li></li>;
    }
  };

  return (
    <div className="milestone">
      <div className="icon">
        <span className="">{data.icon}</span>
      </div>
      <div className="count">{index + 1}.</div>
      <h3>{data.title}</h3>
      <ul className="progress-status">
        {new Array(data.totalRate).fill("").map((item, index) => {
          return renderRate(index);
        })}
      </ul>
      <div className="activities_status">
        {data.sub_title}
        {data.hoverData ? (
          <>
            <div className="activities_status_hovercont">
              <div>
                <ol type="1">
                  {" "}
                  {data.hoverData?.map((item, index) => {
                    return <li>{item}</li>;
                  })}{" "}
                </ol>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <span
        className="btn btn-complete-now"
        onClick={() => navigate(data.link)}
      >
        <span>Complete Now</span>
      </span>
    </div>
  );
};

export default HomeCard;
