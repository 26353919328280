import React from "react";

const ExploreFilterBox = () => {
  return (
    <div className="filterGroup" id="accordionFilters">
      <div className="fiterItem mb-3">
        <div className="filterHeader">
          <div className="d-flex justify-content-between align-items-center position-relative">
            <button
              className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
              type="button"
            >
              <span className="font-18 font-600">Shortlisted Course(s)</span>
              <span className="toggle"></span>
            </button>
          </div>
        </div>
        <div className="collapse" id="collapseShortlistedCourse">
          <div className="card card-body mt-2">
            <div className="overflow-hidden">
              <div className="scrollMe">
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="course"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Architecture
                  </label>
                </div>
                <div className="form-check d-flex flex-wrap">
                  <input className="form-check-input me-2" type="radio" />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Engineering & Technology
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fiterItem mb-3">
        <div className="filterHeader">
          <div className="d-flex justify-content-between align-items-center position-relative">
            <button
              className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
              type="button"
            >
              <span className="font-18 font-600">Subcourse</span>
              <span className="toggle"></span>
            </button>
          </div>
        </div>
        <div className="collapse show" id="collapseSubcourse">
          <div className="card card-body">
            <div className="overflow-hidden">
              <div className="scrollMe">
                <div className="form-check d-flex flex-wrap">
                  <input className="form-check-input me-2" type="radio" />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Mechanical Engineering
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fiterItem mb-3">
        <div className="filterHeader">
          <div className="d-flex justify-content-between align-items-center position-relative">
            <button
              className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
              type="button"
            >
              <span className="font-18 font-600">Shortlisted Country(s)</span>
              <span className="toggle"></span>
            </button>
          </div>
        </div>
        <div className="collapse" id="collapseCountry">
          <div className="card card-body">
            <div className="overflow-hidden">
              <div className="scrollMe">
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Germany
                  </label>
                </div>
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    India
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fiterItem mb-3">
        <div className="filterHeader">
          <div className="d-flex justify-content-between align-items-center position-relative">
            <button
              className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
              type="button"
            >
              <span className="font-18 font-600">College Types</span>
              <span className="toggle"></span>
            </button>
          </div>
        </div>
        <div className="collapse" id="collapseCollegeTypes">
          <div className="card card-body">
            <div className="overflow-hidden">
              <div className="scrollMe">
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Aspirational
                  </label>
                </div>
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Best Fit
                  </label>
                </div>
                <div className="form-check d-flex flex-wrap">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                    Safe
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreFilterBox;
