import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkillCard from "../../../Components/SkillsCard/skill-card";
import { skills } from "../../../services/skills/skills";

const LifeSkills = () => {
  const navigate = useNavigate();
  const [skillData, setSkillData] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState();

  const allSkills = async () => {
    await skills().then((res) => {
      if (res?.data?.data) {
        setSkillData(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    allSkills();
  }, []);

  return (
    <>
      <div className="flex-1 d-flex flex-column container">
        <div className="flex-1">
          <div className="mt-4 pb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="h-100 p-0">
                  <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                    <h3
                      data-aos="fade-left"
                      data-aos-delay="600"
                      className="font-32 font-600 mb-1 block-title aos-init aos-animate"
                    >
                      Skills List
                    </h3>
                    <p className="font-16 font-400 mb-2">
                      Life Skills are for everyone, be it a student in school or
                      college, or a working professional!
                    </p>
                  </div>

                  <div className="skill-wrap">
                    <div className="skill-range">
                      <h2 className="font-26 font-500 text-center mb-5">
                        Select a skill you want to acquire
                      </h2>
                    </div>
                    <form>
                      <div className="select-skill row">
                        <div className="d-flex justify-content-center flex-wrap text-center select-skill-container p-0">
                          {skillData?.map((val, index) => {
                            return (
                              <div className="select" key={index}>
                                <SkillCard
                                  data={val}
                                  setSelectedSkill={setSelectedSkill}
                                  selectedSkill={selectedSkill}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-16 font-500"
                        onClick={() => {
                          if (selectedSkill) {
                            navigate(
                              "/student/skill-detail/" + selectedSkill?.id
                            );
                          }
                        }}
                      >
                        <span>Procced</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeSkills;
