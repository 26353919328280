import React, { useState } from "react";

const CareerBox = ({ data }) => {
  const [isShowMore, setIsShowMore] = useState(true);
  const toggleActive = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <React.Fragment>
      <div className="album_blk">
        <div className="album_imgblk position-relative">
          <img src="/assets/images/mask-group-47.png" alt="" />
        </div>
        <div className="album_contblk eventsbox">
          <div className="d-flex flex-wrap justify-content-between align-items-start">
            <div className="flex-1">
              <h3 className="font-18 font-600 mb-2">{data?.eventName}</h3>
              <p className="font-15 line-height-23">
                {isShowMore ? data?.eventDesc?.slice(0, 70) : data?.eventDesc}
              </p>
              {data?.eventDesc && data?.eventDesc?.length > 70 && (
                <span
                  className="green-link text-decoration-underline"
                  style={{ cursor: "pointer" }}
                  onClick={toggleActive}
                >
                  {isShowMore ? "Read More" : "Read less"}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CareerBox;
