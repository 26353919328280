import React, { useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";

const ForgotPassword = ({ setIsForgot }) => {
  const { userForgotPassword } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("tab1");
  const [validated, setValidated] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [payload, setPayload] = useState({
    email: "",
  });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    let data = {
      email: payload.email,
      user_type: "counselor",
    };
    const res = await userForgotPassword(data);
    setValidated(true);
    if (res && res.data?.meta?.status) {
      // navigate("/user-reset-password");
    }
  };

  return (
    <>
      <div
        className="col-lg-6 col-md-6 col-sm-12"
        style={{ paddingRight: "0" }}
      >
        <div className="mibile-logo d-block d-md-none p-4 text-center">
          <img
            src="/assets/images/miles.jpg"
            width="160"
            className="img-fluid"
            alt="miles"
          />
        </div>
        <div
          className="signup h-100 container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="tab-pane nav-link" style={{ width: "70%" }}>
            <h2
              className="text-white text-center mb-4"
              style={{ position: "relative", zIndex: "1" }}
            >
              Forgot Password
            </h2>

            <div className="">
              <Form
                noValidate
                className="needs-validation"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <div className="form-group mb-3 form-control-email">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    value={payload.email}
                    onChange={(e) =>
                      setPayload({ ...payload, email: e.target.value })
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label
                        htmlFor="button2"
                        className="text-white font-14 font-500 cursor-pointer"
                        onClick={() => setIsForgot(false)}
                        style={{ zIndex: 1, position: "relative" }}
                      >
                        Back to login?
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-14 font-500"
                  style={{ background: "#5cae48" }}
                >
                  <span>Submit</span>
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
