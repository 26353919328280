import React from "react";
import { useNavigate } from "react-router";

const ProductsItem = ({ data, addToCart }) => {
  const navigate = useNavigate();

  return (
    <div className="product-item">
      <span className="discount">
        {data.discount_type === "flat" && data.discount
          ? "Flat ₹" + data.discount.toFixed(2) + " Off"
          : data.discount + "% Off"}
      </span>
      <div className="product-item-img">
        <img
          src={data?.image ? data.image : "/assets/images/showcase_1.png"}
          alt=""
          style={{ height: "350px" }}
        />
      </div>
      <div className="product-item-detail">
        <h3 className="product-title">{data?.name}</h3>

        <div
          className="product-item-pricedetail"
          onClick={() => navigate("/user/product-details/" + data.id)}
        >
          <div className="product-item-price">
            <div className="webprice">
              <span>₹</span>
              {(
                parseInt(data?.price) -
                (data.discount_type === "flat"
                  ? data.discount
                  : (data.discount / 100) * data.price)
              )?.toFixed(2)}
            </div>
            <div className="actualprice">
              <span>₹</span>
              {data?.price.toFixed(2)}
            </div>
          </div>
          {/* <div className="product-item-price-save">
            <div className="product-item-save">
              <span>₹</span>
              2,000
            </div>
            <label className="savelabel">You Earn</label>
          </div> */}
        </div>

        <div className="product-item-buttons-share">
          <button
            className="btn-addToInventory"
            onClick={() => addToCart(data)}
          >
            Add to Inventory
          </button>
          {/* <button className="btn-lead">Refer a Lead</button>
          <div className="share-product">
            <a className="share"></a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductsItem;
