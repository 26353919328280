import React from 'react';
import './App.css';
import AllRoutes from './routes/allRoute';
import { AuthProvider } from './Contexts/auth-context';


function App() {
  return (
    <React.Fragment>
      <AuthProvider>
    <AllRoutes/>
    </AuthProvider>
   </React.Fragment>
  );
}

export default App;
